import React from "react";

import {useMutation, useQuery} from "@apollo/react-hooks";
import {PLACE_ORDER_MUTATION} from "../../Cart/ShippingDetails/mutations";
import {GET_ORDERS_QUERY} from "../../Orders/OrdersList/queries";
import {cloneDeep} from "@apollo/client/utilities/common/cloneDeep";
import {GET_RESTAURANT_QUERY} from "../../Restaurants/RestaurantsList/queries";
import {GET_SUPPLIER_QUERY} from "../../Suppliers/SuppliersList/queries";
import {GET_ALIASES_QUERY} from "../../Cart/ShippingDetails/queries";
import {GET_SHOPPING_CART_QUERY} from "../../Suppliers/SupplierShoppingList/queries";


const GraphQlFetchData = ({
                              supplierTrueId,
                              restaurantTrueId,
                              cartId,
                          }) => {


    const {
        data: cartData,
        error: cartError,
        loading: cartLoading,
        refetch: cartRefetch
    } = useQuery(GET_SHOPPING_CART_QUERY, {
        variables: {
            id: cartId
        }
    });


    const [createOrder, {loading: mutationLoading}] = useMutation(PLACE_ORDER_MUTATION, {
        update(cache, mutationData) {
            try {
                //--Adding the new product to the cache--//

                //Retrieving current products list from cache
                const {orders} = cache.readQuery({query: GET_ORDERS_QUERY});
                //Adding the new product to the list
                const updateOrdersList = cloneDeep(orders);
                updateOrdersList.edges = [{
                    __typename: 'OrderEdge',
                    node: mutationData.data.createOrder.order
                }].concat(orders.edges);

                cache.writeQuery({
                    query: GET_ORDERS_QUERY,
                    data: {orders: updateOrdersList},
                });
            } catch (e) {
                //In this case, the cache is empty, it will be loaded from the API when redirecting to the restaurants list, so we do nothing here
            }
        }
    });

    const [createOrderWithPayment, {loading: paymentLoading}] = useMutation(PLACE_ORDER_MUTATION, {
        update(cache, mutationData) {
            try {
                //--Adding the new product to the cache--//

                //Retrieving current products list from cache
                const {orders} = cache.readQuery({query: GET_ORDERS_QUERY});
                //Adding the new product to the list
                const updatedOrdersList = cloneDeep(orders);
                updatedOrdersList.edges = [{
                    __typename: 'OrderEdge',
                    node: mutationData.data.createWithPaymentOrder.order
                }].concat(orders.edges);

                cache.writeQuery({
                    query: GET_ORDERS_QUERY,
                    data: {orders: updatedOrdersList},
                });
            } catch (e) {
                //In this case, the cache is empty, it will be loaded from the API when redirecting to the restaurants list, so we do nothing here
            }
        }
    });

    const {
        data: restaurantData,
        error: restaurantError,
        refetch: restaurantRefetch
    } = useQuery(GET_RESTAURANT_QUERY, {variables: {id: restaurantTrueId}});

    const {
        data: supplierData,
        error: supplierError,
        loading: supplierLoading,
        refetch: supplierRefetch
    } = useQuery(GET_SUPPLIER_QUERY, {
        variables: {
            id: supplierTrueId,
            restaurantId: restaurantTrueId
        }
    });
    const {
        data: aliasesData,
        error: aliasesError,
        loading: aliasesLoading,
        refetch: aliasesRefetch
    } = useQuery(GET_ALIASES_QUERY, {
        fetchPolicy: "network-only", variables: {
            supplierId: supplierTrueId,
        }
    });


    return (

        {
            supplierError, supplierLoading, supplierData, supplierRefetch,
            aliasesData, aliasesError, aliasesLoading, aliasesRefetch,
            createOrderWithPayment, paymentLoading,
            mutationLoading, createOrder,
            cartData, cartError, cartLoading, cartRefetch,
            restaurantData, restaurantError, restaurantRefetch
        }
    );
};

export default GraphQlFetchData;
