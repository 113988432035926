import {gql} from "apollo-boost";

const ORDER_FIELDS = gql`
    fragment OrderFields on Order {
        id
        _id
        deliveryDate
        supplier {
            _id
            name
            id
        }
        reference
        totalWithVat
        currency
        deliveryFeesWithVat
        restaurant {
            _id
            id
            name
        }
        shippingAddress {
            street
            city
            zipCode
            countryCode
        }
        items {
            edges {
                node {
                    id
                    _id
                    quantity
                    total
                    name
                    image {
                        id
                        background
                        small
                        medium
                        large
                        cover
                    }
                    unit
                    unitPrice
                    unitTotal
                }
            }
        }
        status
        paymentStatus
        statusHistory {
            edges {
                node {
                    status
                    createdAt
                    id
                }
            }
        }
    }
`;

export const GET_ORDER_QUERY = gql`
    
    ${ORDER_FIELDS}
    query getOrder($id: Int!) {
        order(id: $id) {
            ...OrderFields
        }
    }
`;

export const CHANGE_ORDER_STATUS_QUERY = gql`
    ${ORDER_FIELDS}
    mutation changeStatusOrder($id: ID!, $status: String!) {
        changeStatusOrder(input: {id: $id, status: $status}) {
            order {
                ...OrderFields
            }
        }
    }
`;
