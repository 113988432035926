import {useQuery} from "@apollo/react-hooks";
import {GET_SHOPPING_LIST_QUERY, GET_SUPPLIER_PRODUCTS_QUERY} from "../SupplierShoppingList/queries";
import {GET_RESTAURANT_QUERY} from "../../Restaurants/RestaurantsList/queries";
import {GET_SUPPLIER_QUERY} from "../SuppliersList/queries";
import {GET_TAXA_QUERY} from "./queries";

const GraphQlFetchData = ({supplierId, restaurantId}) => {


    const {data: supplierData, error: supplierError, loading: supplierLoading} = useQuery(GET_SUPPLIER_QUERY, {
        variables: {id: supplierId, restaurantId: restaurantId},
    });

    //Wee need cache and network policy to ensure a refresh when a custom product is edited
    const {
        data: shoppingListData,
        error: shoppingListError,
        loading: shoppingListLoading,
    } = useQuery(GET_SHOPPING_LIST_QUERY, {
        variables: {
            supplierId: supplierId,
            restaurantId: restaurantId
        },
        pollInterval: 1000
    });
    //get all products by supplier
    const {loading: isLoadingSupplierProducts, error, data} = useQuery(GET_SUPPLIER_PRODUCTS_QUERY, {
        variables: {
            restaurantId: restaurantId,
            supplierId: supplierId,
            offset: 0,
            limit: 100000
        }

    });
    //get restaurant infos
    const {
        loading: restaurantLoading,
        error: restaurantError,
        data: restaurantData,
    } = useQuery(GET_RESTAURANT_QUERY, {variables: {id: restaurantId}});

    /* query taxon field */
    const {loading: taxonLoading, error: taxonError, data: taxonData} = useQuery(GET_TAXA_QUERY, {
        variables: {supplierId: supplierId, restaurantId: restaurantId},
    });

    return ({
        restaurantData,
        restaurantError,

        data,
        error,
        isLoadingSupplierProducts,

        shoppingListData,
        shoppingListError,

        supplierData,
        supplierError,

        taxonLoading,
        taxonError,
        taxonData
    })
};

export default GraphQlFetchData;
