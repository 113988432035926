import {createSlice} from "@reduxjs/toolkit";

const initialState =
    {
        appIsReadyToUpdate: false,
        appIsUpdating: false,
    };

const updaterSlice = createSlice({
    name: 'updater',
    initialState: initialState,
    reducers: {
        updateReady(state, action) {
            state.appIsReadyToUpdate = true;
        },
        launchUpdate(state, action) {
            state.appIsUpdating = true;
        },
        updated(state, action) {
            state.appIsReadyToUpdate = false;
            state.appIsUpdating = false;
        }
    }
});

export const {updateReady, updated, launchUpdate} = updaterSlice.actions;
export default updaterSlice.reducer;

