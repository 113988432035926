// noinspection JSIgnoredPromiseFromCall

import {useState, useEffect} from 'react';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {GET_SHOPPING_CART_QUERY} from "../SupplierShoppingList/queries";
import {
    ADD_NOTE_ON_CART_ITEM,
    ADD_TO_CART,
    CLEAR_CART,
    CREATE_SHOPPING_CART,
    REMOVE_FROM_CART,
    UPDATE_DELIVERY_DATE
} from "./mutations";

export function useShoppingCart(shoppingCartId, restaurantId, supplierId) {
    const [shoppingCart, setShoppingCart] = useState(undefined);
    const [createShoppingCart, {data: createShoppingCartData, loading: createShoppingCartLoading, error: createShoppingCartError}] = useMutation(CREATE_SHOPPING_CART);
    const [addToShoppingCart, {data: addToShoppingCartData, loading: addToShoppingCartLoading, error: addToShoppingCartError}] = useMutation(ADD_TO_CART);
    const [removeFromShoppingCart, {data: removeFromShoppingCartData, loading: removeFromShoppingCartLoading, error: removeFromShoppingCartError}] = useMutation(REMOVE_FROM_CART);
    const [clearCart, {data: clearCartData, loading: clearCartLoading, error: clearCartError}] = useMutation(CLEAR_CART);
    const [addCartItemNote, {data: addCartItemNoteData, loading: addCartItemNoteLoading, error: addCartItemNoteError}] = useMutation(ADD_NOTE_ON_CART_ITEM);
    const [updateShoppingCart, {data: updateCartData, loading: updateCartLoading, error: updateCartError}] = useMutation(UPDATE_DELIVERY_DATE);
    const {data, loading, error, refetch: refetchShoppingCart} = useQuery(GET_SHOPPING_CART_QUERY, {variables: {id: shoppingCartId}});

    useEffect(() => {
        if (!shoppingCartId && restaurantId && supplierId) {
            if (createShoppingCartData) {
                refetchShoppingCart({id: createShoppingCartData.createShoppingCart.shoppingCart._id});
            }
            //If no cart found for the supplier, init a new one
            else if (!shoppingCart && !createShoppingCartLoading) {
                createShoppingCart({
                    variables: {
                        restaurantId: restaurantId,
                        supplierId: supplierId
                    }
                });
            }
        }
    }, [shoppingCartId, restaurantId, supplierId, createShoppingCartData]);

    useEffect(() => {
        if (addToShoppingCartData && !addToShoppingCartLoading && !addToShoppingCartError) {
            refetchShoppingCart({id: addToShoppingCartData.addToShoppingCart.shoppingCart._id});
        }
    }, [addToShoppingCartData]);

    useEffect(() => {
        if (removeFromShoppingCartData && !removeFromShoppingCartLoading && !removeFromShoppingCartError) {
            refetchShoppingCart({id: removeFromShoppingCartData.removeFromShoppingCart.shoppingCart._id});
        }
    }, [removeFromShoppingCartData]);

    useEffect(() => {
        if (clearCartData && !clearCartLoading && !clearCartError) {
            refetchShoppingCart(clearCartData.clearShoppingCart.shoppingCart._id);
        }
    }, [clearCartData]);

    useEffect(() => {
        if (addCartItemNoteData && !addCartItemNoteLoading && !addCartItemNoteError) {
            refetchShoppingCart(shoppingCart?._id ?? shoppingCartId);
        }
    }, [addCartItemNoteData]);

    useEffect(() => {
        if (!loading && !error && data) {
            setShoppingCart(data.shoppingCart);
        } else {
            setShoppingCart(null);
        }
    }, [data]);

    return {
        shoppingCart,
        refetchShoppingCart,
        addToShoppingCart,
        removeFromShoppingCart,
        clearCart,
        addCartItemNote,
        updateShoppingCart
    };
}
