import {call, put, select, takeEvery} from 'redux-saga/effects';
import {
    bearerTokenExpired,
    denyAccessToUnauthenticatedUser,
    login,
    logout,
    setBearerToken
} from "../reducers/authentication";
import {push} from "connected-react-router";
import {DASHBOARD, LOGIN, RESTAURANTS} from "../constants/Routes";
import {client, persistor} from "../index";
import amplitudeClient from "../amplitudeWrapper";
import {INDIVIDUAL} from "../constants/UserTypes";
import localforage from "localforage";

//@TODO: find a way to avoid using state
function* denyAccess(action) {
    const state = yield select();
    if (!state.authentication.authenticated) {
        yield put(push(LOGIN));
    }
}

function* loginRedirect(action) {
    yield call(() => amplitudeClient.logEvent('user logged in'));
    const state = yield select();
    if (state.authentication.type === INDIVIDUAL) {
        yield put(push(RESTAURANTS));
    } else {
        yield put(push(DASHBOARD));
    }
}

function* clearStoreAndCache(action) {
    console.log('logout', client);
    yield call(async () => {
        await persistor.purge();
        await client.clearStore();
        localforage.clear().then(function () {
            localforage.removeItem('persist:root')
            console.log('Database is now empty.');
        }).catch(function (err) {
            console.log(err);
        });
    });
    yield window.location.replace('/login');
}

//@TODO: Get both token and trigger login action ??
function* refreshToken(action) {
    const state = yield select();
    try {
        const newBearerToken = yield call(fetchNewBearerToken, state.authentication.refresh_token);
        yield put(setBearerToken(newBearerToken));
    } catch (e) {
        yield put(logout());
    }
}

//@TODO: put endpoint url in a config file
//@TODO: log tokens errors ??
export const fetchNewBearerToken = (refreshToken) => {
    return new Promise(((resolve, reject) => {
        const data = new FormData();
        data.append('refresh_token', refreshToken);
        fetch(`${window.apiEndpointUrl}/token/refresh`, {method: 'POST', body: data})
            .then((response) =>
                response.json())
            .then((data) => {
                if (data.token !== '' && data.token !== undefined) {
                    resolve(data.token);
                } else {
                    reject('impossible to get a new token');
                }
            }).catch(e => reject('impossible to get a new token'));
    }));
}

export function* watchDenyAccessToUnauthenticatedUser() {
    yield takeEvery(denyAccessToUnauthenticatedUser, denyAccess)
}

export function* watchLogin() {
    yield takeEvery(login, loginRedirect)
}

export function* watchLogout() {
    yield takeEvery(logout, clearStoreAndCache)
}

export function* watchRefreshToken() {
    yield takeEvery(bearerTokenExpired, refreshToken)
}
