import {createSlice} from "@reduxjs/toolkit";
import {PROFESSIONAL} from "../constants/UserTypes";

const initialState =
    {
        authenticated: false,
        email: null,
        firstName: null,
        bearer_token: null,
        refresh_token: null,
        userType: PROFESSIONAL,
        language: navigator.language ? navigator.language.substr(0,2) : 'fr',
    };

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: initialState,
    reducers: {
        login(state, action) {
            state.email = action.payload.email;
            state.firstName = action.payload.firstName;
            state.bearer_token = action.payload.bearer_token;
            state.refresh_token = action.payload.refresh_token;
            state.authenticated = true;
            state.userType = action.payload.userType;
        },
        logout(state, action) {
            state.email = null;
            state.firstName = null;
            state.bearer_token = null;
            state.refresh_token = null;
            state.authenticated = false;
        },
        setBearerToken(state, action) {
            state.bearer_token = action.payload;
            state.authenticated = true;
        },
        denyAccessToUnauthenticatedUser(state, action) {
        },
        bearerTokenExpired(state, action) {
        },
        selectLanguage(state, action) {
            state.language = action.payload
        }
    }
});

export const {login, logout, denyAccessToUnauthenticatedUser, bearerTokenExpired, setBearerToken, selectLanguage} = authenticationSlice.actions;
export default authenticationSlice.reducer;

