import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputBase from '@material-ui/core/InputBase';
import {blueGrey} from "@material-ui/core/colors";
import {debounce} from "lodash";

const useStyles = makeStyles(theme => ({

    iconButton: {},
    addIcon: {
        fontSize: '3rem',
    },
    removeIcon: {
        fontSize: '3rem',
    },
    input: {
        fontWeight: 900,
        color: blueGrey[800],
        backgroundColor: blueGrey[50],
        borderRadius: 5,
        textAlign: 'center !important',
        width: "40px",
    },
}));

const CartQuantityWidget = ({
                                setProductCartQuantity,
                                unavailable,
                                supplierActive,
                                cartQuantity,
                                product,
                                editMod
                            }) => {
    const [quantity, setQuantity] = useState(cartQuantity);

    const debounced = useRef(
        debounce((newQty) => {
            setProductCartQuantity(newQty);
        }, 300)
    );
    const classes = useStyles();

    // Ensure to sync with server qty
    useEffect(() => {
        setQuantity(cartQuantity);
    }, [cartQuantity]);

    // If state quantity changes, call the debounce method
    useEffect(() => {
        if (quantity !== cartQuantity) {
            debounced.current(quantity);
        }
    }, [quantity]);

    const preventEventPropagation = (e) => {
        if (e !== undefined) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const addToCart = (e) => {
        preventEventPropagation(e);
        setQuantity(quantity + 1.0);
    };

    const substractFromCart = (e) => {
        preventEventPropagation(e);
        setQuantity(Math.max(0, quantity - 1.0));
    };

    return (
        <>
            <Grid container justify="center"
                  alignItems="center"
                  wrap='nowrap'
            >
                {product ? (<>
                    {!editMod && supplierActive && !unavailable ? (
                        <>
                            <IconButton className={classes.iconButton}
                                        onClick={substractFromCart}>
                                <RemoveCircleOutlineOutlinedIcon color="primary"
                                                                 className={classes.removeIcon}/>
                            </IconButton>
                            <InputBase type="number" value={quantity}
                                       onChange={(e) => {
                                           setQuantity(Math.max(e.target.value, 0));
                                       }}
                                       onFocus={(e) => e.target.select()}
                                       onClick={preventEventPropagation}
                                       classes={{input: classes.input}}/>
                            <IconButton classes={{root: classes.iconButton}}
                                        onClick={addToCart}>
                                <AddCircleOutlinedIcon className={classes.addIcon}
                                                       color="primary"/>
                            </IconButton>
                        </>
                    ) : null}
                </>) : null}
            </Grid>
        </>
    );
};
export default CartQuantityWidget
