import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {blueGrey} from "@material-ui/core/colors";
import parseIsoDate from "yup/lib/util/isodate";
import {FormattedDate} from "react-intl";
import {Skeleton} from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: 15
    },
    paper: {
        backgroundColor: blueGrey[50],
        padding: 10
    },
    date: {
        fontSize: '0.7rem',
        color: theme.palette.grey[800],
        marginTop: 5,
        fontWeight: 600,
    },
    chatMessage:
        {
            fontWeight: 500,
            wordBreak: 'break-word',
            '&p': {}
        }
}));

const SupplierMessage = ({message}) => {
    const classes = useStyles();

    return (
        <Grid item container justify="flex-start" className={classes.container}>
            <Grid item xs={10}>
                <Paper className={classes.paper} elevation={0}>
                    {message ?
                        (<>
                            {message.picture ? <img className={classes.picture} alt="pièce jointe" src={message.picture} /> : null}
                            <div className={classes.chatMessage} dangerouslySetInnerHTML={{__html: message.content}}/>
                        </>)
                        :
                        <Skeleton/>}
                    {message ? <Typography align="right" className={classes.date}>
                        <FormattedDate value={parseIsoDate(message.creationDate)}
                                       year="numeric"
                                       month="numeric"
                                       day="2-digit"
                                       hour="numeric"
                                       minute="numeric"
                                       second="numeric"/>
                    </Typography> : <Skeleton/>}

                </Paper>
            </Grid>
        </Grid>
    );
};

export default SupplierMessage;
