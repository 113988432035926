import Grid from "@material-ui/core/Grid";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import {FormattedMessage} from "react-intl";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';

const useStyles = makeStyles(theme => ({
    partnerIcon: {
        color: theme.palette.primary.main,
        fontSize: '1rem',
    },
    partnerText: {
        fontWeight: 400,
        textTransform: 'lowercase',
        fontSize: '0.8rem'
    }
}));

const SupplierPartnerTag = () => {
    const classes = useStyles();

    return (
        <Grid container alignItems="center">
            <StorefrontOutlinedIcon className={classes.partnerIcon}/>
            <Typography className={classes.partnerText}>
                <FormattedMessage id="suppliersList.partner"
                                  defaultMessage="Partenaire Foodomarket"/>
            </Typography>
        </Grid>
    )
};

export default SupplierPartnerTag;