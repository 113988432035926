import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {FormattedMessage} from "react-intl";
import Button from "@material-ui/core/Button";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import SupplierPromoBox from "./SupplierPromoBox";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import StoreMallDirectoryOutlinedIcon from "@material-ui/icons/StoreMallDirectoryOutlined";
import GeneralPurposeCard from "../GeneralPurpose/GeneralPurposeCard";
import {blueGrey} from "@material-ui/core/colors";
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import IconButton from "@material-ui/core/IconButton";
import GraphQlFetchData from "./graphQlFetchData";
import Transition from "../Commun/Transition"

const useStyles = makeStyles((theme) => ({
    closeButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: 0,
    },
    heading: {
        backgroundColor: 'White',
        textTransform: 'uppercase',
        fontSize: '1rem',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#111',
        borderBottom: '1px solid #e2e2e2',
        zIndex: 1000,
        boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;'
    },
    content: {
        padding: 0
    },
    icon: {
        fontSize: '2.5rem',
        padding: 10,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        color: theme.palette.primary.contrastText,
    },
    name: {
        fontWeight: 'bold',
    },
    cardContent: {
        flexGrow: 1,
        color: blueGrey[800]
    },
    centerBlock: {
        flexGrow: 1
    },
    iconBlock: {
        display: 'flex',
        marginRight: 15
    },
    restaurantCard: {
        margin: 10
    },
    chooseRestaurant: {
        margin: 20
    },
    backButton: {
        padding: 0,
        marginRight: '10px',
    },
    progressBox: {
        height: '50vh'
    }
}));


const PromotionDiscoveryScreen = ({openState, close, restaurants}) => {
    const classes = useStyles();
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [sortedProducts, setSortedProducts] = useState(null)
    const {promotions, promotionsLoading} = GraphQlFetchData({selectedRestaurant})

    const sortProductBySupplier = () => {
        const sortingTable = {};

        promotions.offers.edges.map((product) => {
            if (!(product.node.supplier._id in sortingTable)) {
                sortingTable[product.node.supplier._id] = {
                    supplierId: product.node.supplier._id,
                    supplierName: product.node.supplier.name,
                    products: []
                }
            }
            sortingTable[product.node.supplier._id].products.push(product.node);
        });
        setSortedProducts(sortingTable);
    }

    useEffect(() => {
        if (restaurants && restaurants.edges.length === 1) {
            setSelectedRestaurant(restaurants[0]._id);
        }

        if (promotions && promotions.offers && !sortedProducts) {
            sortProductBySupplier();
        }
    })

    console.log(restaurants);

    return (
        <Dialog fullScreen open={openState} onClose={close} TransitionComponent={Transition}>
            <DialogTitle className={classes.heading} disableTypography>
                {selectedRestaurant && restaurants.edges.length > 1 && (
                    <IconButton aria-label="delete" onClick={() => setSelectedRestaurant(null)}
                                className={classes.backButton}>
                        <KeyboardBackspaceOutlinedIcon/>
                    </IconButton>
                )}
                <FormattedMessage
                    id="promoDiscoveryDialog.heading" defaultMessage="Les promotions du moment"/>
            </DialogTitle>
            <DialogContent className={classes.content}>
                {restaurants && restaurants.edges.length > 1 && !selectedRestaurant && (
                    <>
                        <Typography className={classes.chooseRestaurant} align="center">
                            <FormattedMessage id="promoDiscoveryDialog.chooseRestaurant"
                                              defaultMessage="Choisissez un restaurant pour découvrir les promotions disponibles."/>
                        </Typography>
                        {restaurants.edges.map((restaurant) =>
                            (
                                <GeneralPurposeCard key={restaurant.node._id} className={classes.restaurantCard}>
                                    <Grid container wrap="nowrap">
                                        <CardActionArea onClick={() => setSelectedRestaurant(restaurant.node._id)}>
                                            <CardContent className={classes.cardContent}>
                                                <Grid container wrap="nowrap" alignItems={"center"}>
                                                    <Grid item className={classes.iconBlock}>
                                                        {<StoreMallDirectoryOutlinedIcon className={classes.icon}/>}
                                                    </Grid>
                                                    <Grid item className={classes.centerBlock}>
                                                        <Typography className={classes.name}
                                                                    align="center">{restaurant.node.name}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Grid>
                                </GeneralPurposeCard>
                            )
                        )}
                    </>
                )}
                {promotionsLoading &&
                    <Grid container alignContent="center" justify="center" className={classes.progressBox}>
                        <Grid item>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                }
                {sortedProducts && selectedRestaurant &&
                    Object.keys(sortedProducts).map((key) =>
                        <SupplierPromoBox supplier={sortedProducts[key]} restaurantId={selectedRestaurant} key={key}/>
                    )}
            </DialogContent>
            <Button className={classes.closeButton} onClick={close}>
                <FormattedMessage id="promoDiscoveryDialog.closeButton" defaultMessage="Fermer"/>
            </Button>
        </Dialog>
    );
}

export default PromotionDiscoveryScreen;