import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import parseIsoDate from "yup/lib/util/isodate";
import {FormattedDate} from "react-intl";
import {Skeleton} from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: 15
    },
    paper: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        padding: 10,
        fontSize: '1rem'
    },
    date: {
        fontSize: '0.7rem',
        color: theme.palette.primary.contrastText,
        marginTop: 5,
        fontWeight: 600,
    },
    chatMessage: {
        fontWeight: 500,
        wordBreak: 'break-word',
        '&table': {
            border: 'none'
        },
        '&>table': {
            border: 'none'
        },
        '&>table>tbody>tr>td': {
            border: 'none',
            borderBottom: '2px solid white',
            paddingLeft: 5
        },
        '&>table>tbody>tr>th': {
            border: 'none',
            borderBottom: '2px solid white',
            paddingLeft: 5,
            minWidth: 64,
            backgroundColor: 'white',
            color: theme.palette.grey[800]
        },
        '&>table>tbody>tr>td:first-child': {
            borderRight: '2px solid white',
        },
        '&>table>tbody>tr>th:first-child': {
            borderRight: '2px solid white',
        }
    },
    picture: {
        width: 'auto',
        maxWidth: '100%',
        objectFit: 'cover',
        borderRadius: 5
    }
}));

const UserMessage = ({message}) => {
    const classes = useStyles();

    return (
        <Grid item container justify="flex-end" className={classes.container}>
            <Grid item xs={10}>
                <Paper className={classes.paper} elevation={0}>
                    {message ?
                        (<>
                            {message.picture ? <img height={300} width={100} className={classes.picture} alt="pièce jointe" src={message.picture} /> : null}
                            <div className={classes.chatMessage} dangerouslySetInnerHTML={{__html: message.content}}/>
                        </>)
                        :
                        <Skeleton/>}
                    {message ? <Typography align="right" className={classes.date}>
                        <FormattedDate value={parseIsoDate(message.creationDate)}
                                       year="numeric"
                                       month="numeric"
                                       day="2-digit"
                                       hour="numeric"
                                       minute="numeric"
                                       second="numeric"/>
                    </Typography> : <Skeleton/>}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default UserMessage;
