import {gql} from "apollo-boost";

export const EDIT_RESTAURANT_MUTATION = gql`
    mutation EditRestaurant(
        $id: Int!, 
        $name: String!,
        $street: String!, 
        $zip: String!, 
        $city: String!, 
        $country: String!,
        $preferredDeliveryTimeFrame: String!,
        $type: Int!,
        $clientMutationId: String
    ) {
        updateRestaurant(
            input:{
                restaurantId: $id,
                name: $name,
                street: $street,
                zipCode: $zip,
                city: $city,
                countryCode: $country
                preferredDeliveryTime: $preferredDeliveryTimeFrame,
                cuisineType: $type,
                clientMutationId: $clientMutationId
        }) {
            restaurant{
                _id
                id
                name
                address {
                    street
                    city
                    zipCode
                    countryCode
                }
            }
        }
    }
`;
