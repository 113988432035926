import {useIntl} from 'react-intl';

export function Hooks() {
    const emailTexts = {
        label: useIntl().formatMessage({
            id: 'forgottenPasswordForm.emailLabel',
            defaultMessage: 'Email',
        }),
        placeholder: useIntl().formatMessage({
            id: 'forgottenPasswordForm.emailPlaceholder',
            defaultMessage: 'Entrez votre email'
        })
    };

    const notificationText = {
        error: useIntl().formatMessage(
            {
                id: 'forgottenPasswordForm.error',
                defaultMessage: 'Une erreur est survenue lors de la demande réinitialisation de mot de passe. Si le problème persiste, contactez-nous.'
            }
        )
    };
    return (
        {
            emailTexts, notificationText
        }
    )
}