import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {createMatchSelector, goBack, push} from "connected-react-router";
import {updateOverlayTitle} from "../../reducers/overlay";
import {clearCart, normalizeCartId, setShippingDetails} from "../../reducers/carts";
import {triggerToastNotification} from "../../reducers/toastNotification";
import ShippingDetailsScreen from "../../components/Shipping/ShippingDetails";
import {displayGoBack, unsetActionsComponent} from "../../reducers/topNavigation";

const mapStateToProps = state => {
    const matchSelector = createMatchSelector('/restaurants/:id/suppliers/:supplierId/shipping/:cartId');
    const match = matchSelector(state);

    return {
        restaurantId: match.params.id,
        supplierId: match.params.supplierId,
        cartId: match.params.cartId,
        userType: state.authentication.userType,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateOverlayTitle: (title) => dispatch(updateOverlayTitle(title)),
    setShippingDetails: (payload) => dispatch(setShippingDetails(payload)),
    push: bindActionCreators(push, dispatch),
    triggerNotification: (payload) => dispatch(triggerToastNotification(payload)),
    goBack: () => dispatch(goBack()),
    unsetActionsComponent: (payload) => dispatch(unsetActionsComponent()),
    displayGoBack: (payload) => dispatch(displayGoBack(payload)),
});

const ShippingDetailsPage = connect(mapStateToProps, mapDispatchToProps)(ShippingDetailsScreen);

export default ShippingDetailsPage;
