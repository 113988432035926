import {connect} from 'react-redux';
import {updateTitle} from "../../reducers/topNavigation";
import {triggerToastNotification} from "../../reducers/toastNotification";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import ForgottenPasswordForm from "../../components/ForgottenPassword/ForgottenPasswordForm";

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
    updateTopNavTitle: (title) => dispatch(updateTitle(title)),
    triggerNotification: (payload) => dispatch(triggerToastNotification(payload)),
    push: bindActionCreators(push, dispatch),
});

const ForgottenPasswordPage = connect(mapStateToProps, mapDispatchToProps)(ForgottenPasswordForm);

export default ForgottenPasswordPage;
