import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import GeneralPurposeCard from "../../GeneralPurpose/GeneralPurposeCard";
import {blueGrey} from "@material-ui/core/colors";
import Skeleton from "@material-ui/lab/Skeleton";
import ProductDetailsSheet from "../ProductDetailsSheet";
import {CardActions, CircularProgress, Link} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import CardActionArea from "@material-ui/core/CardActionArea";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import RemoveProductFromListConfirmDialog from "../removeProductFromListConfirmDialog";
import amplitudeClient from "../../../amplitudeWrapper";
import {useIntl, FormattedMessage} from "react-intl";
import AcUnitIcon from '@material-ui/icons/AcUnit';
import {Alert} from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import {price} from "../../../utils/currency";
import GraphQlFetchData from "./graphQlFetchData";

//@TODO: find a way to avoid !important ??
const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: blueGrey[100],
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    frozen: {
        marginLeft: '-15px',
        paddingTop: '5px',
        zIndex: 100,
        color: '#3FD0D4',
        padding: '1px 1px'
    },
    name: {
        fontWeight: 500,
        textTransform: 'uppercase',
        color: blueGrey[800],
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '0.9rem'
    },
    cardHeader: {
        //backgroundColor: blueGrey[50],
        width: '100%',
        //borderBottom: `3px solid ${blueGrey[100]}`
    },
    cardContent: {
        flexGrow: 1,
        padding: '0 !important',
        maxWidth: '100%'
    },
    centerBlock: {
        flexGrow: 1,
        minWidth: '40px',
        maxWidth: 'calc(100vw - 145px)',
        padding: 8
    },
    iconBlock: {
        display: 'flex',
        paddingLeft: 6,
    },
    unit: {
        color: blueGrey[400],
        fontWeight: 300,
        fontSize: '0.8rem'
    },
    iconButton: {
        marginLeft: '0 !important',
        padding: 5,
    },
    price: {
        marginLeft: 8,
        backgroundColor: blueGrey[100],
        fontSize: '0.9rem'
    },
    skeletonRight: {
        marginRight: 20
    },
    skeletonLeft: {
        marginLeft: 20
    },
    addButton: {
        backgroundColor: '#d5f6e2',
        color: theme.palette.primary.main,
    },
    addIcon: {
        //fontSize: '2.3rem'
    },
    cardContainer: {
        borderBottom: `3px solid ${theme.palette.grey[100]}`,
        position: 'relative',
    },
    removeIcon: {
        color: theme.palette.primary.contrastText
    },
    removeButton: {
        zIndex: 1500,
    },
    priceDetails: {
        fontSize: '0.7rem'
    },
    infoGrid: {
        padding: '5px !important'
    },
    overlay: {
        backgroundColor: theme.palette.primary.main,
        filter: 'opacity: 0.4',
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: '1000',
        opacity: '0.4'
    },
    circle: {
        position: 'absolute'
    },
    promo: {
        backgroundColor: theme.palette.promo.main,
        borderRadius: 50,
        fontSize: '0.6rem',
        color: 'white',
        fontWeight: 600,
        position: 'absolute',
        padding: '7px  5px',
    },
    successAlert: {
        zIndex: 10000
    }
}));

//@TODO: split card in sub components (e.g. body, action...)
//@TODO: fix card action buttons on very small screen (320px)
const ProductCardMercurial = ({product, supplierId, restaurantId, shoppingList, triggerNotification, currency}) => {
    const classes = useStyles();
    const intl = useIntl();
    const {mutationLoading, addToShoppingList} = GraphQlFetchData({supplierId, restaurantId})

    const [detailsDialogOpenState, setDetailsDialogOpenState] = useState(false);
    const [removeProductFromListDialogState, setremoveProductFromListDialogState] = useState(false);

    const toggleDetailsDialogOpenState = () => {
        setDetailsDialogOpenState(!detailsDialogOpenState);
    };

    const toggleRemoveProductFromListDialogState = () => {
        setremoveProductFromListDialogState(!removeProductFromListDialogState);
    };

    const notificationText = {
        success: intl.formatMessage(
            {
                id: 'productCard.SuccessNotificationText',
                defaultMessage: ' a été ajouté à votre liste d\'achat'
            }
        ),
        error: intl.formatMessage(
            {
                id: 'productCard.errorNotificationText',
                defaultMessage: 'Une erreur est survenue lors de l\'ajout du produit'
            }
        ),
    };


    const [successToastState, setSuccessToastState] = useState(false);
    const itemId = product._id;

    const addProduct = () => {
        if (!mutationLoading && shoppingList && !inShoppingList) {
            addToShoppingList({
                variables: {input: {
                    listId:shoppingList._id,
                    listItems: [{variantId:product._id, supplierId: supplierId, quantity: 1}],
                }}})
                .then(() => {
                    amplitudeClient.logEvent('product added to shopping list');
                    setSuccessToastState(true);
                })
                .catch(e => triggerNotification({variant: 'error', message: notificationText.error}))
        }
    };

    const inShoppingList = shoppingList && shoppingList.items.edges.some(item => item.node.offer?._id === itemId);
    const shoppingListItemId = shoppingList && shoppingList.items.edges.find(item => item.node.offer?._id === itemId)?.node._id;

    const shoppingListURI = `/restaurants/${window.btoa(`/api/restaurants/${restaurantId}`)}/suppliers/${window.btoa(`/api/suppliers/${supplierId}`)}`;
    return (
        <>
         <Snackbar open={successToastState} className={classes.successAlert}
                      onClose={() => setSuccessToastState(false)}>
                <Alert variant="standard" severity="success">
                    "{product.name}" {notificationText.success}
                    <Link href={shoppingListURI} variant="button" color="primary"><FormattedMessage
                        id="productCardMercurial.goToShoppingList" defaultMessage="Passer commande"/></Link>
                </Alert>
            </Snackbar>
            <GeneralPurposeCard>
                <Grid container wrap="nowrap" className={classes.cardContainer}>
                    {inShoppingList && <div className={classes.overlay}></div>}
                    <CardActionArea onClick={toggleDetailsDialogOpenState}>
                        <CardContent className={classes.cardContent}>
                            <Grid container wrap="nowrap" alignItems={"center"}>
                                <Grid item className={classes.iconBlock}>
                                    {product ? (<>
                                            {product.image ? (
                                                <Avatar className={classes.avatar} alt="P" variant="rounded"
                                                        src={product.image.medium}/>) : (
                                                <Avatar className={classes.avatar} variant="rounded">
                                                    <StoreMallDirectoryOutlinedIcon className={classes.icon}/>
                                                </Avatar>)}
                                        </>) :
                                        <Skeleton variant="circle" width={40} height={40}/>}
                                    {product && product.frozen  ? (
                                            <span className={classes.frozen}>  <AcUnitIcon/>  </span>)
                                        : null}
                                    {product && product.promotion ? (
                                            <span className={classes.promo}><FormattedMessage
                                                id="productCardMercurial.promo" defaultMessage="Promo"/></span>)
                                        : null}
                                </Grid>
                                <Grid item className={classes.centerBlock}>
                                    <Grid item container>
                                        {product ?
                                            <Grid item className={classes.cardHeader}>
                                                <Typography className={classes.name}>{product.name}</Typography>
                                            </Grid>
                                            :
                                            <Skeleton variant="text" width="90%"/>
                                        }
                                        {product ? (
                                            <Grid item container className={classes.centerBlock} alignItems="center"
                                                  spacing={2}>
                                                <Grid item className={classes.infoGrid}>
                                                    {product.priceWithoutVat ? (
                                                        <>
                                                            <Chip label={`${price(product.priceWithoutVat, currency)}`} size="small"
                                                                  className={classes.price}/>
                                                        </>
                                                    ) : null}
                                                </Grid>
                                                <Grid item className={classes.infoGrid}>
                                                    <Typography display="inline"
                                                                className={classes.unit}>{product.quantity}</Typography>
                                                    <Typography className={classes.unit}
                                                                display="inline">{product.unit}</Typography>
                                                </Grid>
                                                <Grid item className={classes.infoGrid}>
                                                    {product.priceWithoutVat ? (
                                                        <Typography className={classes.priceDetails}>
                                                            {product.priceDetailsWithoutVat ?
                                                                <>{product.priceDetailsWithoutVat}</> :
                                                                <>{`(${price(product.priceWithoutVat.toFixed(2), currency)}/ ${product.unit})`}</>
                                                            }
                                                        </Typography>) : null}
                                                </Grid>
                                            </Grid>
                                        ) : <Skeleton width="100%" className={classes.skeletonLeft}/>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        {product ? (<>
                            {inShoppingList ? (
                                <IconButton className={classes.removeButton}
                                            onClick={toggleRemoveProductFromListDialogState}>
                                    <DeleteOutlineOutlinedIcon className={classes.removeIcon}/>
                                </IconButton>
                            ) : (
                                <IconButton className={classes.addButton} onClick={addProduct}>
                                    <AddOutlinedIcon className={classes.addIcon}/>
                                    {mutationLoading && <CircularProgress className={classes.circle}/>}
                                </IconButton>
                            )}
                        </>) : <Skeleton width="100%" className={classes.skeletonRight}/>}
                    </CardActions>
                </Grid>
                {product ?
                    <>
                        <RemoveProductFromListConfirmDialog triggerNotification={triggerNotification}
                                                            handleClose={toggleRemoveProductFromListDialogState}
                                                            openState={removeProductFromListDialogState}
                                                            product={product}
                                                            restaurantId={restaurantId} supplierId={supplierId}
                                                            shoppingListItemId={shoppingListItemId}
                                                            shoppingListId={shoppingList._id}
                        />
                        <ProductDetailsSheet product={product} openState={detailsDialogOpenState}
                                             toggleState={toggleDetailsDialogOpenState}/>
                    </>
                    : null}
            </GeneralPurposeCard>

        </>
    );
};

export default ProductCardMercurial;
