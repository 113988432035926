import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import {FormattedMessage} from 'react-intl';
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import BaseTextField from "../../Inputs/BaseTextField";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from "@material-ui/core/Paper";
import {LOGIN} from "../../../constants/Routes";
import {Hooks} from "./hooks"

const useStyles = makeStyles(theme => ({
    input: {
        marginBottom: 25,
    },
    connexionButton: {
        marginBottom: '30px',
    },
    iconContainer: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 90,
        height: 110,
        width: 110,
        marginTop: 25,
        marginBottom: 20,
    },
    textWrapper: {
        marginBottom: 25,
        padding: '0 10px',
    },
    iconWrapper: {
        height: '100%',
    },
    logo: {
        height: 75
    },
    link: {
        color: theme.palette.primary.main
    },
    info: {
        backgroundColor: "#ccf4ff",
        margin: 20,
        padding: 20,
        borderRadius: 4
    },
    infoText: {
        padding: '5px 10px',
        fontSize: '0.9rem',
        color: theme.palette.info.contrastText,
    },
}));

const ForgottenPasswordForm = ({push, triggerNotification, login}) => {
    const classes = useStyles();
    const {emailTexts, notificationText} = Hooks()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [emailSent, setEmailSent] = useState(false);

    return (
        <>

            <Grid container justify={"center"} alignItems={"center"}>
                <Paper className={classes.iconContainer}>
                    <Grid container justify={"center"} alignItems={"center"} className={classes.iconWrapper}>
                        <Grid item>
                            <img src="logo192.png" alt="Foodomarket" className={classes.logo}/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid className={classes.textWrapper}>
                <Typography variant="h6" gutterBottom align={"center"}>
                    <FormattedMessage
                        id="forgottenPasswordForm.heading"
                        defaultMessage="Mot de passe oublié"
                    />
                </Typography>
                {emailSent ? (
                    <Grid container>
                        <Grid item className={classes.info}>
                            <Typography gutterBottom align={"center"} className={classes.infoText}>
                                <FormattedMessage
                                    id="forgottenPasswordForm.success"
                                    defaultMessage="Votre demande de réinitialisation de mot de passe a bien été prise en compte. Vous allez recevoir un email dans quelques instants. Pour finaliser la récuparation de votre mot de passe, il vou suffira de cliquer sur le lien contenu dans l'email."
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Typography variant="body1" gutterBottom align={"center"}>
                        <FormattedMessage
                            id="forgottenPasswordForm.subheading"
                            defaultMessage="Pour réinitialiser votre mot de passe, entrez votre email dans le formulaire ci-dessous."
                        />
                    </Typography>
                )}
            </Grid>
            {!emailSent && (
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validationSchema={Yup.object({
                        email: Yup.string()
                            .email(emailTexts.errorInvalidEmail)
                            .required(emailTexts.errorRequired),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const data = new FormData();
                        data.append("form[email]", values.email);
                        //@TODO: put this in a saga
                        fetch(`${window.backEndBaseUrl}/shop/request-password-reset?channel=app`, {
                            method: 'POST', body: data, headers: {
                                'X-Requested-With': 'XMLHttpRequest'
                            }
                        })
                            .then((response) =>
                                response.json())
                            .then((data) => {
                                if (data.ok === true) {
                                    setEmailSent(true);
                                }
                            })
                            .catch(e => triggerNotification({variant: 'error', message: notificationText.error}));
                        setSubmitting(false);
                    }}
                >
                    <Form id="form">
                        <Grid container justify={"center"} direction={"column"} alignItems={"center"}>
                            <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                <Field component={BaseTextField} name="email" id="email" type="email"
                                       label={emailTexts.label}
                                       fullWidth
                                       placeholder={emailTexts.placeholder} className={classes.input}/>
                            </Grid>
                            <Grid item lg={2} md={3} sm={4} xs={10}>
                                <Button variant="contained" color="primary" size="large" type="submit"
                                        className={classes.connexionButton} disabled={false}>
                                    <FormattedMessage
                                        id="forgottenPassword.validate"
                                        defaultMessage="Valider"/>
                                    {false &&
                                    <CircularProgress size={24} className={classes.buttonProgress}/>
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            )}
            <Grid container justify={"center"} direction={"column"} alignItems={"center"}>
                <Grid item lg={2} md={3} sm={4} xs={10}>
                    <Button color="primary" onClick={() => push(LOGIN)}>
                        <FormattedMessage
                            id="forgottenPassword.backToLogin"
                            defaultMessage="Revenir au formulaire de connexion"
                        />
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default ForgottenPasswordForm;
