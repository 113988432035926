import Typography                        from "@material-ui/core/Typography";
import {FormattedDate, FormattedMessage} from "react-intl";
import parseIsoDate                      from "yup/lib/util/isodate";
import Skeleton                          from "@material-ui/lab/Skeleton";
import ShoppingCartOutlinedIcon          from "@material-ui/icons/ShoppingCartOutlined";
import EuroOutlinedIcon                  from "@material-ui/icons/EuroOutlined";
import React                             from "react";
import CreditCardOutlinedIcon            from "@material-ui/icons/CreditCardOutlined";
import Grid                              from "@material-ui/core/Grid";
import LocalShippingOutlinedIcon         from "@material-ui/icons/LocalShippingOutlined";
import {makeStyles}                      from "@material-ui/core/styles";
import {blueGrey}                        from "@material-ui/core/colors";
import {price}                           from "../../../utils/currency";
import * as Statuses                     from "../../../constants/OrderStatus";
import Chip                              from "@material-ui/core/Chip";
import HourglassEmptyOutlinedIcon        from "@material-ui/icons/HourglassEmptyOutlined";
import InfoOutlinedIcon                  from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: '0px 8px 7px -1px rgba(180,201,219,0.2), 0px 0px 10px 1px rgba(180,201,219,0.1), 0px 1px 3px 1px rgba(180,201,219,0.1)',
    },
    icon: {
        fontSize: '2.5rem',
        padding: 10,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        color: theme.palette.primary.contrastText,
    },
    name: {
        fontWeight: 500,
        textTransform: 'uppercase',
        fontSize: '1.1rem',
        marginBottom: 0
    },
    cardContent: {
        flexGrow: 1,
        padding: '16px !important',
        color: blueGrey[800],
        borderBottom: '4px solid #33d573',
        //borderTop: `6px solid ${theme.palette.grey[50]}`
    },
    centerBlock: {
        flexGrow: 1,
    },
    chatIcon: {
        //color: theme.palette.grey.A700,
    },
    partnerIcon: {
        color: theme.palette.primary.main,
        fontSize: '1rem',
    },
    partnerText: {
        fontWeight: 400,
        textTransform: 'lowercase',
        fontSize: '0.8rem'
    },
    iconBlock: {
        display: 'flex',
        marginRight: 15,
    },
    actionBox: {
        backgroundColor: 'hsl(144, 66%, 94%)'
    },
    notValidated: {
        filter: 'opacity(0.4)'
    },
    minOrderText: {
        fontSize: '0.7rem',
        color: theme.palette.grey[500]
    },
    delivery: {
        fontSize: '0.7rem',
        color: theme.palette.grey[500],
        marginBottom: 10
    },
    infoTitle: {
        fontWeight: 700,
    },
    infoIcon: {
        fontSize: '1rem',
        marginRight: 5,
        marginBottom: '-4px',
    },


    nameBox: {
        flexGrow: 1
    },
    infoBox: {
        backgroundColor: 'hsl(204, 15%, 97%)',
        borderRadius: 10,
        marginTop: 10,
        padding: 10,
    },
    commande: {
        width: '100%',
    }
}));

const SupplierDescription = ({supplier, getNextDeliveryDay, ShoppingCartOutlinedIcon, from, userType}) => {
    const classes = useStyles();

    const paymentMethod = (method) => {
        switch (method) {
            case 'FOODOMARKET_CREDIT_CARD':
                return (
                    <FormattedMessage id="supplierCard.online"
                                      defaultMessage="Paiement en ligne"/>);
            case 'SUPPLIER_ON_DELIVERY':
                return (
                    <FormattedMessage id="supplierCard.onDelivery"
                                      defaultMessage="Paiement à la livraison"/>);
            case 'SUPPLIER_DIRECT_DEBIT':
                return (
                    <FormattedMessage id="supplierCard.directDebit"
                                      defaultMessage="Prélévement automatique"/>);
            case 'FOODOMARKET_DIRECT_DEBIT':
                return (
                    <FormattedMessage id="supplierCard.directDebit"
                                      defaultMessage="Prélévement automatique"/>);
            case 'SUPPLIER_BANK_TRANSFER':
                return (
                    <FormattedMessage id="supplierCard.bankWire"
                                      defaultMessage="Virement sur facture"/>);
            case 'FOODOMARKET_BANK_TRANSFER':
                return (
                    <FormattedMessage id="supplierCard.bankWire"
                                      defaultMessage="Virement sur facture"/>);
        }
    }
    return (
        <>
            <Grid className={classes.infoBox}>
                <Typography className={classes.delivery}>
                    {supplier ?
                        (<>
                            {getNextDeliveryDay() && (
                                <LocalShippingOutlinedIcon color="primary"
                                                           className={classes.infoIcon}/>
                            )}
                        </>)
                        :
                        <Skeleton/>}
                    {supplier ? (
                        <>
                            {getNextDeliveryDay() && (
                                <>
                                                        <span className={classes.infoTitle}>
                                                    <FormattedMessage id="supplierCard.delivery"
                                                                      defaultMessage="Livraison : "/>
                                                </span>
                                    <FormattedMessage id="supplierCard.nextDeliveryDate"
                                                      defaultMessage="Commandez avant "/>
                                    {supplier.orderCutOff ? supplier.orderCutOff : '12h'}
                                    <FormattedMessage id="supplierCard.nextDeliveryDate2"
                                                      defaultMessage=" pour être livré le "/>
                                    <FormattedDate value={parseIsoDate(getNextDeliveryDay())}
                                                   month="long"
                                                   day="2-digit"
                                                   weekday="long"/>
                                </>)}

                        </>) : <Skeleton/>}
                </Typography>

                {
                    supplier ? (
                        <Typography className={classes.minOrderText}>
                            <ShoppingCartOutlinedIcon color="primary" className={classes.infoIcon}/>
                            <span className={classes.infoTitle}>
                                                    <FormattedMessage id="supplierCard.minOrderAmount"
                                                                      defaultMessage="Min. de commande : "/>
                                                </span>
                            {supplier.minOrderAmount ? (supplier.orderUnit === 'kg' ? `${supplier.minOrderAmount} kg` : price(supplier.minOrderAmount, supplier.currency)) :
                                <FormattedMessage id="supplierCard.none"
                                                  defaultMessage="Aucun"/>}
                        </Typography>
                    ) : <Skeleton/>
                }
                {
                    supplier ? (
                        <Typography className={classes.minOrderText}>
                            <EuroOutlinedIcon color="primary" className={classes.infoIcon}/>
                            <span className={classes.infoTitle}>
                                                    <FormattedMessage id="supplierCard.shippingFees"
                                                                      defaultMessage="Frais de port : "/>
                                                </span>
                            {supplier.deliveryCost ? `${from} ${price(supplier.deliveryCost.toFixed(2), supplier.currency)}` :
                                <FormattedMessage id="supplierCard.none"
                                                  defaultMessage="Aucun"/>}
                        </Typography>
                    ) : <Skeleton/>
                }
                {
                    supplier && supplier.freeShippingThreshold > 0 && (
                        <Typography className={classes.minOrderText}>
                            <ShoppingCartOutlinedIcon color="primary" className={classes.infoIcon}/>
                            <span className={classes.infoTitle}>
                                                    <FormattedMessage id="supplierCard.franco"
                                                                      defaultMessage="Frais de port offert à partir de : "/>
                                                </span>
                            {price(supplier.freeShippingThreshold, supplier.currency)}
                        </Typography>
                    )
                }
                {
                    <>
                        {supplier ? (
                            <Typography className={classes.minOrderText}>
                                <CreditCardOutlinedIcon color="primary"
                                                        className={classes.infoIcon}/>
                                <span className={classes.infoTitle}>
                                                    <FormattedMessage id="supplierCard.paymentMethod"
                                                                      defaultMessage="Mode de paiement : "/>
                                </span>
                                {paymentMethod(supplier.paymentMethod)}
                            </Typography>
                        ) : <Skeleton/>}
                    </>
                }
            </Grid>
        </>
    );
};
export default SupplierDescription
