import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ConnectedRouter} from "connected-react-router";
import configureStore, {history, sagaMiddleware} from "./stores/store";
import {Provider} from "react-redux";
import CssBaseline from '@material-ui/core/CssBaseline';
import {ApolloProvider} from '@apollo/react-hooks';
import rootSaga from "./sagas/rootSaga";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {ApolloClient, ApolloLink, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from "apollo-link-context";
import {PersistGate} from 'redux-persist/integration/react'
import {onError} from "apollo-link-error";
import {logout, setBearerToken} from "./reducers/authentication";
import {persistCache} from 'apollo-cache-persist';
import localforage from "localforage";
import {updateReady} from "./reducers/updater";
import {blueGrey} from "@material-ui/core/colors";
import "@fortawesome/fontawesome-free";
import {RetryLink} from "apollo-link-retry";
import {fromPromise} from 'apollo-link';
import {fetchNewBearerToken} from "./sagas/authentication";
import * as Sentry from '@sentry/browser';
import ReactPixel from 'react-facebook-pixel';
import {createUploadLink} from "apollo-upload-client";

const {store, persistor} = configureStore();

//Starting up facebook pixel
ReactPixel.init('216691788895045');
ReactPixel.pageView();
//----------------------

//Starting up sentry
if (process.env.REACT_APP_GRAPHQL_ENV === 'prod') {
    Sentry.init({dsn: "https://291cf0bb45ff4a858499144fcbcd2d03@o381862.ingest.sentry.io/5209877"});
    const state = store.getState();
    Sentry.configureScope(function (scope) {
        scope.setUser({"email": state.authentication.email});
    });
    Sentry.setUser({email: state.authentication.email});
}
//---------------------

//Starting root saga (contain all watchers)
sagaMiddleware.run(rootSaga);

//@TODO: put Apollo init in it's own file
//--------Apollo client initialization-------

const apolloCache = new InMemoryCache();

//Init Apollo (GraphQl data) persistence
persistCache({
    cache: apolloCache,
    storage: localforage,
});

const apiOrigin = process.env.REACT_APP_API_ORIGIN;
if (!apiOrigin) {
    throw new Error('API_ORIGIN is not defined');
}
switch (process.env.REACT_APP_GRAPHQL_ENV) {
    case 'preprod':
        window.apiEndpointUrl = 'https://staging.foodomarket.com/api';
        window.backEndBaseUrl = 'https://staging.foodomarket.com';
        break;
    case 'prod':
        window.apiEndpointUrl = 'https://shop.foodomarket.com/api';
        window.backEndBaseUrl = 'https://shop.foodomarket.com';
        break;
    default:
        window.apiEndpointUrl ='https://staging.foodomarket.com/api';
        window.backEndBaseUrl = 'https://staging.foodomarket.com';
        break;
}

const httpLink = createHttpLink({
    uri: `${window.apiEndpointUrl}/graphql`,
});

const errorLink = onError(({response, graphQLErrors, networkError, operation, forward}) => {
    if (graphQLErrors)
        graphQLErrors.map(({message, locations, path}) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );

    if (networkError) console.log(`[Network error]: ${networkError}`);

    if (networkError && networkError.statusCode === 401) {
        const state = store.getState();
        //We need to refresh the bearer token
        return fromPromise(fetchNewBearerToken(state.authentication.refresh_token).then(token => {
            //Once we get a new token, we store it in redux
            store.dispatch(setBearerToken(token));
            return true;
        }).catch(e => {
            //We cant refresh the token, disconnecting user
            store.dispatch(logout());
        })).filter(value => Boolean(value))
            .flatMap(() => {
                // retry the request, returning the new observable
                return forward(operation);
            });
    }
});

const authLink = setContext((_, {headers}) => {
    const state = store.getState();

    return {
        headers: {
            ...headers,
            Authorization: `Bearer ${state.authentication.bearer_token}`,
        }
    }
});

const retryLink = new RetryLink(
    {
        delay: {
            initial: 300,
            max: Infinity,
            jitter: true
        },
        attempts: {
            max: 5,
            retryIf: (error) => {
                console.log('request retry error :', error);
                return !!error
            }
        }
    }
);

const uploadLink = createUploadLink({
    uri: `${window.apiEndpointUrl}/graphql`,
});

const link = ApolloLink.from([
    retryLink,
    errorLink,
    authLink,
    //httpLink,
    uploadLink,
]);

const client = new ApolloClient({
    link: link,
    cache: apolloCache,
    fetchPolicy: 'cache-and-network'
});
//-----------------------------------------------

//@TODO: put loc init in it's own file
//-------Initializing localization plugin-------------------

//Loading polyfill if browser doesn't support Intl.PluralRules and Intl.RelativeTimeFormat
if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill');
    require('@formatjs/intl-pluralrules/dist/locale-data/fr');
}

if (!Intl.RelativeTimeFormat) {
    require('@formatjs/intl-relativetimeformat/polyfill');
    require('@formatjs/intl-relativetimeformat/dist/locale-data/fr');
}

/*
@TODO: "Plug" this var to state when implementing multilingual
@TODO: Find a clean way to automatically generate translation files
  (e.g. https://objectpartners.com/2019/04/03/translate-create-react-app-with-react-intl/) that's properly integrated with create-react-app
*/
const localeProp = 'en';
//-------------------------------------------------------------

//@TODO: put theme config in it's own file
//---------Materialize-ui theme configuration------------------
const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#73ffa3',
            main: '#33d573',
            dark: '#00a246',
            contrastText: '#005700',
        },
        secondary: {
            main: '#011547',
        },
        success: {
            main: '#20da9b',
        },
        error: {
            main: '#fe5e78',
        },
        info: {
            main: '#09c8ff',
        },
        grey: blueGrey,
        text: {
            primary: blueGrey[800]
        },
        promo: {
            main: '#ff8533',
        }
    },
    typography: {
        fontFamily: 'Montserrat',
    },
});
//-------------------------------------------------------------
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
                <ApolloProvider client={client}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline/>
                        <App apiOrigin={apiOrigin}/>
                    </ThemeProvider>
                </ApolloProvider>
            </ConnectedRouter>
        </PersistGate>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

const config = {
    onUpdate: (registration) => {
        //The global variable name is important, it's used in the authentication saga
        window.__zoooob = registration;
        store.dispatch(updateReady());
    }
};

serviceWorker.register(config);

//Exporting Apollo client and redux persistor for logout saga
export {client, persistor, store}
