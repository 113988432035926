import {price} from "../../utils/currency";
import {isNumber} from "lodash";

export function validateShoppingCart(cart, orderUnit, orderMinAmount, currency) {
    let cartWeight = 0.0;

    for (let {node: cartItem} of cart.items.edges) {
        cartWeight += cartItem.quantity * cartItem.offer.weight;
    }

    const isMinOrderReached = orderUnit === 'kg'
        ? isNumber(orderMinAmount) && cartWeight >= orderMinAmount
        : isNumber(orderMinAmount) && cart.totalWithoutVat >= orderMinAmount
    ;

    const formattedMinOrderMessage = (orderUnit === 'kg' ? `${orderMinAmount} kg` : price(orderMinAmount, currency));
    const remainingOrderAmount = orderMinAmount - (orderUnit === 'kg' ? cartWeight : cart.total);
    const formattedRemainingOrderAmount = !isMinOrderReached ? (orderUnit === 'kg' ? `${remainingOrderAmount.toFixed(2)} kg` : price(remainingOrderAmount.toFixed(2), currency)) : '';

    return {
        isMinOrderReached,
        cartWeight,
        cartTotal: cart.totalWithoutVat.toFixed(2),
        formattedMinOrderMessage,
        formattedRemainingOrderAmount
    };
}
