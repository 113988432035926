import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from "@material-ui/core/Button";
import {FormattedMessage, useIntl} from 'react-intl';
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import BaseTextField from "../../Inputs/BaseTextField";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from "@material-ui/core/Paper";
import {LOGIN} from "../../../constants/Routes";
import {Hooks} from "./hooks"

const useStyles = makeStyles(theme => ({
    input: {
        marginBottom: 25,
    },
    connexionButton: {
        marginBottom: '30px',
    },
    iconContainer: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 90,
        height: 110,
        width: 110,
        marginTop: 25,
        marginBottom: 20,
    },
    textWrapper: {
        marginBottom: 25,
        padding: '0 10px',
    },
    iconWrapper: {
        height: '100%',
    },
    logo: {
        height: 75
    },
}));

const SetNewPasswordForm = ({push, triggerNotification, login, token}) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {passwordTexts, notificationText, formValidate} = Hooks()


    const checkPasswords = (password, repeatedPassword) => {
        return password === repeatedPassword;
    }

    return (
        <>
            <Grid container justify={"center"} alignItems={"center"}>
                <Paper className={classes.iconContainer}>
                    <Grid container justify={"center"} alignItems={"center"} className={classes.iconWrapper}>
                        <Grid item>
                            <img src="../logo192.png" alt="Foodomarket" className={classes.logo}/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid className={classes.textWrapper}>
                <Typography variant="h6" gutterBottom align={"center"}>
                    <FormattedMessage
                        id="forgottenPasswordForm.newPassword"
                        defaultMessage="Nouveau mot de passe"
                    />
                </Typography>
                <Typography variant="body1" gutterBottom align={"center"}>
                    <FormattedMessage
                        id="forgottenPasswordForm.instructions"
                        defaultMessage="Définissez votre nouveau mot de passe en remplissant le formulaire ci-dessous."
                    />
                </Typography>
            </Grid>
            <Formik
                initialValues={{
                    newPassword: '',
                    newPasswordRepeat: '',
                }}
                validationSchema={Yup.object(formValidate)}
                onSubmit={(values, {setSubmitting}) => {
                    if (checkPasswords(values.newPassword, values.newPasswordRepeat)) {
                        const data = new FormData();
                        data.append("form[password]", values.newPassword);
                        data.append("form[password_again]", values.newPasswordRepeat);
                        //@TODO: put this in a saga
                        fetch(`${window.backEndBaseUrl}/shop/changement-mot-de-passe/${token}`, {
                            method: 'POST', body: data, headers: {
                                'X-Requested-With': 'XMLHttpRequest'
                            }
                        })
                            .then((response) =>
                                response.json())
                            .then((data) => {
                                if (data.ok === true) {
                                    triggerNotification({variant: 'success', message: notificationText.success});
                                    setTimeout(() => {
                                        push(LOGIN);
                                    }, 4000)
                                }
                            })
                            .catch(e => triggerNotification({variant: 'error', message: notificationText.error}));
                        setSubmitting(false);
                    } else {
                        triggerNotification({variant: 'error', message: notificationText.passwordsDontMatch});
                        setSubmitting();
                    }
                }}
            >
                <
                    Form
                    id="form">
                    < Grid
                        container
                        justify={"center"}
                        direction={"column"}
                        alignItems={"center"}>
                        < Grid
                            item
                            container
                            justify={"center"}
                            lg={2}
                            md={3}
                            sm={4}
                            xs={10}>
                            < Field
                                component={BaseTextField}
                                name="newPassword"
                                id="password"
                                type="password"
                                label={passwordTexts.label}
                                fullWidth
                                placeholder={passwordTexts.placeholder}
                                className={classes.input}
                            />
                        </Grid>
                        <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                            <Field component={BaseTextField} name="newPasswordRepeat" id="repeat-password"
                                   type="password"
                                   label={passwordTexts.repeatLabel} fullWidth
                                   placeholder={passwordTexts.repeatPlaceholder} className={classes.input}/>
                        </Grid>
                        <Grid item lg={2} md={3} sm={4} xs={10}>
                            <Button variant="contained" color="primary" size="large" type="submit"
                                    className={classes.connexionButton} disabled={false}>
                                <FormattedMessage
                                    id="forgottenPassword.validate"
                                    defaultMessage="Valider"/>
                                {false &&
                                <CircularProgress size={24} className={classes.buttonProgress}/>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </>
    );
};

export default SetNewPasswordForm;
