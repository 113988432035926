import React, {useEffect} from "react";
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import EmptyList from "../../EmptyList";
import {useIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import OrderCard from "../OrderCard";
import {makeStyles} from "@material-ui/core";
import {AutoSizer, CellMeasurer, CellMeasurerCache, List,} from "react-virtualized";
import SearchResults from 'react-filter-search';
import SearchBar from "../../SearchBar";
import {useQuery} from "@apollo/react-hooks";
import {GET_ORDERS_QUERY, GET_RESTAURANTS_ORDERS_QUERY} from "./queries";
import FetchingError from "../../Errors/FetchingError";
import GraphQlFetchData from "./graphQlFetchData";

const useStyles = makeStyles(theme => ({
    ordersBox: {
        //overflowY: 'auto',
        flex: '1 1 auto',
        padding: 8,
        height: 'calc(100vh - 165px)'
    },
    innerGrid: {
        padding: '5px 10px',
    },
    container: {
        maxHeight: '100%'
    },
}));

//@TODO: seperate component for restaurant orders list?
const OrdersList = ({
                        updateTopNavTitle,
                        push,
                        unsetActionsComponent,
                        updateTopNavSubtitle,
                        displayMenu,
                        denyAccessToUnauthenticatedUser,
                        restaurantId
                    }) => {


    if (restaurantId) {
        const decodedRestaurantId = window.atob(restaurantId);
        const restaurantSegments = decodedRestaurantId.split('/');
        restaurantId = parseInt(restaurantSegments.pop());
    }

    const {
        error,
        loading,
        data,
        refetch,
        restaurantOrders,
        restaurantsOrdersLoading,
        restaurantOrdersError,
        restaurantOrdersRefetch
    } = GraphQlFetchData({restaurantId})

    const intl = useIntl();
    const classes = useStyles();

    const [searchValue, setSearchValue] = React.useState('');

    let cache = new CellMeasurerCache({
        fixedWidth: true,
    });

    const handleChange = event => {
        const {value} = event.target;
        setSearchValue(value);
    };

    const pageTitle = intl.formatMessage(
        {
            id: 'ordersPage.title',
            defaultMessage: 'Mes commandes'
        }
    );

    useEffect(() => {
        (async () => {
            await denyAccessToUnauthenticatedUser();
            await displayMenu();
            await updateTopNavTitle(pageTitle);
            await updateTopNavSubtitle('');
            await unsetActionsComponent();
        })()

    }, [])

    const searchText = intl.formatMessage(
        {
            id: 'ordersPage.placeholder',
            defaultMessage: 'Rechercher des commandes',
        }
    );

    if (loading || restaurantsOrdersLoading) {
        const skeleton = [0, 1, 2, 3, 4, 5, 6];
        return (
            <Grid container justify={"center"} className={classes.container}>
                {skeleton.map((item, key) => (
                    <Grid item xs={12} key={key} className={classes.innerGrid}>
                        <OrderCard/>
                    </Grid>
                ))}
            </Grid>
        );
    }

    const refetchOrders = () => {
        if (restaurantId) {
            restaurantOrdersRefetch().then(null).catch(e => null);
        } else {
            refetch().then(null).catch(e => null);
        }
    };

    if (error || restaurantOrdersError) {
        return (
            <FetchingError action={refetchOrders}/>);
    }

    return (
        <>
            {data || restaurantOrders ? (
                    <>
                        <Grid container justify={"center"} className={classes.container} wrap="nowrap" direction="column">
                            <Grid item container>
                                <SearchBar placeholder={searchText} onChange={handleChange} value={searchValue}/>
                            </Grid>
                            <Grid container className={classes.ordersBox}>
                                <SearchResults
                                    value={searchValue}
                                    data={data ? data.orders.edges : restaurantOrders.orders.edges}
                                    renderResults={results =>
                                        (
                                            <AutoSizer>
                                                {({height, width}) => (
                                                    <List width={width}
                                                          height={height}
                                                          rowCount={results.length}
                                                          rowHeight={cache.rowHeight}
                                                          rowRenderer={({
                                                                            key,
                                                                            index,
                                                                            parent,
                                                                            isScrolling,
                                                                            isVisible,
                                                                            style
                                                                        }) => {
                                                              return (
                                                                  <CellMeasurer
                                                                      cache={cache}
                                                                      columnIndex={0}
                                                                      key={key}
                                                                      parent={parent}
                                                                      rowIndex={index}
                                                                  >
                                                                      <Grid item xs={12} className={classes.innerGrid}
                                                                            key={key}
                                                                            style={style}>
                                                                          <OrderCard order={results[index].node}
                                                                                     push={push}/>
                                                                      </Grid>
                                                                  </CellMeasurer>
                                                              )
                                                          }}
                                                          overscanRowCount={10}
                                                    />)}
                                            </AutoSizer>
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </>
                ) :
                (<EmptyList icon={ListAltOutlinedIcon} heading={"Il n'y a rien ici !"}
                            text={"Pour passer votre première commande rendez-vous sur la liste de vos fournisseur."}/>)}
        </>
    );
};

export default OrdersList;
