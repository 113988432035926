import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {createMatchSelector, goBack, push} from "connected-react-router";
import {updateOverlayTitle} from "../../reducers/overlay";
import {clearCart, normalizeCartId, setShippingDetails} from "../../reducers/carts";
import {triggerToastNotification} from "../../reducers/toastNotification";
import CheckoutScreen from "../../components/Checkout/CheckoutScreen";
import {displayGoBack, unsetActionsComponent} from "../../reducers/topNavigation";

const mapStateToProps = state => {
    const matchSelector = createMatchSelector('/restaurants/:id/suppliers/:supplierId/checkout/:cartId');
    const match = matchSelector(state);
    return {
        cartId: parseInt(match.params.cartId),
        restaurantId: match.params.id,
        supplierId: match.params.supplierId,
        userType: state.authentication.userType,
        locale: state.authentication.language
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateOverlayTitle: (title) => dispatch(updateOverlayTitle(title)),
    setShippingDetails: (payload) => dispatch(setShippingDetails(payload)),
    push: bindActionCreators(push, dispatch),
    triggerNotification: (payload) => dispatch(triggerToastNotification(payload)),
    goBack: () => dispatch(goBack()),
    unsetActionsComponent: (payload) => dispatch(unsetActionsComponent()),
    displayGoBack: (payload) => dispatch(displayGoBack(payload)),
    clearCart: (payload) => dispatch(clearCart(payload)),
});

const CheckoutPage = connect(mapStateToProps, mapDispatchToProps)(CheckoutScreen);

export default CheckoutPage;
