import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {FormattedMessage} from 'react-intl';
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import BaseTextField from "../../Inputs/BaseTextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import amplitudeClient from "../../../amplitudeWrapper";
import GraphQlFetchData from "./graphQlFetchData";
import {Hooks} from "./hooks";
import {FormValidation} from "../utils"
import {useQuery} from "@apollo/react-hooks";
import {GET_RESTAURANT_QUERY} from "../../Restaurants/RestaurantsList/queries";

const useStyles = makeStyles(theme => ({
    iconContainer: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 90,
        height: 90,
        width: 90,
        marginTop: 25,
        marginBottom: 20,
    },
    textWrapper: {
        marginBottom: 25,
        padding: '0 10px',
    },
    iconWrapper: {
        height: '100%',
    },
    icon: {
        color: 'white',
        fontSize: '3rem'
    },
    input: {
        marginBottom: 25,
    },
    resetPasswordLink: {
        cursor: 'pointer'
    },
    connexionButton: {
        margin: '30px 0',
    },
    formGrid: {
        paddingTop: 20,
    },
    buttonProgress: {
        position: 'absolute'
    }
}));

//@TODO: split form and header
const CreateNewSupplierForm = ({
                                   updateOverlayTitle,
                                   scriptsLoadedSuccessfully,
                                   triggerNotification,
                                   restaurantId,
                                   push,
                                   goBack
                               }) => {
    const classes = useStyles();
    const {pageTitle, notificationText} = Hooks()
    const {formValidate, emailTexts, phoneTexts, nameTexts} = FormValidation()
    //extracting restaurant id from base64 encoded IRI
    const decodedRestaurantId = window.atob(restaurantId);
    const segments = decodedRestaurantId.split('/');
    const id = segments.pop();

    const {createSupplier, mutationLoading} = GraphQlFetchData({id});

    const {
        loading: restaurantLoading,
        error: restaurantError,
        data: restaurantData,
        refetch: restaurantRefetch
    } = useQuery(GET_RESTAURANT_QUERY, {
        variables: {id: decodedRestaurantId},
    });

    useEffect(() => {
        updateOverlayTitle(pageTitle);
    }, []);

    //@TODO: Put this in a Hook or sub component ?
    //--- Google Places autocomplete--------
    /*let autocomplete;
    let formikSetFieldValue;

    const geolocate = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                const circle = new window.google.maps.Circle(
                    {center: geolocation, radius: position.coords.accuracy});
                autocomplete.setBounds(circle.getBounds());
            });
        }
    };

    const fillInAddress = () => {
        const place = autocomplete.getPlace();
        formikSetFieldValue('address', place.formatted_address);
    };

    if (scriptsLoadedSuccessfully) {
        autocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById('address')
        );

        autocomplete.setFields(['address_component', 'formatted_address']);
        autocomplete.addListener('place_changed', fillInAddress);
    }*/
    //--------------------------------------------

    //@TODO: validating phone number format ??
    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                phone: '',
            }}
            validationSchema={Yup.object(formValidate)}
            onSubmit={(values, {setSubmitting}) => {
                createSupplier({
                    variables: {
                        name: values.name,
                        email: values.email,
                        phone: values.phone,
                        locale: restaurantData.restaurant.locale
                    }
                }).then(() => {
                    amplitudeClient.logEvent('supplier created');
                    goBack();
                    triggerNotification({variant: 'success', message: notificationText.success});
                }).catch(e => triggerNotification({variant: 'error', message: notificationText.error}));
                setSubmitting(false);
            }}
        >
            {/*@TODO: This should be a separate component*/}
            {({setFieldValue}) => {
                //We need to access setFieldValue outside of the Formik component to populate inputs with data from Google Places autocomplete.
                //@TODO: use local state to avoid using setFieldValue outside of Formik component ??
                //formikSetFieldValue = setFieldValue;

                return (<Form className={classes.form} /*onFocus={geolocate}*/>
                    <Grid container justify={"center"} alignItems={"center"} direction="column"
                          className={classes.formGrid}>
                        <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                            <Field component={BaseTextField} name="name" id="name" type="text"
                                   label={nameTexts.label}
                                   fullWidth
                                   placeholder={nameTexts.placeholder} className={classes.input}
                                   helperText={nameTexts.helperText}/>
                        </Grid>
                        <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                            <Field component={BaseTextField} name="email" id="email" type="email"
                                   label={emailTexts.label} fullWidth
                                   placeholder={emailTexts.placeholder} className={classes.input}
                                   helperText={emailTexts.helperText}/>
                        </Grid>
                        <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                            <Field component={BaseTextField} name="phone" id="phone" type="text"
                                   label={phoneTexts.label} fullWidth
                                   placeholder={phoneTexts.placeholder}
                                   className={classes.input}
                                   helperText={phoneTexts.helperText}
                            />
                        </Grid>
                        <Grid item lg={2} md={3} sm={4} xs={10}>
                            <Button variant="contained" color="primary" size="large" type="submit"
                                    className={classes.connexionButton} disabled={mutationLoading}>
                                <FormattedMessage
                                    id="createSupplierPage.add"
                                    defaultMessage="Ajouter"/>
                                {mutationLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>)
            }
            }
        </Formik>
    );
};

//Script loader avoids to load Google scripts on every pages
//export default ScriptLoader('https://maps.googleapis.com/maps/api/js?key=AIzaSyAPN_7_svR5FjaOyFD6StAZjPgYMx9kYl8&libraries=places')(CreateNewSupplierForm);
export default CreateNewSupplierForm;