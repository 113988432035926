import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {updateSubtitle, updateTitle} from "../../reducers/topNavigation";
import {push} from "connected-react-router";
import UserInformation from "../../components/UserAccount/UserInformation";
import {logout} from "../../reducers/authentication";

const mapStateToProps = state => ({
    user: {email: state.authentication.email}
});

const mapDispatchToProps = (dispatch) => ({
    updateTopNavTitle: (title) => dispatch(updateTitle(title)),
    updateTopNavSubtitle: (title) => dispatch(updateSubtitle(title)),
    push: bindActionCreators(push, dispatch),
    logout: (payload) => dispatch(logout(payload)),
});

const UserAccountPage = connect(mapStateToProps, mapDispatchToProps)(UserInformation);

export default UserAccountPage;