import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import {RESTAURANTS, SUPPORT} from "../../constants/Routes";
import Button from "@material-ui/core/Button";
import SupportWizard from "../Support/SupportWizard";
import CircularProgress from "@material-ui/core/CircularProgress";
import PromotionDiscoveryScreen from "../PromotionDiscoveryScreen";
import GraphQlFetchData from "./graphQlFetchData";

const useStyles = makeStyles(theme => ({
    container: {
        padding: 10
    },
    innerGrid: {
        marginBottom: 8,
    },
    actionButton: {
        marginBottom: 15,
        height: 70
    },
    actionIcon: {
        marginRight: 10,
        fontSize: '2rem'
    },
    loadingContainer: {
        height: '50vh'
    },
    promoButton: {
        backgroundColor: theme.palette.promo.main
    }
}));

const Dashboard = ({
                       updateTopNavTitle,
                       push,
                       setSelectedRestaurant,
                       triggerNotification,
                       unsetActionsComponent,
                       denyAccessToUnauthenticatedUser,
                       displayMenu,
                       updateTopNavSubtitle
                   }) => {


        const {restaurants, restaurantsRefetch, restaurantsError, restaurantsLoading} = GraphQlFetchData()

        const intl = useIntl();
        const classes = useStyles();

        const [supportWizardState, setSupportWizardState] = useState(false);
        const [promoDialogOpen, setPromoDialogOpen] = useState(false);

        const closePromoDialog = () => {
            setPromoDialogOpen(false);
        }

        const openPromoDialog = () => {
            setPromoDialogOpen(true);
        }

        const pageTitle = intl.formatMessage(
            {
                id: 'dashboardPage.title',
                defaultMessage: 'Accueil'
            }
        );

        useEffect(() => {
            (async () => {
                await denyAccessToUnauthenticatedUser();
                await displayMenu();
                await updateTopNavTitle(pageTitle);
                await updateTopNavSubtitle('');
                await unsetActionsComponent();
            })()

        }, [])


        const helpText = intl.formatMessage(
            {
                id: 'dashboard.orderHelpText',
                defaultMessage: 'Sélectionnez le restaurant pour lequel vous souhaitez passer commande'
            }
        );

        const helpTextSupplier = intl.formatMessage(
            {
                id: 'dashboard.orderHelpTextSelectSupplier',
                defaultMessage: 'Sélectionnez un fournisseur pour constituer un panier et passer commande'
            }
        );

        const newOrder = () => {
            if (restaurants.restaurants.length === 1) {
                const encodedId = window.btoa(restaurants.restaurants[0].id);
                setSelectedRestaurant({restaurantId: encodedId});
                push(`${RESTAURANTS}/${encodedId}/suppliers?origin=dashboard`);
                triggerNotification({
                    variant: 'info',
                    message: helpTextSupplier
                });
            } else {
                push(RESTAURANTS);
                triggerNotification({
                    variant: 'info',
                    message: helpText
                });
            }
        };

        return (
            <>
                {restaurants &&
                    <SupportWizard open={supportWizardState} setOpen={setSupportWizardState} restaurants={restaurants}
                                   push={push}/>}
                {restaurants ?
                    <Grid container className={classes.container}>
                        <Grid item xs={12}>
                            <Grid container direction="column" className={classes.actionsContainer}>
                                <Button color="primary" variant="contained" className={classes.actionButton}
                                        onClick={newOrder}>
                                    <ShoppingCartOutlinedIcon className={classes.actionIcon}/>
                                    <FormattedMessage id="dashboard.newOrder"
                                                      defaultMessage="Passer commande"/>
                                </Button>
                                <Button color="secondary" variant="contained" className={classes.actionButton}
                                        onClick={() => setSupportWizardState(true)}>
                                    <HelpOutlineOutlinedIcon className={classes.actionIcon}/>
                                    <FormattedMessage id="dashboard.help"
                                                      defaultMessage="Obtenir de l'aide a propos d'une commande"/>
                                </Button>
                                {/*<Button variant="contained" className={`${classes.actionButton} ${classes.promoButton}`}
                                        onClick={openPromoDialog}>
                                    <ShoppingCartOutlinedIcon className={classes.actionIcon}/>
                                    <FormattedMessage id="dashboard.promotions"
                                                      defaultMessage="Découvrir les promos du moment"/>
                                </Button>*/}
                            </Grid>
                        </Grid>
                        {/*{restaurants && restaurants.restaurants &&
                            <PromotionDiscoveryScreen openState={promoDialogOpen} close={closePromoDialog}
                                                      restaurants={restaurants.restaurants}/>}*/}
                    </Grid> :
                    <Grid container justify="center" alignItems="center" className={classes.loadingContainer}>
                        <CircularProgress/>
                    </Grid>
                }
            </>
        );
    }
;

export default Dashboard;
