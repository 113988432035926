import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import Fab from "@material-ui/core/Fab";
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import IconButton from "@material-ui/core/IconButton";
import {blueGrey} from "@material-ui/core/colors";

import {CircularProgress} from "@material-ui/core";
import amplitudeClient from "../../../amplitudeWrapper";
import Compressor from 'compressorjs';
import GraphQlFetchData from "./graphQlFetchData";

const useStyles = makeStyles(theme => ({
    container: {},
    input: {
        backgroundColor: blueGrey[100],
        borderRadius: 10,
        padding: '5px 10px',
        marginRight: 10,
        flexGrow: 1,
        marginBottom: 3
    },
    fab: {
        flexGrow: 1
    },
    inputFile: {
        width: 0.1,
        height: 0.1,
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1,
    },
    takePictureLabel: {
        display: 'flex',
    },
    takePictureButton: {},
    actionsContainer: {
        width: 'unset',
    },
    circle: {
        color: theme.palette.primary.light,
    }
}));

const ChatForm = ({message, setMessage, supplierId, refetchMessages}) => {
        const classes = useStyles();

        const decodedSupplierId = window.atob(supplierId);
        const supplierSegments = decodedSupplierId.split('/');
        const supplierTrueId = supplierSegments.pop();

    const {createMessage, mutationLoading} = GraphQlFetchData({supplierTrueId})

        const sendMessage = () => {
            createMessage({
                variables: {
                    supplierId: supplierTrueId,
                    content: message.text,
                    important: message.important,
                    picture: message.picture
                }
            })
                .then(() => {
                        refetchMessages();
                        setMessage({
                            supplier: {id: supplierTrueId},
                            message:
                                {
                                    text: null,
                                    picture: null
                                }
                        });
                        document.getElementById("textMessage").value = ' ';
                        amplitudeClient.logEvent('message sent');
                    }
                )
                .catch(e => e);
        };

        const onChange = (e) => setMessage({
            supplier: {id: supplierTrueId},
            message:
                {
                    text: e.target.value,
                    picture: message && message.picture,
                    important: message && message.important,
                }
        });

        const getPicture = (e) => {
            //We want the image in base64
            new Compressor(e.target.files[0], {
                quality: 0.2,
                success(result) {

                    let reader = new FileReader();

                    reader.addEventListener("load", function () {
                        setMessage({
                            supplier: {id: supplierTrueId},
                            message:
                                {
                                    text: message && message.text,
                                    picture: reader.result,
                                    important: message && message.important,
                                }
                        });
                    }, false);

                    reader.readAsDataURL(result);
                    amplitudeClient.logEvent('photo added to message');
                },
                error(err) {
                    console.log(err.message);
                },
            });
        };

        return (
            <Grid container className={classes.container} justify="center" alignItems="center">
                <form id="pictureForm">
                    <input id="picture" type="file" accept="image/*" className={classes.inputFile}
                           onChange={getPicture}/>
                </form>
                <InputBase id="textMessage" multiline className={classes.input} rows={message && message.text ? 6 : 1}
                           rowsMax={6}
                           value={message && message.text}
                           onChange={onChange}/>
                <Grid container item direction="column" className={classes.actionsContainer} justify="center"
                      alignItems="center">
                    <Grid item>
                        <IconButton className={classes.takePictureButton}>
                            <label htmlFor="picture" className={classes.takePictureLabel}>
                                <PhotoCameraOutlinedIcon/>
                            </label>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Fab color="secondary" size="small" style={message && message.text ? null : {display: "none"}}
                             onClick={sendMessage}>
                            {mutationLoading ? <CircularProgress classes={{circle: classes.circle}}/> : <SendOutlinedIcon/>}
                        </Fab>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
;

export default ChatForm;
