import React, {useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from "@material-ui/core/Grid";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import amplitudeClient from "../../amplitudeWrapper";
import {Skeleton} from "@material-ui/lab";
import {UseIcons} from "./hooks";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
        boxShadow: "0px -12px 4px -1px rgba(0,0,0,0.1), 0px 0px 20px 0px rgba(0,0,0,0.1), 0px 1px 5px 0px rgba(0,0,0,0.1)",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        textTransform: 'uppercase',
        fontSize: '0.8rem'
    },
    toolBar: {
        paddingRight: 4
    }
}));

const MainAppBar = ({
                        openDrawer,
                        title,
                        subtitle,
                        ActionsComponent,
                        displayGoBack,
                        push,
                        isLoadingTitle,
                        isLoadingSubTitle,
                        triggerNotification,
                        ...rest
                    }) => {
    const classes = useStyles();

    const onEdit = useCallback(() => {
        if(ActionsComponent)
        ActionsComponent.actionFunction()
        else triggerNotification({
            variant: 'error',
            message: 'Action can not be performed !'
        })
    }, [ActionsComponent])

    const {editIcon, msgIcon} = UseIcons({ActionsComponent, onEdit, triggerNotification})
    return (
        <AppBar position="sticky" color="default" classes={{root: classes.root}}>
            {!rest.isCatalog && <Toolbar className={classes.toolBar}>
                {displayGoBack ? (
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="back"
                                onClick={() => push(displayGoBack)}>
                        <ArrowBackOutlinedIcon/>
                    </IconButton>
                ) : (
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                                onClick={() => {
                                    amplitudeClient.logEvent('open main drawer clicked');
                                    openDrawer();
                                }}>
                        <MenuIcon/>
                    </IconButton>
                )}

                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="h6" className={classes.title}>
                            {isLoadingTitle ? <Skeleton width="40%"/> : title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" className={classes.title}>
                            {isLoadingSubTitle ? <Skeleton width="60%"/> : subtitle}
                        </Typography>
                    </Grid>
                </Grid>

                {rest.icon === "message" && ActionsComponent !== null &&
                msgIcon()}
                {rest.icon === "edit" && ActionsComponent !== null &&
                editIcon()
                }

            </Toolbar>}
            {
                rest.isCatalog && <Toolbar className={classes.toolBar}>
                    <Grid container justify={'center'} style={{textTransform: 'UPPERCASE'}}>
                        {title} - {subtitle}
                    </Grid>
                </Toolbar>
            }
        </AppBar>
    );
};

export default MainAppBar;
