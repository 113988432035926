import List from "@material-ui/core/List";
import React from "react";
import * as Routes from '../../../constants/Routes';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import DrawerListItem from "../DrawerListItem";
import {useIntl} from "react-intl";

const LeftDrawerSecondaryList = ({closeDrawer, push, pathname, updateTopNavTitle}) => {

    const intl = useIntl();

    const myAccountTitle = intl.formatMessage(
        {
            id: 'drawerMenu.myAccount',
            defaultMessage: 'Mon compte'
        }
    );

    const supportTitle = intl.formatMessage(
        {
            id: 'drawerMenu.support',
            defaultMessage: 'Support'
        }
    );


    const itemList = [
        {
            path: Routes.ACCOUNT,
            text: myAccountTitle,
            icon: AccountBoxOutlinedIcon,
        },
        {
            path: Routes.SUPPORT,
            text: supportTitle,
            icon: ContactSupportOutlinedIcon,
        },
    ];

    return (
        <List>
            {itemList.map((item, index) => (
                <DrawerListItem push={push} currentPath={pathname} item={item} key={index}
                                updateTopNavTitle={updateTopNavTitle}/>
            ))}
        </List>
    )
};

export default LeftDrawerSecondaryList;
