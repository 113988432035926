import {connect} from 'react-redux';
import RestaurantsList from "../../components/Restaurants/RestaurantsList";
import {push} from "connected-react-router";
import {bindActionCreators} from "redux";
import {triggerToastNotification} from "../../reducers/toastNotification";
import {updateTitle, updateSubtitle, unsetActionsComponent} from "../../reducers/topNavigation";
import {denyAccessToUnauthenticatedUser} from "../../reducers/authentication";
import {displayMenu} from "../../reducers/topNavigation";
import {setSelectedRestaurant} from "../../reducers/restaurants";

const mapStateToProps = state => ({
    userType: state.authentication.userType
});

const mapDispatchToProps = (dispatch) => ({
    updateTopNavTitle: (title) => dispatch(updateTitle(title)),
    updateTopNavSubtitle: (subtitle) => dispatch(updateSubtitle((subtitle))),
    push: bindActionCreators(push, dispatch),
    triggerNotification: (payload) => dispatch(triggerToastNotification(payload)),
    denyAccessToUnauthenticatedUser: () => dispatch(denyAccessToUnauthenticatedUser()),
    unsetActionsComponent: (payload) => dispatch(unsetActionsComponent()),
    displayMenu: (payload) => dispatch(displayMenu(payload)),
    setSelectedRestaurant: (payload) => dispatch(setSelectedRestaurant(payload))
});

const RestaurantsPage = connect(mapStateToProps, mapDispatchToProps)(RestaurantsList);

export default RestaurantsPage;
