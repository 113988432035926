import {useIntl} from 'react-intl';

const Hooks = () => {
    const pageTitle = useIntl().formatMessage(
        {
            id: 'newProductPage.title',
            defaultMessage: 'Créer un nouveau produit'
        }
    );

    const notificationText = {
        success: useIntl().formatMessage(
            {
                id: 'createProductPage.SuccessNotificationText',
                defaultMessage: 'Produit créé avec succès'
            }
        ),
        error: useIntl().formatMessage(
            {
                id: 'createProductPage.errorNotificationText',
                defaultMessage: 'Une erreur est survenue lors de la création d\'un nouveau produit.'
            }
        ),
    };

    return {
        pageTitle, notificationText
    }
}
export default Hooks
