import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import React from "react";
import {fade, makeStyles} from "@material-ui/core";
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

const useStyles = makeStyles(theme => ({
    search: {
        flexGrow: 1,
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.grey.A100, 0.25),
        '&:hover': {
            backgroundColor: fade(theme.palette.grey.A100, 0.50),
        },
        margin: '10px 5px 10px 5px'
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.grey.A700
    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        marginRight: 33,
        position: 'relative',
        zIndex: 1200,
    },
    clearIcon: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: theme.palette.grey[300],
        padding: 5,
        zIndex: 1100,
    }
}));

const SearchBar = ({placeholder, onChange, value}) => {
    const classes = useStyles();

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon/>
            </div>
            {value && <div className={classes.clearIcon}>
                <ClearOutlinedIcon onClick={() => {
                    onChange({target: {value: ""}});
                }}/>
            </div>}
            <InputBase
                placeholder={placeholder}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{'aria-label': 'search'}}
                onChange={onChange}
                value={value}
                onFocus={(e) => e.target.select()}
            />
        </div>
    )
};

export default SearchBar;
