import {gql} from "apollo-boost";

export const GET_TOP_CATEGORIES_QUERY = gql`
    query getTopCategories($locale: String!) {
        getTopCategories(locale: $locale) {
            _id
            locale
            taxonId
            position
            name
        }
    }
`;