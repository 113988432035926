import React from "react";
import PageWrapper from "../PageWrapper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage} from 'react-intl';
import {random} from 'lodash'
import ReactTimeout from 'react-timeout'

const useStyles = makeStyles(theme => ({
    container: {},
    heading: {
        color: 'white',
        textTransform: 'uppercase',
        marginTop: '10vh',
        fontSize: '1.2rem',
        fontWeight: 500
    },
    logo: {
        height: '8rem',
        marginLeft: 0,
        marginTop: '5vh'
    },
    loader: {
        marginTop: '20vh',
        paddingLeft: 24
    }
}));

const UpdateScreen = ({updated, setTimeout}) => {

    const classes = useStyles();

    setTimeout(() => {
        updated();
    }, 1000*random(4,6));

    return (
        <PageWrapper backgroundStyle="bgPrimary">
            <Grid container direction="column" className={classes.container} alignItems="center"
                  justify="space-between">
                <Grid item>
                    <img src="logo192.png" className={classes.logo} alt="Foodomarket"/>
                </Grid>
                <Grid item>
                    <Typography align="center" className={classes.heading}>
                        <FormattedMessage id="updateScreen.text2" defaultMessage="Foodomarket se met à jour"/>
                    </Typography>
                </Grid>
                <Grid item className={classes.loader}>
                    <div className="loader-container">
                        <div className="loading"><i></i><i></i><i></i><i></i></div>
                    </div>
                </Grid>
            </Grid>
        </PageWrapper>
    );
};

export default ReactTimeout(UpdateScreen);
