import {createSlice} from "@reduxjs/toolkit";

const initialState =
    {
        title: '',
        subtitle: '',
        ActionsComponent: null,
        displayGoBack: false,
        isLoadingTitle: false,
        isLoadingSubTitle: false
    };

const topNavigationSlice = createSlice({
    name: 'topNavigation',
    initialState: initialState,
    reducers: {
        updateTitle(state, action) {
            if(action.payload === 'isLoading') state.isLoadingTitle = true;
            else {
                state.title = action.payload;
                state.isLoadingTitle = false
            }
        },
        updateSubtitle(state, action) {
            if(action.payload === 'isLoading') state.isLoadingSubTitle = true;
            else {
                state.subtitle = action.payload;
                state.isLoadingSubTitle = false;
            }
        },
        setActionsComponent(state, action) {
            state.ActionsComponent = action.payload;
        },
        updateActionsComponent(state, action) {
            state.ActionsComponent = {...state.ActionsComponent, data: action.payload.data}   },
        unsetActionsComponent(state, action) {
            state.ActionsComponent = null;
        },
        displayGoBack(state, action) {
            state.displayGoBack = action.payload;
        },
        displayMenu(state, action) {
            state.displayGoBack = false;
        }
    }
});

export const {
    updateTitle,
    updateSubtitle,
    setActionsComponent,
    unsetActionsComponent,
    displayGoBack,
    displayMenu,
    updateActionsComponent
} = topNavigationSlice.actions;
export default topNavigationSlice.reducer;

