import {gql} from "apollo-boost";

export const PLACE_ORDER_MUTATION = gql`
    mutation createOrder($cartId: Int!, $origin: String, $creditCard: String, $saveCard: Boolean, $clientMutationId: String) {
        createOrder(
            input:{
                cartId: $cartId
                origin: $origin
                creditCard: $creditCard
                saveCard: $saveCard
                clientMutationId: $clientMutationId
            }) {
            order {
                _id
                id
                deliveryDate
                deliveryFeesWithVat
                restaurant {
                    name
                    id
                    _id
                }
                supplier {
                    name
                    id
                    _id
                }
                items {
                    edges {
                        node {
                            name
                        }
                    }
                }
                currency
                status
                paymentStatus
                total: totalWithVat
                payment {
                    paymentUrl
                    sessionId
                    publishableKey
                    paymentMethodId
                    clientSecret
                }
            }
        }
    }
`;

export const UPDATE_LYRA_ALIAS = gql`
    mutation UpdateLyraAlias($aliasId: String!, $label: String!, $supplierId: Int!) {
        updateCreditCard(input: {cardId: $aliasId, label: $label, supplierId: $supplierId}) {
            creditCard{
                _id
                id
                brand
                expirationDate
                number
                label
                paymentProvider
            }
        }
    }
`;

export const DELETE_LYRA_ALIAS = gql`
    mutation DeleteLyraAlias($aliasId: String!, $supplierId: Int!) {
        deleteCardAlias: removeCreditCard(input: {cardId: $aliasId, supplierId: $supplierId}) {
            creditCard{
                _id
                id
            }
        }
    }
`;
