import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import {indexOf} from "lodash";
import categories from "../../Products/categories";
import {FormattedMessage} from "react-intl";
import Button from "@material-ui/core/Button";
import {RESTAURANTS} from "../../../constants/Routes";

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: '15px',
        gridGap: "20px",
        padding: '5px 10px'
    },
    content: {
        backgroundColor: "#ffff",
        "&:hover": {
            opacity: 0.8
        },
        boxShadow: "0px 8px 7px -1px rgb(180 201 219 / 20%), 0px 0px 10px 1px rgb(180 201 219 / 10%), 0px 1px 3px 1px rgb(180 201 219 / 10%)",
        borderRadius: 5,
        padding: '4px 5px'
    },
    leftBloc: {
        height: '100px',
    },
    rightBloc: {
        cursor: 'pointer'
    },
    title: {
        fontWeight: 500,
        fontSize: '18px'
    },
    subTitle: {
        fontSize: '12px',
        '& span': {
            marginRight: 5
        }
    },
    image: {
        width: '100%',
        height: '90px',
        objectFit: 'cover',
        overflow: 'hidden',
        borderRadius: '10px'
    },
    noData: {
        fontWeight: 400,
        fontSize: '1.5em'
    },
    closeButton: {
        borderRadius: 0,
        width: '100%',
        position: 'fixed',
        bottom: 57,
    },
    shoppingListButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: 0,
        width: '100%',
        position: 'fixed',
        bottom: 95,
    },
}));

const MainCatalog = ({
                         restaurantData,
                         availableCategories,
                         totalProductsByCat,
                         onSelectCategory,
                         push,
                         encodedRestaurantId,
                         encodedSupplierId
                     }) => {
    const classes = useStyles();

    const goToSuppliers = () => {
        push(`${RESTAURANTS}/${encodedRestaurantId}/suppliers`);
    }

    const goToShoppingList = () => {
        push(`${RESTAURANTS}/${encodedRestaurantId}/suppliers/${encodedSupplierId}`);
    }

    return (
        <Grid justify={'center'} container className={classes.container}>
            {categories[restaurantData?.restaurant?.address?.countryCode || "FR"].map((category, index) => {
                if (indexOf(availableCategories, category.id) !== -1) {
                    const taxonCategory = totalProductsByCat(category.id);
                    const totalProducts = taxonCategory.node.products.edges.length;
                    return (
                        <Grid key={index} item container xs={12} md={12} xl={4} spacing={1} className={classes.content}
                              onClick={() => onSelectCategory((category))}>
                            <Grid item xs={4} md={2} className={classes.leftBloc}>
                                <img className={classes.image}
                                     src={"/images" + category?.image}
                                     alt={category.image}/>
                            </Grid>
                            <Grid item xs={8} md={10} className={classes.rightBloc}>
                                <Grid>
                                    <Grid item>
                                        <Grid className={classes.title}>
                                            {category.name}</Grid>
                                        <Grid className={classes.subTitle}>
                                            <span>{totalProducts}</span>
                                            <FormattedMessage
                                                id={totalProducts > 1 ? "catalog.categoriesBoxSubTitle" : "catalog.categoryBoxSubTitle"}
                                                defaultMessage="produits disponibles"/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                }
            })}
            {availableCategories.length === 0 && <Grid className={classes.noData}><FormattedMessage
                id={"catalog.noData"}
                defaultMessage="la mercuriale est vide"/>&nbsp;!</Grid>}
            <Button className={classes.shoppingListButton} onClick={goToShoppingList}><FormattedMessage
                id="mainCatalog.order" defaultMessage="Passer une commande"/></Button>
            <Button className={classes.closeButton} onClick={goToSuppliers}><FormattedMessage
                id="mainCatalog.closeButton" defaultMessage="Fermer"/></Button>
        </Grid>
    );
};

export default MainCatalog;
