/**
 * Gets the symbol for a currency code
 *
 * @param {String|undefined|null} code
 * @returns {string}
 */
export const symbol = (code) => {
    switch (code) {
        case 'GBP':
            return '£';
        case 'USD':
        case 'MXN':
            return '$';
        case 'EUR':
        default:
            return '€';
    }
}

/**
 * Gets the formatted price for display, given a currency code
 *
 * @param {String|float} value
 * @param {String|undefined|null} code
 * @returns {string}
 */
export const price = (value, code) => {
    switch (code) {
        case 'GBP':
        case 'USD':
        case 'MXN':
            return symbol(code) + ` ${value}`;
        case 'EUR':
        default:
            return `${value} ` + symbol(code)
    }
};