import {createSlice} from "@reduxjs/toolkit";

const initialState =
    {};

const supportSlice = createSlice({
    name: 'support',
    initialState: initialState,
    reducers: {
        sendMessageToSupport(state, action) {
        }
    }
});

export const {sendMessageToSupport} = supportSlice.actions;
export default supportSlice.reducer;