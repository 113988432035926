import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    wrapper: {
        minHeight: '100vh',
        marginBottom: '-60px'
    },
    bgPrimary: {
        backgroundColor: theme.palette.primary.main,
    }
}));

const PageWrapper = ({children, backgroundStyle}) => {
    const classes = useStyles();
    return (
        <Grid container direction={"column"} className={`${classes.wrapper} ${classes[backgroundStyle]}`}>
            {children}
        </Grid>
    );
};

export default PageWrapper;
