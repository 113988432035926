import React from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {GET_ALIAS_QUERY, GET_ALIASES_QUERY} from "../ShippingDetails/queries";
import {DELETE_LYRA_ALIAS} from "../ShippingDetails/mutations";
import {remove} from "lodash";
import {cloneDeep} from "@apollo/client/utilities/common/cloneDeep";

const GraphQlFetchData = ({aliasId, supplierId}) => {

    const {
        data: aliasData,
        error: aliasError,
        loading: aliasLoading,
        refetch: aliasRefetch
    } = useQuery(GET_ALIAS_QUERY, {variables: {aliasId: aliasId, supplierId: supplierId}});

    const [deleteAlias, {loading: mutationLoading}] = useMutation(DELETE_LYRA_ALIAS, {
        update(cache, mutationData) {
            const {cardAliases} = cache.readQuery({query: GET_ALIASES_QUERY, variables: {supplierId: supplierId}});
            let aliases = cloneDeep(cardAliases);

            remove(aliases, (alias) => {
                return alias._id === aliasId;
            });

            cache.writeQuery({
                query: GET_ALIASES_QUERY,
                data: {cardAliases: aliases},
                variables: {supplierId: supplierId}
            });
        }
    });


    return (
        {deleteAlias, mutationLoading}
    )
}

export default GraphQlFetchData;

