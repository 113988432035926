import React from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {GET_ALIAS_QUERY} from "../ShippingDetails/queries";
import {UPDATE_LYRA_ALIAS} from "../ShippingDetails/mutations";


const GraphQlFetchData = ({aliasId, supplierId}) => {
    const {
        data: aliasData,
        error: aliasError,
        loading: aliasLoading,
        refetch: aliasRefetch
    } = useQuery(GET_ALIAS_QUERY, {variables: {aliasId: aliasId, supplierId: supplierId}});

    const [updateAlias, {loading: mutationLoading}] = useMutation(UPDATE_LYRA_ALIAS, {
        update(cache, mutationData) {
        }
    });

    return (
        {aliasData, aliasError, aliasLoading, aliasRefetch, updateAlias, mutationLoading}
    )
}

export default GraphQlFetchData;

