import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {denyAccessToUnauthenticatedUser} from "../../reducers/authentication";
import PaymentCallback from "../../components/Orders/PaymentCallback";

const mapStateToProps = state => {
    const authResult = state.router.location.query.vads_auth_result;
    const transactionStatus = state.router.location.query.vads_trans_status;
    const stripeStatus = state.router.location.query.stripeStatus;
    return {
        authResult: authResult,
        transactionStatus: transactionStatus,
        stripeStatus: stripeStatus
    }
};

const mapDispatchToProps = (dispatch) => ({
    push: bindActionCreators(push, dispatch),
    denyAccessToUnauthenticatedUser: (payload) => dispatch(denyAccessToUnauthenticatedUser(payload)),
});

const PaymentCallbackPage = connect(mapStateToProps, mapDispatchToProps)(PaymentCallback);

export default PaymentCallbackPage;
