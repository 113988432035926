import React from 'react';
import * as Routes from '../../constants/Routes';
import {makeStyles} from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import HomeIcon from '@material-ui/icons/Home';
import amplitudeClient from "../../amplitudeWrapper";
import {PROFESSIONAL} from "../../constants/UserTypes";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 1000,
        boxShadow: '0px -3px 10px 0px rgba(0,0,0,0.2)'
    },
    action: {
        color: theme.palette.grey[400]
    }
}));

const MainBottomBar = ({push, pathname, userType, selectedRestaurantId}) => {
    const classes = useStyles();

    const goToOrdersList = () => {
        amplitudeClient.logEvent('order list displayed', {origin: 'bottom navigation'});
        push(Routes.ORDERS);
    };


    return (
        <>
            <BottomNavigation value={pathname} className={classes.root}
                              showLabels
                              className={classes.root}>
                <BottomNavigationAction  label={<FormattedMessage
                    id="bottomBar.home"
                    defaultMessage="Accueil"/>} value="/" className={classes.action} icon={<HomeIcon/>}
                                        onClick={() => {
                                            push(Routes.DASHBOARD);
                                        }}/>
                <BottomNavigationAction label={<FormattedMessage
                    id="bottomBar.restaurants"
                    defaultMessage="Restaurants"/>} value="/restaurants" className={classes.action}
                                        icon={<StoreMallDirectoryOutlinedIcon/>}
                                        onClick={() => {
                                            push(Routes.RESTAURANTS);
                                        }}/>
                <BottomNavigationAction label={<FormattedMessage
                    id="bottomBar.orders"
                    defaultMessage="Mes Achats"/>} value="/orders" className={classes.action}
                                        icon={<ListAltOutlinedIcon/>} onClick={goToOrdersList}/>
            </BottomNavigation>
        </>
    );
};

export default MainBottomBar;

