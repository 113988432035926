import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import {useMutation, useQuery} from "@apollo/react-hooks";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import {GET_ALIAS_QUERY} from "../ShippingDetails/queries";
import {Skeleton} from "@material-ui/lab";
import {UPDATE_LYRA_ALIAS} from "../ShippingDetails/mutations";
import LinearProgress from "@material-ui/core/LinearProgress";
import {FormattedMessage} from "react-intl";
import GraphQlFetchData from "./graphQlFetchData";

const EditCardDialog = ({aliasId, close, supplierId}) => {
    const processField = (e) => {
        setCardLabel(e.target.value)
    }

    //supplierId = parseInt(supplierId);

    const {
        aliasLoading,
        aliasData,
        aliasError,
        aliasRefetch,
        mutationLoading,
        updateAlias
    } = GraphQlFetchData({aliasId, supplierId})

    const [cardLabel, setCardLabel] = useState();

    useEffect(() => {
        const onCompleted = (aliasData) => {
            if (aliasData && aliasData.cardAlias) {
                setCardLabel(aliasData.cardAlias.label)
            }
        };
        if (onCompleted) {
            if (onCompleted && !aliasLoading && !aliasError) {
                onCompleted(aliasData);
            }
        }
    }, [aliasLoading, aliasData, aliasError]);

    const validateEdit = () => {
        console.log(supplierId);
        updateAlias({
            variables: {
                aliasId: aliasData.cardAlias._id,
                label: cardLabel,
                supplierId: supplierId
            }
        }).then(() => {
            close();
        })
            .catch((e) => console.log(e))
    }

    return (
        <Dialog
            open={!!aliasId}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                id="alert-dialog-title">{<FormattedMessage id="editCard.Editerlacarte"
                                                           defaultMessage="Editer la carte"/>}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage id="editCard.dialogText"
                                      defaultMessage="Entrez un nom pour la carte"/>
                </DialogContentText>
                {aliasData && aliasData.cardAlias ? (
                    <TextField id="standard-basic" label="Nom de la carte" value={cardLabel ? cardLabel : ""}
                               disabled={mutationLoading}
                               onChange={processField}/>) : (
                    <Skeleton/>)}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={validateEdit}>
                    <FormattedMessage id="editCard.save"
                                      defaultMessage="Sauvegarder"/>
                </Button>
                <Button onClick={close}
                        autoFocus>
                    <FormattedMessage id="editCard.cancel"
                                      defaultMessage="Annuler"/>
                </Button>
            </DialogActions>
            {mutationLoading ? <LinearProgress/> : null}
        </Dialog>
    )
}

export default EditCardDialog;

