import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {FormattedMessage} from "react-intl";

const NoDeliveryNotification = ({supplier}) => {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage id="noDeliveryNotifications.text"
                                      defaultMessage="Les commandes chez"/> {supplier.name} <FormattedMessage
                    id="noDeliveryNotifications.text2" defaultMessage="sont momentanément indisponible."/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    <FormattedMessage id="noDeliveryNotifications.close" defaultMessage="Fermer"/>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NoDeliveryNotification;