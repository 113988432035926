import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import {FormattedMessage, useIntl} from "react-intl";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
//import Button from "@material-ui/core/Button";
//import {CONTACT_US} from "../../../constants/Routes";
import {blueGrey} from "@material-ui/core/colors";
import {LinearProgress} from "@material-ui/core";
import GraphQlFetchData from "./graphQlFetchData";

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.primary.main,
        height: 100,
        width: '100%',
        marginBottom: 80,
    },
    iconContainer: {
        borderRadius: 120,
        height: 120,
        width: 120,
        backgroundColor: 'white',
        marginTop: 40,
    },
    iconWrapper: {
        height: '100%',
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: '5rem'
    },
    text: {
        padding: 10,
    },
    list: {
        backgroundColor: blueGrey[50],
        borderRadius: 4,
        marginBottom: 10
    }
}));

//@TODO: split header
const SupportInformation = ({push, updateTopNavTitle, unsetActionsComponent}) => {
    const classes = useStyles();
    const intl = useIntl();
    const [displayPhoneNumber, setDisplayPhoneNumber] = useState("")
    const pageTitle = intl.formatMessage(
        {
            id: 'supportPage.title',
            defaultMessage: 'Support'
        }
    );

    useEffect(() => {
        updateTopNavTitle(pageTitle);
        unsetActionsComponent();
    }, [])

    const {
        restaurantError, restaurantRefetch, restaurantData, restaurantLoading
    } = GraphQlFetchData({})
    {
        restaurantData && restaurantData.restaurant && restaurantData.restaurant.address &&
        console.log(restaurantData.restaurant.address.country)
    }

    useEffect(() => {

        let countryFlag = restaurantData?.restaurant?.address.country || {};
        switch (countryFlag) {
            case "FR":
                setDisplayPhoneNumber("01 71 54 61 45");
                break;
            case "BE":
                setDisplayPhoneNumber("02 897 87 26");
                break;
            case "ES":
                setDisplayPhoneNumber("919 34 73 09");
                break;
            case "GB" :
                setDisplayPhoneNumber("020 7113 4982");
                break;
            default:
                setDisplayPhoneNumber("01 71 54 61 45");

        }
    }, [restaurantData])
    return (
        <>
            <Grid item container className={classes.header} justify={"center"}>
                <Grid item container justify={"center"} alignItems={"center"}>
                    <Paper className={classes.iconContainer}>
                        <Grid container justify={"center"} alignItems={"center"} className={classes.iconWrapper}>
                            <Grid item>
                                <HelpOutlineOutlinedIcon className={classes.icon}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="h6" gutterBottom align={"center"}>
                    <FormattedMessage id="supportPage.heading" defaultMessage="Contact"/>
                </Typography>
                <Typography variant={"body1"} gutterBottom align={"center"} className={classes.text}>
                    <FormattedMessage id="supportPage.subHeading"
                                      defaultMessage="Un problème ? Une suggestion ? Quelque soit votre question, vous pouvez nous connecter via :"/>
                </Typography>
            </Grid>
            <Grid item container justify={"center"}>
                <List className={classes.list}>

                    {restaurantData !== undefined && restaurantData.restaurant !== undefined && restaurantData.restaurant.address !== undefined &&

                    <Grid>
                        <ListItem button onClick={() => window.location.href = "tel:" + displayPhoneNumber}>
                            <ListItemIcon>
                                <PhoneOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText primary={displayPhoneNumber}/>
                        </ListItem>
                    </Grid>

                    }
                    {(restaurantLoading || restaurantData === undefined) && <LinearProgress/>}
                    <ListItem button onClick={() => window.location.href = "mailto:hello@foodomarket.com"}>
                        <ListItemIcon>
                            <EmailOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary="hello@foodomarket.com"/>
                    </ListItem>
                </List>
            </Grid>
            {/*<Grid item>
                <Typography variant="body1" gutterBottom align="center" className={classes.text}>
                    <FormattedMessage id="supportPage.inAppText" defaultMessage="Ou directement dans l'application :"/>
                </Typography>
            </Grid>
            <Grid item container justify="center">
                <Button variant="contained" color="secondary"
                        onClick={() => push(CONTACT_US, {modal: true, fullHeight: false})}>
                    <FormattedMessage id="supportPage.contactUsButtonText" defaultMessage="Nous contacter"/>
                </Button>
            </Grid>*/}
        </>
    );
};

export default SupportInformation;