import List from "@material-ui/core/List";
import React from "react";
import * as Routes from '../../../constants/Routes';
import {useIntl} from "react-intl";
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import HomeIcon from '@material-ui/icons/Home';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import DrawerListItem from "../DrawerListItem";
import {PROFESSIONAL} from "../../../constants/UserTypes";

const LeftDrawerPrimaryList = ({closeDrawer, push, pathname, updateTopNavTitle, user, selectedRestaurantId}) => {

    const intl = useIntl();

    const dashboardTitle = intl.formatMessage(
        {
            id: 'drawerMenu.dashboardTitle',
            defaultMessage: 'Accueil'
        }
    );

    const restaurantsTitlePro = intl.formatMessage(
        {
            id: 'drawerMenu.restaurantsTitle',
            defaultMessage: 'Restaurants'
        }
    );

    const suppliersTitle = intl.formatMessage(
        {
            id: 'drawerMenu.suppliersTitle',
            defaultMessage: 'Fournisseurs'
        }
    );

    const ordersTitle = intl.formatMessage(
        {
            id: 'drawerMenu.ordersTitle',
            defaultMessage: 'Commandes'
        }
    );

    const itemListPro = [
        {
            path: Routes.DASHBOARD,
            text: dashboardTitle,
            icon: HomeIcon
        },
        {
            path: Routes.RESTAURANTS,
            text:restaurantsTitlePro,
            icon: StoreMallDirectoryOutlinedIcon,
        },
       /* {
            path: Routes.SUPPLIERS,
            text: suppliersTitle,
            icon: LocalShippingOutlinedIcon,
        },*/
        {
            path: Routes.ORDERS,
            text: ordersTitle,
            icon: ListAltOutlinedIcon,
        },
    ];

    const itemListIndividual = [
        {
            path: `/restaurants/${selectedRestaurantId}/suppliers`,
            text: suppliersTitle,
            icon: LocalShippingOutlinedIcon,
        },
        {
            path: Routes.ORDERS,
            text: ordersTitle,
            icon: ListAltOutlinedIcon,
        },
    ];

    const items = user.userType === PROFESSIONAL ? itemListPro : itemListIndividual;

    return (
        <List>
            {items.map((item, index) => (
                <DrawerListItem push={push} currentPath={pathname} item={item} key={index} updateTopNavTitle={updateTopNavTitle}/>
            ))}
        </List>
    )
};

export default LeftDrawerPrimaryList;
