import React from "react";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {FormattedMessage, useIntl} from "react-intl";
import ProductCard from "../../Products/ProductCard";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import Chip from "@material-ui/core/Chip";
import {blueGrey} from "@material-ui/core/colors";
import {map} from 'lodash';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import clsx from 'clsx';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import amplitudeClient from "../../../amplitudeWrapper";
import {useQuery} from "@apollo/react-hooks";
import {GET_SUPPLIER_QUERY} from "../../Suppliers/SuppliersList/queries";
import FetchingError from "../../Errors/FetchingError";
import {INDIVIDUAL, PROFESSIONAL} from "../../../constants/UserTypes";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import RemoveShoppingCartOutlinedIcon from '@material-ui/icons/RemoveShoppingCartOutlined';
import GeneralPurposeCard from "../../GeneralPurpose/GeneralPurposeCard";
import CardContent from "@material-ui/core/CardContent";
import {normalizeCartId} from "../../../reducers/carts";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import {price} from "../../../utils/currency";
//import graphql hook
import GraphQlFetchData from "./graphQlFetchData";
import FormStep from "../../Commun/FormStep";
import {useShoppingCart} from "../../Suppliers/ShoppingCart/hook";
import {validateShoppingCart} from "../utils";

//@TODO: put stepper in its own component, with local state
const useStyles = makeStyles(theme => ({
    container: {
        padding: 10,
    },
    innerGrid: {
        marginBottom: 8,
    },
    search: {
        flexGrow: 1
    },
    topContainer: {
        padding: 10
    },
    totalBlock: {
        marginTop: 20,
    },
    actionBlock: {
        marginTop: 20
    },
    total: {
        backgroundColor: blueGrey[100]
    },
    stepper: {
        padding: '0 10px 20px'
    },
    active: {
        color: theme.palette.primary.main
    },
    completed: {},
    root: {
        color: blueGrey[400]
    },
    alertDialogHeader: {
        backgroundColor: theme.palette.error.main,
        color: 'white',
        textAlign: 'center',
        fontSize: '1rem'
    },
    alertDialogIcon: {
        fontSize: '2rem'
    },
    alertDialogText: {
        fontSize: '0.9rem',
        textAlign: 'center',
        marginTop: 15,
        fontWeight: 600
    },
    amoutRemaining: {
        display: 'block',
        fontWeight: '700',
        fontSize: '1.5rem',
        marginTop: 20,
        borderRadius: 50,
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[800],
        marginBottom: 20,
        paddingLeft: 11
    },
    amountWarningBox: {
        backgroundColor: "#ccf4ff",
        padding: '10px 20px',
        borderRadius: 4,
        marginTop: 15,
    },
    amountWarningText: {
        fontSize: '0.8rem',
        color: theme.palette.info.contrastText,
    },
    infoIcon: {
        marginRight: 15
    },
    emptyCart: {
        marginBottom: 20
    },
    emptyCartDialogTitle: {
        fontSize: '1rem',
        fontWeight: '500',
        color: 'white',
        backgroundColor: theme.palette.warning.main,
        marginBottom: 15
    },
    emptyCartDialogIcon: {
        marginBottom: 5
    },
    shippingFeesCardContent: {
        paddingBottom: '16px !important'
    },
    shippingFeesIcon: {
        fontSize: '2rem',
        marginRight: 30
    },
    shippingFees: {
        marginLeft: 20,
        backgroundColor: theme.palette.grey[100],
        padding: '5px 20px',
        borderRadius: '50px',
    },
    shippingFeesText: {
        fontWeight: 600,
        fontSize: '0.9rem',
        textTransform: 'uppercase'
    }
}));

const CartSummary = ({
                         push,
                         cartId,
                         userType,
                         updateOverlayTitle,
                         restaurantId,
                         supplierId,
                         setCurrentCartId,
                         locale
                     }) => {
    const classes = useStyles();
    const intl = useIntl();
    //@TODO: put the two dialogs in a separate components
    const [alertOpen, setAlertOpen] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const {shoppingCart: cart, addToShoppingCart, removeFromShoppingCart, clearCart, addCartItemNote} = useShoppingCart(parseInt(cartId));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const closeAlert = () => {
        setAlertOpen(false);
    };

    const emptyCart = () => {
        clearCart({
            variables: {
                id: parseInt(cartId),
            }
        });
        handleClose();
    };

    const buttonText = intl.formatMessage(
        {
            id: 'cartSummary.buttonText',
            defaultMessage: 'Passer commande'
        }
    );

    const totalApprox = intl.formatMessage(
        {
            id: 'cartSummary.totalApprox',
            defaultMessage: 'Total (approximatif)'
        }
    );

    const totalInclVAT = intl.formatMessage(
        {
            id: 'cartSummary.totalInclVAT',
            defaultMessage: 'Total (TTC)'
        }
    );

    let canOrder = true;

    if (userType === INDIVIDUAL && cart.total < 10) {
        canOrder = false;
    }

    const decodedRestaurantId = window.atob(restaurantId);
    const restaurantSegments = decodedRestaurantId.split('/');
    const restaurantTrueId = parseInt(restaurantSegments.pop());

    const decodedSupplierId = window.atob(supplierId);
    const supplierSegments = decodedSupplierId.split('/');
    const supplierTrueId = parseInt(supplierSegments.pop());

    const {supplierData, supplierRefetch, supplierError, supplierLoading} = GraphQlFetchData({
        supplierTrueId,
        restaurantTrueId
    })

    if (supplierLoading) {
        updateOverlayTitle('isLoading');
    }

    let isMinOrderReached, formattedRemainingOrderAmount;

    if (supplierData && cart) {
        updateOverlayTitle(supplierData.supplier.name);
        const valid = validateShoppingCart(cart, supplierData.supplier.orderUnit, supplierData.supplier.minOrderAmount, supplierData.supplier.currency);
        isMinOrderReached = valid.isMinOrderReached;
        formattedRemainingOrderAmount = valid.formattedRemainingOrderAmount;
    }

    const cartIcon = () => {
        return (
            <div
                className={clsx({
                    [classes.active]: classes.active,
                })}
            >
                <ShoppingCartOutlinedIcon/>
            </div>
        )
    };

    const shippingIcon = () => {
        return (
            <div
                className={classes.root}
            >
                <LocalShippingOutlinedIcon/>
            </div>
        )
    };

    const checkOutIcon = () => {
        return (
            <div>
                <PaymentOutlinedIcon/>
            </div>
        )
    };

    const validateCart = () => {
        amplitudeClient.logEvent('cart validated');
        setCurrentCartId(normalizeCartId(restaurantId, supplierId));
        push(`/restaurants/${restaurantId}/suppliers/${supplierId}/shipping/${cartId}`)
    };

    if (isMinOrderReached) {
        amplitudeClient.logEvent('min order amount not reach dialog displayed');
    }

    const refetchData = () => {
        supplierRefetch().then(null).catch(e => null);
    };

    if (supplierError) {
        return (
            <FetchingError action={refetchData}/>);
    }
    const stepperData = [
        {
            title: "checkout.stepperCart",
            defaultTitle: "Panier",
            icon: cartIcon
        },
        {
            title: "checkout.stepperShipping",
            defaultTitle: "Livraison",
            icon: shippingIcon
        },
        {
            title: "checkout.stepperCheckout",
            defaultTitle: "Paiement",
            icon: checkOutIcon
        }
    ];

    return (
        <>
            {cart && cart.items?.edges && supplierData ? (
                    <>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title" align="center" className={classes.emptyCartDialogTitle}
                                         disableTypography>
                                <Grid container justify="center" direction="column" alignItems="center">
                                    <RemoveShoppingCartOutlinedIcon className={classes.emptyCartDialogIcon}/>
                                    <FormattedMessage id="cartSummary.emptyCartConfirmHeading"
                                                      defaultMessage="Vider le panier"/>
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <FormattedMessage id="cartSummary.emptyCartConfirm"
                                                      defaultMessage="Êtes-vous sûr de vouloir vider votre panier ?"/>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={emptyCart} color="secondary">
                                    <FormattedMessage id="cartSummary.emptyCartConfirmYes" defaultMessage="Oui"/>
                                </Button>
                                <Button onClick={handleClose} color="secondary" autoFocus>
                                    <FormattedMessage id="cartSummary.emptyCartConfirmNo" defaultMessage="Non"/>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {!isMinOrderReached ? (
                            <Dialog open={alertOpen}>
                                <DialogTitle id="alert-dialog-title" className={classes.alertDialogHeader}
                                             disableTypography>
                                    <Grid container justify="center">
                                        <Grid item>
                                            <ErrorOutlineOutlinedIcon className={classes.alertDialogIcon}/>
                                        </Grid>
                                        <Grid item>
                                            <FormattedMessage
                                                id="alertMinOrderAmount.heading"
                                                defaultMessage="Le montant minimum de commande n'est pas atteint"/>
                                        </Grid>
                                    </Grid>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description" className={classes.alertDialogText}>
                                        <FormattedMessage
                                            id="alertMinOrderAmount.textTop"
                                            defaultMessage="Le fournisseur peut refuser la commande."/>
                                        <span
                                            className={classes.amoutRemaining}>{formattedRemainingOrderAmount}</span>
                                        <FormattedMessage
                                            id="alertMinOrderAmount.textBottom"
                                            defaultMessage="Manquant pour commander."/>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button color="secondary" onClick={closeAlert}>
                                        <FormattedMessage
                                            id="alertMinOrderAmount.text"
                                            defaultMessage="Fermer"
                                        />
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        ) : null}
                        <FormStep data={stepperData} styles={{mainStepper: classes.stepper}}>
                            <Grid container justify={"center"} className={classes.container}>
                                <Grid item>
                                    <Button size="small" onClick={handleClickOpen} color="secondary"
                                            className={classes.emptyCart}
                                            startIcon={<RemoveShoppingCartOutlinedIcon/>}><FormattedMessage
                                        id="cartSummary.emptyCartConfirmHeading"
                                        defaultMessage="Vider le panier"/></Button>
                                </Grid>
                                {map(cart.items.edges, ({node: cartLine}, index) => (
                                    <Grid item xs={12} key={index} className={classes.innerGrid}>
                                        <ProductCard currency={supplierData.supplier.currency}
                                                     product={cartLine.offer || cartLine}
                                                     addToCart={addToShoppingCart}
                                                     subtractFromCart={removeFromShoppingCart}
                                                     supplierId={supplierId}
                                                     supplierIRI={supplierId}
                                                     restaurantId={restaurantId}
                                                     restaurantIRI={restaurantId}
                                                     cartQuantity={cartLine.quantity}
                                                     supplierActive={true}
                                                     userType={userType}
                                                     setProductQuantity={addToShoppingCart}
                                                     setProductNote={addCartItemNote}
                                                     locale={locale}
                                                     unavailable={false}
                                                     productNote={cartLine.note}
                                                     cartSummary={true}
                                                     shoppingCartId={cartId}
                                                     shoppingCartItemId={cartLine._id}
                                        />
                                    </Grid>
                                ))}
                                <Grid item container justify="center" className={classes.totalBlock}>
                                    <Badge badgeContent={cart.totalQuantity} color="secondary">
                                        <ShoppingCartOutlinedIcon fontSize="large"/>
                                    </Badge>
                                    <Chip
                                        label={`${totalApprox} : ${price(cart.total.toFixed(2), supplierData.supplier.currency)}`}
                                        className={classes.total}/>
                                </Grid>
                                <Grid item container justify="center" className={classes.actionBlock}>
                                    <Button variant="contained" color="primary" disabled={!canOrder}
                                            onClick={validateCart}>{buttonText}</Button>
                                </Grid>
                                {userType !== PROFESSIONAL &&
                                <Grid container justify="center" alignItems="center" wrap="nowrap"
                                      className={classes.amountWarningBox}>
                                    <InfoOutlinedIcon className={classes.infoIcon}/>
                                    <Typography className={classes.amountWarningText}>
                                        <FormattedMessage id="cartSummary.cartAmountWarning"
                                                          defaultMessage="Le montant prelevé peut être inferieur au montant de la commande car :"/>
                                        <ul>
                                            <li>
                                                <FormattedMessage id="cartSummary.cartAmountWarning2"
                                                                  defaultMessage="Il est fonction du poids réel du produit frais livré."/>
                                            </li>
                                            <li>
                                                <FormattedMessage id="cartSummary.cartAmountWarning3"
                                                                  defaultMessage="Les produits manquants (en cas de rupture de stock) ne vous sont pas prélevés."/>
                                            </li>
                                        </ul>
                                    </Typography>
                                </Grid>
                                }
                            </Grid></FormStep>
                    </>) :
                (<Typography>
                    Empty
                </Typography>)}
        </>
    );
};

export default CartSummary;
