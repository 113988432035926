import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import ContactForm from "../../components/Support/ContactForm";
import {updateOverlayTitle} from "../../reducers/overlay";

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
    updateOverlayTitle: (title) => dispatch(updateOverlayTitle(title)),
    push: bindActionCreators(push, dispatch),
});

const ContactPage = connect(mapStateToProps, mapDispatchToProps)(ContactForm);

export default ContactPage;
