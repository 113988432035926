import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {FormattedMessage, useIntl} from 'react-intl';
import ScriptLoader from "react-script-loader-hoc";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import BaseTextField from "../../Inputs/BaseTextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import amplitudeClient from "../../../amplitudeWrapper";
import Skeleton from "react-loading-skeleton";
import GraphQlFetchData from "./graphQlFetchData";
import {FormValidation} from '../utils'
import {Hooks} from "./hooks"

const useStyles = makeStyles(theme => ({
    iconContainer: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 90,
        height: 90,
        width: 90,
        marginTop: 25,
        marginBottom: 20,
    },
    textWrapper: {
        marginBottom: 25,
        padding: '0 10px',
    },
    iconWrapper: {
        height: '100%',

    },
    icon: {
        color: 'white',
        fontSize: '3rem'
    },
    input: {
        marginBottom: 25,
    },
    resetPasswordLink: {
        cursor: 'pointer'
    },
    connexionButton: {
        margin: '30px 0',
    },
    formGrid: {
        paddingTop: 20,
    },
    buttonProgress: {
        position: 'absolute'
    }
}));

//@TODO: split form and header
const EditSupplierForm = ({updateOverlayTitle, scriptsLoadedSuccessfully, triggerNotification, supplierId, goBack}) => {
    const classes = useStyles();
    const {formValidate, emailTexts, phoneTexts, nameTexts} = FormValidation();
    const {pageTitle, notificationText} = Hooks()
    //extracting restaurant id from base64 encoded IRI
    const decodedSupplierId = window.atob(supplierId);

    const {
        supplierData,
        supplierLoading,
        supplierError,
        editSupplier,
        mutationLoading
    } = GraphQlFetchData({decodedSupplierId})


    useEffect(() => {
        updateOverlayTitle(pageTitle);

    }, [])


    //@TODO: Put this in a Hook or sub component ?
    //--- Google Places autocomplete--------
    let autocomplete;
    let formikSetFieldValue;

    const geolocate = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                const circle = new window.google.maps.Circle(
                    {center: geolocation, radius: position.coords.accuracy});
                autocomplete.setBounds(circle.getBounds());
            });
        }
    };

    const fillInAddress = () => {
        const place = autocomplete.getPlace();
        formikSetFieldValue('address', place.formatted_address);
    };

    if (scriptsLoadedSuccessfully) {
        autocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById('address')
        );

        autocomplete.setFields(['address_component', 'formatted_address']);
        autocomplete.addListener('place_changed', fillInAddress);
    }
    //--------------------------------------------

    if (supplierLoading || supplierError) {
        if (supplierError) {
            triggerNotification({variant: 'error', message: notificationText.errorLoadingData});
        }
        return (
            <>
                <Grid container justify={"center"} direction={"column"} alignItems={"center"}>
                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                        <div style={{width: '100%', height: '50px'}}><Skeleton height={40}/></div>
                        <div style={{width: '100%', height: '50px'}}><Skeleton width={100}/></div>
                    </Grid>
                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                        <div style={{width: '100%', height: '50px'}}><Skeleton height={40}/></div>
                        <div style={{width: '100%', height: '50px'}}><Skeleton width={100}/></div>
                    </Grid>
                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                        <div style={{width: '100%', height: '50px'}}><Skeleton height={40}/></div>
                        <div style={{width: '100%', height: '50px'}}><Skeleton width={100}/></div>
                    </Grid>
                    <Grid item container lg={2} md={3} sm={4} xs={10}>
                        <div style={{width: '100%', height: '50px'}}><Skeleton height={50}/></div>
                    </Grid>
                </Grid>
            </>
        )
    }

    //@TODO: validating phone number format ??
    return (
        <Formik
            initialValues={{
                name: supplierData.supplier.name,
                email: supplierData.supplier.email,
                phone: supplierData.supplier.telephone,
            }}
            validationSchema={Yup.object(
                formValidate
            )}
            onSubmit={(values, {setSubmitting}) => {
                editSupplier({
                    variables: {
                        id: supplierData.supplier._id,
                        name: values.name,
                        email: values.email,
                        phone: values.phone,
                    }
                })
                    .then(() => {
                        amplitudeClient.logEvent('supplier edited');
                        goBack();
                        triggerNotification({variant: 'success', message: notificationText.success});
                    })
                    .catch(e => {
                        console.log(e);
                        triggerNotification({variant: 'error', message: notificationText.error})
                    });
                setSubmitting(false);
            }}
        >
            {/*@TODO: This should be a separate component*/}
            {({setFieldValue}) => {
                //We need to access setFieldValue outside of the Formik component to populate inputs with data from Google Places autocomplete.
                //@TODO: use local state to avoid using setFieldValue outside of Formik component ??
                formikSetFieldValue = setFieldValue;

                return (<Form className={classes.form} onFocus={geolocate}>
                    <Grid container justify={"center"} alignItems={"center"} direction="column"
                          className={classes.formGrid}>
                        <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                            <Field component={BaseTextField} name="name" id="name" type="text"
                                   label={nameTexts.label}
                                   fullWidth
                                   placeholder={nameTexts.placeholder} className={classes.input}
                                   helperText={nameTexts.helperText}/>
                        </Grid>
                        <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                            <Field component={BaseTextField} name="email" id="email" type="email"
                                   label={emailTexts.label} fullWidth
                                   placeholder={emailTexts.placeholder} className={classes.input}
                                   helperText={emailTexts.helperText}/>
                        </Grid>
                        <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                            <Field component={BaseTextField} name="phone" id="phone" type="text"
                                   label={phoneTexts.label} fullWidth
                                   placeholder={phoneTexts.placeholder}
                                   className={classes.input}
                                   helperText={phoneTexts.helperText}
                            />
                        </Grid>
                        <Grid item lg={2} md={3} sm={4} xs={10}>
                            <Button variant="contained" color="primary" size="large" type="submit"
                                    className={classes.connexionButton} disabled={mutationLoading}>
                                <FormattedMessage
                                    id="addRestaurantForm.buttonText"
                                    defaultMessage="Valider"/>
                                {mutationLoading && <CircularProgress size={24} className={classes.buttonProgress}/>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Form>)
            }
            }
        </Formik>
    );
};


//Script loader avoids to load Google scripts on every pages
export default ScriptLoader('https://maps.googleapis.com/maps/api/js?key=AIzaSyAPN_7_svR5FjaOyFD6StAZjPgYMx9kYl8&libraries=places')(EditSupplierForm);
