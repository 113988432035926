import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {FormattedMessage, useIntl} from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import {Field, Form, Formik} from "formik";
import BaseTextField from "../../Inputs/BaseTextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import amplitudeClient from "../../../amplitudeWrapper";
import Skeleton from "@material-ui/lab/Skeleton";
import GraphQlFetchData from "./graphQlFetchData";
import Hooks from "./hooks"
import Utils from "../utils";
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
    category: {
        width: '100%'
    },
    field: {
        marginTop: 25
    },
    validateButton: {
        marginTop: 25,
        marginBottom: 25
    },
    buttonProgress: {
        position: 'absolute'
    },
    skeleton: {
        width: '100%',
        height: 60,
    }
}));

//@TODO: Add price ??
const CreateNewProductForm = ({
                                  updateOverlayTitle,
                                  restaurantId,
                                  supplierId,
                                  triggerNotification,
                                  goBack,
                                  shoppingListId
                              }) => {
    const classes = useStyles();
    const { pageTitle, notificationText} = Hooks()
    const {formValidation,categoryTexts, refCodeTexts, nameTexts, unitTexts } = Utils()

    useEffect(() => {
        updateOverlayTitle(pageTitle);
    }, [])


    const decodedRestaurantId = window.atob(restaurantId);
    const restaurantSegments = decodedRestaurantId.split('/');
    const restaurantTrueId = restaurantSegments.pop();

    const decodedSupplierId = window.atob(supplierId);
    const supplierSegments = decodedSupplierId.split('/');
    const supplierTrueId = supplierSegments.pop();

    const {categoriesData, mutationLoading, createProduct} = GraphQlFetchData({
        supplierTrueId,
        restaurantTrueId,
        decodedRestaurantId,
        triggerNotification,
        supplierId,
        restaurantId,
        shoppingListId, notificationText
    })


    return (
        <>
            <Formik
                initialValues={{
                    category: '',
                    name: '',
                    unit: '',
                    refCode: '',
                }}
                validationSchema={Yup.object(formValidation)}
                onSubmit={(values, {setSubmitting}) => {
                    createProduct({
                        variables: {
                            name: values.name,
                            categoryId: values.category,
                            unit: values.unit,
                            code: values.refCode,
                            restaurantId: restaurantTrueId,
                            supplierId: supplierTrueId
                        }
                    })
                        .then(() => {
                            amplitudeClient.logEvent('custom product created');
                            goBack();
                            triggerNotification({variant: 'success', message: notificationText.success});
                        })
                        .catch(e => triggerNotification({variant: 'error', message: notificationText.error}));
                    setSubmitting(false);
                }}
            >
                <Form className={classes.form}>
                    <Grid container justify={"center"} alignItems={"center"} direction="column">
                        <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                            {categoriesData ? (
                                <Field component={BaseTextField} name="category" id="category" type="text"
                                       label={categoryTexts.label}
                                       fullWidth
                                       select
                                       placeholder={categoryTexts.placeholder} className={classes.field}
                                       helperText={categoryTexts.helperText}>
                                    {categoriesData && categoriesData.getTopCategories.map((category, key) => {
                                            return <MenuItem value={category.taxonId} key={key}>{category.name}</MenuItem>
                                        }
                                    )}
                                </Field>
                            ) : <Skeleton className={classes.skeleton}/>}
                        </Grid>
                        <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                            <Field component={BaseTextField} name="name" id="name" type="text"
                                   label={nameTexts.label} fullWidth
                                   placeholder={nameTexts.placeholder} className={classes.field}
                                   helperText={nameTexts.helperText}/>
                        </Grid>
                        <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                            <Field component={BaseTextField} name="unit" id="unit" type="text"
                                   label={unitTexts.label} fullWidth
                                   select
                                   placeholder={unitTexts.placeholder}
                                   helperText={unitTexts.helperText}
                                   className={classes.field}>
                                <MenuItem value="KG"><FormattedMessage id="productAdd.kg" defaultMessage="Kilogramme"/></MenuItem>
                                <MenuItem value="L"><FormattedMessage id="productAdd.liter"
                                                                      defaultMessage="Litre"/></MenuItem>
                                <MenuItem value="CO"><FormattedMessage id="productAdd.pack"
                                                                       defaultMessage="Colis"/></MenuItem>
                                <MenuItem value="PC"><FormattedMessage id="productAdd.piece"
                                                                       defaultMessage="Pièce"/></MenuItem>
                            </Field>
                        </Grid>
                        <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                            <Field component={BaseTextField} name="refCode" id="refCode" type="text"
                                   label={refCodeTexts.label} fullWidth
                                   placeholder={refCodeTexts.placeholder}
                                   helperText={refCodeTexts.helperText}
                                   className={classes.field}
                            />
                        </Grid>
                        <Grid item lg={2} md={3} sm={4} xs={10}>
                            <Button variant="contained" color="primary" size="large" type="submit"
                                    className={classes.validateButton} disabled={mutationLoading}>
                                <FormattedMessage
                                    id="productAdd.add"
                                    defaultMessage="Ajouter"/>
                                {mutationLoading && <CircularProgress size={24} className={classes.buttonProgress}/>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </>
    );
};

export default CreateNewProductForm;
