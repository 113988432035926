import {gql} from "apollo-boost";

export const CREATE_PRODUCT_MUTATION = gql`
    mutation createProduct($restaurantId: String!, $supplierId: String!, $name: String!, $unit: String!, $categoryId: Int!, $code: String!) {
        createProduct(input:{restaurantId: $restaurantId, supplierId: $supplierId, name: $name, unit: $unit, categoryId: $categoryId, code: $code}) {
            product{
                id
                _id
                supplierId
                mainCategory
                name
                image
                imageLarge
                unit
                price
                explicitContent
                frozen
                priceDetail
                createdByUser
                origin
                bio
                promotion
            }
        }
    }
`;
