import {useMutation} from "@apollo/react-hooks";
import {ADD_SUPPLIER_MUTATION} from "../AddSupplierList/mutations";
import {GET_RESTAURANT_SUPPLIERS_QUERY} from "../SuppliersList/queries";


const GraphQlFetchData = ({restaurantId}) => {


    const [addSupplierMutation, {loading: mutationLoading}] = useMutation(ADD_SUPPLIER_MUTATION, {
        update(cache, mutationData) {
            try {
                //--Adding the new restaurant to the cache--//

                //Retrieving current restaurants list from cache
                const {restaurantSuppliers} = cache.readQuery({
                    query: GET_RESTAURANT_SUPPLIERS_QUERY,
                    variables: {restaurantId: restaurantId}
                });

                //Adding new restaurant to the list
                cache.writeQuery({
                    query: GET_RESTAURANT_SUPPLIERS_QUERY,
                    variables: {restaurantId: restaurantId},
                    data: {restaurantSuppliers: restaurantSuppliers.concat(mutationData.data.bindSupplier.supplier)},
                });
            } catch (e) {
                //In this case, the cache is empty, it will be loaded from the API when redirecting to the restaurants list, so we do nothing here
            }
        }
    });


    return (
        {
            addSupplierMutation, mutationLoading
        }
    )
};

export default GraphQlFetchData;
