import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage} from "react-intl";
import Typography from "@material-ui/core/Typography";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {RESTAURANTS} from "../../../constants/Routes";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    successIconWrapper: {
        marginTop: 20
    },
    icon: {
        color: theme.palette.error.main,
        fontSize: '6rem'
    },
    title: {
        padding: 10,
        fontSize: '2rem'
    },
    info: {
        backgroundColor: "#ccf4ff",
        margin: 20,
        padding: 20,
        borderRadius: 4
    },
    infoText: {
        padding: '5px 10px',
        fontSize: '0.9rem',
        color: theme.palette.info.contrastText,
    },
    subTitle: {
        padding: 10,
        fontSize: '1.15rem'
    },
    returnIcon: {
        paddingTop: 10,
        paddingLeft: 10
    },
    error: {
        color: theme.palette.error.main
    }
}));

//@TODO: put stepper in its own component, with local state
const FailedOrder = ({push, displayGoBack, denyAccessToUnauthenticatedUser, updateTopNavSubtitle, updateTopNavTitle, authResult, transactionStatus}) => {

    const classes = useStyles();

    useEffect(() => {
        denyAccessToUnauthenticatedUser();
        displayGoBack(`/restaurants`);

        updateTopNavSubtitle('');
        updateTopNavTitle('échec de la commande');
    }, [])

    return (
        <>
            <Grid container justify="center" alignItems="center" direction="column">
                <Grid item className={classes.successIconWrapper}>
                    <CancelOutlinedIcon className={classes.icon}/>
                </Grid>
                <Grid item>
                    <Typography variant="h5" className={classes.title} align="center">
                        <FormattedMessage id="failedOrder.heading" defaultMessage="Oups !"/>
                    </Typography>
                </Grid>
                {authResult === "51" && (
                    <Typography align="center" className={classes.error}>
                        <strong><FormattedMessage id="failedOrder.error"
                                                  defaultMessage="Erreur : "/>
                        </strong>
                        <FormattedMessage id="failedOrder.noMoney"
                                          defaultMessage="autorisation refusée pour cause de plafond dépassé."/>
                    </Typography>
                )}
                {authResult === "05" && (
                    <Typography align="center" className={classes.error}>
                        <strong><FormattedMessage id="failedOrder.error"
                                                  defaultMessage="Erreur : "/>
                        </strong>
                        <FormattedMessage id="failedOrder.crypto"
                                          defaultMessage="autorisation refusée suite à une erreur dans
                                        le cryptogramme visuel saisi."/>
                    </Typography>
                )}
                {authResult !== "05" && authResult !== "51" && transactionStatus === "REFUSED" && (
                    <Typography align="center" className={classes.error}>
                        <strong><FormattedMessage id="failedOrder.error"
                                                  defaultMessage="Erreur : "/>
                        </strong>
                        <FormattedMessage id="failedOrder.3d"
                                          defaultMessage="échec de l'autentification 3D Secure."/>
                    </Typography>
                )}
                {authResult !== "05" && authResult !== "51" && transactionStatus === "ABANDONED" && (
                    <Typography align="center" className={classes.error}>
                        <strong><FormattedMessage id="failedOrder.error"
                                                  defaultMessage="Erreur : "/>
                        </strong>
                        <FormattedMessage id="failedOrder.abandoned"
                                          defaultMessage="Vous avez quitter prématurément la page de paiement."/>
                    </Typography>
                )}
                <Grid item>
                    <Typography variant="h5" className={classes.subTitle} align="center">
                        <FormattedMessage id="failedOrder.paymentFailed"
                                          defaultMessage="Le paiement de votre commande n'a pas pu être validé, par conséquent, votre commande a été annulée."/>
                    </Typography>
                </Grid>

                <Grid item className={classes.info}>
                    <Typography className={classes.infoText}>
                        <FormattedMessage id="failedOrder.text1"
                                          defaultMessage="Votre compte bancaire ne sera pas débité. Vous pouvez à nouveau tenter de passer commande."/>
                    </Typography>
                    <Typography className={classes.infoText}>
                        <FormattedMessage id="failedOrder.text2"
                                          defaultMessage="Si le problème persiste, contactez-nous."/>
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={() => push(RESTAURANTS)}>
                        <FormattedMessage id="failedOrder.buttonText" defaultMessage="Retour à l'accueil"/>
                    </Button>
                </Grid>
            </Grid>
        </>
    )
};

export default FailedOrder;