import * as React from 'react';
import MuiSelect from '@material-ui/core/Select';
import {fieldToSelect, SelectProps,} from 'formik-material-ui';

const BaseSelect = (props: SelectProps) => (
    <MuiSelect
        {...fieldToSelect(props)}
        variant="outlined"
    />
);

export default BaseSelect;
