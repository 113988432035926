
import {useMutation, useQuery} from "@apollo/react-hooks";
import {EDIT_PRODUCT_MUTATION} from "./mutations";
import {GET_PRODUCT_QUERY} from "./queries";
import {GET_RESTAURANT_QUERY} from "../../Restaurants/RestaurantsList/queries";
import {GET_TOP_CATEGORIES_QUERY} from "../CreateNewProductForm/queries";

const GraphQlFetchData = ({productId, decodedRestaurantId, restaurantTrueId, supplierTrueId}) => {


    const {data} = useQuery(GET_PRODUCT_QUERY, {
        variables: {
            restaurantId: restaurantTrueId,
            supplierId: supplierTrueId,
            productId: productId
        }
    });

    const {
        data: restaurantData,
        error: restaurantError,
        loading: restaurantLoading
    } = useQuery(GET_RESTAURANT_QUERY, {variables: {id: decodedRestaurantId}});

    const {
        loading: categoriesLoading,
        error: categoriesError,
        data: categoriesData,
        refetch: categoriesRefresh
    } = useQuery(GET_TOP_CATEGORIES_QUERY, {
        variables: {
            locale: restaurantData ? restaurantData.restaurant.locale : 'fr_FR',
        },
        skip: !restaurantData
    });

    const [editProduct, {loading: mutationLoading}] = useMutation(EDIT_PRODUCT_MUTATION);


    return (
        {editProduct, mutationLoading, categoriesLoading, categoriesRefresh, categoriesData, restaurantData, data}
    );
};

export default GraphQlFetchData;
