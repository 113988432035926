import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import {FormattedMessage} from "react-intl";
import {useQuery} from "@apollo/react-hooks";
import {GET_RESTAURANT_SUPPLIERS_QUERY} from "../../Suppliers/SuppliersList/queries";
import Grid from "@material-ui/core/Grid";
import GeneralPurposeCard from "../../GeneralPurpose/GeneralPurposeCard";
import {makeStyles} from "@material-ui/core";
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import Typography from "@material-ui/core/Typography";
import {blueGrey} from "@material-ui/core/colors";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import amplitudeClient from "../../../amplitudeWrapper";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';

const useStyles = makeStyles(theme => ({
    button: {
        width: 150
    },
    card: {
        marginBottom: 15,
        color: blueGrey[800],
        borderBottom: `4px solid ${blueGrey[300]}`,
    },
    icon: {
        marginRight: 10
    },
    container: {
        padding: '5px 10px 10px 10px'
    },
    supplierName: {
        width: '100%',
        marginBottom: 10,
        fontWeight: 500,
        textTransform: 'uppercase',
        fontSize: '1.1rem',
    },
    heading: {
        fontSize: '1rem',
        fontWeight: '500',
        color: 'white',
        backgroundColor: theme.palette.primary.main
    },
    headingIcon: {
        marginBottom: 5,
        fontSize: '2rem'
    },
    actions: {
        justifyContent: 'center',
    },
    backButton: {
        color: 'white',
        marginLeft: '-20px'
    },
    headingText: {
        textTransform: 'uppercase'
    }
}));

const SupportWizard = ({open, setOpen, restaurants, push}) => {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const {loading, error, data, refetch} = useQuery(GET_RESTAURANT_SUPPLIERS_QUERY, {
        variables: {restaurantId: restaurants.restaurants.edges[0].node._id}
    });

    console.log(data);

    const isAvailable = (supplier) => {
        switch (supplier.node.accountStatus) {
            case 'OPEN':
                return true;
            case 'WAITING':
                return true;
            default:
                return false;
        }
    }

    const openChat = (supplier) => {
        amplitudeClient.logEvent('chat displayed', {origin: 'support wizard'});
        const encodedRestaurantId = window.btoa(restaurants.restaurants.edges[0].node.id);
        const encodedSupplierId = window.btoa(supplier.node.id);
        push(`/restaurants/${encodedRestaurantId}/suppliers/${encodedSupplierId}/chat`, {modal: true})
    }

    return (
        <div>
            {<Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="responsive-dialog-title"
            >

                <DialogTitle id="responsive-dialog-title" disableTypography className={classes.heading}>
                    <Grid container justify="center" wrap="nowrap" alignItems="center">
                        <IconButton onClick={() => setOpen(false)} className={classes.backButton}>
                            <ArrowBackOutlinedIcon/>
                        </IconButton>
                        <Typography align="center" className={classes.headingText}>
                            <FormattedMessage id="supportWizard.heading"
                                              defaultMessage="Contacter le support d'un fournisseur"/>
                        </Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent>

                    {data && data.suppliers.edges.map((supplier, index) => (
                        <Grid item xs={12} key={supplier.node._id}>
                            {isAvailable(supplier) &&
                                <GeneralPurposeCard className={classes.card}>
                                    <Grid container justify="space-between" className={classes.container}>
                                        <Typography className={classes.supplierName}>{supplier.node.name}</Typography>
                                        <Button variant="contained" color="primary" className={classes.button}
                                                href={`tel:${supplier.node.supportPhone}`}>
                                            <PhoneOutlinedIcon className={classes.icon}/>
                                            <FormattedMessage id="supportWizard.call"
                                                              defaultMessage="Appeler"/>
                                        </Button>
                                        <Button variant="contained" color="secondary" className={classes.button}
                                                href={`mailto:${supplier.node.supportEmails[0]}`}>
                                            <TextsmsOutlinedIcon className={classes.icon}/>
                                            <FormattedMessage id="supportWizard.sendEmail"
                                                              defaultMessage="Envoyer un email"/>
                                        </Button>
                                    </Grid>
                                </GeneralPurposeCard>}
                        </Grid>
                    ))}
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button autoFocus onClick={() => setOpen(false)} color="primary">
                        <FormattedMessage id="supportWizard.close"
                                          defaultMessage="Fermer"/>
                    </Button>
                </DialogActions>
            </Dialog>}
        </div>
    );
}
export default SupportWizard;
