import React, {useEffect, useState} from "react";
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import EmptyList from "../../EmptyList";
import {makeStyles} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";
import SupplierCard from "../SupplierCard";
import Grid from "@material-ui/core/Grid";
import {RESTAURANTS} from "../../../constants/Routes";
import FetchingError from "../../Errors/FetchingError";
import amplitudeClient from "../../../amplitudeWrapper";
import Button from "@material-ui/core/Button";
import {PROFESSIONAL} from "../../../constants/UserTypes";
import FabAddBottomLeft from "../../Fabs/FabAddBottomRight";
import GraphQlFetchData from "./graphQlFetchData";

const useStyles = makeStyles(theme => ({
    container: {
        padding: 10,
    },
    innerGrid: {
        marginBottom: 8,
    },
    closeEditButton: {
        marginTop: 7,
        borderRadius: 0,
        padding: 8,
        position: 'fixed',
        width: '100%',
        bottom: 56
    },
}));

const SuppliersList = ({
                           setSelectedRestaurant,
                           query,
                           updateTopNavTitle,
                           setBearerToken,
                           displayMenu,
                           userType,
                           updateTopNavSubtitle,
                           push,
                           restaurantId,
                           unsetActionsComponent,
                           denyAccessToUnauthenticatedUser,
                           displayGoBack,
                           setActionsComponent,
                           locale,
                           updateActionsComponent
                       }) => {
    const [editMod, setEditMod] = useState(false);

    const intl = useIntl();
    const classes = useStyles();
    const decodedRestaurantId = window.atob(restaurantId);

    const pageSubtitle = intl.formatMessage(
        {
            id: 'suppliersPage.title',
            defaultMessage: 'Fournisseurs'
        }
    );
    const {
        data,
        loading,
        error,
        refetch,
        restaurantError,
        restaurantData,
        restaurantLoading,
        shoppingCartsLoading,
        shoppingCarts
    } = GraphQlFetchData({updateTopNavTitle, decodedRestaurantId})

    const editTopNav = async () => {
        await updateTopNavTitle(pageSubtitle);
        await updateTopNavSubtitle('')
    }
    useEffect(() => {
        (async () => {
            await denyAccessToUnauthenticatedUser();
            await unsetActionsComponent();
            await query.origin === 'dashboard' ? displayGoBack(`/`) : displayGoBack(`/restaurants`);
            await userType === PROFESSIONAL ? updateTopNavSubtitle(pageSubtitle) : editTopNav()
            await setCustomComponent()
        })()
    }, [])

    //There an issue with the restaurant id
    /*if (restaurantData && restaurantData.restaurant === null) {
        throw new Error(`impossible to fetch restaurant. restaurant id : ${decodedRestaurantId}`);
    }*/

    if (restaurantLoading) {
        updateTopNavTitle("isLoading");
    }
    const handleEdit = () => {
        updateActionsComponent({
            data: true
        })
        setEditMod(true)
        amplitudeClient.logEvent('edit mod entered')
    }
    const setCustomComponent = () => {

        setActionsComponent({
            actionFunction: () => handleEdit(),
            data: editMod
        })
    }
    //---------------------

    if (loading || restaurantLoading) {
        const skeleton = [0, 1, 2, 3, 4, 5, 6];
        return (
            <Grid container justify={"center"} className={classes.container}>
                {skeleton.map((item, key) => (
                    <Grid item xs={12} key={key} className={classes.innerGrid}>
                        <SupplierCard/>
                    </Grid>
                ))}
            </Grid>
        );
    }

    const refetchSuppliers = () => {
        refetch().then(null).catch(e => null)
    };

    if (error || restaurantError) {
        return (
            <FetchingError action={refetchSuppliers}/>
        );
    }

    const addSupplier = () => {
        push(`${RESTAURANTS}/${restaurantId}/suppliers/create-new`, {modal: true});
        amplitudeClient.logEvent('add a supplier clicked');
        //push(`${RESTAURANTS}/${restaurantId}/suppliers/add`, {modal: true});
    };

    const sortSupplier = () => {
        const openedAccounts = [];
        const waitingAccounts = [];

        data.suppliers.edges.map((supplier) => {
            switch (supplier.node.accountStatus) {
                case 'OPEN':
                    openedAccounts.push(supplier.node);

                    break;
                default:
                    waitingAccounts.push(supplier.node);
            }
        });

        return openedAccounts.concat(waitingAccounts);

    }

    const onEditNavData = () => {
        setEditMod(false)
        updateActionsComponent({
            data: false
        })
    }
    return (
        <>
            {data ? (
                    <>
                        <Grid container justify={"center"} className={classes.container}
                              style={editMod ? {paddingBottom: 35} : null}>
                            {data.suppliers.edges.map((supplier) => (
                                <Grid item xs={12} key={supplier.node.id} className={classes.innerGrid}>

                                    <SupplierCard supplier={supplier.node} push={push} restaurantId={restaurantId}
                                                  editMod={editMod} userType={userType} locale={locale}
                                                  setSelectedRestaurant={setSelectedRestaurant}
                                                  denyAccessToUnauthenticatedUser={denyAccessToUnauthenticatedUser}
                                                  displayMenu={displayMenu}
                                                  updateTopNavSubtitle={updateTopNavSubtitle}
                                    />

                                </Grid>
                            ))}
                        </Grid>
                        {/*   {editMod ? (
                            <Button onClick={onEditNavData} size="small" color="primary"
                                    className={classes.closeEditButton} variant="contained">
                                <FormattedMessage id="supplierShop.closeEditMod" defaultMessage="Terminer l'édition"/>
                            </Button>
                        ) : null}*/}
                        {/*   <FabAddBottomLeft action={addSupplier}/>*/}
                    </>
                ) :
                (<EmptyList icon={LocalShippingOutlinedIcon} heading={"Il n'y a rien ici!"}
                            text={"Pour ajouter votre premier fournisseur, cliquer sur le bouton ci dessous."}
                            action={addSupplier}/>)}
        </>
    );
};

export default SuppliersList;
