export const DASHBOARD = '/';
export const RESTAURANTS = '/restaurants';
export const SUPPLIERS = '/restaurants/:id/suppliers';
export const ORDERS = '/orders';
export const ACCOUNT = '/account';
export const SUPPORT = '/support';
export const CONTACT_US = '/support/contact';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const WELCOME = '/welcome';
export const ADD_RESTAURANT = '/restaurants/add';
export const EDIT_RESTAURANT = '/restaurants/:id/edit';
export const ADD_SUPPLIER = '/restaurants/:id/suppliers/add';
export const NEW_SUPPLIER = '/restaurants/:id/suppliers/create-new';
export const MANAGEMENT ='/supplierManagement/suppliers/:supplierId';
export const SUPPLIER_SHOP = '/restaurants/:id/suppliers/:supplierId';
export const ADD_PRODUCT = '/restaurants/:id/suppliers/:supplierId/add-product';
export const EDIT_PRODUCT = '/restaurants/:id/suppliers/:supplierId/edit-product/:productId';
export const ORDER_DETAILS = '/orders/:id';
export const SHIPPING_DETAILS = '/restaurants/:id/suppliers/:supplierId/shipping/:cartId';
export const CART_SUMMARY = '/restaurants/:id/suppliers/:supplierId/cart-summary/:cartId';
export const CHAT = '/restaurants/:id/suppliers/:supplierId/chat';
export const EDIT_USER_INFO = '/account/edit-info';
export const CHANGE_PASSWORD = '/account/change-password';
export const UPDATE = '/update';
export const RESTAURANTS_ORDER = '/restaurants/:id/orders';
export const EDIT_SUPPLIER = '/suppliers/:supplierId/edit';
export const SUPPLIER_INDIVIDUAL_SHOP = '/restaurants/:id/supplierShop/:supplierId';
export const SUCCESSFUL_ORDER = '/successfulOrder';
export const FAILED_ORDER = '/failedOrder/:authResult/:transStatus';
export const PAYMENT_CALLBACK = '/paymentCallback';
export const CHECKOUT = '/restaurants/:id/suppliers/:supplierId/checkout/:cartId';
export const FORGOTTEN_PASSWORD = '/forgotten-password';
export const SET_NEW_PASSWORD = '/new-password/:token';
export const CATALOG = '/restaurants/:restaurantId/suppliers/:supplierId/catalog';
export const CATALOG_PRODUCTS = '/restaurants/:restaurantId/suppliers/:supplierId/catalog/:catalogId'
