import {connect} from 'react-redux';
import SuppliersList from "../../components/Suppliers/SuppliersList";
import {bindActionCreators} from "redux";
import {createMatchSelector, push} from "connected-react-router";
import {
    setActionsComponent,
    unsetActionsComponent, updateActionsComponent,
    updateSubtitle,
    updateTitle,
} from "../../reducers/topNavigation";
import {denyAccessToUnauthenticatedUser, setBearerToken} from "../../reducers/authentication";
import {displayGoBack, displayMenu} from "../../reducers/topNavigation";
import {setSelectedRestaurant} from "../../reducers/restaurants";

const mapStateToProps = (state) => {
    const matchSelector = createMatchSelector('/restaurants/:id/suppliers');
    const match = matchSelector(state);
    return {
        restaurantId: match ? match.params.id : 123,
        userType: state.authentication.userType,
        locale: state.authentication.language,
        query: state.router.location.query
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateTopNavTitle: (title) => dispatch(updateTitle(title)),
    updateTopNavSubtitle: (subtitle) => dispatch(updateSubtitle((subtitle))),
    push: bindActionCreators(push, dispatch),
    setActionsComponent: (component) => dispatch(setActionsComponent(component)),
    unsetActionsComponent: (payload) => dispatch(unsetActionsComponent()),
    denyAccessToUnauthenticatedUser: (payload) => dispatch(denyAccessToUnauthenticatedUser(payload)),
    displayGoBack: (payload) => dispatch(displayGoBack(payload)),
    displayMenu: (payload) => dispatch(displayMenu(payload)),
    setBearerToken: (payload) => dispatch(setBearerToken(payload)),
    setSelectedRestaurant: (payload) => dispatch(setSelectedRestaurant(payload)),
    updateActionsComponent: (payload) => dispatch(updateActionsComponent(payload))

});

const SuppliersPage = connect(mapStateToProps, mapDispatchToProps)(SuppliersList);

export default SuppliersPage;
