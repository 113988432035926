import React from "react";
import UserAvatar from "../../UserAvatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    email: {
        fontSize: '0.8rem',
        color: theme.palette.info.dark,
        marginBottom: 20
    },
}));

const LeftDrawerUserInfo = ({user}) => {
    const classes = useStyles();

    return (
        <Grid container justify={"center"} direction="column" alignItems="center">
            <UserAvatar/>
            <Typography gutterBottom align="center" className={classes.email}>
                {user.email}
            </Typography>
        </Grid>
    )
};

export default LeftDrawerUserInfo;

