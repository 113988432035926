import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles(theme => ({
    input: {
        marginBottom: 25,
    },
    connexionButton: {
        margin: '30px 0',
    }
}));

const EditUserInfoForm = ({user}) => {
    const classes = useStyles();

    user = {
        firsname: 'Jean',
        lastname: 'Bond',
        email: 'jean@bond.com',
        phone: '00 00 00 00 07'
    };

    const intl = useIntl();

    const firstnameTexts = {
        label: intl.formatMessage({
            id: 'editUserInfoForm.firstnameLabel',
            defaultMessage: 'Votre prénom',
        }),
        placeholder: intl.formatMessage({
            id: 'editUserInfoForm.firstnamePlaceholder',
            defaultMessage: 'Entrez votre prénom'
        })
    };

    const lastnameTexts = {
        label: intl.formatMessage({
            id: 'editUserInfoForm.lastnameLabel',
            defaultMessage: 'Votre nom',
        }),
        placeholder: intl.formatMessage({
            id: 'editUserInfoForm.lastnamePlaceholder',
            defaultMessage: 'Entrez votre nom'
        })
    };

    const emailTexts = {
        label: intl.formatMessage({
            id: 'editUserInfoForm.emailLabel',
            defaultMessage: 'Votre email',
        }),
        placeholder: intl.formatMessage({
            id: 'editUserInfoForm.emailPlaceholder',
            defaultMessage: 'Entrez votre email'
        })
    };

    const phoneTexts = {
        label: intl.formatMessage({
            id: 'editUserInfoForm.phoneLabel',
            defaultMessage: 'Votre numéro de téléphone',
        }),
        placeholder: intl.formatMessage({
            id: 'editUserInfoForm.phonePlaceholder',
            defaultMessage: 'Entrez votre numéro de téléphone'
        })
    };


    return (
        <>
            <form>
                <Grid container justify={"center"} direction={"column"} alignItems={"center"}>
                    <Grid item container justify={"center"} sm={8} xs={11}>
                        <TextField id="firstname" name="firstname" label={firstnameTexts.label} variant="filled"
                                   fullWidth
                                   placeholder={firstnameTexts.placeholder} className={classes.input}
                                   defaultValue={user.firsname}/>
                    </Grid>
                    <Grid item container justify={"center"} sm={8} xs={11}>
                        <TextField id="lastname" name="lastname" label={lastnameTexts.label} variant="filled"
                                   fullWidth
                                   placeholder={lastnameTexts.placeholder} className={classes.input}
                                   defaultValue={user.lastname}/>
                    </Grid>
                    <Grid item container justify={"center"} sm={8} xs={11}>
                        <TextField id="email" name="email" label={emailTexts.label} variant="filled"
                                   fullWidth
                                   placeholder={emailTexts.placeholder} className={classes.input}
                                   defaultValue={user.email}/>
                    </Grid>
                    <Grid item container justify={"center"} sm={8} xs={11}>
                        <TextField id="phone" name="phone" label={phoneTexts.label} variant="filled"
                                   fullWidth
                                   placeholder={phoneTexts.placeholder} className={classes.input}
                                   defaultValue={user.phone}/>
                    </Grid>
                    <Grid item lg={2} md={3} sm={4} xs={10}>
                        <Button variant="contained" color="primary" size="large" className={classes.connexionButton}>
                            <FormattedMessage
                                id="editUserInfoForm.buttonText"
                                defaultMessage="Sauvegarder les modifications"
                            />
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default EditUserInfoForm;
