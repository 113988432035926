import {connect} from 'react-redux';
import RegisterForm from "../../components/RegisterForm";
import {updateTitle} from "../../reducers/topNavigation";

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
    updateTopNavTitle: (title) => dispatch(updateTitle(title)),
});

const RegisterPage = connect(mapStateToProps, mapDispatchToProps)(RegisterForm);

export default RegisterPage;