import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    action: {
        color: theme.palette.grey[300]
    },
    selected: {
        backgroundColor: `${theme.palette.grey[50]} !important`
    }
}));

const DrawerListItem = ({currentPath, item, push}) => {
    const classes = useStyles();

    //We need to extract the icon nam to use it as a component
    const ListIcon = item.icon;

    return (
        <ListItem selected={item.path === currentPath} classes={{selected: classes.selected}} button onClick={() => {
            push(item.path);
        }}>
            <ListItemIcon>
                <ListIcon className={classes.action}/>
            </ListItemIcon>
            <ListItemText primary={item.text}/>
        </ListItem>
    )
};

export default DrawerListItem;