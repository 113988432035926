import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import GeneralPurposeCard from "../../GeneralPurpose/GeneralPurposeCard";
import {price} from "../../../utils/currency";

//@TODO: find a way to avoid !important ??
const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: 15,
        backgroundColor: theme.palette.primary.main,
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    name: {
        fontWeight: 500,
        paddingRight: 10,
    },
    cardContent: {
        flexGrow: 1,
        padding: '5px 10px !important'
    },
    centerBlock: {
        flexGrow: 1
    },
    iconBlock: {
        display: 'flex',
    },
    unit: {
        color: theme.palette.grey[300],
        fontWeight: 300,
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'flex-end',
        marginLeft: 10
    },
    price: {
        marginLeft: 8,
        backgroundColor: theme.palette.grey[100],
        fontSize: '0.9rem'
    },
    icon: {
        color: theme.palette.primary.contrastText
    }
}));

//@TODO: split card in sub components (e.g. body, action...)
const ProductCardOrderDetails = ({product, locale,currency}) => {
    const classes = useStyles();

    return (
        <GeneralPurposeCard>
            <Grid container wrap="nowrap">
                <CardContent className={classes.cardContent}>
                    <Grid container wrap="nowrap" alignItems={"center"}>
                        <Grid item className={classes.iconBlock}>
                            {product.image ? (<Avatar className={classes.avatar} alt="P" variant="rounded" src={product.image.small} />) : (
                                <Avatar className={classes.avatar} variant="rounded">
                                    <StoreMallDirectoryOutlinedIcon className={classes.icon}/>
                                </Avatar>)}

                        </Grid>
                        <Grid item container direction="column">
                            <Grid item>
                                <Typography className={classes.name}>{product.name}</Typography>
                            </Grid>
                            <Grid item container wrap="nowrap" alignItems="center">
                                <Grid item container className={classes.centerBlock} alignItems="center">
                                    {product.total ? (
                                        <>
                                            <Chip label={price(product.total, currency)} size="small" className={classes.price}/>
                                        </>
                                    ) : null}
                                </Grid>
                                <Grid item container justify="flex-end">
                                    <Typography variant={"h4"}>{product.quantity}</Typography>
                                    <Typography className={classes.unit} display="inline">{product.unit}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Grid>
        </GeneralPurposeCard>
    );
};

export default ProductCardOrderDetails;
