import {findIndex} from 'lodash';
import {GET_SHOPPING_LIST_QUERY} from "../Suppliers/SupplierShoppingList/queries";
import {REMOVE_PRODUCT_FROM_LIST_MUTATION} from "./removeProductFromListConfirmDialog/mutations";
import {useMutation} from "@apollo/react-hooks";
import {cloneDeep} from "@apollo/client/utilities/common/cloneDeep";


const GraphQlFetchData = ({

                              supplierId,
                              restaurantId,
                          }) => {
    const [removeProductFromList, {loading: mutationLoading}] = useMutation(REMOVE_PRODUCT_FROM_LIST_MUTATION, {
        update(cache, mutationData) {
            try {
                cache.writeQuery({
                    query: GET_SHOPPING_LIST_QUERY,
                    variables: {restaurantId: restaurantId, supplierId: supplierId},
                    data: {getShoppingList: mutationData.data.removeFromShoppingList.shoppingList},
                });
            } catch (e) {
            }
        }
    });

    return (
        {removeProductFromList, mutationLoading}
    );
};

export default GraphQlFetchData;
