import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {Grid} from "@material-ui/core";

import ProductCardMercurial from "../../Products/ProductCardMercurial";
import {triggerToastNotification} from "../../../reducers/toastNotification";
import {useDispatch} from "react-redux";
import {cloneDeep} from "@apollo/client/utilities/common/cloneDeep";
import {findIndex} from "lodash";
import {AutoSizer, CellMeasurer, CellMeasurerCache, List,} from "react-virtualized";
import SearchBar from "../../SearchBar";
import SearchResults from "react-filter-search";

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: '10px',
        height: 'calc(100vh - 217px)',
    },
    dNone: {
        display: 'none'
    },
    dBlock: {
        display: 'block',
        color: 'white',
        borderRadius: 0,
        backgroundColor: '#33d573',
        padding: '12px'
    },
    card: {
        padding: "5px 10px"
    },
    search:{
        margin: '0px 5px'
    }

}));

const CatalogProducts = ({supplier, restaurant, shoppingList, listProduct}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState("");

    //virtualized rendering cache
    const cache = React.useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 100,
        })
    );
    const onSearchProduct = (event) => {
        setSearchInput(event.target.value)
    }
    const processProduct = () => {
        const sortedProduct = [];
        //We need to clone the products because the object is immutable
        const products = cloneDeep(listProduct);

        if (products && products.length) {
            products.map((product) => {
                if (shoppingList && shoppingList.supplierShoppingList !== null) {
                    const index = findIndex(shoppingList.supplierShoppingList.items.edges, (item) => {
                        return item.node._id === product?.node._id;
                    });

                    if (index !== -1) {
                        product.node.inShoppingList = true;
                        product.node.shoppingListItemId = shoppingList.supplierShoppingList.items.edges[index]._id;
                    } else {
                        product.node.inShoppingList = false;
                    }
                }
                sortedProduct.push(product);
            });
        }
        return sortedProduct;
    }

    return (
        <>
            <Grid className={classes.search}>
                <SearchBar placeholder={"searching..."} onChange={onSearchProduct}
                           value={searchInput}/>
            </Grid>
            <Grid container className={classes.container}>
                <SearchResults
                    value={searchInput}
                    data={processProduct()}
                    renderResults={filteredItems =>
                        <AutoSizer>
                            {({height, width}) => (
                                <List width={width}
                                      height={height}
                                      rowHeight={cache.current.rowHeight}
                                      deferredMeasurementCache={cache.current}
                                      rowCount={filteredItems.length}
                                      rowRenderer={({
                                                        key,
                                                        index,
                                                        parent,
                                                        isScrolling,
                                                        isVisible,
                                                        style
                                                    }) => {
                                          const product = filteredItems[index];
                                          return (
                                              <CellMeasurer
                                                  key={key}
                                                  cache={cache.current}
                                                  parent={parent}
                                                  columnIndex={0}
                                                  rowIndex={index}
                                              >
                                                  <Grid item xs={12} key={index} style={{...style}}
                                                        className={classes.card}>
                                                      <ProductCardMercurial
                                                          currency={supplier?.currency}
                                                          product={product.node.offers.edges[0].node}
                                                          supplierId={supplier?._id}
                                                          restaurantId={restaurant._id}
                                                          shoppingList={shoppingList.supplierShoppingList}
                                                          triggerNotification={(payload) => dispatch(triggerToastNotification(payload))}/>
                                                  </Grid>
                                              </CellMeasurer>
                                          )
                                      }}
                                />)}
                        </AutoSizer>}
                />

            </Grid></>
    );
};

export default CatalogProducts;
