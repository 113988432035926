import {GET_RESTAURANT_QUERY} from "../../Restaurants/RestaurantsList/queries";
import {useQuery} from "@apollo/react-hooks";
import {GET_RESTAURANT_SUPPLIERS_QUERY, GET_SHOPPING_CARTS_QUERY} from "./queries";

const GraphQlFetchData = ({

                              updateTopNavTitle,
                              decodedRestaurantId
                          }) => {
    //const restaurantId = window.atob(decodedRestaurantId);
    const restaurantSegments = decodedRestaurantId.split('/');
    const restaurantTrueId = parseInt(restaurantSegments.pop());


    const {
        loading: restaurantLoading,
        error: restaurantError,
        data: restaurantData
    } = useQuery(GET_RESTAURANT_QUERY, {
        variables: {id:restaurantTrueId }, /*onCompleted: (result) => {
            if (result.restaurant !== null) updateTopNavTitle(result?.restaurant?.name)
            else throw new Error(`impossible to fetch restaurant. restaurant id : ${decodedRestaurantId}`)
        }*/
    });

    //The suppliers query is fired only if we get the restaurant data
    const {loading, error, data, refetch} = useQuery(GET_RESTAURANT_SUPPLIERS_QUERY, {
        variables: {restaurantId: restaurantData && restaurantData.restaurant && restaurantData.restaurant._id ? restaurantData.restaurant._id : ''},
        skip: !(restaurantData && restaurantData.restaurant && restaurantData.restaurant._id),
        pollInterval: 20000
    });

    const {
        loading: shoppingCartsLoading,
        error: shoppingCartsError,
        data: shoppingCarts,
        refetch: shoppingCartsRefresh
    } = useQuery(GET_SHOPPING_CARTS_QUERY, {
        variables: {
            restaurantId: restaurantTrueId
        }
    });

    return (
        {   // get restaurant query
            restaurantData, restaurantError, restaurantLoading,
            // get restaurant suppliers query
            error, data, refetch, loading,
            // get shopping carts query
            shoppingCartsLoading, shoppingCartsError, shoppingCarts, shoppingCartsRefresh
        }
    );
};

export default GraphQlFetchData;
