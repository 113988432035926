import {findIndex} from 'lodash';
import {
    GET_SHOPPING_LIST_QUERY,
    MY_SHOPPING_LIST_QUERY,
    GET_SUPPLIER_PRODUCTS_QUERY
} from "./queries";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {GET_RESTAURANT_QUERY} from "../../Restaurants/RestaurantsList/queries";
import {GET_RESTAURANT_SUPPLIERS_QUERY} from "../../Suppliers/SuppliersList/queries";

import {GET_SUPPLIER_QUERY} from "../SuppliersList/queries";
import {CREATE_SHOPPING_LIST_MUTATION} from "./mutations";

import {cloneDeep} from "@apollo/client/utilities/common/cloneDeep";
import {
    BATCH_REMOVE_PRODUCT_FROM_LIST_MUTATION,
} from "../../Products/removeProductFromListConfirmDialog/mutations";


const GraphQlFetchData = ({
                              updateTopNavTitle,
                              updateTopNavSubtitle,
                              restaurantId,
                              supplierId,
                              decodedRestaurantId,
                              supplierTrueId,
                              restaurantTrueId,
                              selectedProducts,
                              setSelectedProducts,
                          }) => {


        const supplierID = parseInt(supplierTrueId)
        const restaurantID = parseInt(restaurantTrueId)
        const [removeProductsFromList, {loading: deleteLoading}] = useMutation(BATCH_REMOVE_PRODUCT_FROM_LIST_MUTATION, {
            update(cache, mutationData) {
                try {
                    setSelectedProducts([]);
                    //Adding new restaurant to the list
                    cache.writeQuery({
                        query: GET_SHOPPING_LIST_QUERY,
                        variables: {restaurantId: restaurantId, supplierId: supplierId},
                        data: {getShoppingList: mutationData.data.removeFromShoppingList.shoppingList},
                    });
                } catch (e) {
                    console.log(e);
                }
            }
        });


        const {
            data: mercurialData,
            error: mercuarialError,
            loading: mercurialLoading
        } = useQuery(GET_SUPPLIER_PRODUCTS_QUERY, {
            variables: {
                restaurantId: restaurantID,
                supplierId: supplierID,
                limit: 2000,
                offset: 0
            },
            //pollInterval: 120000,
            fetchPolicy: 'no-cache'
        });


        //Updating top nav title with restaurant name
        const {
            data: restaurantData,
            error: restaurantError,
            loading: restaurantLoading
        } = useQuery(GET_RESTAURANT_QUERY, {
            variables: {id: restaurantID},
            onCompleted: (res) => {
                if (res) updateTopNavTitle(res?.restaurant?.name || "")
            }
        });

        //Updating top nav subtitle with supplier name
        const {data: supplierData, error: supplierError, loading: supplierLoading} = useQuery(GET_SUPPLIER_QUERY, {
            variables: {id: supplierID, restaurantId: restaurantID},
            onCompleted: (res) => {
                res && updateTopNavSubtitle(res?.supplier.name || "")
            },
            //pollInterval: 60000
        });

        //Wee need cache and network policy to ensure a refresh when a custom product is edited
        const {
            data: shoppingListData,
            error: shoppingListError,
            loading: shoppingListLoading,
            refetch: shoppingListRefetch
        } = useQuery(GET_SHOPPING_LIST_QUERY, {
            variables: {
                supplierId: supplierID,
                restaurantId: restaurantID
            },
            //pollInterval: 10000,
        });


        let [createShoppingList, {loading: mutationLoading}] = useMutation(CREATE_SHOPPING_LIST_MUTATION, {
            update(cache, mutationData) {
                try {
                    cache.writeQuery({
                        query: GET_SHOPPING_LIST_QUERY,
                        variables: {restaurantId: restaurantID, supplierId: supplierID},
                        data: {supplierShoppingList: mutationData.data.createShoppingList.shoppingList},
                    });
                } catch (e) {
                    console.log(e);
                }
            }
        });
        const {loading, error, data, refetch} = useQuery(GET_RESTAURANT_SUPPLIERS_QUERY, {
            variables: {restaurantId: restaurantID},
            //pollInterval: 20000
        });

        return (
            {
                removeProductsFromList,
                deleteLoading,
                //supplier products query
                mercurialData,
                mercurialLoading,
                mercuarialError,
                //restaurant query
                restaurantError,
                restaurantData,
                restaurantLoading,
                //supplier query
                supplierError, supplierData, supplierLoading,
                //shopping list
                shoppingListError, shoppingListRefetch, shoppingListData, shoppingListLoading,
                //create shopping list mutation
                createShoppingList, mutationLoading,
                data,
            }
        );
    }
;

export default GraphQlFetchData;
