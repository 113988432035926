import {connect} from 'react-redux';
import LoginForm from "../../components/LoginForm";
import {updateTitle} from "../../reducers/topNavigation";
import {triggerToastNotification} from "../../reducers/toastNotification";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {login} from "../../reducers/authentication";

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
    updateTopNavTitle: (title) => dispatch(updateTitle(title)),
    triggerNotification: (payload) => dispatch(triggerToastNotification(payload)),
    push: bindActionCreators(push, dispatch),
    login: (payload) => dispatch(login(payload))
});

const LoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginForm);

export default LoginPage;
