import React from 'react';
import cx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles(({breakpoints, spacing}) => ({
    root: {
        borderRadius: 4, // 16px
        transition: '0.3s',
        boxShadow: '0px 8px 7px -1px rgba(180,201,219,0.2), 0px 0px 10px 1px rgba(180,201,219,0.1), 0px 1px 3px 1px rgba(180,201,219,0.1)',
        position: 'relative',
        marginLeft: spacing(3),
        overflow: 'initial',
        background: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        paddingBottom: spacing(1),
        flexDirection: 'row',
        paddingTop: spacing(1),
        margin: spacing(1),
        width: '100%'
    },
    media: {
        height: 0,
        paddingBottom: '30%',
        borderRadius: spacing(1),
        backgroundColor: '#fff',
        position: 'relative',
        width: '30%',
        marginLeft: spacing(-1),
        marginTop: 0,
        transform: 'translateX(-8px)',
        boxShadow: '0px 8px 7px -1px rgba(180,201,219,0.2), 0px 0px 10px 1px rgba(180,201,219,0.1), 0px 1px 3px 1px rgba(180,201,219,0.1)',
        /*'&:after': {
            content: '" "',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'linear-gradient(147deg, #eafbf1 0%, #95e9b7 74%)',
            borderRadius: spacing(1), // 16
            opacity: 0.5,
        },*/
    },
    categoryName: {
        textTransform: 'uppercase',
        fontWeight: '600',
        fontSize: '1rem'
    },
    button: {
        marginTop: 15
    }
}));

const ProductCategoryCard = ({image, categoryName, onClick, products}) => {
    const classes = useStyles();

    return (
        <Card className={cx(classes.root)}>
            <CardMedia
                className={classes.media}
                image={
                    `/images${image}`
                }
            />
            <CardContent>
                <Typography className={classes.categoryName}>{categoryName}</Typography>
                <Button color="primary" onClick={onClick} className={classes.button}>
                    <FormattedMessage id="productCategoryCard.buttonText" defaultMessage="Voir les"/> {products.length} <FormattedMessage id="productCategoryCard.buttonText2" defaultMessage="produits"/>
                </Button>
            </CardContent>
        </Card>
    );
};


export default ProductCategoryCard;