import React from "react";
import Grid from "@material-ui/core/Grid";
import {FormattedMessage, useIntl} from "react-intl";
import {makeStyles} from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import {RESTAURANTS} from "../../../constants/Routes";
import {blueGrey} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Slide from '@material-ui/core/Slide';
import Chip from '@material-ui/core/Chip';
import amplitudeClient from "../../../amplitudeWrapper";
import {price} from "../../../utils/currency";
import {validateShoppingCart} from "../utils";

const useStyles = makeStyles(theme => ({
    container: {
        position: 'fixed',
    },
    minAmountReach: {
        width: '100%',
        padding: '12px 30px',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 0
    },
    minAmountNotReach: {
        width: '100%',
        padding: '12px 30px',
        backgroundColor: theme.palette.error.main,
        borderRadius: 0
    },
    total: {
        backgroundColor: blueGrey[100],
        color: theme.palette.secondary.main
    },
    textBlock: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.secondary.main
    },
    cartIcon: {
        marginRight: 7,
        fontSize: '1.1rem',
        marginBottom: 2,
        color: theme.palette.secondary.main
    },
    chip: {
        height: 20,
        paddingRight: 2,
        paddingLeft: 2,
        marginLeft: 10,
        color: 'white',
        backgroundColor: '#011547'
    },
    minOrderAmount: {
        fontSize: '0.8rem',
        marginBottom: '-5px',
        marginTop: 5
    }
}));

//@TODO: adapt for wide screen  and desktop
const FloatingCart = ({cart, push, orderMinAmount, noBottomBar, currency, orderUnit}) => {
    const classes = useStyles();
    const intl = useIntl();

    const orderButtonText = intl.formatMessage(
        {
            id: 'floatingCart.orderButtonText',
            defaultMessage: 'Voir le panier'
        }
    );

    if (!cart) {
        cart = {
            totalWithoutVat: 0,
            items: {
                totalCount: 0
            },
        }
    }

    const cartApproximatedTotal = cart.totalWithoutVat.toFixed(2);

    const gotToCartSummary = () => {
        if (cart.total > 0) {
            amplitudeClient.logEvent('floating cart clicked');
            const restaurantId = window.btoa(cart.restaurant.id);
            const supplierId = window.btoa(cart.supplier.id);
            push(`${RESTAURANTS}/${restaurantId}/suppliers/${supplierId}/cart-summary/${cart._id}`, {modal: true});
        }
    };

    const {isMinOrderReached, formattedMinOrderMessage} = validateShoppingCart(cart, orderUnit, orderMinAmount, currency);

    return (
        <Slide direction="up" in={!!cart.items.totalCount} mountOnEnter unmountOnExit>
            <Grid container className={classes.container} onClick={gotToCartSummary} style={noBottomBar ? {bottom: 0} : {bottom: 56}}>
                <Paper className={!isMinOrderReached ? classes.minAmountNotReach : classes.minAmountReach} elevation={20}>
                    <Grid container alignItems="center" justify="space-between">
                        <div className={classes.textBlock}>
                            <ShoppingCartOutlinedIcon className={classes.cartIcon}/>
                            {orderButtonText} <Chip label={cart.items.totalCount} size="small" className={classes.chip}/>
                        </div>
                        <Typography>{price(cartApproximatedTotal, currency)}</Typography>
                    </Grid>
                    {!isMinOrderReached ? (
                        <Typography align="center" className={classes.minOrderAmount}>
                            <FormattedMessage
                                id="floatingCart.minOrder"
                                defaultMessage="Minimum de commande"/>
                            : {formattedMinOrderMessage}
                        </Typography>
                    ) : null}
                </Paper>
            </Grid>
        </Slide>
    );
};

export default FloatingCart;
