import {FormattedMessage} from "react-intl";
import React from "react";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import {format} from "date-fns";
import {PROFESSIONAL} from "../../../constants/UserTypes";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import EuroOutlinedIcon from '@material-ui/icons/EuroOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ListItemText from "@material-ui/core/ListItemText";
import {price} from "../../../utils/currency";


const CheckoutScreenDetails = ({cartData, supplierData, prices, userType}) => {

    return (
        <>
            <List dense={true} aria-label="main mailbox folders">
                <ListItem>
                    <ListItemIcon>
                        <LocalShippingOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary={<FormattedMessage id="checkout.shippingFees"
                                                             defaultMessage="Frais de port"/>}
                                  secondary={`${price(cartData.shoppingCart.deliveryFeesWithoutVat, supplierData.supplier.currency)} ${prices.exclVAT} / ${price(cartData.shoppingCart.deliveryFeesWithVat, supplierData.supplier.currency)} ${prices.inclVAT}`}/>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <EuroOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary={<FormattedMessage id="checkout.total"
                                                             defaultMessage="Montant total de la commande"/>}
                                  secondary={`${price(cartData.shoppingCart.totalWithoutVat, supplierData.supplier.currency)} ${prices.exclVAT} / ${price(cartData.shoppingCart.total, supplierData.supplier.currency)} ${prices.inclVAT}`}/>
                </ListItem>
                {cartData.shoppingCart.scheduledDate && userType === PROFESSIONAL && (
                    <ListItem>
                        <ListItemIcon>
                            <CalendarTodayOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={<FormattedMessage id="checkout.deliveryDate"
                                                                 defaultMessage="Date de livraison"/>}
                                      secondary={format(new Date(cartData.shoppingCart.scheduledDate), 'dd/MM/yyyy')}/>
                    </ListItem>
                )}
            </List>

        </>
    )
}
export default CheckoutScreenDetails
