import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import Button from "@material-ui/core/Button";
//import Skeleton from '@material-ui/lab/Skeleton';
import Skeleton from 'react-loading-skeleton';
import GeneralPurposeCard from "../../GeneralPurpose/GeneralPurposeCard";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import {FormattedMessage, useIntl} from "react-intl";
import {RESTAURANTS} from "../../../constants/Routes";
import CardActionArea from "@material-ui/core/CardActionArea";
import {blueGrey} from "@material-ui/core/colors";
import amplitudeClient from "../../../amplitudeWrapper";

const useStyles = makeStyles(theme => ({
    icon: {
        fontSize: '2.5rem',
        padding: 10,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        color: theme.palette.primary.contrastText,
    },
    name: {
        fontWeight: 'bold',
    },
    cardContent: {
        flexGrow: 1,
        color: blueGrey[800]
    },
    centerBlock: {
        flexGrow: 1
    },
    iconBlock: {
        display: 'flex',
        marginRight: 15
    },
}));

//@TODO: cleaner callback function for menu click events
const RestaurantCard = ({restaurantData, push, triggerNotification}) => {
    const classes = useStyles();
    const intl = useIntl();

    //Handling action menu state locally, no need for global state
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //We use base64 encoded id because the graphql id contain slashes
    let encodedId = null;
    if (restaurantData) {
        encodedId = window.btoa(restaurantData.id);
    }

    const selectRestaurant = () => {
        amplitudeClient.logEvent('restaurant selected');
        push(`${RESTAURANTS}/${encodedId}/suppliers`);
    };

    const message = intl.formatMessage(
        {
            id: 'restaurantActionMenu.helper',
            defaultMessage: 'Sélectionnez un fournisseur pour passer commande'
        }
    );

    return (
        <GeneralPurposeCard>
            <Grid container wrap="nowrap">
                <CardActionArea onClick={selectRestaurant}>
                    <CardContent className={classes.cardContent}>
                        <Grid container wrap="nowrap" alignItems={"center"}>
                            <Grid item className={classes.iconBlock}>
                                {restaurantData ? <StoreMallDirectoryOutlinedIcon className={classes.icon}/> :
                                    <Skeleton circle={true} height={40} width={40}/>}

                            </Grid>
                            <Grid item className={classes.centerBlock}>
                                <Typography className={classes.name}
                                            align="center">{restaurantData ? restaurantData.name :
                                    <Skeleton/>}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    {restaurantData ? (
                        <>
                            <IconButton onClick={handleClick}>
                                <MoreVertOutlinedIcon/>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => {
                                    push(`${RESTAURANTS}/${encodedId}/edit`, {modal: true});
                                    handleClose()
                                }}>
                                    <FormattedMessage id="restaurantActionMenu.edit"
                                                      defaultMessage="Editer les informations"/>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    push(`${RESTAURANTS}/${encodedId}/suppliers`);
                                    triggerNotification({
                                        variant: 'info',
                                        message: message
                                    });
                                    handleClose();
                                }}>

                                    <FormattedMessage id="restaurantActionMenu.order"
                                                      defaultMessage="Passer une commande"/>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    amplitudeClient.logEvent('restaurant orders list displayed', {origin: 'restaurant card'});
                                    push(`${RESTAURANTS}/${encodedId}/orders`);
                                    handleClose();
                                }}>
                                    <FormattedMessage id="restaurantActionMenu.orderHistory"
                                                      defaultMessage="Voir l'historique des commandes"/>
                                </MenuItem>
                            </Menu>
                        </>) : (<Skeleton/>)}
                </CardActions>
            </Grid>
        </GeneralPurposeCard>
    );
};

export default RestaurantCard;
