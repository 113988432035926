import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage} from "react-intl";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Grid} from "@material-ui/core";
import {FAILED_ORDER, SUCCESSFUL_ORDER} from "../../../constants/Routes";

const useStyles = makeStyles(theme => ({
    container: {
        padding: 20,
        height: '100vh'
    }
}));

//@TODO: put stepper in its own component, with local state
const PaymentCallback = ({push, denyAccessToUnauthenticatedUser, authResult, transactionStatus, stripeStatus}) => {

    useEffect(()=> {
        denyAccessToUnauthenticatedUser();
    }, [])

    const classes = useStyles();

    if (authResult === "00" || stripeStatus === "success") {
        push(SUCCESSFUL_ORDER);
    } else {
        authResult = authResult ? authResult : "auth";
        transactionStatus = transactionStatus ? transactionStatus : "status";
        if(stripeStatus === "cancel") {
            transactionStatus = "ABANDONED";
        }
        push(`/failedOrder/${authResult}/${transactionStatus}`);
    }

    return (
        <>
            <Grid container direction="column" justify="center" className={classes.container}>
                <Typography gutterBottom align="center">
                    <FormattedMessage id="paymentCallback.processing" defaultMessage="Traîtement en cours"/>
                </Typography>
                <LinearProgress/>
                <Typography gutterBottom align="center">
                    <FormattedMessage id="paymentCallback.redirectText"
                                      defaultMessage="Vous aller être redirigé dans quelques instants"/>
                </Typography>
            </Grid>
        </>
    )
};

export default PaymentCallback;