import React, {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import CheckoutScreenDetails from "../CheckoutScreenDetails/index"
import {makeStyles} from "@material-ui/core";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import clsx from "clsx";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import {blueGrey} from "@material-ui/core/colors";
import amplitudeClient from "../../../amplitudeWrapper";
import {format} from "date-fns";
import {forIn, isEmpty, map} from "lodash";
import ReactPixel from "react-facebook-pixel";
import {SUCCESSFUL_ORDER} from "../../../constants/Routes";
import FetchingError from "../../Errors/FetchingError";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import EditCardDialog from "../../Cart/EditCardDialog";
import DeleteCardDialog from "../../Cart/DeleteCardDialog";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import {PROFESSIONAL} from "../../../constants/UserTypes";
import {STRIPE_PROD_PUBLIC_KEYS, STRIPE_TEST_PUBLIC_KEYS} from "../../../constants/General";
import {loadStripe} from "@stripe/stripe-js";
import GraphQlFetchData from "./graphQlFetchData";
import FormStep from "../../Commun/FormStep";

const useStyles = makeStyles(theme => ({
    input: {
        marginBottom: 25,
    },
    mainContainer: {
        marginTop: 130
    },
    connexionButton: {
        margin: '30px 0',
    },
    textContainer: {
        padding: 15
    },
    stepper: {
        padding: '0 10px 20px',
        zIndex: 1100,
        boxShadow: '0px 8px 8px 0px rgba(0,0,0, 0.1)',
        position: 'fixed',
        width: '100%',
        paddingTop: 20,
        borderTop: '1px solid #f5f5f5',
    },
    active: {
        color: theme.palette.primary.main
    },
    completed: {},
    root: {
        color: blueGrey[400]
    },
    deliveryStart: {
        marginRight: 10
    },
    deliveryIntervalLabel: {
        marginBottom: 20,
    },
    deliveryIntervalMiddleText: {
        height: 56,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    creditCard: {
        width: '100%',
        marginBottom: 10
    },
    cardGrid: {
        padding: '10px 20px',
    },
    cardNumber: {
        marginTop: 10,
        marginBottom: 5,
        fontSize: '0.9rem'
    },
    cardBrand: {
        fontSize: '1rem',
        fontWeight: '600',
    },
    cardIcon: {
        fontSize: '1.5rem',
        marginRight: 15
    },
    cardExpiration: {
        fontSize: '0.9rem'
    },
    paymentTitle: {
        textTransform: 'uppercase',
        fontSize: '0.9rem',
        fontWeight: 700,
        marginBottom: 15,
        borderBottom: '1px solid #e2e2e2',
        width: '100%',
    },
    info: {
        backgroundColor: "#ccf4ff",
        margin: 20,
        padding: 10,
        borderRadius: 4
    },
    infoText: {
        padding: '5px 10px',
        fontSize: '0.9rem',
        color: theme.palette.info.contrastText,
    },
    selectedCard: {
        backgroundColor: theme.palette.primary.main,
    },
    or: {
        marginTop: 15,
        marginBottom: 15,
        fontSize: '1.2rem',
        fontWeight: 500,
        width: '100%'
    },
    paymentInstructions: {
        fontSize: '0.8rem',
        marginBottom: 10
    },
    cardBottom: {
        backgroundColor: theme.palette.grey[50],
        justifyContent: 'space-between',
    },
    deleteIcon: {
        fontSize: '1.05rem',
        color: theme.palette.error.main,
        marginRight: 4
    },
    editIcon: {
        fontSize: '1.05rem',
        marginRight: 4
    },
    loader: {
        marginTop: 150
    }
}));

//@TODO: adapt for wide screen  and desktop
const CheckoutScreen = ({
                            updateOverlayTitle,
                            userType,
                            push,
                            restaurantId,
                            supplierId,
                            cartId,
                            origin,
                            clearCart,
                            setShippingDetails,
                            triggerNotification,
                        }) => {
    const classes = useStyles();
    const intl = useIntl();

    const decodedRestaurantId = window.atob(restaurantId);
    const restaurantSegments = decodedRestaurantId.split('/');
    const restaurantTrueId = parseInt(restaurantSegments.pop());

    const decodedSupplierId = window.atob(supplierId);
    const supplierSegments = decodedSupplierId.split('/');
    const supplierTrueId = parseInt(supplierSegments.pop());

    const [updateCard, setUpdateCard] = useState(false);
    const [saveCard, setSaveCard] = useState(true);
    const [cardEditMod, setCardEditMod] = useState(0);
    const [aliasIdToUse, setAliasIdToUse] = useState(null);
    const [cardToDelete, setCardToDelete] = useState(0);

    const notificationText = {
        success: intl.formatMessage(
            {
                id: 'checkout.SuccessNotificationText',
                defaultMessage: 'Commande passée avec succès'
            }
        ),
        error: intl.formatMessage(
            {
                id: 'checkout.errorNotificationText',
                defaultMessage: 'Une erreur est survenue lors de la création de la commande.'
            }
        )
    };

    const prices = {
        inclVAT: intl.formatMessage(
            {
                id: 'checkout.inclVAT',
                defaultMessage: 'TTC'
            }
        ),
        exclVAT: intl.formatMessage(
            {
                id: 'checkout.exclVAT',
                defaultMessage: 'HT'
            }
        )
    };


    const {
        aliasesLoading,
        aliasesRefetch,
        aliasesData,
        aliasesError,
        createOrder,
        mutationLoading,
        supplierLoading,
        supplierError,
        supplierData,
        supplierRefetch,
        restaurantRefetch,
        restaurantData,
        restaurantError, paymentLoading, cartLoading, cartRefetch, cartData, cartError
    } = GraphQlFetchData({
        supplierTrueId,
        restaurantTrueId,
        cartId,
    })

    if (supplierLoading) {
        updateOverlayTitle('isLoading');
    }

    if (supplierData) {
        updateOverlayTitle(supplierData.supplier.name);
    }

    const placeOrder = (values) => {
        amplitudeClient.logEvent('order placed');
        createOrder({
            variables: {
                cartId,
                origin,
            }
        })
            .then(() => {
                push(`/restaurants/${restaurantId}/suppliers/${supplierId}`);
                triggerNotification({variant: 'success', message: notificationText.success});
                clearCart({id: cartId})
            })
            .catch((e) => {
                triggerNotification({variant: 'error', message: notificationText.error});
            })
    };

    const processStripeCheckout = async (stripeCheckOutSessionId, supplierId, currency) => {
        const suppliersException = [437];
        if (suppliersException.includes(parseInt(supplierId))) {
            currency = 'EUR';
        }
        const key = process.env.REACT_APP_GRAPHQL_ENV === 'prod' ? STRIPE_PROD_PUBLIC_KEYS[currency] : STRIPE_TEST_PUBLIC_KEYS[currency];
        const stripePromise = loadStripe(key);
        const stripe = await stripePromise;

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId: stripeCheckOutSessionId,
        });

        if (result.error) {
            console.log(result.error);
            triggerNotification({variant: 'error', message: notificationText.error});
        }
    }

    const paymentNextActionProcess = async (clientSecret, paymentMethodId, supplierId, currency) => {
        const suppliersException = [437];
        if (suppliersException.includes(parseInt(supplierId))) {
            currency = 'EUR';
        }

        const key = process.env.REACT_APP_GRAPHQL_ENV === 'prod' ? STRIPE_PROD_PUBLIC_KEYS[currency] : STRIPE_TEST_PUBLIC_KEYS[currency];
        const stripePromise = loadStripe(key);
        const stripe = await stripePromise;

        stripe.confirmCardPayment(clientSecret,
            {
                payment_method: paymentMethodId
            }).then((result) => {
            if (result.error) {
                console.log(result.error);
                triggerNotification({variant: 'error', message: notificationText.error});
            } else {
                push(SUCCESSFUL_ORDER);
            }
        }).catch(e => {
            console.log(e);
            triggerNotification({variant: 'error', message: notificationText.error});
        })
    }

    //@Todo: reduce code duplication
    const placeOrderWithPayment = (values) => {
        amplitudeClient.logEvent('order placed');
        createOrder({
            variables: {
                cartId,
                origin,
                creditCard: aliasIdToUse !== 0 ? aliasIdToUse : null,
                saveCard: saveCard && !aliasIdToUse,
            }
        })
            .then(({data}) => {
                if (data.createOrder.order.paymentStatus === 'NONE'
                    || data.createOrder.order.paymentStatus === 'AUTHORIZED'
                    || data.createOrder.order.paymentStatus === 'SUCCEEDED'
                ) {
                    setShippingDetails({
                        supplierId: supplierId,
                        restaurantId: restaurantId,
                        shippingFees: parseFloat(data.createOrder.order.deliveryFeesWithVat),
                    });
                    ReactPixel.track('InitiateCheckout', {
                        content_category: 'products',
                        contents: data.createOrder.order.items,
                        currency: data.createOrder.order.currency,
                        value: data.createOrder.order.total
                    });

                    //We got a paymentUrl data (an url or an authorization code), it's a Lyra payment
                    if (data.createOrder.order.payment.paymentUrl) {
                        try {
                            //We get a payment URL, redirecting user
                            const url = new URL(data.createOrder.order.payment.paymentUrl);
                            window.location.replace(url.href);
                        } catch (e) {
                            //No payment URL, it's a zero click transaction
                            //Checking the auth result
                            if (data.createOrder.order.payment.paymentUrl === "0") {
                                push(SUCCESSFUL_ORDER);
                            } else {
                                push(`/failedOrder/${data.createOrder.order.payment.paymentUrl}/none`);
                            }
                        }
                    }

                    //We got a stripeCheckOutSessionId (a session id or a payment intent status), it's a stripe payment
                    if (data.createOrder.order.payment.sessionId) {

                        if (data.createOrder.order.payment.sessionId && !!aliasIdToUse) {
                            //It's a payment with alias
                            if (data.createOrder.order.payment.sessionId === "requires_capture" || data.createOrder.order.payment.sessionId === "succeeded") {
                                push(SUCCESSFUL_ORDER);
                            } else {
                                push(`/failedOrder/${data.createOrder.order.payment.sessionId}/none`);
                            }
                        } else {
                            processStripeCheckout(data.createOrder.order.payment.sessionId, data.createOrder.order.supplier._id, data.createOrder.order.currency);
                        }
                    }

                    if (data.createOrder.order.payment.clientSecret && data.createOrder.order.payment.paymentMethodId) {
                        paymentNextActionProcess(data.createOrder.order.payment.clientSecret, data.createOrder.order.payment.paymentMethodId, data.createOrder.order.supplierId, data.createOrder.order.currency);
                    }

                } else {
                    console.log(data.createOrder.order.paymentStatus);
                    triggerNotification({variant: 'error', message: notificationText.error});
                }
            })
            .catch((e) => {
                console.log(e);
                triggerNotification({variant: 'error', message: notificationText.error});
            })
    };

    const refetchData = () => {
        restaurantRefetch().then(null).catch(e => null);
        supplierRefetch().then(null).catch(e => null);
        cartRefetch().then(null).catch(e => null);
    };

    if (restaurantError || supplierError) {
        return (
            <FetchingError action={refetchData}/>);
    }

    const isCreditCardEnabled = () => {
        return supplierData.supplier.paymentMethod === 'FOODOMARKET_CREDIT_CARD';
    }

    const processOrder = () => {
        if (isCreditCardEnabled()) {
            placeOrderWithPayment();
        } else {
            placeOrder();
        }
    }

    const checkIcon = () => {
        return (
            <div>
                <CheckOutlinedIcon/>
            </div>
        )
    };

    const checkOutIcon = () => {
        return (
            <div className={clsx({
                [classes.active]: classes.active,
            })}>
                <PaymentOutlinedIcon/>
            </div>
        )
    };

    if (aliasesData && supplierData) {
        if (aliasesData.cardAliases[0]) {
            let matchingAlias = false;
            aliasesData.cardAliases.map((alias) => {
                if (alias.paymentProvider === supplierData.supplier.paymentProvider) {
                    matchingAlias = alias._id;
                }
            });

            if (matchingAlias !== false && aliasIdToUse === null) {
                setAliasIdToUse(matchingAlias);
            }

            if (matchingAlias === false && aliasIdToUse !== null) {
                setAliasIdToUse(null);
            }
        }

        if (aliasIdToUse !== null && !aliasesData.cardAliases[0]) {
            setAliasIdToUse(null);
        }
    }

    const stepperData = [
        {
            title: "checkout.stepperCart",
            defaultTitle: "Panier",
            icon: checkIcon
        },
        {
            title: "checkout.stepperShipping",
            defaultTitle: "Livraison",
            icon: checkIcon
        },
        {
            title: "checkout.stepperCheckout",
            defaultTitle: "Paiement",
            icon: checkOutIcon
        }
    ]
    return (

        <FormStep data={stepperData} activeStep={3} styles={{mainStepper: classes.stepper}}>
            {(cartLoading || supplierLoading || cartLoading) &&
            <Grid container justify="center" alignItems="center" className={classes.loader}>
                <CircularProgress/>
            </Grid>
            }
            {restaurantData && supplierData && cartData && (
                <Grid container justify={"center"} direction={"column"} alignItems={"center"}
                      className={classes.mainContainer}>
                    <Grid item container xs={10}>
                        <Typography align="center" className={classes.paymentTitle}>
                            <FormattedMessage id="checkout.orderSummary" defaultMessage="Récapitulatif de commande"/>
                        </Typography>
                        <CheckoutScreenDetails cartData={cartData} supplierData={supplierData}
                                               prices={prices} userType={userType}/>
                    </Grid>
                    {isCreditCardEnabled() && (
                        <>
                            <Grid item container xs={10}>
                                <Typography align="center" className={classes.paymentTitle}>
                                    <FormattedMessage id="checkout.cardPayment"
                                                      defaultMessage="Paiement par carte bancaire"/></Typography>
                                {aliasesData && !isEmpty(aliasesData.cardAliases) ? (
                                    <>
                                        <Typography className={classes.paymentInstructions}>
                                            <FormattedMessage id="checkout.selectCardText"
                                                              defaultMessage="Sélectionnez la carte avec laquelle vous souhaitez effectuer le paiement"/>
                                        </Typography>
                                        {cardEditMod !== 0 &&
                                        <EditCardDialog aliasId={cardEditMod}
                                                        supplierId={supplierTrueId}
                                                        close={() => setCardEditMod(0)}/>
                                        }
                                        {cardToDelete !== 0 &&
                                        <DeleteCardDialog aliasId={cardToDelete}
                                                          supplierId={supplierTrueId}
                                                          close={() => setCardToDelete(0)}/>}
                                        {aliasesLoading ? (
                                            <Grid container justify="center" alignItems="center">
                                                <CircularProgress/>
                                            </Grid>
                                        ) : (
                                            <>
                                                {aliasesData.cardAliases.map((alias) => {
                                                        if (alias.paymentProvider === supplierData.supplier.paymentProvider) {
                                                            return (
                                                                <Card key={alias._id}
                                                                      className={`${classes.creditCard} ${alias._id === aliasIdToUse ? classes.selectedCard : null}`}>
                                                                    <CardActionArea
                                                                        onClick={() => setAliasIdToUse(alias._id)}
                                                                        className={classes.cardGrid}>
                                                                        <Grid container alignItems="center">
                                                                            <CreditCardOutlinedIcon
                                                                                className={classes.cardIcon}/>
                                                                            <Typography className={classes.cardBrand}>
                                                                                {alias.label ? alias.label : alias.brand}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Typography
                                                                            className={classes.cardNumber}>{alias.number}</Typography>
                                                                        <Typography className={classes.cardExpiration}>
                                                                            EXP: {alias.expirationDate}
                                                                        </Typography>
                                                                    </CardActionArea>
                                                                    <CardActions className={classes.cardBottom}>
                                                                        <Button size="small" color="secondary"
                                                                                onClick={() => setCardEditMod(alias._id)}>
                                                                            <EditOutlinedIcon className={classes.editIcon}/>
                                                                            <FormattedMessage id="checkout.editCard"
                                                                                              defaultMessage="Editer"/>
                                                                        </Button>
                                                                        <Button size="small" color="secondary"
                                                                                onClick={() => setCardToDelete(alias._id)}>
                                                                            <DeleteForeverOutlinedIcon
                                                                                className={classes.deleteIcon}/>
                                                                            <FormattedMessage id="checkout.deleteCard"
                                                                                              defaultMessage="Supprimer"/>
                                                                        </Button>
                                                                    </CardActions>
                                                                </Card>
                                                            )
                                                        }
                                                    }
                                                )}
                                                {aliasesData && aliasesData.cardAliases.length > 1 && aliasIdToUse !== null && (
                                                    <Typography className={classes.or} align="center"
                                                                variant="body1">
                                                        <FormattedMessage id="checkout.or"
                                                                          defaultMessage="OU"/>
                                                    </Typography>
                                                )}
                                                <Card
                                                    className={`${classes.creditCard} ${!aliasIdToUse ? classes.selectedCard : null}`}>
                                                    <CardActionArea className={classes.cardGrid}
                                                                    onClick={() => setAliasIdToUse(0)}>
                                                        <Grid container alignItems="center">
                                                            <CreditCardOutlinedIcon
                                                                className={classes.cardIcon}/>
                                                            <Typography className={classes.cardBrand}>
                                                                <FormattedMessage id="checkout.addNewCard"
                                                                                  defaultMessage="Ajouter une nouvelle carte"/>
                                                            </Typography>
                                                        </Grid>
                                                    </CardActionArea>
                                                </Card>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Card className={classes.creditCard}>
                                            <Grid container alignItems="center"
                                                  className={classes.cardGrid}>
                                                <CreditCardOutlinedIcon
                                                    className={classes.cardIcon}/>
                                                <Typography className={classes.cardBrand}>
                                                    <FormattedMessage id="checkout.noCard"
                                                                      defaultMessage="Aucune carte enregistrée"/>
                                                </Typography>
                                            </Grid>
                                        </Card>

                                        <FormControlLabel
                                            control={<Checkbox checked={saveCard}
                                                               onChange={() => setSaveCard(!saveCard)}
                                                               name="checkedA"/>}
                                            label={<FormattedMessage id="checkout.saveCard"
                                                                     defaultMessage="Enregister ma carte"/>}
                                        />
                                        <Typography variant="caption">
                                            <FormattedMessage id="checkout.saveCardText"
                                                              defaultMessage="L'enregistrement s'effectuera au moment dupaiement. Il est entièrement sécurisé par Lyra© ou Stripe©"/>
                                        </Typography>
                                    </>
                                )}
                            </Grid>
                            {userType === PROFESSIONAL && (
                                <Grid item className={classes.info}>
                                    <Typography className={classes.infoText}>
                                        <FormattedMessage id="checkout.text1"
                                                          defaultMessage="Lors d'un paiement par carte bancaire, et afin de prendre en compte les éventuelles variations de poids des articles, le montant total de l'autorisation bancaire est supérieur de 30% au total du panier. Le montant réellement débité sur votre compte sera lui ajusté au montant de la facture."/>
                                    </Typography>
                                </Grid>
                            )}
                        </>
                    )}
                    <Button variant="contained" color="primary" size="medium" onClick={processOrder}
                            className={classes.connexionButton}
                            disabled={mutationLoading || paymentLoading || (aliasIdToUse === null && isCreditCardEnabled() && isEmpty(aliasesData))}>
                        <FormattedMessage
                            id="checkout.buttonText"
                            defaultMessage="Confirmer la commande"/>
                        {(mutationLoading || paymentLoading) &&
                        <CircularProgress size={24} className={classes.buttonProgress}/>}
                    </Button>
                </Grid>
            )}
        </FormStep>
    );
};

export default CheckoutScreen;
