import {createBrowserHistory} from 'history';
import {applyMiddleware, compose, createStore} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import createSagaMiddleware from 'redux-saga'
import createRootReducer from "../reducers/rootReducer";
import {persistStore, persistReducer, createTransform} from 'redux-persist'
import localforage from "localforage";
import Flatted from "flatted";

//Allow support for circular reference in state objects
export const transformCircular = createTransform(
    (inboundState, key) => Flatted.stringify(inboundState),
    (outboundState, key) => Flatted.parse(outboundState),
);

const persistConfig = {
    key: 'root',
    storage: localforage,
    blacklist: ['router'],
    transforms: [transformCircular]
};

export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();

let composeEnhancers = compose;

if(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && process.env.NODE_ENV === 'development') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

//@TODO: use configureStore function from Redux Toolkit
export default function configureStore(preloadedState) {
    const store = createStore(
        persistReducer(persistConfig, createRootReducer(history)), // root reducer with router state
        preloadedState,
        composeEnhancers(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                sagaMiddleware,
            ),
        ),
    );

    let persistor = persistStore(store);

    return { store, persistor }
}
