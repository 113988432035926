import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage, useIntl} from 'react-intl';
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import amplitudeClient from "../../amplitudeWrapper";

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.primary.main,
        height: 80,
        width: '100%',
        marginBottom: 80,
    },
    iconContainer: {
        borderRadius: 90,
        height: 130,
        width: 130,
        backgroundColor: 'white',
        marginTop: 15,
    },
    iconWrapper: {
        height: '100%',
    },
    logo: {
        height: 110
    },
    heading: {
        paddingLeft: 15,
        paddingRight: 15,
    },
    listIcon: {
        color: theme.palette.grey[300]
    },
    listText: {
        fontSize: '0.9rem'
    },
    installHeading: {
        fontWeight: 'bold',
        marginTop: 20,
        fontSize: '1.05rem',
        paddingLeft: 15,
        paddingRight: 15,
    },
    installText: {
        padding: 15,
    },
    installButton: {
        marginBottom: 20
    },
    mainContainer: {
        padding: '0 20px'
    }
}));

//@TODO: adapt height on small phones
//@TODO: Choose between installation or connexion/register cta based on installation state
const WelcomeScreen = ({install, close}) => {

    const classes = useStyles();

    amplitudeClient.logEvent('install popup triggered');

    const intl = useIntl();

    const messages = {
        line1: intl.formatMessage(
            {
                id: 'welcomeScreen.line1',
                defaultMessage: 'Prise en charge de tous vos établissements et fournisseurs'
            }),
        line2: intl.formatMessage(
            {
                id: 'welcomeScreen.line2',
                defaultMessage: 'Passage de commande rapide et intuitif'
            }
        ),
        line3: intl.formatMessage(
            {
                id: 'welcomeScreen.line3',
                defaultMessage: 'Suivit des commandes clair et automatisé'
            }
        )
    };

    return (
        <>
            <Grid item container className={classes.header} justify={"center"}>
                <Grid item container justify={"center"} alignItems={"center"}>
                    <Paper className={classes.iconContainer}>
                        <Grid container justify={"center"} alignItems={"center"} className={classes.iconWrapper}>
                            <Grid item>
                                <img src="logoalt192.png" alt="Foodomarket" className={classes.logo}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container justify={"center"} alignItems={"center"} direction={"column"}
                  className={classes.mainContainer}>
                <Typography variant="h6" gutterBottom align={"center"} className={classes.heading}>
                    <FormattedMessage id="welcomeScreen.heading"
                                      defaultMessage="Bienvenue sur l'application Foodomarket !"/>
                </Typography>
                <Typography variant={"body2"} gutterBottom align={"center"}>
                    <FormattedMessage id="welcomeScreen.subheading"
                                      defaultMessage="L'application simplifie et accélère la gestion de vos commandes et de vos fournisseurs. C'est le compagnon idéal de tous les restaurateurs !"/>
                </Typography>

                <List>
                    <ListItem>
                        <ListItemIcon>
                            <StorefrontOutlinedIcon className={classes.listIcon}/>
                        </ListItemIcon>
                        <ListItemText primary={messages.line1}
                                      className={classes.listText} disableTypography/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ShoppingCartOutlinedIcon className={classes.listIcon}/>
                        </ListItemIcon>
                        <ListItemText primary={messages.line2} className={classes.listText}
                                      disableTypography/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <LocalShippingOutlinedIcon className={classes.listIcon}/>
                        </ListItemIcon>
                        <ListItemText primary={messages.line3} classes={classes.listText}
                                      disableTypography/>
                    </ListItem>
                </List>

                {/*<Typography variant="subtitle1" gutterBottom align={"center"} className={classes.installHeading}>
                    <FormattedMessage id="welcomeScreen.installHeading"
                                      defaultMessage="Pourquoi installer l'application Foodomarket"/>
                </Typography>*/}
                {/* <Typography variant={"body1"} gutterBottom align={"center"} className={classes.installText}>
                    <FormattedMessage id="welcomeScreen.installSubheading"
                                      defaultMessage="Pour profiter pleinement de toutes les fonctionnalités de Foodomarket, il vous suffit d'installer l'application sur votre téléphone."/>
                </Typography>*/}
                <Button variant="contained" color="primary" onClick={() => {
                    install();
                    close()
                }} size="large"
                        className={classes.installButton}>
                    <FormattedMessage id="welcomeScreen.installButton"
                                      defaultMessage="Installer l'application maintenant"/>
                </Button>
                <Button color="primary" size="small" onClick={close}>
                    <FormattedMessage id="welcomeScreen.installLater"
                                      defaultMessage="Installer plus tard"/>
                </Button>
            </Grid>
        </>
    );
};

export default WelcomeScreen;
