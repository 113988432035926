import {gql} from "apollo-boost";
import {SHOPPING_LIST_FIELDS} from "../../../graphql/fragments/ShoppingListFields";

export const GET_SUPPLIER_PRODUCTS_QUERY = gql`
    query getSupplierProducts($restaurantId: Int!, $supplierId: Int!, $limit: Int!, $cursor: String) {
        offers(
            first: $limit
            after: $cursor
            restaurantId: $restaurantId
            filters: {
                suppliers: [$supplierId]
            }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    _id
                    supplier {
                        _id
                    }
                    name
                    image {
                        medium
                        large
                    }
                    unit
                    priceWithoutVat
                    content
                    frozen
                    priceDetailsWithoutVat
                    createdByUser
                    origin
                    organic
                    promotion
                    weight
                }
            }
        }
    }

`;
export const GET_SHOPPING_LIST_QUERY = gql`
    ${SHOPPING_LIST_FIELDS}
    query ShoppingList($restaurantId: Int!, $supplierId: Int!) {
        supplierShoppingList(restaurantId: $restaurantId, supplierId: $supplierId) {
            ...ShoppingListFields
        }
    }
`;
export const MY_SHOPPING_LIST_QUERY = gql`
    query ShoppingListSpecific($Id: Int!) {
        shoppingList (id: $Id) {
            id
            _id
            name
            suppliersCount
            updatedAt
            items{
                edges{
                    node{
                        name
                        quantity
                        offer {
                            _id
                            name
                            image {
                                medium
                                large
                            }
                            unit
                            priceWithoutVat
                            content
                            frozen
                            priceDetailsWithoutVat
                            createdByUser
                            origin
                            organic
                            promotion
                            weight
                        }
                    }
                }
            }
        }}
`;

export const GET_SHOPPING_CART_QUERY = gql`
    query ShoppingCart($id: Int!) {
        shoppingCart(id: $id) {
            _id
            supplier {
                _id
                id
                name
                paymentMethod
                paymentProvider
            }
            restaurant {
                _id
                id
            }
            itemsTotalWithoutVat
            itemsTotalWithVat
            totalWithoutVat
            total: totalWithVat
            currency
            productsSubstitutionAllowed
            note
            scheduledDate
            deliveryFeesWithoutVat
            deliveryFeesWithVat
            items(first: 100) {
                totalCount
                edges {
                    node {
                        _id
                        note
                        quantity
                        unit
                        totalWithoutVat
                        totalWithVat
                        offer {
                            _id
                            name
                            origin
                            content
                            unit
                            organic
                            image {
                                medium
                                large
                            }
                            weight
                        }
                    }
                }
            }
        }
    }
`;
