import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {FormattedMessage, useIntl} from "react-intl";
import * as Yup from "yup";
import {Field, Form, Formik, useFormikContext} from "formik";
import Grid from "@material-ui/core/Grid";
import BaseTextField from "../../Inputs/BaseTextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import {useMutation} from "@apollo/react-hooks";
import {CREATE_CLAIM_MUTATION} from "./mutations";
import InputBase from "@material-ui/core/InputBase";
import Compressor from "compressorjs";
import amplitudeClient from "../../../amplitudeWrapper";
import {DeleteForeverOutlined} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {fade} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    field: {
        marginTop: 25
    },
    validateButton: {
        marginTop: 25,
        marginBottom: 25
    },
    buttonProgress: {
        position: 'absolute'
    },
    imagePreview: {
        border: '1px solid #e2e2e2e2',
        padding: 5,
        borderRadius: 5,
        marginTop: 20,
        width: '100%'
    },
    inputFile: {
        width: 0.1,
        height: 0.1,
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1,
    },
    pictureButton: {
        backgroundColor: theme.palette.grey[50],
        padding: '15px 8px',
        borderRadius: 5,
        border: '1px dashed #e2e2e2',
        marginTop: 20,
        marginBottom: 20,
        width: '100%',
        textAlign: 'center'
    },
    deleteImageButton: {
        position: 'absolute',
        right: 0,
        backgroundColor: theme.palette.error.main,
        color: 'white',
        padding: 5,
        top: 16,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.70),
        },
    },
    imageWrapper: {
        position: 'relative'
    }
}));

const CreateNewClaimForm = ({openState, close, restaurantId, supplierId, triggerNotification}) => {
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [picture, setPicture] = useState([]);

    let [createClaim, {loading: mutationLoading}] = useMutation(CREATE_CLAIM_MUTATION);

    const addPicture = (img) => {
        setPicture((prevState) => {
                prevState.push(img);
                return [...prevState];
            }
        );
    }

    const removePicture = (imgIndex) => {
        setPicture((prevState) => {
                prevState.splice(imgIndex, 1);
                return [...prevState];
            }
        );
    }

    const getPicture = (file) => {
        new Compressor(file, {
            quality: 0.5,
            success(result) {
                addPicture(result);
            },
            error(err) {
                console.log(err.message);
            },
        });
    }

    const notificationSuccessMessage = intl.formatMessage(
        {
            id: 'claimForm.successMessage',
            defaultMessage: 'Votre litige a été créer avec succès'
        }
    );

    const notificationErrorMessage = intl.formatMessage(
        {
            id: 'claimForm.errorMessage',
            defaultMessage: 'Une erreur survenue lors de la création du litige. Veuillez réessayer ultérieurement.'
        }
    );

    const problemDetails = {
        'litigation.delivery':
            [
                'litigation.undelivered_notified',
                'litigation.undelivered_not_notified',
                'litigation.delivery_out_of_time_slot',
                'litigation.delivery_postponed',
            ],
        'litigation.quality':
            [
                'litigation.damaged_product',
                'litigation.non_conforming_quality',
            ],
        'litigation.price':
            [
                'litigation.price_difference',
            ],
        'litigation.order_preparation':
            [
                'litigation.out_of_stock',
                'litigation.wrong_quantity',
                'litigation.wrong_product',
            ],
        'litigation.invoice':
            [
                'litigation.no_invoice',
                'litigation.credit_note_not_received',
            ]
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openState}
            onClose={close}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title"><FormattedMessage id="claimForm.title"
                                                                        defaultMessage="Déclarer un litige"/></DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        problem: '',
                        details: '',
                        callBackClient: false,
                        description: '',
                        picture: undefined
                    }}
                    validationSchema={Yup.object({
                        problem: Yup.string()
                            .required(),
                        details: Yup.string()
                            .required(),
                        callBackClient: Yup.boolean()
                            .required(),
                        description: Yup.string()
                            .required()
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        //Processing images before sending them
                        const images = [];
                        picture.map((image) => {
                            images.push({
                                file: image
                            });
                        });
                        createClaim({
                            variables: {
                                restaurantId: restaurantId,
                                supplierId: supplierId,
                                problem: values.problem,
                                callbackRequired: values.callBackClient,
                                details: values.details,
                                message: {
                                    content: values.description,
                                    pictures: images
                                }
                            }
                        }).then(r => {
                            triggerNotification({variant: 'success', message: notificationSuccessMessage});
                            setPicture([]);
                            close();
                        }).catch(e => {
                            console.log(e);
                            triggerNotification({variant: 'error', message: notificationErrorMessage});
                        })
                        setSubmitting(false);
                    }}
                >
                    {({values}) => {
                        return (
                            <Form id="form">
                                <Grid container justify={"center"} direction={"column"} alignItems={"center"}>
                                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                        <Field component={BaseTextField} name="problem" id="problem" type="text"
                                               className={classes.field}
                                               label={<FormattedMessage id="claimForm.issueTypeLabel"
                                                                        defaultMessage="Type de litige"/>} fullWidth
                                               select
                                               helperText={<FormattedMessage id="claimForm.issueTypeHelp"
                                                                             defaultMessage="Sélectionner le type de problème que vous souhaitez signaler"/>}>
                                            <MenuItem value="litigation.delivery"><FormattedMessage
                                                id="litigation.delivery"
                                                defaultMessage="Livraison"/></MenuItem>
                                            <MenuItem value="litigation.quality"><FormattedMessage
                                                id="litigation.quality"
                                                defaultMessage="Qualité"/></MenuItem>
                                            <MenuItem value="litigation.price"><FormattedMessage
                                                id="litigation.price"
                                                defaultMessage="Prix"/></MenuItem>
                                            <MenuItem value="litigation.order_preparation"><FormattedMessage
                                                id="litigation.order_preparation"
                                                defaultMessage="Préparation commande"/></MenuItem>
                                            <MenuItem value="litigation.invoice"><FormattedMessage
                                                id="litigation.invoice"
                                                defaultMessage="Facture"/></MenuItem>
                                        </Field>
                                    </Grid>
                                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                        <Field component={BaseTextField} name="details" id="details" type="text"
                                               className={classes.field}
                                               label={<FormattedMessage id="claimForm.requestTypeLabel"
                                                                        defaultMessage="Details"/>} fullWidth
                                               select
                                               helperText={<FormattedMessage id="claimForm.requestTypeHelp"
                                                                             defaultMessage="Précisez le problème"/>}>
                                            {values && values.problem !== '' &&
                                                problemDetails[values.problem].map(
                                                    (item) => <MenuItem value={item}><FormattedMessage
                                                        id={item}/></MenuItem>
                                                )
                                            }
                                        </Field>
                                    </Grid>
                                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                        <Field component={BaseTextField} name="callBackClient" id="callBackClient"
                                               type="text"
                                               className={classes.field}
                                               label={<FormattedMessage id="claimForm.wishTobBeCalledBack"
                                                                        defaultMessage="Souhaitez-vous être rappelé ?"/>}
                                               fullWidth
                                               select>
                                            <MenuItem value={false}><FormattedMessage
                                                id="claimForm.callbackNo"
                                                defaultMessage="Non"/></MenuItem>
                                            <MenuItem value={true}><FormattedMessage id="claimForm.callbackYes"
                                                                                     defaultMessage="Oui"/></MenuItem>
                                        </Field>
                                    </Grid>
                                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                        <Field label={<FormattedMessage id="claimForm.descriptionLabel"
                                                                        defaultMessage="Description"/>}
                                               className={classes.field}
                                               helperText={<FormattedMessage id="claimForm.descriptionHelp"
                                                                             defaultMessage="Décrivez plus précisément le problème rencontré"/>}
                                               component={BaseTextField} name="description" id="description" type="text"
                                               multiline rows={3}
                                               fullWidth/>
                                    </Grid>
                                    <Grid item container justify={"center"} spacing={1}>
                                        {picture && picture.map((image, key) => <Grid item xs={4}
                                                                                      className={classes.imageWrapper}><img
                                            key={key} id="preview"
                                            src={URL.createObjectURL(image)}
                                            className={classes.imagePreview}/><IconButton
                                            className={classes.deleteImageButton}
                                            onClick={() => removePicture(key)}
                                            aria-label="delete">
                                            <DeleteForeverOutlined/>
                                        </IconButton></Grid>)}
                                    </Grid>
                                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                        <label htmlFor="picture" className={classes.pictureButton}><FormattedMessage
                                            id="claimForm.addPicture"
                                            defaultMessage="Ajouter une photo"/></label>
                                        <InputBase onChange={(event) => {
                                            getPicture(event.target.files[0])
                                        }} className={classes.inputFile} component={BaseTextField} name="picture"
                                                   id="picture"
                                                   type="file" fullWidth/>
                                    </Grid>
                                    <Grid item lg={2} md={3} sm={4} xs={10}>
                                        <Button variant="contained" color="primary" size="large" type="submit"
                                                disabled={mutationLoading}>
                                            <FormattedMessage
                                                id="claimForm.validateButton"
                                                defaultMessage="Valider"/>
                                            {mutationLoading &&
                                                <CircularProgress size={24} className={classes.buttonProgress}/>
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={close} color="primary">
                    Annuler
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateNewClaimForm;