import {gql} from "apollo-boost";

export const GET_ALIASES_QUERY = gql`
    query geAliases($supplierId: Int!) {
        cardAliases: creditCards(supplierId: $supplierId) {
            _id
            id
            brand
            expirationDate
            number
            label
            paymentProvider
        }
    }
`;

export const GET_ALIAS_QUERY = gql`
    query getAlias($aliasId: String!, $supplierId: Int!) {
        cardAlias: creditCard(id: $aliasId, supplierId: $supplierId) {
            _id
            id
            brand
            expirationDate
            number
            label
            paymentProvider
        }
    }
`;
