import Drawer from "@material-ui/core/Drawer";
import React from "react";
import LeftDrawerPrimaryList from "../LeftDrawerPrimaryList";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import LeftDrawerSecondaryList from "../LeftDrawerSecondaryList";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import LeftDrawerUserInfo from "../LeftDrawerUserInfo";
import Typography from "@material-ui/core/Typography";
import {APP_VERSION} from "../../../constants/General";
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {FormattedMessage} from "react-intl";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ReactCountryFlag from "react-country-flag";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    closeIcon: {
        margin: 10
    },
    versionGrid: {
        height: '100%',
    },
    selectFormControl: {
        padding: '0 20px',
        marginBottom: 8
    },
    flag: {
        marginRight: 5,
        borderRadius: 5,
        marginBottom: 2
    },
    selectLabel: {
        paddingLeft: 26,
    }
});

//@TODO : make the drawer permanent on desktop
const LeftDrawer = ({drawerState, closeDrawer, push, pathname, updateTopNavTitle, user, selectedRestaurantId, selectLanguage}) => {
    const classes = useStyles();
    const handleChange = (event) => {
        selectLanguage(event.target.value);
    };

    return (
        <div>
            <Drawer open={drawerState} onClose={closeDrawer}>
                <div
                    className={classes.list}
                    role="presentation"
                    onClick={closeDrawer}
                    onKeyDown={closeDrawer}
                >
                    <CloseOutlinedIcon className={classes.closeIcon}/>
                    <LeftDrawerUserInfo user={user}/>
                    <Divider/>
                    <LeftDrawerPrimaryList closeDrawer={closeDrawer} push={push} pathname={pathname} user={user}
                                           updateTopNavTitle={updateTopNavTitle}
                                           selectedRestaurantId={selectedRestaurantId}/>
                    <Divider/>
                    <LeftDrawerSecondaryList closeDrawer={closeDrawer} push={push} pathname={pathname}
                                             updateTopNavTitle={updateTopNavTitle}/>
                </div>
                <Grid container direction="column" justify="flex-end" className={classes.versionGrid}>
                    <FormControl className={classes.selectFormControl}>
                        <InputLabel id="language-select-label" className={classes.selectLabel}>
                            <FormattedMessage id="drawer.language"
                                              defaultMessage="Langue sélectionnée"/>
                        </InputLabel>
                        <Select
                            labelId="language-select-label"
                            id="language-select"
                            value={user.language}
                            onChange={handleChange}
                        >
                            <MenuItem value="fr"><ReactCountryFlag countryCode="FR" svg className={classes.flag}/> Français</MenuItem>
                            <MenuItem value="en"><ReactCountryFlag countryCode="GB" svg className={classes.flag}/> English</MenuItem>
                            <MenuItem value="es"><ReactCountryFlag countryCode="ES" svg className={classes.flag}/> Spanish</MenuItem>
                        </Select>
                    </FormControl>
                    <Button onClick={() => window.location.reload(true)}>
                        <FormattedMessage id="drawer.reload"
                                          defaultMessage="Recharger l'application"/>
                    </Button>
                    <Typography variant="caption" align="center" gutterBottom>version : {APP_VERSION}</Typography>
                </Grid>
            </Drawer>
        </div>
    );
};

export default LeftDrawer;