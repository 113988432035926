import {createSlice} from "@reduxjs/toolkit";

const initialState = {};

const messagesSlice = createSlice({
    name: 'messages',
    initialState: initialState,
    reducers: {
        setMessage(state, action) {
            state[action.payload.supplier.id] = {
                text: action.payload.message.text,
                picture: action.payload.message.picture,
                important: action.payload.message.important,
            }
        },
    }
});

export const {setAsImportant, setMessage} = messagesSlice.actions;
export default messagesSlice.reducer;

