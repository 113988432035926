import {connect} from 'react-redux';
import {updateTitle} from "../../reducers/topNavigation";
import {triggerToastNotification} from "../../reducers/toastNotification";
import {bindActionCreators} from "redux";
import {createMatchSelector, push} from "connected-react-router";
import SetNewPasswordForm from "../../components/ForgottenPassword/SetNewPasswordForm";

const mapStateToProps = state => {
    const matchSelector = createMatchSelector('/new-password/:token');
    const match = matchSelector(state);
    return {
        token: match.params.token,
    };
};

const mapDispatchToProps = (dispatch) => ({
    triggerNotification: (payload) => dispatch(triggerToastNotification(payload)),
    push: bindActionCreators(push, dispatch),
});

const SetNewPasswordPage = connect(mapStateToProps, mapDispatchToProps)(SetNewPasswordForm);

export default SetNewPasswordPage;
