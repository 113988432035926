import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {GET_SUPPLIER_QUERY} from "../../Suppliers/SuppliersList/queries";

const GraphQlFetchData = ({
                              supplierTrueId,
                              restaurantTrueId,
                          }) => {
    const {
        data: supplierData,
        error: supplierError,
        loading: supplierLoading,
        refetch: supplierRefetch
    } = useQuery(GET_SUPPLIER_QUERY, {
        variables: {
            id: supplierTrueId,
            restaurantId: restaurantTrueId
        }
    });

    return (
        {supplierData, supplierLoading, supplierRefetch, supplierError}
    );
};

export default GraphQlFetchData;
