import {useIntl} from 'react-intl';
import * as Yup from "yup";

export function Hooks() {
    const emailTexts = {
        label: useIntl().formatMessage({
            id: 'loginForm.emailLabel',
            defaultMessage: 'Email',
        }),
        placeholder: useIntl().formatMessage({
            id: 'loginForm.emailLabel.emailPlaceholder',
            defaultMessage: 'Entrez votre email'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'loginForm.emailRequired',
            defaultMessage: 'L\'email est requis pour vous identifier'
        }),
        errorInvalidEmail: useIntl().formatMessage({
            id: 'loginForm.invalidEmail',
            defaultMessage: 'L\'email n\'est pas valide'
        })

    };

    const passwordTexts = {
        label: useIntl().formatMessage({
            id: 'loginForm.passwordLabel',
            defaultMessage: 'Mot de passe',
        }),
        placeholder: useIntl().formatMessage({
            id: 'loginForm.passwordPlaceholder',
            defaultMessage: 'Entrez votre mot de passe'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'loginForm.passwordRequired',
            defaultMessage: 'Le mot de passe est requis pour vous identifier'
        })
    };

    const notificationText = {
        error: useIntl().formatMessage(
            {
                id: 'loginForm.error',
                defaultMessage: 'Une erreur est survenue'
            }
        ),
        badCredentials: useIntl().formatMessage(
            {
                id: 'loginForm.badCredentials',
                defaultMessage: 'Le mot de passe ou l\'email est incorrect',
            }
        )
    };
    const formValidate = {
        email: Yup.string()
            .email(emailTexts.errorInvalidEmail)
            .required(emailTexts.errorRequired),
        password: Yup.string()
            .required(passwordTexts.errorRequired)
    }
    return ({
        formValidate, notificationText, emailTexts, passwordTexts
    })
}