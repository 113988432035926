import {gql} from "apollo-boost";

export const EDIT_SUPPLIER_MUTATION = gql`
    mutation editSupplier($id: ID!, $name: String!, $email: String!, $phone: String!) {
        editSupplier(input:{id: $id, name: $name, email: $email, phone: $phone}) {
            supplier{
                _id
                id
                name
                email
                telephone
                partner
                createdByUser
                status
                nbUnreadMessages
                minOrderAmount
            }
        }
    }
`;
