import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {FormattedMessage} from "react-intl";
import ProductCard from "../../Products/ProductCard";
import FloatingCart from "../../Cart/FloatingCart";
import {filter, indexOf, isEmpty} from 'lodash';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {RESTAURANTS} from "../../../constants/Routes";
import {useQuery} from "@apollo/react-hooks";
import categories from "../../Products/categories";
import FetchingError from "../../Errors/FetchingError";
import amplitudeClient from "../../../amplitudeWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentDots} from "@fortawesome/free-regular-svg-icons";
import {GET_SUPPLIER_QUERY} from "../SuppliersList/queries";
import {Skeleton} from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Link from "@material-ui/core/Link";
import Badge from "@material-ui/core/Badge";
import {GET_SUPPLIER_PRODUCTS_QUERY} from "../SupplierShoppingList/queries";
import ProductCategoryCard from "../../Products/ProductCategoryCard";
import SupplierIndividualProductList from "../SupplierIndividualProductList";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import NoDeliveryNotification from "../NoDeliveryNotification";
import GraphQlFetchData from "./graphQlFetchData";

const useStyles = makeStyles(theme => ({
    container: {},
    withFloatingCart: {
        paddingBottom: 64
    },
    innerGrid: {
        padding: '5px 10px',
    },
    search: {
        flexGrow: 1
    },
    topContainer: {
        padding: 10,
        boxShadow: '0px 8px 8px 0px rgba(0,0,0, 0.1)',
        zIndex: 1100,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
    },
    productsBox: {
        flex: '1 1 auto',
    },
    categoryFilterProduct: {
        width: '100%',
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    closeEditButton: {
        marginTop: 7,
        borderRadius: 0,
        padding: 8
    },
    alertDialogHeader: {
        backgroundColor: theme.palette.error.main,
        color: 'white',
        textAlign: 'center',
        fontSize: '1rem'
    },
    alertDialogIcon: {
        fontSize: '2rem'
    },
    alertDialogText: {
        fontSize: '0.9rem',
        textAlign: 'center',
        marginTop: 15,
        fontWeight: 600
    },
    link: {
        marginTop: 30,
        display: 'block'
    }
}));

//@TODO: too many rerender, make better conditions adn state management to reduce rerenders
//@TODO: reorganise state management instructions
const SupplierProductCategoriesList = ({
                                           updateTopNavTitle,
                                           updateTopNavSubtitle,
                                           push,
                                           initNewCart,
                                           cart,
                                           restaurantId,
                                           supplierId,
                                           addProductToCart,
                                           subtractProductFromCart,
                                           setActionsComponent,
                                           displayGoBack,
                                           denyAccessToUnauthenticatedUser,
                                           triggerNotification,
                                           setProductQuantity,
                                       }) => {

    const classes = useStyles();

    const [openCategoryProductsList, setOpenCategoryProductsList] = useState(false);

    const toggleCategoryProductsList = () => {
        setOpenCategoryProductsList(!openCategoryProductsList);
    };

    const decodedRestaurantId = window.atob(restaurantId);
    const restaurantSegments = decodedRestaurantId.split('/');
    const restaurantTrueId = restaurantSegments.pop();

    const decodedSupplierId = window.atob(supplierId);
    const supplierSegments = decodedSupplierId.split('/');
    const supplierTrueId = supplierSegments.pop();

    const {
        supplierData,
        supplierError,
        supplierLoading,
        mercuarialError,
        mercurialData,
        mercurialLoading,
        refetchMercurial
    } = GraphQlFetchData({updateTopNavTitle, supplierTrueId, restaurantTrueId})

    useEffect(() => {
        denyAccessToUnauthenticatedUser();
        displayGoBack(`/restaurants/${restaurantId}/suppliers`);
        updateTopNavSubtitle('');
        if (supplierLoading) {
            updateTopNavTitle("isLoading");
        }
    }, [])

    //-------


    //If no cart found for the supplier, init a new one
    if (cart === undefined) {
        initNewCart({supplierId: supplierId, restaurantId: restaurantId});
        cart = {products: {}, total: 0}
    }

    //@TODO: put this in a component
    setActionsComponent(() => (
        <>
            <IconButton onClick={globalSearch}>
                <SearchOutlinedIcon/>
            </IconButton>
            <IconButton onClick={() => {
                amplitudeClient.logEvent('chat displayed', {origin: 'supplier mercurial'});
                push(`${RESTAURANTS}/${restaurantId}/suppliers/${supplierId}/chat`, {modal: true})
            }}>
                {supplierData && supplierData.mySupplier.nbUnreadMessages ? (
                    <Badge badgeContent={supplierData.mySupplier.nbUnreadMessages} color="secondary">
                        <FontAwesomeIcon icon={faCommentDots}/>
                    </Badge>
                ) : (
                    <FontAwesomeIcon icon={faCommentDots}/>
                )}
            </IconButton>
        </>
    ));

    //---------------------

    const [alertDialogState, setAlertDialogState] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState();

    const closeAlertDialog = () => {
        setAlertDialogState(false);
    };

    let availableCategories = [];
    if (mercurialData && mercurialData.supplierProducts.edges !== null) {
        mercurialData.supplierProducts.edges.forEach((item, key) => {
            if (indexOf(availableCategories, parseInt(item.node.mainCategory)) === -1) {
                availableCategories.push(parseInt(item.node.mainCategory));
            }
        });
    }

    const getFilteredProducts = (category) => {
        if (category) {
            return filter(mercurialData.supplierProducts.edges, (product) => {
                //Filter by category
                if (parseInt(product.node.mainCategory) === parseInt(category.id)) {
                    return true;
                }
            });
        } else {

            if (!selectedCategory) {
                return mercurialData.supplierProducts.edges;
            }

            return filter(mercurialData.supplierProducts.edges, (product) => {
                //Filter by category
                if (parseInt(product.node.mainCategory) === parseInt(selectedCategory.id)) {
                    return true;
                }
            });
        }
    };

    const displayCategoryProducts = (category) => {
        setSelectedCategory(category);
        toggleCategoryProductsList();
    };

    const globalSearch = () => {
        setSelectedCategory(null);
        toggleCategoryProductsList();
    };

    const refetchProducts = () => {
        refetchMercurial().then(null).catch(e => null)
    };

    if (supplierError || mercuarialError) {
        return (
            <FetchingError action={refetchProducts}/>
        );
    }

    if (mercurialLoading) {
        const skeleton = [0, 1, 2, 3, 4, 5, 6];
        return (
            <Grid container justify={"center"} className={classes.container}>
                {skeleton.map((item, key) => (
                    <Grid item xs={12} key={key} className={classes.innerGrid}>
                        <ProductCard/>
                    </Grid>
                ))}
            </Grid>
        );
    }

    return (
        <>
            {supplierData && isEmpty(supplierData.mySupplier.openingDays) && (
                <NoDeliveryNotification supplier={supplierData.mySupplier}/>
            )}
            {supplierData && mercurialData && mercurialData.supplierProducts.edges && supplierData.mySupplier.status !== 'ACCOUNT_OPENED' ? (
                <Dialog open={alertDialogState} onClose={closeAlertDialog}>
                    <DialogTitle id="alert-dialog-title" className={classes.alertDialogHeader}
                                 disableTypography>
                        <Grid container justify="center" direction="column">
                            <Grid item>
                                <ErrorOutlineOutlinedIcon className={classes.alertDialogIcon}/>
                            </Grid>
                            <Grid item>
                                <FormattedMessage
                                    id="alertSupplierAccount.heading"
                                    defaultMessage="Compte fournisseur inactif"/>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" className={classes.alertDialogText}>
                            <FormattedMessage
                                id="alertSupplierAccount.textTop"
                                defaultMessage="Votre compte founisseur n'a pas encore été validé. Pour plus d'information rendez-vous sur : "/>
                            <Link href="https://www.foodomarket.com/shop/mes-fournisseurs" className={classes.link}
                                  color="secondary">
                                Mes fournisseurs
                            </Link>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={closeAlertDialog}>
                            <FormattedMessage
                                id="alertMinOrderAmount.text"
                                defaultMessage="Fermer"
                            />
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : null}
            {supplierData && mercurialData && mercurialData.supplierProducts.edges ? (
                <>
                    <Grid container direction="column" justify="center" alignItems="center" wrap="nowrap"
                          className={`${classes.container} ${cart.totalQuantity ? (classes.withFloatingCart) : null}`}>
                        {categories.map(category => {
                            if (indexOf(availableCategories, category.id) !== -1) {
                                return (
                                    <Grid item container key={category.id}>
                                        <ProductCategoryCard categoryName={category.name} image={category.image}
                                                             productsCount={50}
                                                             onClick={() => displayCategoryProducts(category)}
                                                             products={getFilteredProducts(category)}/>
                                    </Grid>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </Grid>
                    <FloatingCart push={push} cart={cart} orderMinAmount={supplierData.mySupplier.minOrderAmount}
                                  currency={supplierData.mySupplier.currency}/>
                    <SupplierIndividualProductList push={push}
                                                   initNewCart={initNewCart}
                                                   cart={cart}
                                                   restaurantId={restaurantId}
                                                   supplierId={supplierId}
                                                   supplierTrueId={supplierTrueId}
                                                   restaurantTrueId={restaurantTrueId}
                                                   addProductToCart={addProductToCart}
                                                   subtractProductFromCart={subtractProductFromCart}
                                                   products={getFilteredProducts()}
                                                   denyAccessToUnauthenticatedUser={denyAccessToUnauthenticatedUser}
                                                   triggerNotification={triggerNotification}
                                                   setProductQuantity={setProductQuantity}
                                                   openState={openCategoryProductsList}
                                                   close={toggleCategoryProductsList}
                                                   supplier={supplierData.mySupplier}
                                                   category={selectedCategory}
                    />
                </>) : null}
        </>
    );
};

export default SupplierProductCategoriesList;
