import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import LinearProgress from "@material-ui/core/LinearProgress";
import {FormattedMessage} from "react-intl";
import GraphQlFetchData from "./graphQlFetchData";

const DeleteCardDialog = ({aliasId, close, supplierId}) => {
    const {mutationLoading, deleteAlias} = GraphQlFetchData({aliasId, supplierId})

    const validateEdit = () => {
        deleteAlias({
            variables: {
                aliasId: aliasId,
                supplierId: supplierId
            }
        }).then(() => {
            close();
        })
        .catch((e) => console.log(e))
    }

    return (
        <Dialog
            open={!!aliasId}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                id="alert-dialog-title">{"Suppression de carte"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage id="deleteCard.dialogText"
                                      defaultMessage="Êtes-vous sûr de vouloir supprimer cette carte ?"/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={validateEdit}>
                    <FormattedMessage id="deleteCard.delete"
                                      defaultMessage="Supprimer"/>
                </Button>
                <Button onClick={close}
                        autoFocus>
                    <FormattedMessage id="deleteCard.cancel"
                                      defaultMessage="Annuler"/>
                </Button>
            </DialogActions>
            {mutationLoading ? <LinearProgress/> : null}
        </Dialog>
    )
}

export default DeleteCardDialog;

