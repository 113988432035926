import {gql} from "apollo-boost";

export const CREATE_NEW_SUPPLIER_MUTATION = gql`
    mutation createSupplier($name: String!, $email: String!, $phone: String!, $locale: String!) {
        createSupplier(input:{name: $name, email: $email, phone: $phone, locale: $locale}) {
            supplier{
                _id
                id
                name
                email
                telephone
                supportPhone
                currency
                partner
                createdByUser
                status
                nbUnreadMessages
                minOrderAmount
                openingDays
                orderCutOff
                deliveryCost
                deliveryWeightUnit
                categories
                freeShippingThreshold
                currentPaymentMethod
                deliveryDates
            }
        }
    }
`;
