import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import OrderStatus from "../OrderStatus";
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import CardActionArea from '@material-ui/core/CardActionArea';
import {ORDERS} from "../../../constants/Routes";
import GeneralPurposeCard from "../../GeneralPurpose/GeneralPurposeCard";
import {blueGrey} from "@material-ui/core/colors";
import amplitudeClient from "../../../amplitudeWrapper";
import {format} from "date-fns";
import parseIsoDate from "yup/lib/util/isodate";
import Skeleton from "@material-ui/lab/Skeleton"

const useStyles = makeStyles(theme => ({
    icon: {
        fontSize: '2.5rem',
        padding: 10,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        color: theme.palette.primary.contrastText,
        marginRight: 15,
    },
    name: {
        fontWeight: 500,
    },
    cardContent: {
        flexGrow: 1,
        padding: '10px !important',
        color: blueGrey[800],
    },
    centerBlock: {
        flexGrow: 1,
    },
    chatIcon: {
        color: theme.palette.grey.A700,
    },
    iconBlock: {
        display: 'flex',
    },
    ref: {
        color: theme.palette.grey.A700,
        marginLeft: 8
    },
    date: {
        color: theme.palette.grey.A700,
        marginRight: 5,
        fontSize: '0.8rem'
    }
}));

const OrderCard = ({order, push}) => {
    const classes = useStyles();

    const goToOrder = () => {
        amplitudeClient.logEvent('order details displayed ');
        push(`${ORDERS}/${order._id}`, {modal: true})
    };

    return (
        <GeneralPurposeCard>
            <Grid container wrap="nowrap">
                <CardActionArea onClick={goToOrder}>
                    <CardContent className={classes.cardContent}>
                        <Grid container wrap="nowrap" alignItems="center">
                            <Grid item className={classes.iconBlock}>
                                {order ? <ListAltOutlinedIcon className={classes.icon}/> :
                                    <Skeleton variant="circle" height={40} width={40}/>}

                            </Grid>
                            <Grid item container className={classes.centerBlock} direction="column">
                                <Grid item container>
                                    <Grid item style={!order ? {flexGrow: 1} : null}>
                                        <Typography className={classes.name} align="center">
                                            {order ? (order.supplier.name) : <Skeleton style={{marginLeft: 20}}/>}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={!order ? {flexGrow: 1} : null}>
                                        <Typography className={classes.ref} align="center">
                                            {order ? (<>#{order.reference}</>) : <Skeleton/>}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="center">
                                    <Grid item style={!order ? {flexGrow: 1} : null}>
                                        <Typography className={classes.deliveryDate} align="left">
                                            {order ? format(parseIsoDate(order.deliveryDate), 'dd/MM/yyyy') :
                                                <Skeleton style={{marginLeft: 20}}/>}</Typography>
                                    </Grid>
                                    <Grid item style={!order ? {flexGrow: 1} : null}>
                                        {order ? (
                                            <>
                                                {order.statusHistory.edges.length !== 0 ?
                                                    <OrderStatus status={order.status}/> :
                                                    <OrderStatus status={'ARCHIVED'}/>}
                                            </>
                                        ) : <Skeleton style={{marginLeft: 10}}/>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Grid>
        </GeneralPurposeCard>
    );
};

export default OrderCard;
