import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Button from "@material-ui/core/Button";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles(theme => ({
    iconContainer: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 90,
        height: 90,
        width: 90,
        marginTop: 25,
        marginBottom: 20,
    },
    textWrapper: {
        marginBottom: 25,
        padding: '0 10px',
    },
    iconWrapper: {
        height: '100%',

    },
    icon: {
        color: 'white',
        fontSize: '3rem'
    },
    input: {
        marginBottom: 25,
    },
    resetPasswordLink: {
        cursor: 'pointer'
    },
    connexionButton: {
        margin: '30px 0',
    }
}));

//@TODO: split form and header
//@TODO: make a proper container for large screen
//@TODO: fix height for small phone
//@TODO: center vertically on large phone
const RegisterForm = () => {
    const classes = useStyles();
    const preventDefault = event => event.preventDefault();

    const intl = useIntl();

    return (
        <>

            <Grid container justify={"center"} alignItems={"center"}>
                <Paper className={classes.iconContainer}>
                    <Grid container justify={"center"} alignItems={"center"} className={classes.iconWrapper}>
                        <Grid item>
                            <ShoppingCartOutlinedIcon className={classes.icon}/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid className={classes.textWrapper}>
                <Typography variant="h6" gutterBottom align={"center"}>
                    <FormattedMessage
                        id="registerForm.heading"
                        defaultMessage="Créer un compte"
                    />
                </Typography>
                <Typography variant="body2" gutterBottom align={"center"}>
                    <FormattedMessage
                        id="registerForm.subheading"
                        defaultMessage="Pour bénéficier de tous les avantages de Foodomarket, créer un compte gratuitement en complétant le formulaire ci-dessous."
                    />
                </Typography>
            </Grid>

            <form>
                <Grid container justify={"center"} direction={"column"} alignItems={"center"}>
                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                        <TextField id="firstname" name="firstname" label={intl.formatMessage({
                            id: 'registerForm.firstnameLabel',
                            defaultMessage: 'Prénom'
                        })} variant="filled" fullWidth
                                   placeholder={intl.formatMessage({
                                       id: 'registerForm.firstnameInputPlaceholder',
                                       defaultMessage: 'Entrez votre prénom'
                                   })} className={classes.input}/>

                    </Grid>
                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                        <TextField id="email" name="email" label={intl.formatMessage({
                            id: 'registerForm.emailLabel',
                            defaultMessage: 'Email'
                        })} variant="filled" fullWidth
                                   placeholder={intl.formatMessage({
                                       id: 'registerForm.emailInputPlaceholder',
                                       defaultMessage: 'Entrez votre email'
                                   })} className={classes.input}/>
                    </Grid>
                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                        <TextField id="phone" name="phone" label={intl.formatMessage({
                            id: 'registerForm.phoneLabel',
                            defaultMessage: 'Téléphone'
                        })} variant="filled" fullWidth
                                   placeholder={intl.formatMessage({
                                       id: 'RegisterForm.phoneInputPlaceholder',
                                       defaultMessage: 'Entrez votre téléphone'
                                   })} className={classes.input}/>

                    </Grid>
                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                        <TextField id="password" name="password" label={intl.formatMessage({
                            id: 'registerForm.passwordLabel',
                            defaultMessage: 'Mot de passe'
                        })} variant="filled" fullWidth
                                   placeholder={intl.formatMessage({
                                       id: 'RegisterForm.passwordInputPlaceholder',
                                       defaultMessage: 'Choisissez un mot de passe'
                                   })} className={classes.input}/>

                    </Grid>
                    <Grid item lg={2} md={3} sm={4} xs={10}>
                        <Button variant="contained" color="primary" size="large" className={classes.connexionButton}>
                            <FormattedMessage
                                id="registerForm.buttonText"
                                defaultMessage="Créer mon compte"
                            />
                        </Button>
                    </Grid>
                    <Grid item lg={2} md={3} sm={4} xs={10}>
                        <Typography variant={"body1"} align={"center"}>
                            <FormattedMessage
                                id="registerForm.loginText"
                                defaultMessage="Déjà utilisateur Foodomarket ?"
                            /> <Link href="#" onClick={preventDefault}
                                     className={classes.resetPasswordLink}>
                            <FormattedMessage
                                id="RegisterForm.loginLinkText"
                                defaultMessage="Connectez-vous !"
                            />
                        </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default RegisterForm;