import {connect} from 'react-redux';
import SuccessfulOrder from "../../components/Orders/SuccessfulOrder";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {displayGoBack, unsetActionsComponent, updateSubtitle, updateTitle} from "../../reducers/topNavigation";
import {denyAccessToUnauthenticatedUser} from "../../reducers/authentication";
import {clearCart} from "../../reducers/carts";

const mapStateToProps = state => {
    return {
        cart: state.carts[state.carts.currentCartId],
        currentCartId: state.carts.currentCartId,
        userType: state.authentication.userType,
    };
};

const mapDispatchToProps = (dispatch) => ({
    push: bindActionCreators(push, dispatch),
    displayGoBack: (payload) => dispatch(displayGoBack(payload)),
    denyAccessToUnauthenticatedUser: (payload) => dispatch(denyAccessToUnauthenticatedUser(payload)),
    updateTopNavTitle: (title) => dispatch(updateTitle(title)),
    updateTopNavSubtitle: (subtitle) => dispatch(updateSubtitle((subtitle))),
    clearCart: (payload) => dispatch(clearCart(payload)),
    unsetActionsComponent: (payload) => dispatch(unsetActionsComponent()),
});

const SuccessfulOrderPage = connect(mapStateToProps, mapDispatchToProps)(SuccessfulOrder);

export default SuccessfulOrderPage;
