import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddSupplierCard from "../AddSupplierCard";
import SearchBar from "../../SearchBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useQuery} from "@apollo/react-hooks";
import {AutoSizer, CellMeasurer, CellMeasurerCache, List,} from "react-virtualized";
import SearchResults from 'react-filter-search';
import {GET_RESTAURANT_QUERY} from "../../Restaurants/RestaurantsList/queries";
import amplitudeClient from "../../../amplitudeWrapper";
import GraphQlFetchData from "./graphQlFetchData";
import Hooks from "./hooks";

const useStyles = makeStyles(theme => ({
    container: {
        padding: 10,
        maxHeight: '100%'
    },
    innerGrid: {
        padding: '5px 10px',
    },
    suppliersBox: {
        overflowY: 'auto',
        flex: '1 1 auto',
        padding: 8,
        height: 'calc(100vh - 240px)'
    },
    actions: {
        flex: '0 0 auto',
        paddingTop: 15,
        borderTop: '2px solid #f2f2f2',
    }
}));

const AddSupplierList = ({
                             updateOverlayTitle,
                             push,
                             restaurantId,
                             denyAccessToUnauthenticatedUser,
                             triggerNotification
                         }) => {

    const decodedRestaurantId = window.atob(restaurantId);
    const {data: restaurantData} = useQuery(GET_RESTAURANT_QUERY, {variables: {id: decodedRestaurantId}});

    const {pageTitle, emptyListMessage, searchText, createNewSupplierButtonText} = Hooks()
    const classes = useStyles();
    const [searchValue, setSearchValue] = React.useState('');

    //const {data, loading, error} = useQuery(GET_SUPPLIERS_QUERY);
    const {data, error, loading} = GraphQlFetchData()
    let cache = new CellMeasurerCache({
        fixedWidth: true,
    });

    const handleChange = event => {
        const {value} = event.target;
        setSearchValue(value);
    };

    /////////////// FOR PAGINATION /////////////////
    /* const {data, loading, fetchMore, error} = useQuery(GET_MORE_SUPPLIERS_QUERY, {
         variables: {limit: 10, offset: 0}
     });*/

    /*const loadMore = () =>
         fetchMore({
             variables: {
                 offset: 10
             },
             updateQuery: (prev, {fetchMoreResult}) => {
                 if (!fetchMoreResult) return prev;
                 return Object.assign({}, prev, {
                     moreSuppliers: [...prev.moreSuppliers, ...fetchMoreResult.moreSuppliers]
                 });
             }
         });*/
    ///////////////////////////////////////////////


    useEffect(() => {
        denyAccessToUnauthenticatedUser();
        updateOverlayTitle(pageTitle);
    }, [])

    if (loading) {
        const skeleton = [0, 1, 2, 3, 4, 5, 6];
        return (
            <Grid container justify={"center"} className={classes.container}>
                {skeleton.map((item, key) => (
                    <Grid item xs={12} key={key} className={classes.innerGrid}>
                        <AddSupplierCard/>
                    </Grid>
                ))}
            </Grid>
        );
    }

    const goToCreateSupplier = () => {
        amplitudeClient.logEvent('create supplier clicked');
        push(`/restaurants/${restaurantId}/suppliers/create-new`, {modal: true, keepOpen: true});
    };

    if (error) return 'error';

    return (
        <Grid container justify={"center"} className={classes.container} wrap="nowrap" direction="column">
            <Grid item container>
                <SearchBar placeholder={searchText} onChange={handleChange}/>
            </Grid>
            <Grid item container className={classes.suppliersBox}>
                {data ? (
                        <SearchResults
                            value={searchValue}
                            data={data.availableSuppliers}
                            renderResults={results => (
                                <AutoSizer>
                                    {({height, width}) => (
                                        <List width={width}
                                              height={height}
                                              rowCount={results.length}
                                              rowHeight={cache.rowHeight}
                                              rowRenderer={({key, index, parent, isScrolling, isVisible, style}) => {
                                                  return (
                                                      <CellMeasurer
                                                          cache={cache}
                                                          columnIndex={0}
                                                          key={key}
                                                          parent={parent}
                                                          rowIndex={index}
                                                      >
                                                          <Grid item xs={12} className={classes.innerGrid} key={key}
                                                                style={style}>
                                                              <AddSupplierCard supplier={results[index]}
                                                                               triggerNotification={triggerNotification}
                                                                               restaurantId={restaurantData.restaurant._id}/>
                                                          </Grid>
                                                      </CellMeasurer>
                                                  )
                                              }}
                                              overscanRowCount={10}
                                        />)}

                                </AutoSizer>
                            )}
                        />
                    ) :
                    (
                        <Typography variant="body1" align="center">
                            {emptyListMessage}
                        </Typography>
                    )}
            </Grid>
            <Grid item container className={classes.actions} justify="center">
                <Button color="primary" variant="contained" size="small" className={classes.createNewSupplierButton}
                        onClick={goToCreateSupplier}>
                    {createNewSupplierButtonText}
                </Button>
            </Grid>
        </Grid>
    );
};

export default AddSupplierList;
