import React from "react"
import {useIntl} from "react-intl";

const Hooks = () => {


    const notificationText = {
        success: useIntl().formatMessage(
            {
                id: 'addRestaurantPage.SuccessNotificationText',
                defaultMessage: 'Restaurant ajouté avec succès'
            }
        ),
        error: useIntl().formatMessage(
            {
                id: 'addRestaurantPage.errorNotificationText',
                defaultMessage: 'Une erreur est survenue lors de la création d\'un nouveau restaurant.'
            }
        ),
    }
    const pageTitle = useIntl().formatMessage(
        {
            id: 'addRestaurantPage.title',
            defaultMessage: 'Ajouter un restaurant'
        }
    );

    return {
        notificationText,
        pageTitle
    }
}
export default Hooks
