import React, {useEffect, useState} from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {FormattedMessage} from "react-intl";
import {InputLabel, makeStyles} from "@material-ui/core";
import FetchingError from "../../Errors/FetchingError";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import BaseTextField from "../../Inputs/BaseTextField";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import {blueGrey} from "@material-ui/core/colors";
import {PROFESSIONAL} from "../../../constants/UserTypes";
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import {indexOf} from 'lodash';
import {format} from "date-fns";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import GraphQlFetchData from "./graphQlFetchData";
import {Hooks} from "./hooks";
import FormStep from "../../Commun/FormStep";
import {useShoppingCart} from "../../Suppliers/ShoppingCart/hook";

const useStyles = makeStyles(theme => ({
    input: {
        marginBottom: 25,
    },
    connexionButton: {
        margin: '30px 0',
    },
    textContainer: {
        marginTop: 120,
        marginBottom: 30
    },
    stepper: {
        padding: '0 10px 20px',
        zIndex: 1100,
        boxShadow: '0px 8px 8px 0px rgba(0,0,0, 0.1)',
        position: 'fixed',
        width: '100%',
        paddingTop: 20,
        borderTop: '1px solid #f5f5f5',
    },
    active: {
        color: theme.palette.primary.main
    },
    completed: {},
    root: {
        color: blueGrey[400]
    },
    deliveryStart: {
        marginRight: 10
    },
    deliveryIntervalLabel: {
        marginBottom: 20,
    },
    deliveryIntervalMiddleText: {
        height: 56,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    deliveryAddressLabel: {
        fontWeight: 600,
        textTransform: 'uppercase',
    },
    pageTitle: {
        textTransform: 'uppercase',
        fontSize: '1rem',
        fontWeight: 700,
        borderBottom: '1px solid #e2e2e2',
        width: '100%',
    },
    deliveryAddressBlock: {
        marginTop: 20,
        backgroundColor: theme.palette.grey[50],
        padding: 15,
        borderRadius: 5
    },
    LabelBlock: {
        fontSize: '12px !important',
        fontWeight: 500,

    },
    productReplacementLabel: {
        fontSize: '0.9rem',
        fontWeight: 500,
        color: '#333'
    },
    loaderWrapper: {
        height: '50vh',
    }
}));

//@TODO: adapt for wide screen  and desktop
const ShippingDetailsScreen = ({
                                   updateOverlayTitle,
                                   userType,
                                   push,
                                   restaurantId,
                                   supplierId,
                                   cartId,
                                   setShippingDetails,
                                   unsetActionsComponent,
                                   triggerNotification,
                                   displayGoBack,
                                   goBack
                               }) => {

    const classes = useStyles();
    const {
        deliveryDayTexts,
        deliveryIntervalEndTexts,
        complementaryInstructionsTexts,
        deliveryIntervalStartTexts,
        deliveryIntervalLabel
    } = Hooks()


    const [productReplacementAgreement, setProductReplacementAgreement] = React.useState(undefined);
    const [deliveryIntervalStart, setDeliveryIntervalStart] = useState(null);
    const [deliveryIntervalEnd, setDeliveryIntervalEnd] = useState(null);

    const {updateShoppingCart} = useShoppingCart();

    useEffect(() => {
        unsetActionsComponent();
        displayGoBack(`/restaurants/${restaurantId}/suppliers/${supplierId}`);
        if (supplierLoading) {
            updateOverlayTitle('isLoading');
        }
    }, [])

    const handleChange = (event) => {
        setProductReplacementAgreement(event.target.value === 'yes');
        // setState({ ...state, [event.target.name]: event.target.checked });
    };

    const decodedRestaurantId = window.atob(restaurantId);
    const restaurantSegments = decodedRestaurantId.split('/');
    const restaurantTrueId = restaurantSegments.pop();

    const decodedSupplierId = window.atob(supplierId);
    const supplierSegments = decodedSupplierId.split('/');
    const supplierTrueId = supplierSegments.pop();

    const {
        restaurantRefetch,
        restaurantData,
        restaurantError,
        supplierRefetch,
        cartData,
        supplierData,
        supplierError,
        supplierLoading
    } = GraphQlFetchData({
        updateOverlayTitle,
        supplierTrueId: parseInt(supplierTrueId),
        decodedRestaurantId,
        restaurantTrueId: parseInt(restaurantTrueId),
        cartId: parseInt(cartId),
    })


    const refetchData = () => {
        restaurantRefetch().then(null).catch(e => null);
        supplierRefetch().then(null).catch(e => null);
    };

    if (restaurantError || supplierError) {
        return (
            <FetchingError action={refetchData}/>);
    }


    const filterDeliveryDays = (day) => {

        if (supplierData.createdByUser) {
            return false;
        } else {
            const date = format(day, 'yyyy-MM-dd');
            const dates = supplierData.supplier.deliveryDates.map(d => format(new Date(d), 'yyyy-MM-dd'));
            const index = indexOf(dates, date);

            return index === -1;
        }
    };

    const getStartingDate = () => {
        //Return the first date from the supplier available delivery days

        if (supplierData.createdByUser) {
            return new Date();
        } else {
            return new Date(supplierData.supplier.deliveryDates[0]);
        }
    }

    //@TODO: put that in a component
    const FormikDatePicker = ({
                                  name,
                                  form: {setFieldValue},
                                  field: {value},
                                  ...rest
                              }) => {
        return (
            <KeyboardDatePicker
                name={name}
                format="dd/MM/yyyy"
                variant="outlined"
                disablePast
                inputVariant="outlined"
                label={deliveryDayTexts.label}
                shouldDisableDate={filterDeliveryDays}
                fullWidth
                placeholder={deliveryDayTexts.placeholder}
                className={classes.input}
                cancelLabel="Annuler"
                okLabel="Valider"
                value={value}
                onChange={value => {
                    setFieldValue('deliveryDate', value);
                }}
            />
        );
    };

    const checkIcon = () => {
        return (
            <div>
                <CheckOutlinedIcon/>
            </div>
        )
    };

    const shippingIcon = () => {
        return (
            <div
                className={clsx({
                    [classes.active]: classes.active,
                })}
            >
                <LocalShippingOutlinedIcon/>
            </div>
        )
    };

    const checkOutIcon = () => {
        return (
            <div>
                <PaymentOutlinedIcon/>
            </div>
        )
    };
    const stepperData = [
        {
            title: "checkout.stepperCart",
            defaultTitle: "Panier",
            icon: checkIcon
        },
        {
            title: "checkout.stepperShipping",
            defaultTitle: "Livraison",
            icon: shippingIcon
        },
        {
            title: "checkout.stepperCheckout",
            defaultTitle: "Paiement",
            icon: checkOutIcon
        }
    ]
    return (
        <>
            {restaurantData && supplierData && cartData ? (
                    <FormStep data={stepperData} styles={{mainStepper: classes.stepper}} activeStep={2}>
                        <Grid container className={classes.textContainer} justify="center">
                            <Grid item lg={2} md={3} sm={4} xs={10}>
                                <Typography variant="h5" align="center" gutterBottom className={classes.pageTitle}>
                                    <FormattedMessage id="deliveryDetailsForm.instructions1"
                                                      defaultMessage="Informations de livraison"/>
                                </Typography>
                            </Grid>
                            <Grid item lg={2} md={3} sm={4} xs={10} className={classes.deliveryAddressBlock}>
                                <Typography variant="body2" align="left" gutterBottom
                                            className={classes.deliveryAddressLabel}>
                                    <FormattedMessage id="deliveryDetailsForm.instructions2"
                                                      defaultMessage="Adresse de livraison"/>
                                </Typography>
                                <Typography variant="body2" align="left">
                                    {restaurantData.restaurant.address.number} {restaurantData.restaurant.address.street} {restaurantData.restaurant.address.zip} {restaurantData.restaurant.address.city}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Formik
                            initialValues={{
                                deliveryIntervalStart: deliveryIntervalStart ?? '08',
                                deliveryIntervalEnd: deliveryIntervalEnd ?? '10',
                                instructions: cartData.shoppingCart.note,
                                deliveryDate: cartData.shoppingCart.scheduledDate ?? getStartingDate(),
                                productReplacementAgreement: productReplacementAgreement
                            }}
                            validationSchema={props => {
                                return Yup.lazy(values => {
                                    return Yup.object().shape({
                                        instructions: Yup.string()
                                            .max(255, '').nullable(),
                                        deliveryIntervalStart: Yup.number()
                                            .required('')
                                            .lessThan(values.deliveryIntervalEnd, deliveryIntervalStartTexts.error),
                                        deliveryIntervalEnd: Yup.number()
                                            .required('')
                                            .moreThan(values.deliveryIntervalStart, deliveryIntervalEndTexts.error),
                                        deliveryDate: Yup.date()
                                            .required(deliveryDayTexts.errorRequired),
                                    })
                                })
                            }}

                            onSubmit={(values, {setSubmitting}) => {
                                updateShoppingCart({
                                    variables: {
                                        cartId: cartData.shoppingCart._id,
                                        deliveryDate: values.deliveryDate,
                                        productsSubstitutionAllowed: productReplacementAgreement
                                    }
                                }).then(() => {
                                    push(`/restaurants/${restaurantId}/suppliers/${supplierId}/checkout/${cartId}`);
                                }).catch((e) => {
                                    console.log(e);
                                })
                                /*setShippingDetails({
                                    supplierId: supplierId,
                                    restaurantId: restaurantId,
                                    cartId: cartId,
                                    note: values.instructions,
                                    deliveryIntervalStart: values.deliveryIntervalStart,
                                    deliveryIntervalEnd: values.deliveryIntervalEnd,
                                    deliveryDate: values.deliveryDate,
                                    productReplacementAgreement: productReplacementAgreement,
                                });*/

                            }}
                        >
                            {({setFieldValue, values}) => {
                                return (
                                    <Form className={classes.form}>
                                        <Grid container justify={"center"} direction={"column"} alignItems={"center"}>
                                            <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                                    <Field name="deliveryDate" id="deliveryDate"
                                                           component={FormikDatePicker}/>
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item container justify="space-between" lg={2} md={3} sm={4} xs={10}>
                                                {userType === PROFESSIONAL && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <InputLabel className={classes.deliveryIntervalLabel}>
                                                                {deliveryIntervalLabel}
                                                            </InputLabel>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Field component={BaseTextField} name="deliveryIntervalStart"
                                                                   id="deliveryIntervalStart"
                                                                   type="text" select
                                                                   label={deliveryIntervalStartTexts.label} fullWidth
                                                                   placeholder={deliveryIntervalEndTexts.placeholder}
                                                                   className={classes.input}>
                                                                <MenuItem value="04">04 : 00</MenuItem>
                                                                <MenuItem value="05">05 : 00</MenuItem>
                                                                <MenuItem value="06">06 : 00</MenuItem>
                                                                <MenuItem value="07">07 : 00</MenuItem>
                                                                <MenuItem value="08">08 : 00</MenuItem>
                                                                <MenuItem value="09">09 : 00</MenuItem>
                                                                <MenuItem value="10">10 : 00</MenuItem>
                                                                <MenuItem value="11">11 : 00</MenuItem>
                                                                <MenuItem value="12">12 : 00</MenuItem>
                                                                <MenuItem value="13">13 : 00</MenuItem>
                                                            </Field>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography align="center"
                                                                        className={classes.deliveryIntervalMiddleText}>
                                                                -
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Field component={BaseTextField} name="deliveryIntervalEnd"
                                                                   id="deliveryIntervalEnd"
                                                                   type="text" fullWidth select
                                                                   label={deliveryIntervalEndTexts.label}
                                                                   placeholder={deliveryIntervalLabel.placeholder}
                                                                   className={classes.input}>
                                                                <MenuItem value="04">04 : 00</MenuItem>
                                                                <MenuItem value="05">05 : 00</MenuItem>
                                                                <MenuItem value="06">06 : 00</MenuItem>
                                                                <MenuItem value="07">07 : 00</MenuItem>
                                                                <MenuItem value="08">08 : 00</MenuItem>
                                                                <MenuItem value="09">09 : 00</MenuItem>
                                                                <MenuItem value="10">10 : 00</MenuItem>
                                                                <MenuItem value="11">11 : 00</MenuItem>
                                                                <MenuItem value="12">12 : 00</MenuItem>
                                                                <MenuItem value="13">13 : 00</MenuItem>
                                                            </Field>
                                                        </Grid>
                                                    </>)}
                                            </Grid>
                                            <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                                <Field component={BaseTextField} name="instructions" id="instructions"
                                                       type="text"
                                                       fullWidth
                                                       multiline rows="4" label={complementaryInstructionsTexts.label}
                                                       placeholder={complementaryInstructionsTexts.placeholder}
                                                       className={classes.input}
                                                       helperText={complementaryInstructionsTexts.helperText}/>
                                            </Grid>
                                            <Grid item lg={2} md={3} sm={4} xs={10}>
                                                <FormControl component="fieldset">
                                                    <FormLabel className={classes.productReplacementLabel}><FormattedMessage
                                                        id="shippingDetailsForm.ProductReplacementLabel"
                                                        defaultMessage="En cas de rupture de stock :"/>
                                                    </FormLabel>
                                                    <RadioGroup name="productReplacementAgreement"
                                                                value={productReplacementAgreement}
                                                                onChange={handleChange}>
                                                        <FormControlLabel value={'yes'} control={<Radio/>}
                                                                          label={<span
                                                                              className={classes.LabelBlock}>
                                                                          <FormattedMessage
                                                                              id="shippingDetailsForm.ProductReplacementTrue"
                                                                              defaultMessage="J'autorise le fournisseur à remplacer les produits manquants"/></span>}/>
                                                        <FormControlLabel value={'no'} control={<Radio/>}
                                                                          label={<span
                                                                              className={classes.LabelBlock}>
                                                                          <FormattedMessage
                                                                              id="shippingDetailsForm.ProductReplacementFalse"
                                                                              defaultMessage="Je n'autorise pas le fournisseur à remplacer les produits manquants"/></span>}/>
                                                    </RadioGroup>
                                                </FormControl>
                                                <Button variant="contained" color="primary" size="medium" type="submit"
                                                        disabled={productReplacementAgreement === undefined}
                                                        className={classes.connexionButton}>
                                                    <FormattedMessage
                                                        id="shippingDetailsForm.buttonText"
                                                        defaultMessage="Valider les informations de livraison"/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </FormStep>
                ) :
                <Grid container justify="center" alignItems="center" className={classes.loaderWrapper}>
                    <CircularProgress/>
                </Grid>
            }
        </>
    );
};

export default ShippingDetailsScreen;
