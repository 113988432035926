import {connect} from 'react-redux';
import OrdersList from "../../components/Orders/OrdersList";
import {unsetActionsComponent, updateTitle, updateSubtitle, displayMenu} from "../../reducers/topNavigation";
import {bindActionCreators} from "redux";
import {createMatchSelector, push} from "connected-react-router";
import {denyAccessToUnauthenticatedUser} from "../../reducers/authentication";

const mapStateToProps = (state) => {
    const matchSelector = createMatchSelector('/restaurants/:id');
    const match = matchSelector(state);
    const data = {restaurantId: null};

    if (match) {
       data.restaurantId = match.params.id
    }

    return data;
};

const mapDispatchToProps = (dispatch) => ({
    updateTopNavTitle: (title) => dispatch(updateTitle(title)),
    updateTopNavSubtitle: (title) => dispatch(updateSubtitle(title)),
    push: bindActionCreators(push, dispatch),
    unsetActionsComponent: (payload) => dispatch(unsetActionsComponent()),
    displayMenu: (payload) => dispatch(displayMenu(payload)),
    denyAccessToUnauthenticatedUser: (payload) => dispatch(denyAccessToUnauthenticatedUser(payload)),
});

const OrdersPage = connect(mapStateToProps, mapDispatchToProps)(OrdersList);

export default OrdersPage;
