import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {FormattedMessage, useIntl} from 'react-intl';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import BaseTextField from "../../Inputs/BaseTextField";
import {RESTAURANTS} from "../../../constants/Routes";
import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from "react-loading-skeleton";
import ScriptLoader from "react-script-loader-hoc";
import {PROFESSIONAL} from "../../../constants/UserTypes";
import GraphQlFetchData from "./graphQlFetchData";
//
import Hooks from "./hooks"
import {FormValidation} from "../utils";

const useStyles = makeStyles(theme => ({
    connexionButton: {
        margin: '30px 0',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    form: {
        marginTop: 30
    },
    input: {
        marginBottom: 25,
    },
}));

//@TODO: find a way to extract mutation handling from the component
const EditRestaurantForm = ({
                                updateOverlayTitle,
                                push,
                                userType,
                                triggerNotification,
                                restaurantId,
                                scriptsLoadedSuccessfully
                            }) => {
    const classes = useStyles();
    const {
        notificationText,
        pageTitle,
        pageTitlePro
    } = Hooks()
    const {formValidate, addressTexts, nameTexts, streetTexts, countryTexts, cityTexts, zipTexts} = FormValidation()
    const decodedRestaurantId = window.atob(restaurantId);
    const restaurantSegments = decodedRestaurantId.split('/');
    const restaurantTrueId = parseInt(restaurantSegments.pop());
    const {error, loading, editRestaurant, mutationLoading, data} = GraphQlFetchData({restaurantTrueId})


    useEffect(() => {
        updateOverlayTitle(userType === PROFESSIONAL ? pageTitlePro : pageTitle);

    }, [])


    //@TODO: make a component
    if (loading || error) {
        if (error) {
            triggerNotification({variant: 'error', message: notificationText.errorLoadingData});
        }
        return (
            <>
                <Grid container justify={"center"} direction={"column"} alignItems={"center"}>
                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                        <div style={{width: '100%', height: '50px'}}><Skeleton height={40}/></div>
                        <div style={{width: '100%', height: '50px'}}><Skeleton width={100}/></div>
                    </Grid>
                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                        <div style={{width: '100%', height: '50px'}}><Skeleton height={40}/></div>
                        <div style={{width: '100%', height: '50px'}}><Skeleton width={100}/></div>
                    </Grid>
                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                        <div style={{width: '100%', height: '50px'}}><Skeleton height={40}/></div>
                        <div style={{width: '100%', height: '50px'}}><Skeleton width={100}/></div>
                    </Grid>
                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                        <div style={{width: '100%', height: '50px'}}><Skeleton height={40}/></div>
                        <div style={{width: '100%', height: '50px'}}><Skeleton width={100}/></div>
                    </Grid>
                    <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                        <div style={{width: '100%', height: '50px'}}><Skeleton height={40}/></div>
                        <div style={{width: '100%', height: '50px'}}><Skeleton width={100}/></div>
                    </Grid>
                    <Grid item container lg={2} md={3} sm={4} xs={10}>
                        <div style={{width: '100%', height: '50px'}}><Skeleton height={50}/></div>
                    </Grid>
                </Grid>
            </>
        )
    }

    if (data) {
        //@TODO: Put this in a Hook or sub component ?
        //--- Google Places autocomplete--------
        let autocomplete;
        let formikSetFieldValue;

        const geolocate = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    const geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    const circle = new window.google.maps.Circle(
                        {center: geolocation, radius: position.coords.accuracy});
                    autocomplete.setBounds(circle.getBounds());
                });
            }
        };

        const fillInAddress = () => {
            const place = autocomplete.getPlace();
            const componentForm = {
                street_number: {nameType: 'short_name', fieldName: 'number'},
                route: {nameType: 'long_name', fieldName: 'street'},
                locality: {nameType: 'long_name', fieldName: 'city'},
                country: {nameType: 'long_name', fieldName: 'country'},
                postal_code: {nameType: 'short_name', fieldName: 'zip'}
            };

            for (let i = 0; i < place.address_components.length; i++) {
                const addressType = place.address_components[i].types[0];
                if (componentForm[addressType]) {
                    const val = place.address_components[i][componentForm[addressType].nameType];
                    formikSetFieldValue(componentForm[addressType].fieldName, val);
                }
            }

            formikSetFieldValue('address', place.formatted_address);
        };

        if (scriptsLoadedSuccessfully) {
            setTimeout(() => {
                autocomplete = new window.google.maps.places.Autocomplete(
                    document.getElementById('address')
                );

                autocomplete.setFields(['address_component', 'formatted_address']);
                autocomplete.addListener('place_changed', fillInAddress);
            }, 0);
        }
        //--------------------------------------------

        return (
            <>
                <Formik
                    initialValues={{
                        name: data.restaurant.name,
                        address: `${data.restaurant.address.street} ${data.restaurant.address.zipCode} ${data.restaurant.address.city} ${data.restaurant.address.countryCode}`,
                        street: data.restaurant.address.street,
                        zip: data.restaurant.address.zipCode,
                        city: data.restaurant.address.city,
                        country: data.restaurant.address.countryCode,
                    }}
                    validationSchema={Yup.object(formValidate)}
                    onSubmit={(values, {setSubmitting}) => {
                        editRestaurant({
                            variables: {
                                id: restaurantTrueId,
                                name: values.name,
                                street: values.street,
                                zip: values.zip,
                                city: values.city,
                                country: values.country,
                                preferredDeliveryTimeFrame: data.restaurant.preferedDeliveryTimeFrame,
                                type: data.restaurant.type._id,
                            }
                        })
                            .then(() => {
                                //Redirecting to restaurants lists
                                push(RESTAURANTS);
                                triggerNotification({variant: 'success', message: notificationText.success});
                            })
                            .catch(e => triggerNotification({variant: 'error', message: notificationText.error}));
                        setSubmitting(false);
                    }}
                >
                    {/*@TODO: This should be a separate component*/}
                    {({setFieldValue}) => {
                        //We need to access setFieldValue outside of the Formik component to populate inputs with data from Google Places autocomplete.
                        //@TODO: use local state to avoid using setFieldValue outside of Formik component ??
                        formikSetFieldValue = setFieldValue;

                        return (<Form className={classes.form} onFocus={geolocate}>
                            <Grid container justify={"center"} alignItems={"center"} direction="column">
                                <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                    <Field component={BaseTextField} name="name" id="name" type="text"
                                           label={nameTexts.label}
                                           fullWidth
                                           placeholder={nameTexts.placeholder} className={classes.input}
                                           helperText={nameTexts.helperText}/>
                                </Grid>
                                <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                    <Field component={BaseTextField} name="address" id="address" type="text"
                                           size="small"
                                           label={addressTexts.label} fullWidth
                                           placeholder={addressTexts.placeholder} className={classes.input}
                                           helperText={addressTexts.helperText}/>
                                </Grid>
                                <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                    <Field component={BaseTextField} name="street" id="street" type="text" size="small"
                                           label={streetTexts.label} fullWidth
                                           placeholder={streetTexts.placeholder}
                                           className={classes.input}
                                    />
                                </Grid>
                                <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                    <Field component={BaseTextField} name="zip" id="zip" type="text" size="small"
                                           label={zipTexts.label} fullWidth
                                           placeholder={zipTexts.placeholder} className={classes.input}
                                    />
                                </Grid>
                                <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                    <Field component={BaseTextField} name="city" id="city" type="text" size="small"
                                           label={cityTexts.label} fullWidth
                                           placeholder={cityTexts.placeholder} className={classes.input}
                                    />
                                </Grid>
                                <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                    <Field component={BaseTextField} name="country" id="country" type="text"
                                           size="small"
                                           label={countryTexts.label} fullWidth
                                           placeholder={countryTexts.placeholder} className={classes.input}
                                    />
                                </Grid>
                                <Grid item lg={2} md={3} sm={4} xs={10}>
                                    <Button variant="contained" color="primary" size="large" type="submit"
                                            className={classes.connexionButton} disabled={mutationLoading}>
                                        <FormattedMessage
                                            id="editRestaurantForm.buttonText"
                                            defaultMessage="Enregistrer les modifications"/>
                                        {mutationLoading &&
                                        <CircularProgress size={24} className={classes.buttonProgress}/>
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>)
                    }
                    }
                </Formik>
            </>
        );
    }
};
//Script loader avoids to load Google scripts on every pages
export default ScriptLoader('https://maps.googleapis.com/maps/api/js?key=AIzaSyAPN_7_svR5FjaOyFD6StAZjPgYMx9kYl8&libraries=places')(EditRestaurantForm);
