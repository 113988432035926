import * as Yup from "yup";
import {useIntl} from "react-intl";

export function FormValidation() {

    const nameTexts = {
        label: useIntl().formatMessage({
            id: 'addRestaurantForm.nameLabel',
            defaultMessage: 'Nom',
        }),
        placeholder: useIntl().formatMessage({
            id: 'addRestaurantForm.namePlaceholder',
            defaultMessage: 'Entrez le nom du restaurant'
        }),
        helperText: useIntl().formatMessage({
            id: 'addRestaurantForm.nameHelper',
            defaultMessage: 'Le nom servira a identifier ce restaurant.'
        }),
        errorToLong: useIntl().formatMessage({
            id: 'addRestaurantForm.nameToLong',
            defaultMessage: 'Le nom ne doit pas dépasser 255 charactères'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'addRestaurantForm.nameRequired',
            defaultMessage: 'Le nom du restaurant est requis.'
        })
    };

    const addressTexts = {
        label: useIntl().formatMessage({
            id: 'addRestaurantForm.fullAddressLabel',
            defaultMessage: 'Adresse',
        }),
        placeholder: useIntl().formatMessage({
            id: 'addRestaurantForm.fullAddressPlaceholder',
            defaultMessage: 'Entrez l\'adresse du restaurant'
        }),
        helperText: useIntl().formatMessage({
            id: 'addRestaurantForm.fullAddressHelper',
            defaultMessage: 'L\'adresse du restaurant sera utilisé comme adresse de livraison pour vos commande.'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'addRestaurantForm.addressRequired',
            defaultMessage: 'L\'adresse du restaurant est requise.'
        }),
        errorToLong: useIntl().formatMessage({
            id: 'addRestaurantForm.fullAddressToLong',
            defaultMessage: 'L\'adresse ne doit pas dépasser 255 charactères'
        })
    };

    const numberTexts = {
        label: useIntl().formatMessage({
            id: 'addRestaurantForm.numberLabel',
            defaultMessage: 'Numéro',
        }),
        placeholder: useIntl().formatMessage({
            id: 'addRestaurantForm.numberPlaceholder',
            defaultMessage: 'Entrez le numéro de rue du restaurant'
        }),
        helperText: useIntl().formatMessage({
            id: 'addRestaurantForm.numberHelper',
            defaultMessage: 'a'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'addRestaurantForm.numberRequired',
            defaultMessage: 'Le numéro de rue du restaurant est requis.'
        }),
        errorToLong: useIntl().formatMessage({
            id: 'addRestaurantForm.numberToLong',
            defaultMessage: 'Le cnuméro de rue ne doit pas dépasser 255 charactères'
        })
    };

    const streetTexts = {
        label: useIntl().formatMessage({
            id: 'addRestaurantForm.streetLabel',
            defaultMessage: 'Voie',
        }),
        placeholder: useIntl().formatMessage({
            id: 'addRestaurantForm.streetPlaceholder',
            defaultMessage: 'Entrez la voie du restaurant'
        }),
        helperText: useIntl().formatMessage({
            id: 'addRestaurantForm.streetHelper',
            defaultMessage: 'a'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'addRestaurantForm.streetRequired',
            defaultMessage: 'La voie du restaurant est requise.'
        }),
        errorToLong: useIntl().formatMessage({
            id: 'addRestaurantForm.streetToLong',
            defaultMessage: 'La voie ne doit pas dépasser 255 charactères'
        })
    };

    const zipTexts = {
        label: useIntl().formatMessage({
            id: 'addRestaurantForm.zipLabel',
            defaultMessage: 'Code Postal',
        }),
        placeholder: useIntl().formatMessage({
            id: 'addRestaurantForm.zipPlaceholder',
            defaultMessage: 'Entrez le code postal du restaurant'
        }),
        helperText: useIntl().formatMessage({
            id: 'addRestaurantForm.zipHelper',
            defaultMessage: 'a'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'addRestaurantForm.zipRequired',
            defaultMessage: 'Le code postal du restaurant est requis.'
        }),
        errorToLong: useIntl().formatMessage({
            id: 'addRestaurantForm.zipToLong',
            defaultMessage: 'Le code postal ne doit pas dépasser 255 charactères'
        })
    };

    const cityTexts = {
        label: useIntl().formatMessage({
            id: 'addRestaurantForm.cityLabel',
            defaultMessage: 'Ville',
        }),
        placeholder: useIntl().formatMessage({
            id: 'addRestaurantForm.cityPlaceholder',
            defaultMessage: 'Entrez la ville du restaurant'
        }),
        helperText: useIntl().formatMessage({
            id: 'addRestaurantForm.cityHelper',
            defaultMessage: 'a'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'addRestaurantForm.cityRequired',
            defaultMessage: 'La ville du restaurant est requise.'
        }),
        errorToLong: useIntl().formatMessage({
            id: 'addRestaurantForm.cityToLong',
            defaultMessage: 'La ville ne doit pas dépasser 255 charactères'
        })
    };

    const countryTexts = {
        label: useIntl().formatMessage({
            id: 'addRestaurantForm.countryLabel',
            defaultMessage: 'Pays',
        }),
        placeholder: useIntl().formatMessage({
            id: 'addRestaurantForm.countryPlaceholder',
            defaultMessage: 'Entrez le pays du restaurant'
        }),
        helperText: useIntl().formatMessage({
            id: 'addRestaurantForm.countryHelper',
            defaultMessage: 'a'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'addRestaurantForm.countryRequired',
            defaultMessage: 'Le pays du restaurant est requise.'
        }),
        errorToLong: useIntl().formatMessage({
            id: 'addRestaurantForm.countryToLong',
            defaultMessage: 'Le pays ne doit pas dépasser 255 charactères'
        })
    };

    const cuisineTypeTexts = {
        label: useIntl().formatMessage({
            id: 'addRestaurantForm.cuisineTypeLabel',
            defaultMessage: 'Type de cuisine',
        }),
        placeholder: useIntl().formatMessage({
            id: 'addRestaurantForm.cuisineTypePlaceholder',
            defaultMessage: 'Entrez le type de cuisine du restaurant'
        }),
        helperText: useIntl().formatMessage({
            id: 'addRestaurantForm.cuisineTypeHelper',
            defaultMessage: 'a'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'addRestaurantForm.cuisineTypeRequired',
            defaultMessage: 'Le type de cuisine du restaurant est requis.'
        }),
    };

    const preferredDeliveryTimeTexts = {
        label: useIntl().formatMessage({
            id: 'addRestaurantForm.preferredDeliveryTimeLabel',
            defaultMessage: 'Heure de livraison préférée',
        }),
        placeholder: useIntl().formatMessage({
            id: 'addRestaurantForm.preferredDeliveryTimePlaceholder',
            defaultMessage: 'Entrez l\'heure de livraison préférée du restaurant'
        }),
        helperText: useIntl().formatMessage({
            id: 'addRestaurantForm.preferredDeliveryTimeHelper',
            defaultMessage: 'a'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'addRestaurantForm.preferredDeliveryTimeRequired',
            defaultMessage: 'L\'heure de livraison préférée du restaurant est requise.'
        }),

    };

    const formValidate = {
        name: Yup.string()
            .max(255, nameTexts.errorToLong)
            .required(nameTexts.errorRequired),
        street: Yup.string()
            .max(255, addressTexts.errorToLong)
            .required(addressTexts.errorRequired),
        preferredDeliveryTime: Yup.string()
            .max(255, preferredDeliveryTimeTexts.errorToLong)
            .required(preferredDeliveryTimeTexts.errorRequired),
        zip: Yup.string()
            .max(255, zipTexts.errorToLong)
            .required(zipTexts.errorRequired),
        city: Yup.string()
            .max(255, cityTexts.errorToLong)
            .required(cityTexts.errorRequired),
        country: Yup.string()
            .max(2, countryTexts.errorToLong)
            .required(countryTexts.errorRequired),
        cuisineType: Yup.number()
            .required(cuisineTypeTexts.errorRequired),
    }


    return ({
        formValidate,
        nameTexts,
        addressTexts,
        numberTexts,
        streetTexts,
        zipTexts,
        cityTexts,
        countryTexts,
        cuisineTypeTexts,
        preferredDeliveryTimeTexts
    });
}
