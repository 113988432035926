import * as Yup from "yup";
import {useIntl} from "react-intl";

function Hooks() {
    const categoryTexts = {
        label: useIntl().formatMessage({
            id: 'newProductForm.categoryLabel',
            defaultMessage: 'Catégorie',
        }),
        placeholder: useIntl().formatMessage({
            id: 'newProductForm.categoryPlaceholder',
            defaultMessage: 'Choissisez la catégorie du produit'
        }),
        helperText: useIntl().formatMessage({
            id: 'newProductForm.categoryHelper',
            defaultMessage: 'La catégorie permettra de trier plus efficacement les produits.'
        }),
        errorTooLong: useIntl().formatMessage({
            id: 'newProductForm.categoryErrorTooLong',
            defaultMessage: 'La catégorie ne doit pas dépasser 255 charactères'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'newProductForm.categoryErrorRequired',
            defaultMessage: 'La catégorie est requise'
        }),
    };
    const nameTexts = {
        label: useIntl().formatMessage({
            id: 'newProductForm.nameLabel',
            defaultMessage: 'Nom',
        }),
        placeholder: useIntl().formatMessage({
            id: 'newProductForm.namePlaceholder',
            defaultMessage: 'Entrez le nom du produit'
        }),
        helperText: useIntl().formatMessage({
            id: 'newProductForm.nameHelper',
            defaultMessage: 'Le nom du produit servira à l\'identifier.'
        }),
        errorTooLong: useIntl().formatMessage({
            id: 'newProductForm.nameErrorTooLong',
            defaultMessage: 'Le nom ne doit pas dépasser 255 charactères'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'newProductForm.nameErrorRequired',
            defaultMessage: 'Le nom est requis'
        }),
    };

    const unitTexts = {
        label: useIntl().formatMessage({
            id: 'newProductForm.unitLabel',
            defaultMessage: 'Unité de commande',
        }),
        placeholder: useIntl().formatMessage({
            id: 'newProductForm.unitPlaceholder',
            defaultMessage: 'Entrez l\'unité de commande'
        }),
        helperText: useIntl().formatMessage({
            id: 'newProductForm.unitHelper',
            defaultMessage: 'Il s\'agit de l\'unité de base qui sera multiplié par la quantité définie lors de la commande.'
        }),
        errorTooLong: useIntl().formatMessage({
            id: 'newProductForm.unitErrorTooLong',
            defaultMessage: 'L\'unité de commande ne doit pas dépasser 255 charactères'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'newProductForm.unitErrorRequired',
            defaultMessage: 'L\'unité de commande est requise'
        }),
    };

    const refCodeTexts = {
        label: useIntl().formatMessage({
            id: 'newProductForm.refCodeLabel',
            defaultMessage: 'Code produit du fournisseur',
        }),
        placeholder: useIntl().formatMessage({
            id: 'newProductForm.refCodePlaceholder',
            defaultMessage: 'Entrez la référence fournisseur du produit'
        }),
        helperText: useIntl().formatMessage({
            id: 'newProductForm.refCodeHelper',
            defaultMessage: 'Si vous disposez de le référence du produit chez votre fournisseur ajoutez le afin de faciliter la prise de commande.'
        }),
        errorTooLong: useIntl().formatMessage({
            id: 'newProductForm.refCodeTooLong',
            defaultMessage: 'le code produit fournisseur ne doit pas dépasser 255 charactères'
        })
    };

    const formValidation = {
        category: Yup.string()
            .max(255, categoryTexts.errorToLong)
            .required(categoryTexts.errorRequired),
        name: Yup.string()
            .max(255, nameTexts.errorToLong)
            .required(nameTexts.errorRequired),
        unit: Yup.string()
            .max(255, unitTexts.errorToLong)
            .required(unitTexts.errorRequired),
        refCode: Yup.string()
            .max(255, refCodeTexts.errorToLong)
    }
    return ({
        formValidation, categoryTexts, refCodeTexts, nameTexts, unitTexts
    })
}

export default Hooks