import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {createMatchSelector, push} from "connected-react-router";
import {triggerToastNotification} from "../../reducers/toastNotification";
import EditRestaurantForm from "../../components/Restaurants/EditRestaurantForm";
import {updateOverlayTitle} from "../../reducers/overlay";

const mapStateToProps = (state) => {
    const matchSelector = createMatchSelector('/restaurants/:id/edit');
    const match = matchSelector(state);
    return {
        restaurantId: match.params.id,
        userType: state.authentication.userType,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateOverlayTitle: (title) => dispatch(updateOverlayTitle(title)),
    push: bindActionCreators(push, dispatch),
    triggerNotification: (payload) => dispatch(triggerToastNotification(payload)),
});

const EditRestaurant = connect(mapStateToProps, mapDispatchToProps)(EditRestaurantForm);

export default EditRestaurant;
