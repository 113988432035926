import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import GeneralPurposeCard from "../../GeneralPurpose/GeneralPurposeCard";
import AddSupplierConfirmDialog from "../AddSupplierConfirmDialog";
import Skeleton from "react-loading-skeleton";
import SupplierPartnerTag from "../SupplierPartnerTag";

const useStyles = makeStyles(theme => ({
    icon: {
        fontSize: '2.5rem',
        padding: 10,
        backgroundColor: theme.palette.grey.A700,
        borderRadius: 50,
        color: 'white',
        marginRight: 15,
    },
    name: {
        fontWeight: 500,
        textTransform: 'uppercase',
    },
    cardContent: {
        flexGrow: 1,
        padding: 5
    },
    iconBlock: {
        display: 'flex',
        paddingLeft: 6,
        marginRight: 5
    },
    centerBlock: {
        flexGrow: 1,
    },
    addIcon: {
        color: theme.palette.primary.main,
        fontSize: '2rem',
    },
    cardAction: {
        padding: 0,
    }
}));

//@TODO: put dialog in parent component (AddSupplierList) and put state in Redux
const AddSupplierCard = ({supplier, triggerNotification, restaurantId}) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <GeneralPurposeCard>
                <Grid container wrap="nowrap" alignItems="center" justify="center">
                    <CardContent className={classes.cardContent}>
                        <Grid container wrap="nowrap" alignItems="center">
                            <Grid item className={classes.iconBlock}>
                                {supplier ? <LocalShippingOutlinedIcon className={classes.icon}/> :
                                    <Skeleton circle={true} height={40} width={40}/>}
                            </Grid>
                            <Grid item className={classes.centerBlock}>
                                <Typography className={classes.name} align="center">{supplier ? supplier.name :
                                    <Skeleton/>}
                                </Typography>
                                {supplier ? (<>{supplier.partner ? (<SupplierPartnerTag/>) : null}</>): null}
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions className={classes.cardAction}>
                        <IconButton onClick={handleClickOpen}>
                            {supplier ? <AddCircleOutlinedIcon className={classes.addIcon}/> : <Skeleton/>}
                        </IconButton>
                    </CardActions>
                </Grid>
            </GeneralPurposeCard>
            {supplier && (<AddSupplierConfirmDialog openState={openDialog} handleClose={handleClose} supplierName={supplier.name}
                                      supplierId={supplier._id} triggerNotification={triggerNotification} restaurantId={restaurantId}/>)}
        </>
    );
};

export default AddSupplierCard;
