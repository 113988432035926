import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {unsetActionsComponent, updateTitle} from "../../reducers/topNavigation";
import {push} from "connected-react-router";
import SupportInformation from "../../components/Support/SupportInformation";

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
    updateTopNavTitle: (title) => dispatch(updateTitle(title)),
    push: bindActionCreators(push, dispatch),
    unsetActionsComponent: (payload) => dispatch(unsetActionsComponent()),
});

const SupportPage = connect(mapStateToProps, mapDispatchToProps)(SupportInformation);

export default SupportPage;