import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {closeToastNotification} from "../../reducers/toastNotification";
import Toast from "../../components/Notifications/Toast";

const mapStateToProps = state => ({
    variant: state.toastNotification.variant,
    message: state.toastNotification.message,
    open: state.toastNotification.open,
    anchorOrigin: state.toastNotification.anchorOrigin
});

const mapDispatchToProps = (dispatch) => ({
    close: bindActionCreators(closeToastNotification, dispatch)
});

const ToastNotification = connect(mapStateToProps, mapDispatchToProps)(Toast);

export default ToastNotification;
