import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {createMatchSelector, push} from "connected-react-router";
import {displayGoBack, updateSubtitle, updateTitle} from "../../reducers/topNavigation";
import {denyAccessToUnauthenticatedUser} from "../../reducers/authentication";
import FailedOrder from "../../components/Orders/FailedOrder";

const mapStateToProps = (state) => {
    const matchSelector = createMatchSelector('/failedOrder/:authResult/:transStatus');
    const match = matchSelector(state);
    return {
        authResult: match.params.authResult,
        transactionStatus: match.params.transStatus
    };
};

const mapDispatchToProps = (dispatch) => ({
    push: bindActionCreators(push, dispatch),
    displayGoBack: (payload) => dispatch(displayGoBack(payload)),
    denyAccessToUnauthenticatedUser: (payload) => dispatch(denyAccessToUnauthenticatedUser(payload)),
    updateTopNavTitle: (title) => dispatch(updateTitle(title)),
    updateTopNavSubtitle: (subtitle) => dispatch(updateSubtitle((subtitle))),
});

const FailedOrderPage = connect(mapStateToProps, mapDispatchToProps)(FailedOrder);

export default FailedOrderPage;
