import React, {Component} from 'react';
import './App.css';
import {Route, Switch} from "react-router";
import * as Routes from './constants/Routes';
import {connect} from 'react-redux';
//import SUPPLIER.MANAGEMENT.PAGE from "./constants/Routes";
import TopNavigation from "./containers/TopNavigation";
import MainDrawer from "./containers/MainDrawer";
import RestaurantsPage from "./containers/RestaurantsPage";
import SuppliersPage from "./containers/SuppliersPage";
import FooterNavigation from "./containers/FooterNavigation";
import OrdersPage from "./containers/OrdersPage";
import DashboardPage from "./containers/DashboardPage";
import LoginPage from "./containers/LoginPage";
import RegisterPage from "./containers/RegisterPage";
import OverlayPage from "./containers/OverlayPage";
import WelcomePage from "./containers/WelcomePage";
import AddRestaurant from "./containers/AddRestaurant";
import AddSupplier from "./containers/AddSupplier";
import CreateNewSupplier from "./containers/CreateNewSupplier";
import SupplierShop from "./containers/SupplierShop";
import AddProduct from "./containers/AddProduct";
import OrderDetails from "./containers/OrderDetails";
import SupplierManagementPage from "./containers/SupplierManagementPage"
import ShippingDetailsPage from "./containers/ShippingDetailsPage";
import CartSummaryPage from "./containers/CartSummaryPage";
import SupportPage from "./containers/SupportPage";
import ContactPage from "./containers/ContactPage";
import Chat from "./containers/Chat";
import UserAccountPage from "./containers/UserAccountPage";
import EditUserInfoPage from "./containers/EditUserInfoPage";
import ChangePasswordPage from "./containers/ChangePasswordPage";
import ToastNotification from "./containers/ToastNotification";
import EditRestaurant from "./containers/EditRestaurant";
import EditProduct from "./containers/EditProduct";
import UpdatePage from "./containers/UpdatePage";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {launchUpdate, updated} from "./reducers/updater";
import UpdateModalDialog from "./components/Notifications/UpdateModalDialog";
import UpdateScreen from "./components/UpdateScreen";
import EditSupplier from "./containers/EditSupplier";
import {INDIVIDUAL} from "./constants/UserTypes";
import SuccessfulOrderPage from "./containers/SuccessfulOrderPage";
import FailedOrderPage from "./containers/FailedOrderPage";
import PaymentCallbackPage from "./containers/PaymentCallbackPage";
import FetchingError from "./components/Errors/FetchingError";
import CheckoutPage from "./containers/CheckoutPage";
import ForgottenPasswordPage from "./containers/ForgottenPasswordPage";
import SetNewPasswordPage from "./containers/SetNewPasswordPage";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import translations from "./i18n/locales";
import {IntlProvider} from "react-intl";
//imort catalog container
import Catalog from "./containers/Catalog";
import NotFoundPage from './components/notFoundPage'
/*
* About the App component routing system :
* Routing is designed to support loading pages in a modal dialog, displayed on top of the precedent page
* To display a page in a modal, a route should be added to the dedicated switch, and the containers/components placed inside the OverlayPage container.
* E.g :
* <Route path={'/test'}>
*   <OverlayPage>
*   <MyComponent/>
*   </OverlayPage>
* </Route>
*
* When linking or pushing to the page, add the following state : {modal: true} to instruct the App component to use the appropriate Switch and location
* */

//@TODO: too much routes in switch, split for better readability
class App extends Component {
    constructor(props) {
        super(props);
        this.previousLocation = this.props.location;
        this.state = {
            hasError: false,
        }
        //this.browserCompatibility = checkBrowser();
    }

    componentDidUpdate() {
        //We check if the route should be rendered in the modal
        const {location} = this.props;
        if (!(location.state && location.state.modal)) {
            this.previousLocation = this.props.location;
        }
    }

    includeNavigation(props) {
        return (
            <>
                <MainDrawer/>
                <TopNavigation icon={props?.icon || null}/>
                <FooterNavigation/>
            </>);
    }

    componentDidCatch(error, info) {
        this.setState({hasError: true});
    }

    render() {
        const {location, updater, launchUpdate, updated, userType, language} = this.props;
        const {hasError} = this.state;

        const isModal = (
            location.state &&
            location.state.modal &&
            this.previousLocation !== location
        );

        if (userType === INDIVIDUAL) {
            setTimeout(() => window.location.replace("https://foodomarket.club"), 5000)
        }

        return (
            <IntlProvider defaultLocale="fr" locale={language} messages={translations[language]}>

                {hasError ? <FetchingError action={() => window.location.replace('/')}/> :
                    (
                        <>
                            {userType === INDIVIDUAL && (
                                <Dialog aria-labelledby="simple-dialog-title" open={true}>
                                    <DialogTitle id="simple-dialog-title" disableTypography style={{
                                        textAlign: 'center',
                                        fontSize: 20,
                                        fontWeight: 600,
                                        backgroundColor: '#33d573',
                                        color: '#005700'
                                    }}>Bienvenue au .club !</DialogTitle>
                                    <DialogContent>
                                        <Grid container justify="center">
                                            <Typography align="center" style={{
                                                fontSize: '0.9rem',
                                                marginBottom: 15,
                                                fontWeight: 500,
                                                marginTop: 20
                                            }}>
                                                Bonne nouvelle ! Nous avons le plaisir de vous annoncer que notre
                                                nouvelle boutique,
                                                entièrement dédiée aux particuliers, est en ligne !
                                            </Typography>
                                            <Typography align="center" style={{fontSize: '0.9rem', marginBottom: 25}}>
                                                Vous y serez redirigé dans quelques instants
                                            </Typography>
                                            <CircularProgress size={30} style={{marginBottom: 25}}/>
                                        </Grid>
                                    </DialogContent>
                                </Dialog>
                            )}
                            {/*<IOsInstallationScreen/>*/}
                            {/*{this.browserCompatibility.browserOutdated || this.browserCompatibility.browserIncompatible ? (
                    <BrowserCompatibilityIssuesNotification/>
                ) : null}*/}
                            {/*Check update is pending, if yes, display a modal notification. If the update is in process, displaying a loading screen*/}
                            {updater.appIsReadyToUpdate ? (
                                <UpdateModalDialog launchUpdate={launchUpdate}/>
                            ) : null}
                            {updater.appIsUpdating ? (
                                <UpdateScreen updated={updated}/>
                            ) : (
                                <>
                                    <ToastNotification/>
                                    {isModal ? (
                                        <Switch>
                                            {/*This switch is only used when the page should be rendered in a modal*/}
                                            <Route path={Routes.EDIT_SUPPLIER}>
                                                <OverlayPage>
                                                    <EditSupplier/>
                                                </OverlayPage>
                                            </Route>
                                            <Route path={Routes.ADD_RESTAURANT}>
                                                <OverlayPage>
                                                    <AddRestaurant/>
                                                </OverlayPage>
                                            </Route>
                                            <Route path={Routes.EDIT_RESTAURANT}>
                                                <OverlayPage>
                                                    <EditRestaurant/>
                                                </OverlayPage>
                                            </Route>
                                            <Route path={Routes.CHAT}>
                                                <OverlayPage>
                                                    <Chat/>
                                                </OverlayPage>
                                            </Route>
                                            <Route path={Routes.NEW_SUPPLIER}>
                                                <OverlayPage>
                                                    <CreateNewSupplier/>
                                                </OverlayPage>
                                            </Route>
                                            <Route path={Routes.ADD_SUPPLIER}>
                                                <OverlayPage>
                                                    <AddSupplier/>
                                                </OverlayPage>
                                            </Route>
                                            <Route path={Routes.ADD_PRODUCT}>
                                                <OverlayPage>
                                                    <AddProduct/>
                                                </OverlayPage>
                                            </Route>
                                            <Route path={Routes.EDIT_PRODUCT}>
                                                <OverlayPage>
                                                    <EditProduct/>
                                                </OverlayPage>
                                            </Route>
                                            <Route path={Routes.ORDER_DETAILS}>
                                                <OverlayPage>
                                                    <OrderDetails/>
                                                </OverlayPage>
                                            </Route>
                                            <Route path={Routes.CART_SUMMARY}>
                                                <OverlayPage>
                                                    <CartSummaryPage/>
                                                </OverlayPage>
                                            </Route>
                                            <Route path={Routes.SHIPPING_DETAILS}>
                                                <OverlayPage>
                                                    <ShippingDetailsPage/>
                                                </OverlayPage>
                                            </Route>
                                            <Route path={Routes.CONTACT_US}>
                                                <OverlayPage>
                                                    <ContactPage/>
                                                </OverlayPage>
                                            </Route>
                                            <Route path={Routes.EDIT_USER_INFO}>
                                                <OverlayPage>
                                                    <EditUserInfoPage/>
                                                </OverlayPage>
                                            </Route>
                                            <Route path={Routes.CHANGE_PASSWORD}>
                                                <OverlayPage>
                                                    <ChangePasswordPage/>
                                                </OverlayPage>
                                            </Route>
                                        </Switch>
                                    ) : null}

                                    <Switch location={isModal ? this.previousLocation : location}>
                                        {/*catalog route*/}
                                        <Route path={Routes.CATALOG} exact={true}>
                                            <TopNavigation isCatalog/>
                                            <Catalog/>
                                            <FooterNavigation/>
                                        </Route>

                                        {/********************************/}
                                        <Route path={Routes.PAYMENT_CALLBACK} exact={true}>
                                            <PaymentCallbackPage/>
                                        </Route>
                                        <Route path={Routes.SUCCESSFUL_ORDER} exact={true}>
                                            {this.includeNavigation()}
                                            <SuccessfulOrderPage/>
                                        </Route>
                                        <Route path={Routes.FAILED_ORDER} exact={true}>
                                            {this.includeNavigation()}
                                            <FailedOrderPage/>
                                        </Route>
                                        <Route path={Routes.UPDATE} exact={true}>
                                            <UpdatePage/>
                                        </Route>
                                        <Route path={Routes.EDIT_RESTAURANT} exact={true}>
                                            {this.includeNavigation()}
                                            <EditRestaurant/>
                                        </Route>
                                        <Route path={Routes.ADD_RESTAURANT} exact={true}>
                                            {this.includeNavigation()}
                                            <AddRestaurant/>
                                        </Route>
                                        <Route exact path={Routes.DASHBOARD} exact={true}>
                                            {this.includeNavigation()}
                                            <DashboardPage/>
                                        </Route>
                                        <Route exact path={Routes.RESTAURANTS} exact={true}>
                                            {this.includeNavigation()}
                                            <RestaurantsPage/>
                                        </Route>
                                        <Route path={Routes.ADD_PRODUCT} exact={true}>
                                            {this.includeNavigation()}
                                            <AddProduct/>
                                        </Route>
                                        <Route path={Routes.EDIT_PRODUCT} exact={true}>
                                            {this.includeNavigation()}
                                            <EditProduct/>
                                        </Route>
                                        <Route path={Routes.CART_SUMMARY} exact={true}>
                                            {this.includeNavigation()}
                                            <CartSummaryPage/>
                                        </Route>
                                        <Route path={Routes.SHIPPING_DETAILS} exact={true}>
                                            {this.includeNavigation()}
                                            <ShippingDetailsPage/>
                                        </Route>
                                        <Route path={Routes.CHECKOUT} exact={true}>
                                            {this.includeNavigation()}
                                            <CheckoutPage origin={this.props.apiOrigin}/>
                                        </Route>
                                        <Route path={Routes.CHAT} exact={true}>
                                            {this.includeNavigation()}
                                            <Chat/>
                                        </Route>
                                        <Route path={Routes.ADD_SUPPLIER} exact={true}>
                                            {this.includeNavigation()}
                                            <AddSupplier/>
                                        </Route>
                                        <Route path={Routes.SUPPLIER_SHOP} exact={true}>
                                            {this.includeNavigation()}
                                            <SupplierShop/>
                                        </Route>
                                        <Route path={Routes.NEW_SUPPLIER} exact={true}>
                                            {this.includeNavigation()}
                                            <CreateNewSupplier/>
                                        </Route>
                                        <Route path={Routes.SUPPLIERS} exact={true}>
                                            {this.includeNavigation()}
                                            <SuppliersPage/>
                                        </Route>
                                        <Route path={Routes.ORDER_DETAILS} exact={true}>
                                            {this.includeNavigation()}
                                            <OrderDetails/>
                                        </Route>
                                        <Route path={Routes.ORDERS} exact={true}>
                                            {this.includeNavigation()}
                                            <OrdersPage/>
                                        </Route>
                                        <Route path={Routes.RESTAURANTS_ORDER} exact={true}>
                                            {this.includeNavigation()}
                                            <OrdersPage/>
                                        </Route>
                                        <Route path={Routes.LOGIN} exact={true}>
                                            <LoginPage/>
                                        </Route>
                                        <Route path={Routes.REGISTER} exact={true}>
                                            <RegisterPage/>
                                        </Route>
                                        <Route path={Routes.WELCOME} exact={true}>
                                            <WelcomePage/>
                                        </Route>
                                        <Route path={Routes.CONTACT_US} exact={true}>
                                            {this.includeNavigation()}
                                            <ContactPage/>
                                        </Route>
                                        <Route path={Routes.SUPPORT} exact={true}>
                                            {this.includeNavigation()}
                                            <SupportPage/>
                                        </Route>
                                        <Route path={Routes.EDIT_USER_INFO} exact={true}>
                                            {this.includeNavigation()}
                                            <EditUserInfoPage/>
                                        </Route>
                                        <Route path={Routes.ACCOUNT} exact={true}>
                                            {this.includeNavigation()}
                                            <UserAccountPage/>
                                        </Route>
                                        <Route path={Routes.CHANGE_PASSWORD} exact={true}>
                                            {this.includeNavigation()}
                                            <ChangePasswordPage/>
                                        </Route>
                                        <Route path={Routes.EDIT_SUPPLIER} exact={true}>
                                            {this.includeNavigation()}
                                            <EditSupplier/>
                                        </Route>
                                        <Route path={Routes.FORGOTTEN_PASSWORD} exact={true}>
                                            <ForgottenPasswordPage/>
                                        </Route>
                                        <Route path={Routes.MANAGEMENT} exact={true}>
                                            <SupplierManagementPage/>
                                        </Route>
                                        <Route path={Routes.SET_NEW_PASSWORD} exact={true}>
                                            <SetNewPasswordPage/>
                                        </Route>

                                        <Route>
                                            <NotFoundPage/>
                                        </Route>

                                    </Switch>
                                </>
                            )}
                        </>
                    )
                }
            </IntlProvider>
        );
    }
}

const mapStateToProps = state => ({
    location: state.router.location,
    updater: state.updater,
    userType: state.authentication.userType,
    language: state.authentication.language
});

const mapDispatchToProps = (dispatch) => ({
    push: bindActionCreators(push, dispatch),
    launchUpdate: bindActionCreators(launchUpdate, dispatch),
    updated: bindActionCreators(updated, dispatch),
});

App = connect(mapStateToProps, mapDispatchToProps)(App);

export default App;
