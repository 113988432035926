const categories = {
    FR:
        [
            {
                name: 'Boucherie',
                id: 34,
                image: '/categories/meat.jpeg'
            },
            {
                name: 'Charcuterie',
                id: 507,
                image: '/categories/cured_meat.png'
            },
            {
                name:'Fruits & Légumes',
                id: 69,
                image: '/categories/fruits-veggies.jpg'
            },
            {
                name:'Épicerie Salée',
                id: 9,
                image: '/categories/grocery.jpg'
            },
            {
                name:'Épicerie Sucrée',
                id: 10,
                image: '/categories/sweets.jpg'
            },
            {
                name:'Crémerie',
                id: 2,
                image: '/categories/dairy.jpeg'
            },
            {
                name: 'Marée',
                id: 11,
                image: '/categories/seafood.jpeg'
            },
            {
                name: 'Consommables',
                id: 537,
                image: '/categories/packaging.jpg'
            },
            {
                name: 'Traiteur',
                id: 275,
                image: '/categories/catering.jpg'
            },
            {
                name: 'Divers',
                id: 296,
                image: '/categories/fruits-veggies.jpg'
            },
            {
                name: 'Boissons',
                id: 503,
                image: '/categories/drinks.jpg'
            },
            {
                name: 'Indisponibles',
                id: 0,
                image: '/categories/fruits-veggies.jpg'
            },
            {
                name: 'Surgelés',
                id: 1744,
                image: '/categories/fruits-veggies.jpg'
            }
        ],
    BE:
        [
            {
                name: 'Boucherie',
                id: 34,
                image: '/categories/meat.jpeg'
            },
            {
                name: 'Charcuterie',
                id: 507,
                image: '/categories/cured_meat.png'
            },
            {
                name:'Fruits & Légumes',
                id: 69,
                image: '/categories/fruits-veggies.jpg'
            },
            {
                name:'Épicerie Salée',
                id: 9,
                image: '/categories/grocery.jpg'
            },
            {
                name:'Épicerie Sucrée',
                id: 10,
                image: '/categories/sweets.jpg'
            },
            {
                name:'Crémerie',
                id: 2,
                image: '/categories/dairy.jpeg'
            },
            {
                name: 'Marée',
                id: 11,
                image: '/categories/seafood.jpeg'
            },
            {
                name: 'Consommables',
                id: 537,
                image: '/categories/packaging.jpg'
            },
            {
                name: 'Traiteur',
                id: 275,
                image: '/categories/catering.jpg'
            },
            {
                name: 'Divers',
                id: 296,
                image: '/categories/fruits-veggies.jpg'
            },
            {
                name: 'Boissons',
                id: 503,
                image: '/categories/drinks.jpg'
            },
            {
                name: 'Indisponibles',
                id: 0,
                image: '/categories/fruits-veggies.jpg'
            },
            {
                name: 'Surgelés',
                id: 1744,
                image: '/categories/fruits-veggies.jpg'
            }
        ],
    GB: [
        {
            name: 'Meat & Poultry',
            id: 914,
            image: '/categories/meat.jpeg'
        },
        {
            name: 'Caterer & Preparations',
            id: 1126,
            image: '/categories/catering.jpg'
        },
        {
            name: 'Dairy',
            id: 916,
            image: '/categories/dairy.jpeg'

        },
        {
            name: 'Delicatessen',
            id: 918,
            image: '/categories/cured_meat.png'
        },
        {
            name: 'Drinks',
            id: 915,
            image: '/categories/drinks.jpg'
        },
        {
            name: 'Fruits & Vegetables',
            id: 913,
            image: '/categories/fruits-veggies.jpg'
        },
        {
            name:'Fish & Seafood',
            id: 917,
            image: '/categories/seafood.jpeg'
        },
        {
            name:'Frozen',
            id: 965,
            image: '/categories/packaging.jpg'
        },
        {
            name:'Grocery',
            id: 1141,
            image: '/categories/grocery.jpg'
        },
        {
            name: 'Indisponibles',
            id: 0,
            image: '/categories/fruits-veggies.jpg'
        }
    ],
    US: [
        {
            name: 'Meat & Poultry',
            id: 3018,
            image: '/categories/meat.jpeg'
        },
        {
            name: 'Caterer & Preparations',
            id: 3024,
            image: '/categories/catering.jpg'
        },
        {
            name: 'Dairy',
            id: 3020,
            image: '/categories/dairy.jpeg'

        },
        {
            name: 'Delicatessen',
            id: 3019,
            image: '/categories/cured_meat.png'
        },
        {
            name: 'Drinks',
            id: 3023,
            image: '/categories/drinks.jpg'
        },
        {
            name: 'Fruits & Vegetables',
            id: 3017,
            image: '/categories/fruits-veggies.jpg'
        },
        {
            name:'Fish & Seafood',
            id: 3021,
            image: '/categories/seafood.jpeg'
        },
        {
            name:'Frozen',
            id: 3025,
            image: '/categories/packaging.jpg'
        },
        {
            name:'Grocery',
            id: 3022,
            image: '/categories/grocery.jpg'
        },
        {
            name: 'Indisponibles',
            id: 0,
            image: '/categories/fruits-veggies.jpg'
        }
    ],
    ES: [
        {
            name: 'Carne',
            id: 1817,
            image: '/categories/meat.jpeg'
        },
        {
            name: 'Comida preparada',
            id: 2280,
            image: '/categories/catering.jpg'
        },
        {
            name: 'Mantequería',
            id: 1963,
            image: '/categories/dairy.jpeg'

        },
        {
            name: 'Charcuterías',
            id: 1818,
            image: '/categories/cured_meat.png'
        },
        {
            name: 'Bebidas',
            id: 1974,
            image: '/categories/drinks.jpg'
        },
        {
            name: 'Frutas y Verduras',
            id: 1814,
            image: '/categories/fruits-veggies.jpg'
        },
        {
            name:'Pescado',
            id: 1954,
            image: '/categories/seafood.jpeg'
        },
        {
            name:'Congelado',
            id: 1958,
            image: '/categories/packaging.jpg'
        },
        {
            name:'Despensa y seco',
            id: 1973,
            image: '/categories/grocery.jpg'
        },
        {
            name: 'Indisponibles',
            id: 0,
            image: '/categories/fruits-veggies.jpg'
        }
    ],
    MX: [
        {
            name: 'Carne',
            id: 3606,
            image: '/categories/meat.jpeg'
        },
        {
            name: 'Comida preparada',
            id: 3774,
            image: '/categories/catering.jpg'
        },
        {
            name: 'Lacteos',
            id: 3832,
            image: '/categories/dairy.jpeg'

        },
        {
            name: 'Salchicheria',
            id: 3607,
            image: '/categories/cured_meat.png'
        },
        {
            name: 'Bebidas',
            id: 3605,
            image: '/categories/drinks.jpg'
        },
        {
            name: 'Frutas y Verduras',
            id: 3608,
            image: '/categories/fruits-veggies.jpg'
        },
        {
            name:'Pescado',
            id: 3609,
            image: '/categories/seafood.jpeg'
        },
        {
            name:'Congelado',
            id: 3651,
            image: '/categories/packaging.jpg'
        },
        {
            name:'Abarrotes',
            id: 3629,
            image: '/categories/grocery.jpg'
        },
        {
            name: 'Indisponibles',
            id: 0,
            image: '/categories/fruits-veggies.jpg'
        }
    ]
}
export default categories;
