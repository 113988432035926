import React from "react";

import {useMutation, useQuery} from "@apollo/react-hooks";
import {CHANGE_ORDER_STATUS_QUERY, GET_ORDER_QUERY} from "./queries";


const GraphQlFetchData = ({orderId}) => {


    let [updateStatus, {loading: mutationLoading}] = useMutation(CHANGE_ORDER_STATUS_QUERY);

    const {error, data, refetch} = useQuery(GET_ORDER_QUERY, {variables: {id: parseInt(orderId)}});


    return (
        {updateStatus, mutationLoading, error, data, refetch}
    );
};

export default GraphQlFetchData;
