import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {FormattedMessage} from "react-intl";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Transition from "../../Commun/Transition"


const useStyles = makeStyles(theme => ({
    updateButton: {
        marginTop: 15,
        marginBottom: 20
    }
}));

const UpdateModalDialog = ({launchUpdate}) => {
    const [open, setOpen] = React.useState(true);
    const classes = useStyles();

    const yesClickHandler = () => {
        launchUpdate();
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title" disableTypography>
                    <Typography align="center" variant="h6">
                        <FormattedMessage id="updateNotification.heading" defaultMessage="Mise à jour disponible"/>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="column" alignItems="center">
                        <DialogContentText id="alert-dialog-slide-description">
                            <FormattedMessage id="updateNotification.textReadyToUpdate"
                                              defaultMessage="Foodomarket est prêt à être mis à jour."/>
                        </DialogContentText>
                        <Button onClick={yesClickHandler} color="primary" variant="contained"
                                className={classes.updateButton}>
                            <FormattedMessage id="updateNotification.buttonText"
                                              defaultMessage="Lancer l'installation"/>
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default UpdateModalDialog;
