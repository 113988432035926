import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {FormattedMessage} from "react-intl";

export default ({data, styles, activeStep, children}) => {
    return (
        <React.Fragment>
            <Stepper className={styles.mainStepper} alternativeLabel activeStep={activeStep}>
                {data.map((ele, index) => <Step key={index}>
                    <StepLabel StepIconComponent={ele.icon}>
                        <FormattedMessage id={ele.title} defaultMessage={ele.defaultTitle}/>
                    </StepLabel>
                </Step>)

                }
            </Stepper>
            {children}
        </React.Fragment>
    )
}