import * as Yup from "yup";
import {useIntl} from "react-intl";


export const FormValidation = () => {
    const nameTexts = {
        label: useIntl().formatMessage({
            id: 'newSupplierForm.nameLabel',
            defaultMessage: 'Nom',
        }),
        placeholder: useIntl().formatMessage({
            id: 'newSupplierForm.namePlaceholder',
            defaultMessage: 'Entrez le nom du fournisseur'
        }),
        helperText: useIntl().formatMessage({
            id: 'newSupplierForm.nameHelper',
            defaultMessage: 'Le nom servira a identifier ce fournisseur. Veuilliez vous assurez qu\'il est exact.'
        }),
        errorToLong: useIntl().formatMessage({
            id: 'newSupplierForm.nameToLong',
            defaultMessage: 'Le nom ne doit pas dépasser 255 charactères'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'newSupplierForm.nameRequired',
            defaultMessage: 'Le nom du fournisseur est requis.'
        })
    };

    const emailTexts = {
        label: useIntl().formatMessage({
            id: 'newSupplierForm.emailLabel',
            defaultMessage: 'Email',
        }),
        placeholder: useIntl().formatMessage({
            id: 'newSupplierForm.EmailPlaceholder',
            defaultMessage: 'Entrez l\'email du fournisseur'
        }),
        helperText: useIntl().formatMessage({
            id: 'newSupplierForm.emailHelper',
            defaultMessage: 'L\'email pourra être utilisé pour vos échanges avec le fournisseur, il est important qu\'il soit exact.'
        }),
        errorToLong: useIntl().formatMessage({
            id: 'newSupplierForm.emailToLong',
            defaultMessage: 'L\'email ne doit pas dépasser 255 charactères'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'newSupplierForm.emailRequired',
            defaultMessage: 'L\'email du fournisseur est requis.'
        }),
        errorInvalidEmail: useIntl().formatMessage({
            id: 'newSupplierForm.emailInvalid',
            defaultMessage: 'L\'email du fournisseur n\'est pas valide.'
        })
    };

    const phoneTexts = {
        label: useIntl().formatMessage({
            id: 'newSupplierForm.phoneLabel',
            defaultMessage: 'Téléphone',
        }),
        placeholder: useIntl().formatMessage({
            id: 'newSupplierForm.phonePlaceholder',
            defaultMessage: 'Entrez le téléphone du fournisseur'
        }),
        helperText: useIntl().formatMessage({
            id: 'newSupplierForm.phoneHelper',
            defaultMessage: 'Le nuémro de téléphone pourra être utilisé pour vos échanges avec le fournisseur, il est important qu\'il soit exact.'
        }),
        errorToLong: useIntl().formatMessage({
            id: 'newSupplierForm.phoneToLong',
            defaultMessage: 'Le numéro de téléphone ne doit pas dépasser 255 charactères'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'newSupplierForm.phoneRequired',
            defaultMessage: 'Le numéro de téléphone du fournisseur est requis.'
        })
    };

    const formValidate = {
        name: Yup.string()
            .max(255, nameTexts.errorToLong)
            .required(nameTexts.errorRequired),
        email: Yup.string()
            .max(255, emailTexts.errorToLong)
            .required(emailTexts.errorRequired)
            .email(emailTexts.errorInvalidEmail),
        phone: Yup.string()
            .max(255, phoneTexts.errorToLong)
            .required(phoneTexts.errorRequired)
    }
    return({
        formValidate, nameTexts, emailTexts, phoneTexts
    })
}

