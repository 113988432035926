import React from "react";
import {useMutation} from "@apollo/react-hooks";
import {CREATE_MESSAGE_MUTATION} from "./mutations";
import {GET_MESSAGES_QUERY} from "../ChatBox/queries";
import {cloneDeep} from "@apollo/client/utilities/common/cloneDeep";


const GraphQlFetchData = ({supplierTrueId}) => {


        let [createMessage, {loading: mutationLoading}] = useMutation(CREATE_MESSAGE_MUTATION, {
            update(cache, mutationData) {
                try {
                    //--Adding the new supplier to the cache--//

                    //Retrieving current restaurants list from cache
                    const {getMessages} = cache.readQuery({
                        query: GET_MESSAGES_QUERY,
                        variables: {supplierId: supplierTrueId}
                    });

                    const updateMessages = cloneDeep(getMessages);
                    updateMessages.edges = getMessages.edges.concat({
                        __typename: 'MessageEdge',
                        node: mutationData.data.createMessage.message
                    });

                    //Adding new restaurant to the list
                    cache.writeQuery({
                        query: GET_MESSAGES_QUERY,
                        variables: {supplierID: supplierTrueId},
                        data: {getMessages: updateMessages},
                    });
                } catch (e) {
                    //In this case, the cache is empty, it will be loaded from the API when redirecting to the restaurants list, so we do nothing here
                }
            }
        });


        return (
            {createMessage, mutationLoading}
        );
    }
;

export default GraphQlFetchData;
