import {gql} from "apollo-boost";

export const GET_PRODUCT_QUERY = gql`
    query getProduct($productId: String!, $restaurantId: String!, $supplierId: String!){
        getProduct(productId: $productId, restaurantId: $restaurantId, supplierId: $supplierId) {
            _id
            id
            name
            unit
            mainCategory
            price
            supplierId
            image
            createdByUser
        }
    }
`;
