import {useQuery} from '@apollo/react-hooks';
import {GET_RESTAURANTS_QUERY} from "./queries";

const GraphQlFetchData = () => {


    const {loading, error, data, refetch} = useQuery(GET_RESTAURANTS_QUERY, {errorPolicy: 'all'});


    return (
        {
            loading, error, data, refetch
        }
    );
};

export default GraphQlFetchData;
