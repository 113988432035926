import {gql} from "apollo-boost";

export const GET_TAXA_QUERY = gql`
    query GetTaxa($restaurantId: Int!, $supplierId:Int!) {
        taxa(
            restaurantId: $restaurantId,
            filters: {
                suppliers: [$supplierId]
            }
        ) {
            edges {
                node {
                    name
                    id
                    _id
                    products {
                        edges {
                            node {
                                id
                                _id
                                name
                                image {
                                    medium
                                    small
                                }
                                startingPrice
                                startingPriceUnit
                                currency
                                offers {
                                    totalCount
                                    pageInfo {
                                        hasNextPage
                                        endCursor
                                    }
                                    edges {
                                        node {
                                            id
                                            _id
                                            supplier {
                                                _id
                                            }
                                            name
                                            image {
                                                medium
                                                large
                                            }
                                            unit
                                            priceWithoutVat
                                            content
                                            frozen
                                            priceDetailsWithoutVat
                                            createdByUser
                                            origin
                                            organic
                                            promotion
                                            weight
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }`;

