
import {useMutation, useQuery} from "@apollo/react-hooks";
import {CREATE_PRODUCT_MUTATION} from "./mutations";
import {GET_SHOPPING_LIST_QUERY, GET_SUPPLIER_PRODUCTS_QUERY} from "../../Suppliers/SupplierShoppingList/queries";
import {ADD_PRODUCT_TO_LIST_MUTATION} from "../AddProductToListConfirmDialog/mutations";
import {GET_RESTAURANT_QUERY} from "../../Restaurants/RestaurantsList/queries";
import {GET_TOP_CATEGORIES_QUERY} from "./queries";
import {cloneDeep} from "@apollo/client/utilities/common/cloneDeep";


const GraphQlFetchData = ({
                              restaurantTrueId,
                              supplierTrueId,
                              decodedRestaurantId,
                              shoppingListId,
                              triggerNotification,
                              restaurantId,
                              supplierId, notificationText
                          }) => {

    const {
        data: restaurantData,
        error: restaurantError,
        loading: restaurantLoading
    } = useQuery(GET_RESTAURANT_QUERY, {variables: {id: decodedRestaurantId}});

    const {
        loading: categoriesLoading,
        error: categoriesError,
        data: categoriesData,
        refetch: categoriesRefresh
    } = useQuery(GET_TOP_CATEGORIES_QUERY, {
        variables: {
            locale: restaurantData ? restaurantData.restaurant.locale : 'fr_FR',
        },
        skip: !restaurantData
    });


    const [createProduct, {loading: mutationLoading}] = useMutation(CREATE_PRODUCT_MUTATION, {
        update(cache, mutationData) {
            try {
                //--Adding the new product to the cache--//

                //Retrieving current products list from cache
                const {supplierProducts} = cache.readQuery({
                    query: GET_SUPPLIER_PRODUCTS_QUERY,
                    variables: {restaurantId: restaurantTrueId, supplierId: supplierTrueId, offset: 0}
                });
                //Adding the new product to the list
                const updateProductsList = cloneDeep(supplierProducts);
                console.log(updateProductsList, mutationData);
                updateProductsList.edges = supplierProducts.edges.concat({
                    __typename: 'ProductEdge',
                    node: mutationData.data.createProduct.product,
                });

                addProductToList({
                    variables: {
                        shoppingListId: shoppingListId,
                        variantId: mutationData.data.createProduct.product._id,
                        unit: mutationData.data.createProduct.product.unit
                    }
                })
                    .then(() => {
                    })
                    .catch(e => triggerNotification({variant: 'error', message: notificationText.error}));

                cache.writeQuery({
                    query: GET_SUPPLIER_PRODUCTS_QUERY,
                    variables: {restaurantId: restaurantTrueId, supplierId: supplierTrueId, offset: 0},
                    data: {supplierProducts: updateProductsList},
                });
            } catch (e) {
                console.log(e);
                //In this case, the cache is empty, it will be loaded from the API when redirecting to the restaurants list, so we do nothing here
            }
        }
    });

    const [addProductToList] = useMutation(ADD_PRODUCT_TO_LIST_MUTATION, {
        update(cache, mutationData) {
            try {
                //--Adding the new restaurant to the cache--//

                //Retrieving current restaurants list from cache
                const {getShoppingList} = cache.readQuery({
                    query: GET_SHOPPING_LIST_QUERY,
                    variables: {restaurantId: restaurantTrueId, supplierId: supplierTrueId}
                });

                const updatedShoppingList = cloneDeep(getShoppingList);

                updatedShoppingList.items = getShoppingList.items.concat(mutationData.data.createShoppingListItem.shoppingListItem);

                //Adding new restaurant to the list
                cache.writeQuery({
                    query: GET_SHOPPING_LIST_QUERY,
                    variables: {restaurantId: restaurantId, supplierId: supplierId},
                    data: {getShoppingList: updatedShoppingList},
                });
            } catch (e) {
                //In this case, the cache is empty, it will be loaded from the API when redirecting to the restaurants list, so we do nothing here
            }
        }
    });


    return (
        {addProductToList, createProduct, mutationLoading, categoriesLoading, categoriesData, restaurantData}
    )
};

export default GraphQlFetchData;
