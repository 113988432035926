import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {FormattedMessage, useIntl} from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import BaseTextField from "../../Inputs/BaseTextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import GraphQlFetchData from "./graphQlFetchData";
import Utils from "../utils";
import Hooks from "./hooks";

const useStyles = makeStyles(theme => ({
    category: {
        width: '100%'
    },
    field: {
        marginTop: 25
    },
    validateButton: {
        marginTop: 25
    }
}));

//@TODO: Add price and weight ??
const EditProductForm = ({updateOverlayTitle, productId, supplierId, restaurantId, triggerNotification, goBack}) => {
    const classes = useStyles();
    const {formValidation, categoryTexts, refCodeTexts, nameTexts, unitTexts} = Utils();
    const {pageTitle, notificationText} = Hooks();
    const decodedRestaurantId = window.atob(restaurantId);
    const restaurantSegments = decodedRestaurantId.split('/');
    const restaurantTrueId = restaurantSegments.pop();

    const decodedSupplierId = window.atob(supplierId);
    const supplierSegments = decodedSupplierId.split('/');
    const supplierTrueId = supplierSegments.pop();
    const {data, restaurantData, categoriesData, editProduct, mutationLoading} = GraphQlFetchData({
        productId,
        decodedRestaurantId,
        restaurantTrueId,
        supplierTrueId
    })


    useEffect(() => {
        updateOverlayTitle(pageTitle);
    }, [])


    return (
        <>
            {data ? (
                <Formik
                    initialValues={{
                        category: data.getProduct.mainCategory,
                        name: data.getProduct.name,
                        unit: data.getProduct.unit,
                        //refCode: data.getProduct.code,
                    }}
                    validationSchema={Yup.object(formValidation)}
                    onSubmit={(values, {setSubmitting}) => {
                        editProduct({
                            variables:
                                {
                                    productId: productId,
                                    restaurantId: restaurantTrueId,
                                    supplierId: supplierTrueId,
                                    name: values.name,
                                    unit: values.unit,
                                    categoryId: values.category,
                                    code: values.code,
                                }
                        })
                            .then(() => {
                                goBack();
                                triggerNotification({variant: 'success', message: notificationText.success});
                            })
                            .catch(e => triggerNotification({variant: 'error', message: notificationText.error}));
                    }}
                >
                    <Form className={classes.form}>
                        <Grid container justify={"center"} alignItems={"center"} direction="column">
                            <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                <Field component={BaseTextField} name="category" id="category" type="text"
                                       label={categoryTexts.label}
                                       fullWidth
                                       select
                                       placeholder={categoryTexts.placeholder} className={classes.field}
                                       helperText={categoryTexts.helperText}>
                                    {categoriesData && categoriesData.getTopCategories.map((category, key) => {
                                            return <MenuItem value={category.taxonId} key={key}>{category.name}</MenuItem>
                                        }
                                    )}
                                </Field>
                            </Grid>
                            <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                <Field component={BaseTextField} name="name" id="name" type="text"
                                       label={nameTexts.label} fullWidth
                                       placeholder={nameTexts.placeholder} className={classes.field}
                                       helperText={nameTexts.helperText}/>
                            </Grid>
                            <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                <Field component={BaseTextField} name="unit" id="unit" type="text"
                                       label={unitTexts.label} fullWidth
                                       select
                                       placeholder={unitTexts.placeholder}
                                       helperText={unitTexts.helperText}
                                       className={classes.field}>
                                    <MenuItem value="KG"><FormattedMessage id="productEdit.kg"
                                                                           defaultMessage="Kilogramme"/></MenuItem>
                                    <MenuItem value="L"><FormattedMessage id="productEdit.liter"
                                                                          defaultMessage="Litre"/></MenuItem>
                                    <MenuItem value="CO"><FormattedMessage id="productEdit.pack"
                                                                           defaultMessage="Colis"/></MenuItem>
                                    <MenuItem value="PC"><FormattedMessage id="productEdit.piece"
                                                                           defaultMessage="Pièce"/></MenuItem>
                                </Field>
                            </Grid>
                            {/*<Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                <Field component={BaseTextField} name="refCode" id="refCode" type="text"
                                       label={refCodeTexts.label} fullWidth
                                       placeholder={refCodeTexts.placeholder}
                                       helperText={refCodeTexts.helperText}
                                       className={classes.field}
                                />
                            </Grid>*/}
                            <Grid item lg={2} md={3} sm={4} xs={10}>
                                <Button variant="contained" color="primary" size="large" type="submit"
                                        className={classes.validateButton} disabled={mutationLoading}>
                                    <FormattedMessage
                                        id="productEdit.buttonText"
                                        defaultMessage="Valider"/>
                                    {mutationLoading && <CircularProgress size={24} className={classes.buttonProgress}/>
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            ) : null}
        </>
    );
};

export default EditProductForm;
