import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import {FormattedMessage} from "react-intl";
import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    addProduct: {
        //position: 'fixed',
        //width: '100%',
        //bottom: 50
    }
}));

const SupplierShoppingListAddProduct = ({supplier, createProduct}) => {
    const classes = useStyles();
    return (
        <>
            {!supplier.partner && (
                <Grid container justify="center" className={classes.addProduct}>
                    <Fab onClick={createProduct} variant="extended" color="secondary">
                        <FormattedMessage id="supplierProductList.addProduct"
                                          defaultMessage="Ajouter un produit"/>
                    </Fab>
                </Grid>
            )}

        </>
    )
}
export default SupplierShoppingListAddProduct