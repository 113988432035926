import React, {useEffect} from "react";
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import EmptyList from "../../EmptyList";
import {ADD_RESTAURANT, RESTAURANTS} from "../../../constants/Routes";
import RestaurantCard from "../RestaurantCard";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import FabAddBottomLeft from "../../Fabs/FabAddBottomRight";

import FetchingError from "../../Errors/FetchingError";
import amplitudeClient from "../../../amplitudeWrapper";
import {INDIVIDUAL, PROFESSIONAL} from "../../../constants/UserTypes";
import GraphQlFetchData from "./graphQlFetchData";
import Hooks from "./hooks"
const useStyles = makeStyles(theme => ({
    container: {
        padding: 10,
    },
    fab: {
        position: 'fixed',
        bottom: 75,
        right: 25,
    },
    innerGrid: {
        marginBottom: 8,
    }
}));

const RestaurantsList = ({
                             updateTopNavTitle,
                             setSelectedRestaurant,
                             userType,
                             updateTopNavSubtitle,
                             push,
                             triggerNotification,
                             denyAccessToUnauthenticatedUser,
                             unsetActionsComponent,
                             displayMenu
                         }) => {

    const classes = useStyles();
    const {pageTitle, pageTitlePro} = Hooks()
    const {loading, error, data, refetch} = GraphQlFetchData()
    if (data && userType === INDIVIDUAL) {
        const encodedId = window.btoa(data.restaurants[0].id);
        setSelectedRestaurant({restaurantId: encodedId});
        push(`${RESTAURANTS}/${encodedId}/suppliers`);
    }

    const addRestaurant = () => {
        amplitudeClient.logEvent('add a restaurant clicked', {origin: 'restaurants list'});
        push(ADD_RESTAURANT, {modal: true});
    };
    useEffect(() => {
        (async () => {
            await denyAccessToUnauthenticatedUser();
            await displayMenu();

            await updateTopNavTitle(userType === PROFESSIONAL ? pageTitlePro : pageTitle);
            await updateTopNavSubtitle('');
            await unsetActionsComponent();
        })()
    }, [])


    if (loading) {
        const skeleton = [0, 1, 2, 3, 4, 5, 6];
        return (
            <Grid container justify={"center"} className={classes.container}>
                {skeleton.map((item, key) => (
                    <Grid item xs={12} key={key} className={classes.innerGrid}>
                        <RestaurantCard/>
                    </Grid>
                ))}
            </Grid>
        );
    }

    const refetchRestaurants = () => {
        refetch().then(null).catch(e => null)
    };

    if (error) {
        return (
            <FetchingError action={refetchRestaurants}/>);
    }

    return (
        <>
            {data.restaurants.edges !== null ? (
                <>
                    <Grid container justify={"center"} className={classes.container}>
                        {data.restaurants?.edges?.map((restaurant, index) => (
                            <Grid item xs={12} key={restaurant._id} className={classes.innerGrid}>
                                <RestaurantCard restaurantData={restaurant.node} push={push}
                                                triggerNotification={triggerNotification}/>
                            </Grid>
                        ))}
                    </Grid>
                    {userType === PROFESSIONAL && <FabAddBottomLeft action={addRestaurant}/>}
                </>
            ) : (
                <EmptyList icon={StoreMallDirectoryOutlinedIcon} heading={"Il n'y a rien ici!"}
                           text={"Pour ajouter votre premier établissement, cliquer sur le bouton ci dessous."}
                           action={addRestaurant}/>
            )}

        </>
    );
};

export default RestaurantsList;
