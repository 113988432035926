import React from "react";

import {useQuery} from "@apollo/react-hooks";
import {GET_RESTAURANTS_QUERY} from "../Restaurants/RestaurantsList/queries";


const GraphQlFetchData = () => {


        const {
            loading: restaurantsLoading,
            error: restaurantsError,
            data: restaurants,
            refetch: restaurantsRefetch
        } = useQuery(GET_RESTAURANTS_QUERY, {errorPolicy: 'all'});


        return (
            {restaurantsLoading, restaurantsError, restaurants, restaurantsRefetch}
        );
    }
;

export default GraphQlFetchData;
