import React, {useState} from "react";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import SearchBar from "../../SearchBar";
import SupplierShoppingListAddProduct from "../SupplierShoppingListAddProduct/index"
import SupplierProductListFilter from "../SupplierProductListFilter/index"
import {FormattedMessage, useIntl} from "react-intl";
import ProductCard from "../../Products/ProductCard";
import SearchResults from "react-filter-search";
import {AutoSizer, CellMeasurer, CellMeasurerCache, List} from "react-virtualized";
import {filter, findIndex, indexOf, reduce} from 'lodash';
import IconButton from "@material-ui/core/IconButton";
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import Button from "@material-ui/core/Button";
import categories from "../../Products/categories";
import FetchingError from "../../Errors/FetchingError";
import amplitudeClient from "../../../amplitudeWrapper";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ProductCardMercurial from "../../Products/ProductCardMercurial";
import {cloneDeep} from "@apollo/client/utilities/common/cloneDeep";
import GraphQlFetchData from "./graphQlFetchData";

const useStyles = makeStyles(theme => ({
    container: {
        height: 'calc(100vh - 36px)'
    },
    withFloatingCart: {
        paddingBottom: 44
    },
    innerGrid: {
        padding: '5px 10px',
    },
    search: {
        flexGrow: 1
    },
    topContainer: {
        padding: 10,
        boxShadow: '0px 8px 8px 0px rgba(0,0,0, 0.1)',
        zIndex: 1100,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
    },
    productsBox: {
        flex: '1 1 auto',
    },
    categoryFilterProduct: {
        width: '100%',
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    closeEditButton: {
        marginTop: 7,
        borderRadius: 0,
        padding: 8
    },
    closeButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: 0,
    },
    emptyMercurial: {
        marginTop: 50
    },
    addProduct: {
        //position: 'fixed',
        //width: '100%',
        //bottom: 50
    }
}));

//@TODO: reorganise state management instructions
const SupplierProductsList = ({
                                  supplier,
                                  restaurant,
                                  shoppingList,
                                  openState,
                                  close,
                                  triggerNotification,
                                  createProduct,
                                  locale,
                              }) => {
    const classes = useStyles();
    const intl = useIntl();
    const [searchValue, setSearchValue] = useState('');
    const [usePromotionOnly, setUsePromotionOnly] = useState(true);

    const {loading, error, data, refetch, productdata, productrefrech, productloading, categoriesRefresh} = GraphQlFetchData({supplier, restaurant})

    //virtualized rendering cache
    let cache = new CellMeasurerCache({
        fixedWidth: true,
    });

    const handleChange = event => {
        const {value} = event.target;
        setSearchValue(value);
    };

    const searchPlaceholder = intl.formatMessage(
        {
            id: 'supplierShop.searchPlaceholder',
            defaultMessage: 'Recherche'
        }
    );

    //@TODO: make a component ??
    const categoriesList = reduce(categories[restaurant.address.country], (result, value) => {
        (result).push(value.id);
        return result;
    }, []);

    const [checked, setChecked] = React.useState(categoriesList);
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

    let availableCategories = [];
    if (data) {
        data.suppliers.edges.forEach((item, key) => {
            // add everything, duplicates will be removed next
            availableCategories.push(...item.node.categories);
            //make unique
            availableCategories = availableCategories.filter((v, i, a) => a.indexOf(v) === i);
        });
    }

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            amplitudeClient.logEvent('product category filter set');
            newChecked.push(value);
        } else {
            amplitudeClient.logEvent('product category filter unset');
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const toggleFilterDrawer = () => {
        if (filterDrawerOpen) {
            setFilterDrawerOpen(false);
        } else {
            amplitudeClient.logEvent('product filters displayed');
            setFilterDrawerOpen(true);
        }
    };

    const getFilteredProducts = () => {
        const products = cloneDeep(productdata?.offers.edges);
        return products

  /*      return filter(products, (product) => {

            const index = findIndex(shoppingList.supplierShoppingList.items, (item) => {
                return item.vid === product.node._id;
            });

            if (index !== -1) {
                product.node.inShoppingList = true;
                product.node.shoppingListItemId = shoppingList.supplierShoppingList.items[index]._id;
            } else {
                product.node.inShoppingList = false;
            }

           //Filter by category
            if (indexOf(checked, parseInt(product.node.mainCategory)) !== -1) {
                return true;
            }

            if (product.node.mainCategory === '') {
                return true;
            }

            //filter by promotion
            if (usePromotionOnly) {
                return product.node.promotion;
            }*/
            return false;
    //    });
    };

    const refetchProducts = () => {
        productrefrech().then(null).catch(e => null)
        categoriesRefresh().then(null).catch(e => null);
    };

    if (error) {
        return (
            <FetchingError action={refetchProducts}/>
        );
    }


    if (productloading) {
        const skeleton = [0, 1, 2, 3, 4, 5, 6];
        return (
            <Grid container justify={"center"} className={classes.container}>
                {skeleton.map((item, key) => (
                    <Grid item xs={12} key={key} className={classes.innerGrid}>
                        <ProductCard/>
                    </Grid>
                ))}
            </Grid>
        );
    }

    return (
        <>
            <Dialog fullScreen open={openState} onClose={close}>
               {/*<SupplierProductListFilter
                    filterDrawerOpen={filterDrawerOpen}
                    toggleFilterDrawer={toggleFilterDrawer}
                    restaurant={restaurant}
                    categories={categories}
                    handleToggle={handleToggle}
                    availableCategories={availableCategories}
                    setUsePromotionOnly={setUsePromotionOnly}
                    usePromotionOnly={usePromotionOnly}
                    checked={checked}/>*/}
                {productdata?.offers ? (
                    <Grid container direction="column"
                          className={classes.container}>
                        <Grid container alignItems="center" className={classes.topContainer}>
                            <Grid item container alignItems="center">
                                <IconButton onClick={close}>
                                    <CloseOutlinedIcon/>
                                </IconButton>
                                <Typography>
                                    {supplier.name}
                                    <FormattedMessage id="supplierProductList.mercurial"
                                                      defaultMessage=" - Mercuriale"/>
                                </Typography>
                            </Grid>
                            <Grid container wrap="nowrap" alignItems="center">
                                <Grid item className={classes.search}>
                                    <SearchBar placeholder={searchPlaceholder} onChange={handleChange}
                                               value={searchValue}/>
                                </Grid>
                                {/*<Grid item className={classes.actionsContainer}>
                                    <IconButton
                                        aria-label="Editer"
                                        aria-haspopup="true"
                                        color="inherit"
                                        onClick={toggleFilterDrawer}
                                    >
                                        <TuneOutlinedIcon color="secondary"/>
                                    </IconButton>
                                </Grid>*/}
                            </Grid>

                        </Grid>
                        {productdata?.offers.edges.length > 0 ? (
                            <Grid container className={classes.productsBox}>
                                <SearchResults
                                    value={searchValue}
                                    data={getFilteredProducts()}
                                    renderResults={results =>
                                        (<>
                                            <AutoSizer>
                                                {({height, width}) => (
                                                    <List width={width}
                                                          height={height}
                                                          rowCount={results.length}
                                                          rowHeight={cache.rowHeight}
                                                          rowRenderer={({
                                                                            key,
                                                                            index,
                                                                            parent,
                                                                            isScrolling,
                                                                            isVisible,
                                                                            style
                                                                        }) => {
                                                              return (
                                                                  <CellMeasurer
                                                                      cache={cache}
                                                                      columnIndex={0}
                                                                      key={key}
                                                                      parent={parent}
                                                                      rowIndex={index}
                                                                  >
                                                                      <Grid item xs={12} key={index} style={style}
                                                                            className={classes.innerGrid}>
                                                                          <ProductCardMercurial
                                                                              currency={supplier.currency}
                                                                              product={results[index].node}
                                                                              supplierId={supplier._id}
                                                                              restaurantId={restaurant._id}
                                                                              shoppingList={shoppingList.supplierShoppingList}
                                                                              triggerNotification={triggerNotification}/>
                                                                      </Grid>
                                                                  </CellMeasurer>
                                                              )
                                                          }}
                                                          overscanRowCount={4}
                                                    />)}
                                            </AutoSizer>
                                        </>)}
                                />
                            </Grid>
                        ) : (
                            <Grid container direction="column" className={classes.emptyMercurial}>
                                <Typography variant="h5" align="center" gutterBottom>
                                    <FormattedMessage id="supplierProductList.emptyMercu"
                                                      defaultMessage="La mercuriale est vide !"/>
                                </Typography>
                                <Typography align="center">
                                    <FormattedMessage id="supplierProductList.emptyMercuHelp"
                                                      defaultMessage='Pour ajouter des produits à la mercuriale, cliquez sur le bouton "Créer un produit"'/>
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                ) : null}
                <Button className={classes.closeButton} onClick={close}>
                    <FormattedMessage id="supplierProductList.closeButton" defaultMessage="Revenir à la liste d'achat"/>
                </Button>
            </Dialog>
        </>
    );
};

export default SupplierProductsList;

