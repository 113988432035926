
import React from "react";
import {makeStyles} from "@material-ui/core";
const useStyles = makeStyles(theme => ({
    text: {
        fontSize:'5px',
        color:'black',
    }
}));


const SupplierManagement=()=>{


    const classes = useStyles();
    console.log('test')

  return(
      <>
          <span className={classes.text}>Hello world</span>
      </>
  );
};
export default SupplierManagement