import {useIntl} from 'react-intl';

function Hooks() {
    const pageTitle = useIntl().formatMessage(
        {
            id: 'editProductPage.title',
            defaultMessage: 'Éditer un produit'
        }
    );


    const notificationText = {
        success: useIntl().formatMessage(
            {
                id: 'editProductForm.SuccessNotificationText',
                defaultMessage: 'Produit édité avec succès'
            }
        ),
        error: useIntl().formatMessage(
            {
                id: 'editProductForm.errorNotificationText',
                defaultMessage: 'Une erreur est survenue lors de l\'édition du produit.'
            }
        ),
    };
    return ({
        notificationText, pageTitle
    })

}

export default Hooks