import {gql} from "apollo-boost";

export const CREATE_SHOPPING_LIST_MUTATION = gql`
    mutation createShoppingList ($restaurantId: Int!, $supplierId: Int, $listName: String, $listItems: [ShoppingListItemInput!],$clientMutationId: String) {
        createShoppingList(input:{restaurantId: $restaurantId, supplierId: $supplierId, listName: $listName,listItems:$listItems,clientMutationId:$clientMutationId}) {
            shoppingList{
                id
                _id
                name
                suppliersCount
                updatedAt
                items{
                    edges {
                        node{
                            id
                            _id
                            quantity
                            name
                            content
                            origin
                            supplier {
                                id
                            }
                            offer {
                                id
                            }
                            priceWithVat
                            priceWithoutVat
                            totalWithVat
                            totalWithoutVat
                        }
                    }
                }
            }
        }
    }
`;


