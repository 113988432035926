import {gql} from "apollo-boost";

export const GET_MESSAGES_QUERY = gql`
    query getMessages($supplierId: String!) {
        getMessages(supplierId: $supplierId, markAsRead: true) {
            edges {
                node {
                    id
                    _id
                    recipient {
                        type
                        email
                        name
                    }
                    sender {
                        type
                        email
                        name
                    }
                    content
                    status
                    creationDate
                    channel
                    channelStatus
                    channelMessageId
                    important
                    picture
                }
            }
        }
    }
`;
