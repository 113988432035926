import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {FormattedMessage, useIntl} from 'react-intl';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import BaseTextField from "../../Inputs/BaseTextField";
import {RESTAURANTS} from "../../../constants/Routes";
import CircularProgress from "@material-ui/core/CircularProgress";
import ScriptLoader from 'react-script-loader-hoc';
import amplitudeClient from "../../../amplitudeWrapper";
import GraphQlFetchData from "./graphQlFetchData";
//
import Hooks from "./hooks"
import {FormValidation} from "../utils";
import BaseSelect from "../../Inputs/BaseSelect";
import MenuItem from "@material-ui/core/MenuItem";
import {InputLabel} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles(theme => ({
    iconContainer: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 90,
        height: 90,
        width: 90,
        marginTop: 25,
        marginBottom: 20,
    },
    textWrapper: {
        marginBottom: 25,
        padding: '0 10px',
    },
    iconWrapper: {
        height: '100%',
    },
    icon: {
        color: 'white',
        fontSize: '3rem'
    },
    input: {
        marginBottom: 25,
    },
    resetPasswordLink: {
        cursor: 'pointer'
    },
    connexionButton: {
        margin: '30px 0',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    form: {
        marginTop: 10
    }
}));

//@TODO: find a way to extract mutation handling from the component
//@TODO: put loc in it's own file
const AddRestaurantForm = ({updateOverlayTitle, push, triggerNotification, scriptsLoadedSuccessfully, locale}) => {
    const classes = useStyles();
    const {
        notificationText,
        pageTitle
    } = Hooks()
    const {
        mutationLoading,
        addRestaurant,
        restaurantTypesLoading,
        restaurantTypesData,
        restaurantTypesError
    } = GraphQlFetchData(locale)
    const {
        formValidate,
        cityTexts,
        zipTexts,
        countryTexts,
        cuisineTypeTexts,
        preferredDeliveryTimeTexts,
        nameTexts,
        streetTexts,
        addressTexts
    } = FormValidation();

    useEffect(() => {
        updateOverlayTitle(pageTitle);

    }, [])

    const cuisineTypeOptions = restaurantTypesData && restaurantTypesData.restaurantTypes.map((type) => {
        return {label: type.name, value: type._id}
    });

    //@TODO: Put this in a Hook or sub component ?
    //--- Google Places autocomplete--------
    let autocomplete;
    let formikSetFieldValue;

    const geolocate = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                const circle = new window.google.maps.Circle(
                    {center: geolocation, radius: position.coords.accuracy});
                autocomplete.setBounds(circle.getBounds());
            });
        }
    };

    const fillInAddress = () => {
        const place = autocomplete.getPlace();
        const componentForm = {
            route: {nameType: 'long_name', fieldName: 'street'},
            locality: {nameType: 'long_name', fieldName: 'city'},
            country: {nameType: 'short_name', fieldName: 'country'},
            postal_code: {nameType: 'short_name', fieldName: 'zip'},
        };

        for (let i = 0; i < place.address_components.length; i++) {
            const addressType = place.address_components[i].types[0];
            if (componentForm[addressType]) {
                const val = place.address_components[i][componentForm[addressType].nameType];
                formikSetFieldValue(componentForm[addressType].fieldName, val);
            }
        }

        formikSetFieldValue('address', place.formatted_address);
    };

    if (scriptsLoadedSuccessfully) {
        autocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById('address')
        );

        autocomplete.setFields(['address_component', 'formatted_address']);
        autocomplete.addListener('place_changed', fillInAddress);
    }
    //--------------------------------------------
    return (
        <>
            <Formik
                initialValues={{
                    name: '',
                    street: '',
                    zip: '',
                    city: '',
                    country: '',
                    preferredDeliveryTime: '',
                    cuisineType: '',
                }}
                validationSchema={Yup.object(formValidate)}
                onSubmit={(values, {setSubmitting}) => {
                    addRestaurant({
                        variables: {
                            name: values.name,
                            preferredDeliveryTime: values.preferredDeliveryTime,
                            cuisineType: values.cuisineType,
                            street: values.street,
                            zip: values.zip,
                            city: values.city,
                            country: values.country,
                        }
                    })
                        .then(() => {
                            //Redirecting to restaurants lists
                            amplitudeClient.logEvent('restaurant added');
                            push(RESTAURANTS);
                            triggerNotification({variant: 'success', message: notificationText.success});
                        })
                        .catch(e => triggerNotification({variant: 'error', message: notificationText.error}));
                    setSubmitting(false);
                }}
            >
                {/*@TODO: This should be a separate component*/}
                {({setFieldValue, errors, touched}) => {
                    //We need to access setFieldValue outside of the Formik component to populate inputs with data from Google Places autocomplete.
                    //@TODO: use local state to avoid using setFieldValue outside of Formik component ??
                    formikSetFieldValue = setFieldValue;

                    return (<Form className={classes.form} onFocus={geolocate}>
                        {restaurantTypesLoading && <CircularProgress/>}
                        {!restaurantTypesLoading &&
                            <Grid container justify={"center"} alignItems={"center"} direction="column">

                            <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                <Field component={BaseTextField} name="name" id="name" type="text"
                                       label={nameTexts.label}
                                       fullWidth
                                       placeholder={nameTexts.placeholder} className={classes.input}
                                       helperText={nameTexts.helperText}/>
                            </Grid>
                            <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                <Field component={BaseTextField} name="address" id="address" type="text" size="small"
                                       label={addressTexts.label} fullWidth
                                       placeholder={addressTexts.placeholder} className={classes.input}
                                       helperText={addressTexts.helperText}/>
                            </Grid>
                            <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                <Field component={BaseTextField} name="street" id="street" type="text" size="small"
                                       label={streetTexts.label} fullWidth
                                       placeholder={streetTexts.placeholder}
                                       className={classes.input}
                                />
                            </Grid>
                            <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                <Field component={BaseTextField} name="zip" id="zip" type="text" size="small"
                                       label={zipTexts.label} fullWidth
                                       placeholder={zipTexts.placeholder} className={classes.input}
                                />
                            </Grid>
                            <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                <Field component={BaseTextField} name="city" id="city" type="text" size="small"
                                       label={cityTexts.label} fullWidth
                                       placeholder={cityTexts.placeholder} className={classes.input}
                                />
                            </Grid>
                            <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                <Field component={BaseTextField} name="country" id="country" type="text" size="small"
                                       label={countryTexts.label} fullWidth
                                       placeholder={countryTexts.placeholder} className={classes.input}
                                />
                            </Grid>
                            <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                <Field component={BaseTextField} name="preferredDeliveryTime" id="preferredDeliveryTime"
                                       type="text" size="small"
                                       label={preferredDeliveryTimeTexts.label} fullWidth
                                       placeholder={preferredDeliveryTimeTexts.placeholder} className={classes.input}
                                />
                            </Grid>
                            <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        error={touched.cuisineType && Boolean(errors.cuisineType)}
                                        id="select-label">
                                        {cuisineTypeTexts.label}
                                    </InputLabel>
                                    <Field name="cuisineType" component={BaseSelect} id="cuisineType"
                                           label={cuisineTypeTexts.label}
                                           error={touched.cuisineType && Boolean(errors.cuisineType)}

                                    >
                                        {cuisineTypeOptions && cuisineTypeOptions.map((cuisineType, index) => (
                                            <MenuItem key={index} value={cuisineType.value}>
                                                {cuisineType.label}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                    <ErrorMessage
                                        name="cuisineType" render={msg => <FormHelperText error>{msg}</FormHelperText>} />

                                </FormControl>
                            </Grid>
                            <Grid item lg={2} md={3} sm={4} xs={10}>
                                <Button variant="contained" color="primary" size="large" type="submit"
                                        className={classes.connexionButton} disabled={mutationLoading}>
                                    <FormattedMessage
                                        id="addRestaurantForm.add"
                                        defaultMessage="Ajouter"/>
                                    {mutationLoading && <CircularProgress size={24} className={classes.buttonProgress}/>
                                    }
                                </Button>
                            </Grid>
                        </Grid>}
                    </Form>)
                }
                }
            </Formik>
        </>
    );
};
//Script loader avoids to load Google scripts on every pages
export default ScriptLoader('https://maps.googleapis.com/maps/api/js?key=AIzaSyAPN_7_svR5FjaOyFD6StAZjPgYMx9kYl8&libraries=places')(AddRestaurantForm);
