import Badge from "@material-ui/core/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentDots} from "@fortawesome/free-regular-svg-icons";
import Typography from "@material-ui/core/Typography";
import {FormattedMessage} from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import SupplierCard from "../SupplierCard";
const useStyles = makeStyles(theme => ({
    actionBox: {
        backgroundColor: 'hsl(144, 66%, 94%)'
    },
    chatLabel: {
        fontSize: '0.8rem',
        marginLeft: 3
    },
    chatButton: {
        borderRadius: 10
    },
    chatIcon: {
        //color: theme.palette.grey.A700,
    }
}));

const SupplierDialogBox=({supplier,nbUnreadMessages, goToChat})=>{
    const classes = useStyles();
console.log(supplier,'supplierssss')
    return(
        <>
            <IconButton color="primary" className={`${classes.actionBox} ${classes.chatButton}`}
                        onClick={goToChat}>
                {supplier && nbUnreadMessages ? (
                    <Badge badgeContent={nbUnreadMessages}
                           color="primary">
                        <FontAwesomeIcon icon={faCommentDots}
                                         className={classes.chatIcon}/>
                        <Typography className={classes.chatLabel}>
                            <FormattedMessage id="supplierCard.chatLabel"
                                              defaultMessage="Discuter"/>
                        </Typography>
                    </Badge>
                ) : (
                    <>
                        <FontAwesomeIcon icon={faCommentDots}
                                         className={classes.chatIcon}/>
                        <Typography className={classes.chatLabel}>
                            <FormattedMessage id="supplierCard.chatLabel"
                                              defaultMessage="Discuter"/>
                        </Typography>
                    </>

                    )}
            </IconButton>
        </>




    );
};
export default SupplierDialogBox;
