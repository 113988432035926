import {gql} from "apollo-boost";

export const ADD_PRODUCT_TO_LIST_MUTATION = gql`
mutation addToShoppingList($input: addToShoppingListInput!) {
  addToShoppingList(input: $input) {
            shoppingList{
              
              id
_id
name
suppliersCount
updatedAt
items{
edges{
node{
id
_id
quantity
name
content
origin
supplier{_id}
offer{name}
              }
                
              }
            }

            }}}
`;
