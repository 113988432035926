import {connect} from 'react-redux';
import OrderDetailsSheet from "../../components/Orders/OrderDetailsSheet";
import SupplierProductCategoriesList from "../../components/Suppliers/SupplierProductCategoriesList"
import {updateOverlayTitle} from "../../reducers/overlay";
import {createMatchSelector, push} from "connected-react-router";
import {bindActionCreators} from "redux";
import {triggerToastNotification} from "../../reducers/toastNotification";
import {setMessage} from "../../reducers/messages";
import {setProductQuantity} from "../../reducers/carts";

const mapStateToProps = (state) => {
    const matchSelector = createMatchSelector('/orders/:id');
    const match = matchSelector(state);
    return {
        orderId: match.params.id,
        selectedRestaurantId: state.restaurants.selectedRestaurantId,
        locale: state.authentication.language
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateOverlayTitle: (title) => dispatch(updateOverlayTitle(title)),
    push: bindActionCreators(push, dispatch),
    triggerNotification: (payload) => dispatch(triggerToastNotification(payload)),
    setMessage: bindActionCreators(setMessage, dispatch),
    setProductQuantity: bindActionCreators(setProductQuantity, dispatch),
});

const OrderDetails = connect(mapStateToProps, mapDispatchToProps)(OrderDetailsSheet);

export default OrderDetails;
