import {gql} from "apollo-boost";

export const GET_ORDERS_QUERY = gql`
    query getOrders($first: Int) {
        orders(first: $first) {
            edges {
                node {
                    _id
                    id
                    deliveryDate
                    supplier {
                        _id
                        name
                    }
                    status
                    reference
                    statusHistory {
                        edges {
                            node {
                                status
                                createdAt
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_RESTAURANTS_ORDERS_QUERY = gql`
    query getRestaurantOrders($restaurantId: Int!) {
        orders(restaurantId: $restaurantId) {
            edges {
                node {
                    _id
                    id
                    deliveryDate
                    supplier {
                        _id
                        name
                    }
                    status
                    reference
                    statusHistory {
                        edges {
                            node {
                                status
                                createdAt
                            }
                        }
                    }
                }
            }
        }
    }
`;
