import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles(theme => ({
    input: {
        marginBottom: 25,
    },
    saveButton: {
        margin: '30px 0',
    },
    oldPassword: {
        marginBottom: 20
    }
}));

const ChangePasswordForm = () => {
    const classes = useStyles();

    const intl = useIntl();

    const oldPasswordTexts = {
        label: intl.formatMessage({
            id: 'changePasswordForm.oldPasswordLabel',
            defaultMessage: 'Votre mot de passe actuel',
        }),
        placeholder: intl.formatMessage({
            id: 'changePasswordForm.oldPasswordPlaceholder',
            defaultMessage: 'Entrez votre mot de passe actuel'
        }),
        helperText: intl.formatMessage({
            id: 'changePasswordForm.oldPasswordHelperText',
            defaultMessage: 'Pour des raison de sécurité, il est necessaire de fournir votre mot de passe actuel.'
        })
    };

    const newPasswordTexts = {
        label: intl.formatMessage({
            id: 'changePasswordForm.newPasswordLabel',
            defaultMessage: 'Votre nouveau mot de passe',
        }),
        placeholder: intl.formatMessage({
            id: 'changePasswordForm.newPasswordPlaceholder',
            defaultMessage: 'Entrez votre nouveau mot de passe'
        }),
        helperText: intl.formatMessage({
            id: 'changePasswordForm.newPasswordHelperText',
            defaultMessage: 'Votre nouveau mot de passe doit etre différent de l\'actuel'
        })
    };

    const confirmPasswordTexts = {
        label: intl.formatMessage({
            id: 'changePasswordForm.confirmPasswordLabel',
            defaultMessage: 'Confirmez votre nouveau de mot passe',
        }),
        placeholder: intl.formatMessage({
            id: 'changePasswordForm.confirmPasswordPlaceholder',
            defaultMessage: 'Retaper votre nouveau mot de passe pour le confirmer'
        }),
        helperText: intl.formatMessage({
            id: 'changePasswordForm.confirmPasswordHelperText',
            defaultMessage: 'Pour des raison de sécurité, merci de retaper votre nouveau mot de passe.'
        })
    };

    return (
        <>
            <form>
                <Grid container justify={"center"} direction={"column"} alignItems={"center"}>
                    <Grid item container justify={"center"} sm={8} xs={11} className={classes.oldPassword}>
                        <TextField id="oldpw" name="oldpw" label={oldPasswordTexts.label} variant="filled"
                                   fullWidth
                                   placeholder={oldPasswordTexts.placeholder} className={classes.input}
                                   helperText={oldPasswordTexts.helperText}/>
                    </Grid>
                    <Grid item container justify={"center"} sm={8} xs={11}>
                        <TextField id="newpw" name="newpw" label={newPasswordTexts.label} variant="filled"
                                   fullWidth
                                   placeholder={newPasswordTexts.placeholder} className={classes.input}
                                   helperText={newPasswordTexts.helperText}/>
                    </Grid>
                    <Grid item container justify={"center"} sm={8} xs={11}>
                        <TextField id="confirmpw" name="confirmpw" label={confirmPasswordTexts.label} variant="filled"
                                   fullWidth
                                   placeholder={confirmPasswordTexts.placeholder} className={classes.input}
                                   helperText={confirmPasswordTexts.helperText}/>
                    </Grid>
                    <Grid item lg={2} md={3} sm={4} xs={10}>
                        <Button variant="contained" color="primary" size="large" className={classes.saveButton}>
                            <FormattedMessage
                                id="changePasswordForm.buttonText"
                                defaultMessage="Sauvegarder mon nouveau mot de passe"
                            />
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default ChangePasswordForm;
