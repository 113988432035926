import {gql} from "apollo-boost";

export const CREATE_MESSAGE_MUTATION = gql`
    mutation createMessage($supplierId: String!, $content: String!, $important: Boolean, $picture: String) {
        createMessage(input:{supplierId: $supplierId, content: $content, important: $important, picture: $picture}) {
            message{
                id
                _id
                recipient {
                    type
                    email
                    name
                }
                sender {
                    type
                    email
                    name
                }
                content
                status
                creationDate
                channel
                channelStatus
                channelMessageId
                important
                picture
            }
        }
    }
`;
