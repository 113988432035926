import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Fab from "@material-ui/core/Fab";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    container: {
        padding: 10,
    },
    fab: {
        position: 'fixed',
        bottom: 75,
        left: 20,
    }
}));

const FabAddBottomLeft = ({action, size}) => {
    const classes = useStyles();

    return (
        <Fab color="secondary" className={classes.fab} onClick={action} size={size}>
            <AddOutlinedIcon/>
        </Fab>
    )
};

export default FabAddBottomLeft;
