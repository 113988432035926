import React, {useEffect, useState} from "react";
import EmptyList from "../../EmptyList";
import {ButtonGroup, makeStyles} from "@material-ui/core";
import UnavailableSupplierDialog from "../UnavailableSupplierDialog/index"
import Grid from "@material-ui/core/Grid";
import FastfoodOutlinedIcon from '@material-ui/icons/FastfoodOutlined';
import {FormattedMessage, useIntl} from "react-intl";
import ProductCard from "../../Products/ProductCard";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
// import ShoppingListProductFilters from "../ShoppingListProductFilters/index"
import FloatingCart from "../../Cart/FloatingCart";
import SearchResults from "react-filter-search";
import {AutoSizer, CellMeasurer, CellMeasurerCache, List} from "react-virtualized";
import {filter, findIndex, indexOf, isEmpty, reduce} from 'lodash';
import Button from "@material-ui/core/Button";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {RESTAURANTS} from "../../../constants/Routes";
// import categories from "../../Products/categories";
import FetchingError from "../../Errors/FetchingError";
import amplitudeClient from "../../../amplitudeWrapper";
import SupplierProductsList from "../SupplierProductsList";
import {PROFESSIONAL} from "../../../constants/UserTypes";
import NoDeliveryNotification from "../NoDeliveryNotification";
import {CartSummary} from "../../Cart/CartSummary";
import {cloneDeep} from "@apollo/client/utilities/common/cloneDeep";
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import GraphQlFetchData from "./graphQlFetchData";
import {useShoppingCart} from "../ShoppingCart/hook";

const useStyles = makeStyles(theme => ({
    container: {
        height: 'calc(100vh - 77px)'
    },
    containerMinNotReach: {
        height: 'calc(100vh - 116px)'
    },
    withFloatingCart: {
        paddingBottom: 64
    },
    innerGrid: {
        padding: '5px 10px',
    },
    search: {
        flexGrow: 1
    },
    topContainer: {
        padding: 10,
        boxShadow: '0px 8px 8px 0px rgba(0,0,0, 0.1)',
        zIndex: 1100,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
    },
    productsBox: {
        flex: '1 1 auto',
    },
    categoryFilterProduct: {
        width: '100%',
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    closeEditButton: {
        marginTop: 7,
        borderRadius: 0,
        padding: 8
    },
    alertDialogHeader: {
        backgroundColor: theme.palette.error.main,
        color: 'white',
        textAlign: 'center',
        fontSize: '1rem'
    },
    alertDialogIcon: {
        fontSize: '2rem'
    },
    alertDialogText: {
        fontSize: '0.9rem',
        textAlign: 'center',
        marginTop: 15,
        fontWeight: 600
    },
    link: {
        marginTop: 30,
        display: 'block'
    },
    actionButtons: {
        fontSize: '0.65rem'
    },
    deleteProductsButton: {
        backgroundColor: theme.palette.error.main,
        margin: '0px 15px 5px 15px'
    },
    confirmDeleteButton: {
        backgroundColor: theme.palette.error.main,
        fontSize: '0.8rem'
    },
    removeItemsDialogConfirmPaper: {
        marginRight: 10,
        marginLeft: 10
    },
    confirmDeleteActions: {
        justifyContent: 'center'
    }
}));

//@TODO: too many rerender, make better conditions adn state management to reduce rerenders
//@TODO: reorganise state management instructions
const SupplierShoppingList = ({
                                  updateTopNavTitle,
                                  updateTopNavSubtitle,
                                  push,
                                  restaurantId,
                                  supplierId,
                                  setActionsComponent,
                                  displayGoBack,
                                  denyAccessToUnauthenticatedUser,
                                  triggerNotification,
                                  locale,
                                  promotionsDisplayRequested
                              }) => {


        const classes = useStyles();
        const intl = useIntl();

        const [selectedProducts, setSelectedProducts] = useState([]);
        const [openDeleteProductDialogConfirmation, setOpenDeleteProductDialogConfirmation] = useState(false);
        const [searchValue, setSearchValue] = useState('');
        const [openMercurialState, setOpenMercurialState] = useState(false);
        const [promotionClosed, setPromotionsClosed] = useState(false);
        //@TODO: put this state in Redux ??
        const [editMod, setEditMod] = useState(false);
        const [alertDialogState, setAlertDialogState] = useState(true);
        const [checked, setChecked] = React.useState(null);
        const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

        const toggleSelectProduct = (shoppingListItemId) => {
            const index = selectedProducts.findIndex(el => el === shoppingListItemId);

            if (index === -1) {
                setSelectedProducts(prevState => {
                    prevState.push(parseInt(shoppingListItemId));
                    return cloneDeep(prevState);
                });
            } else {
                setSelectedProducts(prevState => {
                    prevState.splice(index, 1);
                    return cloneDeep(prevState);
                });
            }
        }

        const toggleMercurialDialog = () => {
            setOpenMercurialState(!openMercurialState);
            if (promotionsDisplayRequested && !promotionClosed) {
                setPromotionsClosed(true);
            }
        };

        //If user clicked on "see promo" in supplier card, a query param is passed in the url,
        //We need to auto open mercurial in this case
        if (promotionsDisplayRequested && !openMercurialState && !promotionClosed) {
            setOpenMercurialState(true);
        }

        const decodedRestaurantId = window.atob(restaurantId);
        const restaurantSegments = decodedRestaurantId.split('/');
        const restaurantTrueId = restaurantSegments.pop();

        const decodedSupplierId = window.atob(supplierId);
        const supplierSegments = decodedSupplierId.split('/');
        const supplierTrueId = supplierSegments.pop();

        useEffect(() => {
            (async () => {
                await denyAccessToUnauthenticatedUser();
                await displayGoBack(`/restaurants/${restaurantId}/suppliers`);
                // await setCustomComponent();

            })()
        }, []);

        const {
            removeProductsFromList,
            deleteLoading,
            //supplier products query
            mercurialData,
            mercurialLoading,
            mercuarialError,
            //restaurant query
            restaurantError,
            restaurantData,
            restaurantLoading,
            //supplier query
            supplierError, supplierData, supplierLoading,
            //shopping list
            shoppingListError, shoppingListRefetch, shoppingListData, shoppingListLoading, data,
            //create shopping list mutation
            createShoppingList, mutationLoading,
        } = GraphQlFetchData({
            updateTopNavTitle,
            updateTopNavSubtitle,
            restaurantId,
            supplierId,
            restaurantTrueId,
            supplierTrueId,
            decodedRestaurantId,
            selectedProducts, setSelectedProducts
        })
        if (restaurantLoading) updateTopNavTitle("isLoading")
        if (supplierLoading) updateTopNavSubtitle("isLoading")
        //-------

        useEffect(() => {
            if (supplierData) {
                setActionsComponent({
                    data: supplierData?.supplier || [],
                    actionFunction: () => {
                        amplitudeClient.logEvent('chat displayed', {origin: 'supplier mercurial'});
                        push(`${RESTAURANTS}/${restaurantId}/suppliers/${supplierId}/chat`, {modal: true})

                    }
                })
            }
        }, [supplierData]);

        const {shoppingCart, addToShoppingCart, removeFromShoppingCart} = useShoppingCart(undefined, parseInt(restaurantTrueId), parseInt(supplierTrueId));

        if (!shoppingCart) {
            return <></>;
        }

        const removeSelectedProductsFromList = () => {
            removeProductsFromList({
                variables: {
                    listId: shoppingListData.supplierShoppingList._id,
                    itemIds: selectedProducts
                }
            }).then((response) => {
                setOpenDeleteProductDialogConfirmation(false);
            }).catch(e => console.log(e))
        }

        const switchToEditMod = () => {
            setEditMod(true);
            amplitudeClient.logEvent('edit mod entered')
        }

        //---------------------

        const closeAlertDialog = () => {
            setAlertDialogState(false);
        };

        //virtualized rendering cache
        let cache = new CellMeasurerCache({
            fixedWidth: true,
        });

        const handleChange = event => {
            const {value} = event.target;
            setSearchValue(value);
        };

        const searchPlaceholder = intl.formatMessage(
            {
                id: 'supplierShop.searchPlaceholder',
                defaultMessage: 'Recherche'
            }
        );

        //------ Processing categories filters------------------------

        //@TODO: make a component ??
        /*        if (restaurantData) {
                    const categoriesList = reduce(categories[restaurantData.restaurant.address.country], (result, value) => {
                        (result).push(value.id);
                        return result;
                    }, []);

                    if (!checked) {
                        setChecked(categoriesList);
                    }
                }*/


        let availableCategories = [];
        if (data !== undefined && data.suppliers !== undefined) {
            data.suppliers.edges.forEach((item, key) => {

                if (indexOf(availableCategories, ...(item.node.categories)) === -1) {
                    availableCategories.push(...item.node.categories);
                }
            });
        }

        const handleToggle = value => () => {
            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];

            if (currentIndex === -1) {
                amplitudeClient.logEvent('product category filter set');
                newChecked.push(value);
            } else {
                amplitudeClient.logEvent('product category filter unset');
                newChecked.splice(currentIndex, 1);
            }

            setChecked(newChecked);
        };

        const toggleFilterDrawer = () => {
            if (filterDrawerOpen) {
                setFilterDrawerOpen(false);
            } else {
                amplitudeClient.logEvent('product filters displayed');
                setFilterDrawerOpen(true);
            }
        };

        const getFilteredProducts = () => {
            return shoppingListData ? shoppingListData.supplierShoppingList.items.edges : [];
        };

        const refetchProducts = () => {
            shoppingListRefetch().then(null).catch(e => null)
        };

        if (shoppingListError || restaurantError || supplierError || mercuarialError) {
            return (
                <FetchingError action={refetchProducts}/>
            );
        }

        //-------------- Skeleton render while fetching data --------
        if (shoppingListLoading || mutationLoading || mercurialLoading) {
            const skeleton = [0, 1, 2, 3, 4, 5, 6];
            return (
                <Grid container justify={"center"} className={classes.container}>
                    {skeleton.map((item, key) => (
                        <Grid item xs={12} key={key} className={classes.innerGrid}>
                            <ProductCard/>
                        </Grid>
                    ))}
                </Grid>
            );
        }

        let createProduct = null;

        if (shoppingListData && restaurantData && supplierData && !mutationLoading) {
            if (shoppingListData?.supplierShoppingList?._id === undefined) {
                createShoppingList({
                    variables: {
                        restaurantId: parseInt(restaurantTrueId),
                        supplierId: parseInt(supplierTrueId),
                        listName: `${restaurantData.restaurant.name} - ${supplierData.supplier.name}`
                    }
                }).then().catch(e => console.log(e))
            }

            createProduct = () => {
                amplitudeClient.logEvent('add custom product clicked');
                push(`/restaurants/${restaurantId}/suppliers/${supplierId}/add-product/${shoppingListData.supplierShoppingList._id}`, {modal: true});
            };
        }

        const isAvailable = () => {
            switch (supplierData?.supplier?.accountStatus) {
                case 'OPEN':
                    return true;
                default:
                    return false;
            }
        }

        const isDeliveryAvailable = () => {
            if (supplierData?.supplier?.createdByUser) {
                return true;
            } else {
                return !isEmpty(supplierData?.supplier?.deliveryDates);
            }
        }

        return (
            <>
                {supplierData && supplierData.supplier && !isDeliveryAvailable() && (
                    <NoDeliveryNotification supplier={supplierData.supplier}/>
                )}
                <UnavailableSupplierDialog alertDialogState={alertDialogState} closeAlertDialog={closeAlertDialog}
                                           supplierData={supplierData} isAvailable={isAvailable}/>
                {/*  <ShoppingListProductFilters handleToggle={handleToggle} availableCategories={availableCategories}
                                            categories={categories} toggleFilterDrawer={toggleFilterDrawer}
                                            checked={checked} country={restaurantData.restaurant.address.country}
                                            filterDrawerOpen={filterDrawerOpen} restaurantData={restaurantData}/>*/}
                {shoppingListData && restaurantData && supplierData &&
                    <SupplierProductsList shoppingList={shoppingListData} supplier={supplierData.supplier}
                                          restaurant={restaurantData.restaurant} openState={openMercurialState}
                                          close={toggleMercurialDialog} triggerNotification={triggerNotification}
                                          createProduct={createProduct} locale={locale}/>}
                {shoppingListData?.supplierShoppingList?.items?.edges?.length && getFilteredProducts() !== undefined ? (
                        <Grid container direction="column"
                              className={`${supplierData.supplier.minOrderAmount > shoppingCart.totalWithoutVat ? classes.containerMinNotReach : classes.container} ${shoppingCart.items.edges.length > 0 && !editMod ? (classes.withFloatingCart) : null}`}
                              style={editMod ? {height: 'calc(100vh - 108px)'} : null}>
                            <Grid container alignItems="center" className={classes.topContainer}>
                                <Grid container wrap="nowrap" alignItems="center" justify="center">
                                    <ButtonGroup size="small" aria-label="small outlined button group">
                                        <Button size="small" color="primary" onClick={switchToEditMod}
                                                className={classes.actionButtons}
                                                startIcon={<EditOutlinedIcon/>} variant="contained">
                                            <FormattedMessage id="supplierShop.modifyShoppingList"
                                                              defaultMessage="Modifier ma liste"/>
                                        </Button>
                                        <Button size="small" color="secondary" onClick={toggleMercurialDialog}
                                                className={classes.actionButtons}
                                                startIcon={<AddOutlinedIcon/>} variant="contained">
                                            <FormattedMessage id="supplierShop.addProductToShoppingList"
                                                              defaultMessage="Ajouter un produit"/>
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.productsBox}>
                                <SearchResults
                                    value={searchValue}
                                    data={getFilteredProducts()}
                                    renderResults={results =>
                                        (<>
                                            <AutoSizer>
                                                {({height, width}) => (
                                                    <List width={width}
                                                          height={height}
                                                          rowCount={results.length}
                                                          rowHeight={cache.rowHeight}
                                                          rowRenderer={({
                                                                            key,
                                                                            index,
                                                                            parent,
                                                                            isScrolling,
                                                                            isVisible,
                                                                            style
                                                                        }) => {
                                                              return (
                                                                  <CellMeasurer
                                                                      cache={cache}
                                                                      columnIndex={0}
                                                                      key={key}
                                                                      parent={parent}
                                                                      rowIndex={index}
                                                                  >
                                                                      <Grid item xs={12} key={index} style={style}
                                                                            className={classes.innerGrid}>
                                                                          <ProductCard
                                                                              currency={supplierData?.supplier?.currency}
                                                                              product={results[index].node.offer || results[index].node}
                                                                              editMod={editMod}
                                                                              push={push}
                                                                              addToCart={addToShoppingCart}
                                                                              subtractFromCart={removeFromShoppingCart}
                                                                              supplierId={supplierTrueId}
                                                                              restaurantId={restaurantTrueId}
                                                                              supplierIRI={supplierId}
                                                                              restaurantIRI={restaurantId}
                                                                              cartQuantity={shoppingCart.items.edges.find(item => item.node.offer?._id === results[index].node.offer?._id)?.node.quantity ?? 0}
                                                                              triggerNotification={triggerNotification}
                                                                              supplierActive={isAvailable()}
                                                                              setProductQuantity={addToShoppingCart}
                                                                              cartSummary={false}
                                                                              toggleMercurialDialog={toggleMercurialDialog}
                                                                              unavailable={!isDeliveryAvailable() || results[index].node.offer === null}
                                                                              userType={PROFESSIONAL}
                                                                              locale={locale}
                                                                              toggleSelectProduct={() => toggleSelectProduct(results[index].node._id)}
                                                                              selectedProducts={selectedProducts}
                                                                              shoppingListItemId={results[index].node._id}
                                                                              shoppingCartId={shoppingCart._id}
                                                                              shoppingCartItemId={shoppingCart.items.edges.find(item => item.node.offer?._id === results[index].node.offer?._id)?.node._id ?? undefined}
                                                                          />
                                                                      </Grid>
                                                                  </CellMeasurer>
                                                              )
                                                          }}
                                                          overscanRowCount={4}
                                                    />)}
                                            </AutoSizer>
                                        </>)}
                                />
                            </Grid>
                            {editMod ? (
                                <>
                                    {selectedProducts.length > 0 &&
                                        <Button onClick={() => setOpenDeleteProductDialogConfirmation(true)} size="small"
                                                variant="contained" startIcon={<DeleteIcon/>}
                                                className={classes.deleteProductsButton}>
                                            <FormattedMessage id="supplierShop.deleteBatch"
                                                              defaultMessage="Supprimer les produits sélectionnés"/>
                                        </Button>
                                    }
                                    <Button onClick={() => setEditMod(false)} size="small" color="primary"
                                            className={classes.closeEditButton} variant="contained">
                                        <FormattedMessage id="supplierShop.closeEditMod" defaultMessage="Terminer l'édition"/>
                                    </Button>
                                </>
                            ) : (
                                <FloatingCart push={push}
                                              cart={shoppingCart}
                                              orderMinAmount={supplierData?.supplier?.minOrderAmount}
                                              orderUnit={supplierData?.supplier?.orderUnit}
                                              currency={supplierData?.supplier?.currency}
                                              locale={locale}/>
                            )}
                        </Grid>
                    ) :
                    (<EmptyList icon={FastfoodOutlinedIcon} heading={<FormattedMessage id="supplierShop.emptyList"
                                                                                       defaultMessage="Votre liste d'achat est vide !"/>}
                                text={<FormattedMessage id="supplierShop.emptyListText"
                                                        defaultMessage="Pour ajouter des produit à votre liste et pouvoir passer commande, cliquez sur le bouton ci dessous."/>}
                                action={toggleMercurialDialog}/>)}
                <Dialog
                    open={openDeleteProductDialogConfirmation}
                    onClose={() => setOpenDeleteProductDialogConfirmation(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{className: classes.removeItemsDialogConfirmPaper}}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" align="center">
                            <FormattedMessage id="supplierShop.deleteBatchButtonText"
                                              defaultMessage="Êtes-vous sûr de vouloir supprimer ces produits de votre liste d'achat ?"/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.confirmDeleteActions}>
                        <Button onClick={removeSelectedProductsFromList} variant="contained"
                                className={classes.confirmDeleteButton} disabled={deleteLoading}>
                            <FormattedMessage id="supplierShop.deleteBatchButton" defaultMessage="Supprimer les produits"/>
                        </Button>
                        <Button autoFocus variant="outlined" onClick={() => setOpenDeleteProductDialogConfirmation(false)}
                                disabled={deleteLoading}>
                            <FormattedMessage id="supplierShop.deleteBatchButtonCancel" defaultMessage="Annuler"/>
                        </Button>
                    </DialogActions>
                    {deleteLoading ? <LinearProgress/> : null}
                </Dialog>
            </>
        );
    }
;

export default SupplierShoppingList;
