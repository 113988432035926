import {useIntl} from "react-intl";

export function Hooks() {
    const deliveryIntervalLabel = useIntl().formatMessage({
        id: 'shippingDetailsForm.deliveryIntervalLabel',
        defaultMessage: 'Créneau de livraison souhaité'
    });

    const deliveryIntervalStartTexts = {
        label: useIntl().formatMessage({
            id: 'shippingDetailsForm.deliveryIntervalStartLabel',
            defaultMessage: 'De',
        }),
        placeholder: useIntl().formatMessage({
            id: 'shippingDetailsForm.selectDeliveryInterval',
            defaultMessage: 'Veuilliez sélectionner un créneau de livraison'
        }),
        error: useIntl().formatMessage({
            id: 'shippingDetailsForm.deliveryIntervalStartError',
            defaultMessage: 'Le créneau de livraison n\'est pas valide'
        }),
    };

    const deliveryDayTexts = {
        label: useIntl().formatMessage({
            id: 'shippingDetailsForm.deliveryDateLabel',
            defaultMessage: 'Date de livraison souhaité',
        }),
        placeholder: useIntl().formatMessage({
            id: 'shippingDetailsForm.deliveryDatePlaceholder',
            defaultMessage: 'Veuilliez sélectionner une date de livraison'
        }),
        error: useIntl().formatMessage({
            id: 'shippingDetailsForm.deliveryDateError',
            defaultMessage: 'La date de livraison n\'est pas valide'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'shippingDetailsForm.deliveryDateErrorRequired',
            defaultMessage: 'La date de livraison est requise'
        }),
    };

    const deliveryIntervalEndTexts = {
        label: useIntl().formatMessage({
            id: 'shippingDetailsForm.deliveryIntervalEndLabel',
            defaultMessage: 'À',
        }),
        placeholder: useIntl().formatMessage({
            id: 'shippingDetailsForm.deliveryIntervalStartPlaceholder',
            defaultMessage: 'Veuilliez sélectionner un créneau de livraison'
        }),
        error: useIntl().formatMessage({
            id: 'shippingDetailsForm.deliveryIntervalEndError',
            defaultMessage: 'Le créneau de livraison n\'est pas valide'
        }),
    };

    const complementaryInstructionsTexts = {
        label: useIntl().formatMessage({
            id: 'shippingDetailsForm.complementaryInstructionsLabel',
            defaultMessage: 'Instructions complémentaires',
        }),
        placeholder: useIntl().formatMessage({
            id: 'shippingDetailsForm.complementaryInstructionsPlaceholder',
            defaultMessage: 'Rédiger ici les instructions complémentaire pour la livraison'
        }),
        helperText: useIntl().formatMessage({
            id: 'shippingDetailsForm.complementaryInstructionsHelper',
            defaultMessage: 'Vous pouvez précisez ici des consignes ou information supplémentaires...'
        }),
    };

    return ({
        deliveryIntervalLabel,
        deliveryIntervalStartTexts,
        complementaryInstructionsTexts,
        deliveryIntervalEndTexts,
        deliveryDayTexts
    })
}