import {gql} from "apollo-boost";

export const GET_RESTAURANT_PROMOTIONS = gql`
    query getPromotions($restaurantId: Int!) {
        offers(
            first: 1000
            restaurantId: $restaurantId
            filters: {
                promo: true
            }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    _id
                    supplier {
                        _id,
                        name
                    }
                    name
                    image {
                        medium
                        large
                    }
                    unit
                    priceWithoutVat
                    content
                    frozen
                    priceDetailsWithoutVat
                    createdByUser
                    origin
                    organic
                    promotion
                    weight
                }
            }
        }
    }
`;