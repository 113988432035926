import React from "react";

import {useQuery} from "@apollo/react-hooks";
import {GET_SUPPLIERS_QUERY} from "./queries";


const GraphQlFetchData = () => {


    const {data, loading, error} = useQuery(GET_SUPPLIERS_QUERY);


    return (
        {data, loading, error}
    );
};

export default GraphQlFetchData;
