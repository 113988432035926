import React from "react";

import {useMutation, useQuery} from '@apollo/react-hooks';
import {GET_RESTAURANTS_QUERY} from "../RestaurantsList/queries";
import {ADD_RESTAURANT_MUTATION} from "./mutations";
import {GET_RESTAURANT_TYPES_QUERY} from "./queries";


const GraphQlFetchData = (locale) => {

    const language = {
        en: 'en_GB',
        fr: 'fr_FR',
        es: 'es_ES',
    };
    const [addRestaurant, {loading: mutationLoading}] = useMutation(ADD_RESTAURANT_MUTATION, {
        update(cache, mutationData) {
            try {
                //Adding the new restaurant to the cache

                //Retrieving current restaurants list from cache
                const {restaurants} = cache.readQuery({query: GET_RESTAURANTS_QUERY});
                const newRestaurant = {node: mutationData.data.createRestaurant.restaurant}
                //Adding new restaurant to the list
                cache.writeQuery({
                    query: GET_RESTAURANTS_QUERY,
                    data: {restaurants: {
                            edges: restaurants.edges.concat(newRestaurant),
                            pageInfo: restaurants.pageInfo,
                            totalCount: restaurants.totalCount
                        }},
                });
            } catch (e) {
                //In this case, the cache is empty, it will be loaded from the API when redirecting to the restaurants list, so we do nothing here
            }
        }
    });

    const {loading: restaurantTypesLoading, error: restaurantTypesError , data: restaurantTypesData} = useQuery(GET_RESTAURANT_TYPES_QUERY, {
        variables: {locale: language[locale] ?? 'fr_FR'},
        errorPolicy: 'all'
    });


    return (
        {addRestaurant, mutationLoading, restaurantTypesLoading, restaurantTypesData, restaurantTypesError}

    );
};
export default GraphQlFetchData;
