import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Fab from "@material-ui/core/Fab";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.primary.main,
        height: 100,
        width: '100%',
        marginBottom: 80,
    },
    iconContainer: {
        borderRadius: 120,
        height: 120,
        width: 120,
        backgroundColor: 'white',
        marginTop: 40,
    },
    iconWrapper: {
        height: '100%',
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: '5rem'
    },
    fab: {
        marginTop: 30,
    },
    text: {
        padding: 10,
    }
}));

const EmptyList = ({icon, heading, text, action}) => {
    const classes = useStyles();
    const ListIcon = icon;

    return (
        <>
            <Grid item container className={classes.header} justify={"center"}>
                <Grid item container justify={"center"} alignItems={"center"}>
                    <Paper className={classes.iconContainer}>
                        <Grid container justify={"center"} alignItems={"center"} className={classes.iconWrapper}>
                            <Grid item>
                                <ListIcon className={classes.icon}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="h6" gutterBottom align={"center"}>
                    {heading}
                </Typography>
                <Typography variant={"body1"} gutterBottom align={"center"} className={classes.text}>
                    {text}
                </Typography>
            </Grid>
            <Grid item container justify={"center"}>
                {action ? (<Fab color={"secondary"} className={classes.fab} onClick={action}>
                    <AddOutlinedIcon/>
                </Fab>) : null}
            </Grid>
        </>
    );
};

export default EmptyList;