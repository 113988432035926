import Drawer from "@material-ui/core/Drawer";
import ListMui from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import {FormattedMessage} from "react-intl";
import {indexOf} from "lodash";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    categoryFilterProduct: {
        width: '100%',
    },
}));

const SupplierProductListFilter = ({
                                       filterDrawerOpen,
                                       toggleFilterDrawer,
                                       restaurant,
                                       categories,
                                       handleToggle,
                                       availableCategories,
                                       checked,
                                       setUsePromotionOnly,
                                       usePromotionOnly
                                   }) => {
    const classes = useStyles();
    return (
        <>
            <Drawer anchor="bottom" open={filterDrawerOpen} onClose={toggleFilterDrawer}>
                <ListMui className={classes.categoryFilterProduct}
                         subheader={
                             <ListSubheader component="div" id="nested-list-subheader">
                                 <FormattedMessage id="supplierProductList.filterHeading"
                                                   defaultMessage="Filtrer les catégories"/>
                             </ListSubheader>
                         }>
                    {categories[restaurant.address.country].map(category => {
                        if (indexOf(availableCategories, category.id) !== -1) {
                            const labelId = `checkbox-list-label-${category.id}`;
                            return (
                                <ListItem key={category.id} role={undefined} dense button
                                          onClick={handleToggle(category.id)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            color="primary"
                                            edge="start"
                                            checked={checked.indexOf(category.id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{'aria-labelledby': labelId}}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={category.name}/>
                                </ListItem>
                            );
                        } else {
                            return null;
                        }
                    })}
                    <ListItem key={-42} role={undefined} dense button
                              onClick={() => {setUsePromotionOnly(!usePromotionOnly)}}>
                        <ListItemIcon>
                            <Checkbox
                                color="primary"
                                edge="start"
                                checked={usePromotionOnly}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{'aria-labelledby': 'promotions'}}
                            />
                        </ListItemIcon>
                        <ListItemText id={'promotions'} primary={<FormattedMessage id="supplierProductList.promo" defaultMessage="Promotions"/>}/>
                    </ListItem>
                </ListMui>
                <Button color="primary" onClick={toggleFilterDrawer}>
                    <FormattedMessage id="supplierProductList.close" defaultMessage="Fermer"/>
                </Button>
            </Drawer>
        </>
    )
}
export default SupplierProductListFilter