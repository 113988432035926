import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import drawerReducer from "./drawer";
import topNavigation from "./topNavigation";
import toastNotification from './toastNotification';
import overlay from './overlay';
import authentication from './authentication';
import carts from './carts';
import updater from './updater';
import messages from './messages';
import support from './support';
import restaurants from './restaurants';

const creatRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    drawerState: drawerReducer,
    topNavigation: topNavigation,
    toastNotification: toastNotification,
    overlay: overlay,
    authentication: authentication,
    carts: carts,
    updater: updater,
    messages: messages,
    support: support,
    restaurants: restaurants
});

export default creatRootReducer;
