import {gql} from "apollo-boost";

export const CREATE_CLAIM_MUTATION = gql`
    mutation createClaim(
        $restaurantId: Int!, 
        $supplierId: Int!, 
        $problem: String!,
        $callbackRequired: Boolean!, 
        $message: ClaimCommentInput!,
        $details: String!
    ) {
        createClaim(
            input:{
                claim: {
                    callbackRequired: $callbackRequired,
                    comment: $message,
                    problem: $problem,
                    details: $details
                },
                restaurantId: $restaurantId, 
                supplierId: $supplierId,
            }) {
            claim {
                id
            }
        }
    }
`;
