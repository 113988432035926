import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Badge from "@material-ui/core/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentDots} from "@fortawesome/free-regular-svg-icons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";


export const UseIcons = ({

                             ActionsComponent,
                             onEdit,
                             triggerNotification

                         }) => {

const handleMsgIcon = () => {
    if(ActionsComponent)
    ActionsComponent.actionFunction();
    else triggerNotification({
        variant: 'error',
        message: 'Action can not be performed !'
    })
}
    const msgIcon = () => {
        return (
            <IconButton onClick={handleMsgIcon}>
                {ActionsComponent && ActionsComponent?.data?.nbUnreadMessages ? (
                    <Badge badgeContent={ActionsComponent?.data.nbUnreadMessages} color="secondary">
                        <FontAwesomeIcon icon={faCommentDots}/>
                    </Badge>
                ) : (
                    <FontAwesomeIcon icon={faCommentDots}/>
                )}
            </IconButton>
        )
    }
    const editIcon = () => {
        return (
            <IconButton style={ActionsComponent.data == true ? {display: 'none'} : {display: 'block'}}
                        onClick={onEdit}>
                <EditOutlinedIcon/>
            </IconButton>
        )
    }
    return (
        {
            editIcon,
            msgIcon
        }
    )
};


