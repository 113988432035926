import {gql} from "apollo-boost";

export const EDIT_PRODUCT_MUTATION = gql`
    mutation editProduct($productId: String!, $restaurantId: String!, $supplierId: String!, $name: String!, $unit: String!, $categoryId: Int!, $code: String) {
        editProduct(input:{productId: $productId, restaurantId: $restaurantId, supplierId: $supplierId, name: $name, unit: $unit, categoryId: $categoryId, code: $code}) {
            product{
                id
                _id
                supplierId
                mainCategory
                name
                image
                imageLarge
                unit
                price
                explicitContent
                frozen
                priceDetail
                createdByUser
                origin
                bio
                promotion
            }
        }
    }
`;
