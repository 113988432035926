import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {updateTitle} from "../../reducers/topNavigation";
import {push} from "connected-react-router";
import EditUserInfoForm from "../../components/UserAccount/EditUserInfoForm";

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
    updateTopNavTitle: (title) => dispatch(updateTitle(title)),
    push: bindActionCreators(push, dispatch),
});

const EditUserInfoPage = connect(mapStateToProps, mapDispatchToProps)(EditUserInfoForm);

export default EditUserInfoPage;
