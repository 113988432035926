import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    selectedRestaurantId: '',
};

const restaurantsSlice = createSlice({
    name: 'restaurants',
    initialState: initialState,
    reducers: {
        setSelectedRestaurant(state, action) {
            state.selectedRestaurantId = action.payload.restaurantId;
        },
    }
});

export const {setSelectedRestaurant} = restaurantsSlice.actions;
export default restaurantsSlice.reducer;
