import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import {FormattedMessage} from "react-intl";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Link from "@material-ui/core/Link";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    alertDialogIcon: {
        fontSize: '2rem'
    },
    alertDialogText: {
        fontSize: '0.9rem',
        textAlign: 'center',
        marginTop: 15,
        fontWeight: 600
    },
    link: {
        marginTop: 30,
        display: 'block'
    },
    alertDialogHeader: {
        backgroundColor: theme.palette.error.main,
        color: 'white',
        textAlign: 'center',
        fontSize: '1rem'
    },
}));

const UnavailableSupplierDialog = ({alertDialogState, closeAlertDialog, supplierData, isAvailable}) => {
    const classes = useStyles();

    return (
        <>

            {supplierData && !isAvailable() ? (
                <Dialog open={alertDialogState} onClose={closeAlertDialog}>
                    <DialogTitle id="alert-dialog-title" className={classes.alertDialogHeader}
                                 disableTypography>
                        <Grid container justify="center" direction="column">
                            <Grid item>
                                <ErrorOutlineOutlinedIcon className={classes.alertDialogIcon}/>
                            </Grid>
                            <Grid item>
                                <FormattedMessage
                                    id="alertSupplierAccount.heading"
                                    defaultMessage="Compte fournisseur inactif"/>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" className={classes.alertDialogText}>
                            <FormattedMessage
                                id="alertSupplierAccount.textTop"
                                defaultMessage="Votre compte founisseur n'a pas encore été validé. Pour plus d'information rendez-vous sur : "/>
                            <Link href="https://www.foodomarket.com/shop/mes-fournisseurs" className={classes.link}
                                  color="secondary">
                                <FormattedMessage
                                    id="alertMinOrderAmount.fournisseurs"
                                    defaultMessage="Mes fournisseurs"
                                />
                            </Link>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={closeAlertDialog}>
                            <FormattedMessage
                                id="alertMinOrderAmount.text"
                                defaultMessage="Fermer"
                            />
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : null}


        </>);
};
export default UnavailableSupplierDialog