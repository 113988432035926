import React from "react";

import {useMutation} from "@apollo/react-hooks";
import {CREATE_NEW_SUPPLIER_MUTATION} from "./mutations";
import {GET_RESTAURANT_SUPPLIERS_QUERY} from "../SuppliersList/queries";


const GraphQlFetchData = ({id}) => {


    let [createSupplier, {loading: mutationLoading}] = useMutation(CREATE_NEW_SUPPLIER_MUTATION, {
        update(cache, mutationData) {
            try {
                //--Adding the new supplier to the cache--//

                //Retrieving current restaurants list from cache
                const {restaurantSuppliers} = cache.readQuery({
                    query: GET_RESTAURANT_SUPPLIERS_QUERY,
                    variables: {restaurantId: id}
                });

                //Adding new restaurant to the list
                cache.writeQuery({
                    query: GET_RESTAURANT_SUPPLIERS_QUERY,
                    variables: {restaurantId: id},
                    data: {restaurantSuppliers: restaurantSuppliers.concat(mutationData.data.createSupplier.supplier)},
                });
            } catch (e) {
                //In this case, the cache is empty, it will be loaded from the API when redirecting to the restaurants list, so we do nothing here
            }
        }
    });


    return (
        {createSupplier, mutationLoading}
    );
};

export default GraphQlFetchData;