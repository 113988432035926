const supplierProductCategories = {
    'BOUCHERIE': {
        icon: 'meat.png',
        name: {fr: 'Boucherie', en: 'Butchery'}
    },
    CHARCUTERIE: {
        icon: 'delicatessen.png',
        name: {fr: 'Charcuterie', en: 'Delicatessen'}
    },
    'CREMERIE': {
        icon: 'dairy.png',
        name: {fr: 'Crémerie', en: 'Dairies'}
    },
    'FRUITS_LEGUMES': {
        icon: 'vegetables.png',
        name: {fr: 'Fruits & Légumes', en: 'Fruits & Vegetables'},
    },
    'EPICERIE': {
        icon: 'grocery.png',
        name: {fr: 'Épicerie Salée', en: 'Grocery'},
    },
    'CAVE': {
        icon: 'drinks.png',
        name: {fr: 'Boissons', en: 'Drink'},
    },
    'MAREE': {
        icon: 'seefood.png',
        name: {fr: 'Marée', en: 'Seafood'},
    },
    'TRAITEUR': {
        icon: 'catering.png',
        name: {fr: 'Traiteur', en: 'Catering'},
    },
    'CONSOMMABLES': {
        icon: 'packaging.png',
        name: {fr: 'Consommables', en: 'Consumables'},
    }
};

export default supplierProductCategories;