import React from "react";

import {useQuery} from "@apollo/react-hooks";
import {GET_MESSAGES_QUERY} from "./queries";
import {GET_SUPPLIER_INFO_QUERY} from "../../Suppliers/SuppliersList/queries";


const GraphQlFetchData = ({supplierTrueId, decodedSupplierId, updateOverlayTitle, pageTitle}) => {


    const {
        loading: messagesLoading,
        error: messagesError,
        data: messagesData,
        refetch: messageRefetch
    } = useQuery(GET_MESSAGES_QUERY, {
        variables: {supplierId: supplierTrueId},
        pollInterval: 5000
    });

    const {
        data: supplierInfoData,
        error: supplierInfoError,
        loading: supplierInfoLoading
    } = useQuery(GET_SUPPLIER_INFO_QUERY, {
        variables: {id: supplierTrueId}, onCompleted: (res) => {
            if (res) updateOverlayTitle(res.supplier.name)
            else updateOverlayTitle(pageTitle)
        }
    });


    return (
        {supplierInfoLoading, supplierInfoError, messagesLoading, messagesError, messagesData, messageRefetch}
    );
};

export default GraphQlFetchData;
