import {useIntl} from 'react-intl';

function Hooks() {
    const pageTitle = useIntl().formatMessage(
        {
            id: 'addSupplierPage.title',
            defaultMessage: 'Ajouter un fournisseur'
        }
    );

    const searchText = useIntl().formatMessage(
        {
            id: 'addSupplierPage.placeholder',
            defaultMessage: 'Rechercher des fournisseurs',
        }
    );

    const emptyListMessage = useIntl().formatMessage(
        {
            id: 'addSupplierPage.emptyMessage',
            defaultMessage: 'Utilisez la bar de recherche ci-dessus pour trouver vos fournisseurs.'
        }
    );

    const createNewSupplierButtonText = useIntl().formatMessage(
        {
            id: 'addSupplierPage.createNewSupplierText',
            defaultMessage: 'Mon fournisseur n\'est pas dans la liste',
        }
    );

    return({
        pageTitle, createNewSupplierButtonText, emptyListMessage, searchText
    })
}
export default Hooks
