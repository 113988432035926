import React, {useState} from 'react';
import '../../../App.css';
import {findIndex} from 'lodash';
import {FormattedMessage, useIntl} from "react-intl";
import Chip from "@material-ui/core/Chip";
import CartQuantityWidget from "../../Cart/CartQuantityWidget";
import IconButton from "@material-ui/core/IconButton";
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import StoreMallDirectoryOutlinedIcon from '@material-ui/core/Icon';
import {RESTAURANTS} from "../../../constants/Routes";
import {makeStyles} from '@material-ui/core/styles';
import RemoveProductFromListConfirmDialog from "../removeProductFromListConfirmDialog/index";
import ProductDetailsSheet from "../ProductDetailsSheet/index";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import GeneralPurposeCard from "../../GeneralPurpose/GeneralPurposeCard";
import {blueGrey} from "@material-ui/core/colors";
import amplitudeClient from "../../../amplitudeWrapper";
import {PROFESSIONAL} from "../../../constants/UserTypes";
import ReactPixel from "react-facebook-pixel";
import CardContent from "@material-ui/core/CardContent";
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import AddProductNoteDialog from "../../Cart/AddProductNoteDialog";
import AcUnitIcon from '@material-ui/icons/AcUnit';
import Checkbox from "@material-ui/core/Checkbox";
import ReactCountryFlag from "react-country-flag";
import {price} from "../../../utils/currency";
import GraphQlFetchData from "../graphQlFetchData";

//@TODO: find a way to avoid !important ??
const useStyles = makeStyles(theme => ({
    avatar: {
        marginTop: "0px",
        backgroundColor: blueGrey[100],
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: 8
    },
    name: {
        marginTop: '5px',
        fontWeight: 600,
        textTransform: 'uppercase',
        color: blueGrey[800],
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '0.9rem',
    },
    cardHeader: {
        width: '100%',
        padding: '5px 10px',
    },
    cardContent: {
        padding: '0 0 10px 0 !important',
        position: 'relative'
    },
    centerBlock: {
        flexGrow: 1,
        justifyContent: 'flex-start'
    },
    unite: {
        color: blueGrey[400],
        fontWeight: 100,
        fontSize: '0.8rem',
        marginTop: "10px",
    },
    details: {
        marginTop: '10px',
        fontSize: '0.8rem',
    },
    iconButton: {
        marginLeft: '0 !important',
        padding: 0,
        width: '100%',
        borderRadius: '0'
    },
    addIcon: {
        fontSize: '3rem',
    },
    removeIcon: {
        fontSize: '3rem'
    },
    input: {
        textAlign: 'center !important',
        fontWeight: 500,
        color: blueGrey[800],
        backgroundColor: blueGrey[50],
        borderRadius: 5,
        margigRight: "0 important!",
        marginTop: "0px"
    },
    price: {
        backgroundColor: blueGrey[100],
        fontSize: '1rem',
        marginTop: '5px',
    },
    skeletonRight: {
        marginRight: 20
    },

    skeletonLeft: {
        marginLeft: 20
    },

    notValidated: {
        filter: 'opacity(0.4)'
    },
    unavailable: {
        paddingRight: 10
    },
    priceDetails: {
        fontSize: '0.7rem'
    },
    cardActions: {

        padding: 0,
        flexGrow: 1,
        justifyContent: 'flex-end'
    },
    removeButton: {
        marginRight: 20
    },
    iconBlock: {
        display: 'flex',
        paddingLeft: 10,
        marginTop: 10,
        flexDirection: 'column',
        position: 'relative'
    },
    cardContainer: {
        borderBottom: `3px solid ${theme.palette.grey[100]}`,
    },
    actionArea: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    cartActions: {
        height: '100%',

        marginRight: 8
    },
    deleteIcon: {
        fontSize: '2rem'
    },
    color: {
        width: '100%',
        fontSize: '10px',
        borderRadius: 0,
    },
    replace: {
        color: blueGrey,
        textDecoration: "underline Grey",
        fontSize: '10px',
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
    contentDetails: {
        marginTop: 5,
        fontSize: '0.8rem'
    },
    replaceProduct: {
        fontSize: '0.7rem'
    },
    frozen: {
        marginLeft: '30px',
        marginTop: '-120px',
        zIndex: 100,
        color: '#3FD0D4'
    },
    unavailableProductBlock: {
        paddingRight: 10
    },
    card: {
        borderBottom: `5px solid ${blueGrey[100]}`
    },
    contentDescription: {
        fontSize: '0.8rem'
    },
    unavailableProductText: {
        fontSize: '0.8rem',
        marginBottom: 5
    },
    checkBox: {
        marginRight: 15
    },
    promo: {
        backgroundColor: theme.palette.promo.main,
        borderRadius: 50,
        fontSize: '0.6rem',
        color: 'white',
        fontWeight: 600,
        position: 'absolute',
        padding: '7px  5px',
    },
    origin: {
        fontSize: '0.7rem',
        fontWeight: 500,
        textTransform: 'uppercase',
        marginLeft: 5
    },
    bio: {
        backgroundColor: '#00b33c',
        borderRadius: 50,
        fontSize: '0.6rem',
        color: 'white',
        fontWeight: 600,
        position: 'absolute',
        bottom: 25,
        padding: '7px  5px'
    }
}));

//@TODO: split card in sub components (e.g. body, action...)
//@TODO: fix card action buttons on very small screen (320px)
const ProductCard = ({
                         handleClose,
                         currency,
                         push,
                         product,
                         setProductNote,
                         addToCart,
                         supplierId,
                         locale,
                         restaurantId,
                         setProductQuantity,
                         cartQuantity,
                         subtractFromCart,
                         editMod,
                         triggerNotification,
                         supplierIRI,
                         restaurantIRI,
                         supplierActive,
                         unavailable,
                         userType,
                         cartSummary,
                         productNote,
                         toggleMercurialDialog,
                         toggleSelectProduct,
                         selectedProducts,
                         shoppingListItemId,
                         shoppingCartId,
                         shoppingCartItemId
                     }) => {
    const classes = useStyles();
    const intl = useIntl();
    const {removeProductFromList, mutationLoading} = GraphQlFetchData({supplierId, restaurantId})

    const selectProduct = (e) => {
        if (e !== undefined) {
            e.preventDefault();
            e.stopPropagation();
        }
        toggleSelectProduct();
    };

    const notificationText = {
        success: intl.formatMessage(
            {
                id: 'removeProduct.SuccessNotificationText',
                defaultMessage: 'Produit retiré avec succès'
            }
        ),
        error: intl.formatMessage(
            {
                id: 'removeProduct.errorNotificationText',
                defaultMessage: 'Une erreur est survenue lors de la suppression du produit'
            }
        ),
    };

    const setProductCartQuantity = (qty) => {
        if (qty > 0) {
            if (!supplierActive) {
                triggerNotification({
                    variant: 'warning',
                    message: "Impossible d'ajouter un produit au panier car votre compte fournisseur n'est pas encore ouvert"
                });

                return ;
            }
            ReactPixel.track('AddToCart', {
                content_type: 'product',
                value: product.price * qty,
                currency: 'EUR',
                content_name: product.name,
                contents: [{
                    id: product._id,
                    quantity: product.quantity,
                    item_price: product.price
                }]
            });

            addToCart({
                variables: {
                    cartId: shoppingCartId,
                    variantId: product._id,
                    quantity: parseFloat(qty)
                }
            });

            amplitudeClient.logEvent('product added to cart');
        } else {
            amplitudeClient.logEvent('product removed from cart');

            subtractFromCart({
                variables: {
                    cartId: shoppingCartId,
                    itemId: shoppingCartItemId
                }
            });
        }
    };

    const [productDetailsState, setProductDetailsState] = useState(false);
    const [productNoteState, setProductNoteState] = useState(false);
    const [removeProductFromListDialogState, setRemoveProductFromListDialogState] = useState(false);

    const toggleProductDetailsState = () => {
        setProductDetailsState(!productDetailsState);
    };

    const toggleProductNoteDialog = (e) => {
        if (e !== undefined) {
            e.preventDefault();
            e.stopPropagation();
        }
        setProductNoteState(!productNoteState);
    };

    const toggleRemoveProductFromListDialogState = (e) => {
        if (e !== undefined) {
            e.preventDefault();
            e.stopPropagation();
        }
        setRemoveProductFromListDialogState(!removeProductFromListDialogState);
    };

    const openMercurial = () => {
        toggleMercurialDialog();
    };

    const removeProduct = () => {
        if (!mutationLoading) {
            removeProductFromList({variables: {id: product._id}})
                .then(() => {
                    amplitudeClient.logEvent('product removed from shopping list');
                    triggerNotification({variant: 'success', message: notificationText.success});
                })
                .catch((e) => {
                    console.log(e);
                    triggerNotification({variant: 'error', message: notificationText.error})
                })
        }
    };

    const toggleRemoveProductunavaible = (e) => {
        if (e !== undefined) {
            e.preventDefault();
            e.stopPropagation();
        }
        removeProduct();
        openMercurial()
    };

    return (
        <>
            {product &&
            <AddProductNoteDialog productNote={productNote} supplierId={supplierId} restaurantId={restaurantId}
                                  productId={product._id}
                                  cartItemId={shoppingCartItemId}
                                  cartQuantity={cartQuantity}
                                  setProductNote={setProductNote} openState={productNoteState}
                                  toggleOpenState={toggleProductNoteDialog}/>}
            <GeneralPurposeCard className={(product && unavailable) ? "pulse" : !cartSummary && classes.card}>
                <Grid onClick={toggleProductDetailsState}
                      className={`${!supplierActive && classes.notValidated}`}>
                    {product ?
                        <>
                            <Grid className={classes.cardHeader}>
                                <Typography className={classes.name}>{product.name}</Typography>
                            </Grid>
                            <Grid className={classes.origin}>
                                <Typography className={classes.origin}>
                                    {product && product.origin ? (
                                        <>
                                            {`Origine : ${product.origin}`} {origin.includes('France') &&
                                        <ReactCountryFlag countryCode="FR" style={{
                                            marginLeft: 4,
                                            marginBottom: 2
                                        }} svg/>}
                                        </>) : null}
                                </Typography>
                            </Grid>
                        </>
                        :
                        <Skeleton variant="text" width="90%" height={25} style={{marginLeft: 5}}/>}
                    <CardContent className={classes.cardContent}>
                        <Grid container wrap="nowrap" className={classes.content}>
                            <Grid item container xs={6}>
                                <Grid className={classes.iconBlock}>
                                    {product ? (<>
                                            {product.image ? (
                                                <Avatar className={classes.large} variant="rounded"
                                                        src={product.image.large}/>) : (
                                                <Avatar className={classes.avatar} variant="rounded">
                                                    <StoreMallDirectoryOutlinedIcon className={classes.icon}/>
                                                </Avatar>)}
                                            {product && product.organic === 'BIO' ? (
                                                <span className={classes.bio}>BIO</span>) : null}
                                            <Typography className={classes.contentDescription}
                                                        align="center">{product.content}
                                            </Typography>
                                            {product && product.frozen && product.content ? (
                                                    <span className={classes.frozen}>  <AcUnitIcon/>  </span>)
                                                : null}
                                            {product && product.promotion ? (
                                                    <span className={classes.promo}><FormattedMessage
                                                        id="productCardMercurial.promo" defaultMessage="Promo"/></span>)
                                                : null}
                                        </>) :
                                        <Skeleton variant="circle" width={40} height={40}/>}
                                </Grid>
                            </Grid>
                            <Grid item container xs={6} justify="flex-end">
                                {
                                    product ? (<>
                                        {editMod ? (
                                            <>
                                                {product.createdByUser && (<IconButton
                                                    onClick={() => push(`${RESTAURANTS}/${restaurantIRI}/suppliers/${supplierIRI}/edit-product/${product._id}`)}>
                                                    <EditOutlinedIcon/>
                                                </IconButton>)}
                                                <Checkbox className={classes.checkBox}
                                                          checked={findIndex(selectedProducts, (item) => {
                                                              return item === shoppingListItemId
                                                          }) !== -1}
                                                          onClick={selectProduct}
                                                          inputProps={{'aria-label': 'primary checkbox'}}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                {unavailable ? (
                                                    <Grid item className={classes.unavailableProductBlock} container
                                                          justify="flex-end">
                                                        <Typography className={classes.unavailableProductText}>
                                                            <FormattedMessage id="productCart.unavailableProduct"
                                                                              defaultMessage="Produit indisponible"/>
                                                        </Typography>
                                                        <Button
                                                            onClick={toggleRemoveProductunavaible}
                                                            className={classes.replaceProduct} size="small"
                                                            variant="contained" color="secondary">
                                                            <FormattedMessage id="ProductCart.replaceProduct"
                                                                              defaultMessage="Remettre le produit"/>
                                                        </Button>
                                                    </Grid>
                                                ) : (
                                                    <CartQuantityWidget
                                                        setProductCartQuantity={setProductCartQuantity}
                                                        unavailable={unavailable}
                                                        supplierActive={supplierActive}
                                                        cartQuantity={cartQuantity}
                                                        product={product}
                                                        editMod={editMod}/>
                                                )}
                                            </>
                                        )}
                                    </>) : <Skeleton width="100%" height={40}/>
                                }
                                {product ? (
                                    <Grid container direction="column" justify="center" alignItems="center">
                                        {product.priceWithoutVat ? (
                                            <Chip
                                                label={`${price(product.priceWithoutVat.toFixed(2), currency)} / ${product.unit}`}
                                                size="small"
                                                className={classes.price}/>
                                        ) : null}
                                        {product.priceWithoutVat && product.content ? (
                                            <Typography align="center" className={classes.contentDetails}>
                                                {product.priceDetailsWithoutVat ?
                                                    <>{product.priceDetailsWithoutVat}</> :
                                                    <>{`(${price(product.priceWithoutVat.toFixed(2), currency)} / ${product.unit})`}</>
                                                }
                                            </Typography>
                                        ) : null}
                                    </Grid>) : null}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
                {product ? (
                    <>
                        {cartSummary &&
                        <Button variant="contained" color="secondary" className={classes.color} size="small"
                                onClick={toggleProductNoteDialog}>
                            <FormattedMessage id="ProductCart.addButton"
                                              defaultMessage="ajouter note"/>
                        </Button>}
                    </>
                ) : <Skeleton width="80%" /*className={classes.skeletonLeft}*//>}
            </GeneralPurposeCard>
            {product ? (<>
                <RemoveProductFromListConfirmDialog triggerNotification={triggerNotification}
                                                    handleClose={toggleRemoveProductFromListDialogState}
                                                    product={product}
                                                    openState={removeProductFromListDialogState}
                                                    restaurantId={restaurantId} supplierId={supplierId}
                                                    shoppingListItemId={product._id}/>
                <ProductDetailsSheet product={product} openState={productDetailsState}
                                     toggleState={toggleProductDetailsState}/>
            </>) : null}
        </>
    );
};

export default ProductCard;
