import {createSlice} from "@reduxjs/toolkit";

const initialState =
    {
        title: '',
        subtitle: '',
        isLoadingTitle: false,
        isLoadingSubTitle: false
    };

const overlaySlice = createSlice({
    name: 'overlay',
    initialState: initialState,
    reducers: {
        updateOverlayTitle(state, action) {
            if(action.payload === 'isLoading') state.isLoadingTitle = true;
            else {
                state.isLoadingTitle = false;
                state.title = action.payload;
            }

        },
        updateOverlaySubtitle(state, action) {
            if(action.payload === 'isLoading') state.isLoadingSubTitle = true;
            else {
                state.isLoadingTitle = false;
                state.subtitle = action.payload;
            }

        }
    }
});

export const {updateOverlayTitle, updateOverlaySubtitle} = overlaySlice.actions;
export default overlaySlice.reducer;

