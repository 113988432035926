import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {FormattedMessage, useIntl} from 'react-intl';
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import BaseTextField from "../../Inputs/BaseTextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Hooks} from "./hooks"

const useStyles = makeStyles(theme => ({
    input: {
        marginBottom: 25,
    },
    submitButton: {
        margin: '30px 0',
    },
    form: {
        marginTop: 20
    }
}));

//@TODO: Mailto and Callto on list buttons
const ContactForm = ({updateOverlayTitle, push}) => {
    const classes = useStyles();
    const {messageTexts, subjectTexts, pageTitle, formValidate} = Hooks()

    useEffect(() => {
        updateOverlayTitle(pageTitle);
    }, [])


    let mutationLoading = false;

    return (
        <>
            <Formik
                initialValues={{
                    subject: '',
                    message: '',
                }}
                validationSchema={Yup.object(formValidate)}
                onSubmit={(values, {setSubmitting}) => {
                }}
            >
                <Form id="form" className={classes.form}>
                    <Grid container justify={"center"} direction={"column"} alignItems={"center"}>
                        <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                            <Field component={BaseTextField} name="subject" id="subject" type="text"
                                   label={subjectTexts.label}
                                   fullWidth
                                   placeholder={subjectTexts.placeholder} className={classes.input}/>
                        </Grid>
                        <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                            <Field component={BaseTextField} name="message" id="message" type="text"
                                   label={messageTexts.label} fullWidth
                                   multiline rows={10}
                                   placeholder={messageTexts.placeholder} className={classes.input}/>
                        </Grid>
                        <Grid item lg={2} md={3} sm={4} xs={10}>
                            <Button variant="contained" color="primary" size="large" type="submit"
                                    className={classes.submitButton} disabled={mutationLoading}>
                                <FormattedMessage
                                    id="contactForm.buttonText"
                                    defaultMessage="Envoyer"/>
                                {mutationLoading &&
                                <CircularProgress size={24} className={classes.buttonProgress}/>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </>
    );
};

export default ContactForm;
