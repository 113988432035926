import MainAppBar from "../../components/MainAppBar";
import {openDrawer} from "../../actions/drawerActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {push} from 'connected-react-router';
import {triggerToastNotification} from "../../reducers/toastNotification";

const mapStateToProps = state => ({
    title: state.topNavigation.title,
    subtitle: state.topNavigation.subtitle,
    ActionsComponent: state.topNavigation.ActionsComponent,
    displayGoBack: state.topNavigation.displayGoBack,
    isLoadingTitle: state.topNavigation.isLoadingTitle,
    isLoadingSubTitle: state.topNavigation.isLoadingSubTitle
});

const mapDispatchToProps = (dispatch) => ({
    openDrawer: bindActionCreators(openDrawer, dispatch),
    push: (payload) => dispatch(push(payload)),
    triggerNotification: (payload) => dispatch(triggerToastNotification(payload)),
});

const TopNavigation = connect(mapStateToProps, mapDispatchToProps)(MainAppBar);

export default TopNavigation;
