import {connect} from 'react-redux';
import Dashboard from "../../components/Dashboard";
import {triggerToastNotification} from "../../reducers/toastNotification";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {displayMenu, unsetActionsComponent, updateTitle, updateSubtitle} from "../../reducers/topNavigation";
import {denyAccessToUnauthenticatedUser} from "../../reducers/authentication";
import {setSelectedRestaurant} from "../../reducers/restaurants";
import SupplierSav from "../../components/Suppliers/SupplierSav"

const mapStateToProps = state => ({
    userType: state.authentication.userType,
});

const mapDispatchToProps = (dispatch) => ({
    updateTopNavTitle: (title) => dispatch(updateTitle(title)),
    updateTopNavSubtitle: (title) => dispatch(updateSubtitle(title)),
    triggerNotification: (payload) => dispatch(triggerToastNotification(payload)),
    push: bindActionCreators(push, dispatch),
    unsetActionsComponent: (payload) => dispatch(unsetActionsComponent()),
    denyAccessToUnauthenticatedUser: () => dispatch(denyAccessToUnauthenticatedUser()),
    displayMenu: (payload) => dispatch(displayMenu(payload)),
    setSelectedRestaurant: (payload) => dispatch(setSelectedRestaurant(payload))
});

const DashboardPage = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default DashboardPage;
