import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Fab from "@material-ui/core/Fab";
import Paper from "@material-ui/core/Paper";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import {FormattedMessage} from "react-intl";
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {logout} from "../../../reducers/authentication";

const useStyles = makeStyles(theme => ({
    header: {
        height: 100,
        width: '100%',
        marginBottom: 80,
    },
    iconContainer: {
        borderRadius: 120,
        height: 120,
        width: 120,
        backgroundColor: 'white',
        marginTop: 40,
    },
    iconWrapper: {
        height: '100%',
    },
    icon: {
        color: theme.palette.error.main,
        fontSize: '5rem'
    },
    fab: {
        marginTop: 30,
    },
    text: {
        padding: 10,
    },
    clearCacheButton: {
        marginTop: 25
    }
}));

const FetchingError = ({action}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const clearCache = () => {
        dispatch(logout());
    }

    return (
        <>
            <Grid item container className={classes.header} justify={"center"}>
                <Grid item container justify={"center"} alignItems={"center"}>
                    <Paper className={classes.iconContainer}>
                        <Grid container justify={"center"} alignItems={"center"} className={classes.iconWrapper}>
                            <Grid item>
                                <ErrorOutlineOutlinedIcon className={classes.icon}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="h6" gutterBottom align={"center"}>
                    <FormattedMessage id="fetchingError.heading" defaultMessage="Oups !"/>
                </Typography>
                <Typography variant={"body1"} gutterBottom align={"center"} className={classes.text}>
                    <FormattedMessage id="fetchingError.text"
                                      defaultMessage="Une erreur est survenue lors du chargement des données."/>
                </Typography>
            </Grid>
            <Grid item container justify={"center"}>
                {action ? (<Fab variant="extended" color="secondary" className={classes.fab} onClick={action}>
                    <RefreshOutlinedIcon/>
                    <FormattedMessage id="fetchingError.buttonText" defaultMessage="Recharger"/>
                </Fab>) : null}
            </Grid>
            <Grid item container justify={"center"}>
                <Button color="secondary" onClick={clearCache} className={classes.clearCacheButton}>
                    <ClearIcon/>
                    <FormattedMessage id="fetchingError.clearCacheText" defaultMessage="Vider le cache"/>
                </Button>
            </Grid>
        </>
    );
};

export default FetchingError;
