import React from "react";

import {useQuery} from "@apollo/react-hooks";
import {GET_ORDERS_QUERY, GET_RESTAURANTS_ORDERS_QUERY} from "./queries";

const GraphQlFetchData = ({restaurantId}) => {


    const {
        data: restaurantOrders,
        error: restaurantOrdersError,
        refetch: restaurantOrdersRefetch,
        loading: restaurantsOrdersLoading
    } = useQuery(GET_RESTAURANTS_ORDERS_QUERY, {
        variables: {restaurantId: restaurantId},
        skip: !restaurantId,
        pollInterval: 5000
    });

    const {loading, error, data, refetch} = useQuery(GET_ORDERS_QUERY, {skip: restaurantId, pollInterval: 5000});


    return (
        {
            loading,
            error,
            data,
            refetch,
            restaurantsOrdersLoading,
            restaurantOrdersError,
            restaurantOrders,
            restaurantOrdersRefetch
        }
    );
};

export default GraphQlFetchData;
