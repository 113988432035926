import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage} from "react-intl";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {RESTAURANTS} from "../../../constants/Routes";
import ReactPixel from 'react-facebook-pixel';
import {map} from 'lodash';
import {INDIVIDUAL} from "../../../constants/UserTypes";

const useStyles = makeStyles(theme => ({
    successIconWrapper: {
        marginTop: 10
    },
    title: {
        padding: 10,
        fontSize: '2rem'
    },
    info: {
        backgroundColor: "#ccf4ff",
        margin: 20,
        padding: 20,
        borderRadius: 4
    },
    infoText: {
        padding: '5px 10px',
        fontSize: '0.9rem',
        color: theme.palette.info.contrastText,
    },
    subTitle: {
        padding: 10,
        fontSize: '1.2rem'
    },
    returnIcon: {
        paddingTop: 10,
        paddingLeft: 10
    },
    button: {
        marginBottom: 15
    }
}));

//@TODO: put stepper in its own component, with local state
const SuccessfulOrder = ({push, unsetActionsComponent, displayGoBack, userType, denyAccessToUnauthenticatedUser, updateTopNavSubtitle, updateTopNavTitle, cart, currentCartId, clearCart}) => {

    useEffect(() => {
        (async () => {
            await denyAccessToUnauthenticatedUser();
            await displayGoBack(`/restaurants`);
            await unsetActionsComponent();

            await updateTopNavSubtitle('')
            await updateTopNavTitle(<FormattedMessage id="successfulOrder.Confirmationdecommande"
                                                      defaultMessage='Confirmation de commande'/>);
            await clearCart({cartId: currentCartId});

        })()
    }, [])


    if (cart) {

        let content = [];
        map(cart.products, (item, key) => {
            content.push({id: key, quantity: item.quantity, item_price: item.product.price})
        });

        /*ReactPixel.track('Purchase',
            {
                content_type: 'product',
                value: parseFloat(cart.total) + parseFloat(cart.shippingFees),
                currency: 'EUR',
                contents: content
            });*/
    }


    const classes = useStyles();
    return (
        <>
            <Grid container justify="center" alignItems="center" direction="column">
                <Grid item className={classes.successIconWrapper}>
                    <svg id="successAnimation" className="animated" xmlns="http://www.w3.org/2000/svg" width="150"
                         height="150"
                         viewBox="0 0 70 70">
                        <path id="successAnimationResult" fill="#D8D8D8"
                              d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
                        <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2"
                                stroke-linecap="round" fill="transparent"/>
                        <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2"
                                  points="23 34 34 43 47 27"
                                  fill="transparent"/>
                    </svg>
                </Grid>
                <Grid item>
                    <Typography variant="h5" className={classes.title} align="center">
                        <FormattedMessage id="successfulOrder.thanks" defaultMessage="Merci !"/>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" className={classes.subTitle} align="center">
                        <FormattedMessage id="successfulOrder.orderSuccessful"
                                          defaultMessage="Votre commande a bien été enregistrée et sera traîtée dans les plus bref délais."/>
                    </Typography>
                </Grid>
                <Grid item className={classes.info}>
                    <Typography className={classes.infoText}>
                        <FormattedMessage id="successfulOrder.text1"
                                          defaultMessage="Votre compte bancaire ne sera débité qu'a l'émission de la facture par le fournisseur."/>
                    </Typography>
                    <Typography className={classes.infoText}>
                        <FormattedMessage id="successfulOrder.text2"
                                          defaultMessage="En cas d'impossibilité d'honorer la totalité de la commande, le montant facturé sera ajusté."/>
                    </Typography>
                    {userType === INDIVIDUAL && (
                        <Typography className={classes.infoText}>
                            <FormattedMessage id="successfulOrder.text3"
                                              defaultMessage="Le débit pourra donc être inférieur à la somme indiqué dans votre panier, mais jamais supérieur."/>
                        </Typography>
                    )}
                </Grid>
                <Grid item>
                    <Button className={classes.button} variant="contained" color="secondary"
                            onClick={() => push(RESTAURANTS)}>
                        <FormattedMessage id="successfulOrder.buttonText" defaultMessage="Retour à l'accueil"/>
                    </Button>
                </Grid>
            </Grid>
        </>
    )
};

export default SuccessfulOrder;