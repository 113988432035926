import {useIntl} from 'react-intl';

function Hooks() {
    const intl = useIntl()
    const pageTitlePro = intl.formatMessage(
        {
            id: 'editRestaurantPage.title',
            defaultMessage: 'Editer un restaurant'
        }
    )

    const pageTitle = intl.formatMessage(
        {
            id: 'editRestaurantPage.address',
            defaultMessage: 'Editer mon adresse'
        }
    )

    const notificationText = {
        success: intl.formatMessage(
            {
                id: 'editRestaurantPage.SuccessNotificationText',
                defaultMessage: 'Edition réussie !'
            }
        ),
        error: intl.formatMessage(
            {
                id: 'editRestaurantPage.errorNotificationText',
                defaultMessage: 'Une erreur est survenue lors de l\'édition.'
            }
        ),
        errorLoadingData: intl.formatMessage(
            {
                id: 'editRestaurantPage.errorLoadingDataText',
                defaultMessage: 'Une erreur est survenue.'
            }
        ),
    };
    return ({
        notificationText, pageTitle, pageTitlePro
    })
}

export default Hooks
