import {useMutation, useQuery} from "@apollo/react-hooks";
import {EDIT_SUPPLIER_MUTATION} from "./mutations";
import {GET_SUPPLIER_INFO_QUERY} from "../SuppliersList/queries";

const GraphQlFetchData = ({decodedSupplierId}) => {


    const {
        data: supplierData,
        error: supplierError,
        loading: supplierLoading
    } = useQuery(GET_SUPPLIER_INFO_QUERY, {variables: {id: decodedSupplierId}});

    let [editSupplier, {loading: mutationLoading}] = useMutation(EDIT_SUPPLIER_MUTATION);


    return (
        {supplierData, supplierLoading, supplierError, editSupplier, mutationLoading}
    );
};

export default GraphQlFetchData
