import React from "react";

import {useMutation, useQuery} from '@apollo/react-hooks';
import {GET_RESTAURANT_QUERY} from "../RestaurantsList/queries";

import {EDIT_RESTAURANT_MUTATION} from "./mutations";


const GraphQlFetchData = ({restaurantTrueId}) => {


    const [editRestaurant, {loading: mutationLoading}] = useMutation(EDIT_RESTAURANT_MUTATION);

    const {loading, error, data} = useQuery(GET_RESTAURANT_QUERY, {variables: {id: restaurantTrueId}});


    return (
        {loading, error, data, editRestaurant, mutationLoading}
    );

}

export default GraphQlFetchData;
