import {createReducer} from "@reduxjs/toolkit";

import {openDrawer, closeDrawer} from "../actions/drawerActions";

import {DRAWER_CLOSED, DRAWER_OPENED} from '../constants/DrawerStates'

const drawerReducer = createReducer(DRAWER_CLOSED, {
    [openDrawer]: state => DRAWER_OPENED,
    [closeDrawer]: state => DRAWER_CLOSED
});

export default drawerReducer;