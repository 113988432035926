import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.primary.main,
        height: 100,
        width: '100%',
        marginBottom: 80,
    },
    iconContainer: {
        borderRadius: 120,
        height: 120,
        width: 120,
        backgroundColor: 'white',
        marginTop: 40,
    },
    iconWrapper: {
        height: '100%',
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: '5rem'
    },
    heading: {
        fontSize: '1.1rem',
        fontWeight: 400,
        color: theme.palette.primary.dark,
        borderBottom: '1px solid #f2f2f2',
        marginBottom: 20
    },
    name: {
        fontWeight: 500,
        marginBottom: 10,
        fontSize: '1.1rem'
    },
    info: {
        marginBottom: 5,
        color: theme.palette.grey.A700
    },
    button: {
        marginTop: 20,
        width: '90%'
    }
}));

//@TODO: split header
const UserInformation = ({push, updateTopNavTitle, user, logout, updateTopNavSubtitle}) => {
    const classes = useStyles();
    const intl = useIntl();

    const pageTitle = intl.formatMessage(
        {
            id: 'userAccountPage.title',
            defaultMessage: 'Mon compte'
        }
    );

   useEffect(() => {
       updateTopNavTitle(pageTitle);
       updateTopNavSubtitle('');
   }, [])

    return (
        <>
            <Grid item container className={classes.header} justify={"center"}>
                <Grid item container justify={"center"} alignItems={"center"}>
                    <Paper className={classes.iconContainer}>
                        <Grid container justify={"center"} alignItems={"center"} className={classes.iconWrapper}>
                            <Grid item>
                                <PersonOutlineOutlinedIcon className={classes.icon}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container justify="center" direction="column" alignItems="center">
                <Grid item>
                    <Typography className={classes.heading} align="center">
                        <FormattedMessage id="userAccountPage.heading" defaultMessage="Mes informations personnelles"/>
                    </Typography>
                </Grid>
               {/* <Grid item>
                    <Typography className={classes.name}>
                        {user.name}
                    </Typography>
                </Grid>*/}
                <Grid item>
                    <Typography className={classes.info}>
                        {user.email}
                    </Typography>
                </Grid>
               {/* <Grid item>
                    <Typography className={classes.info}>
                        {user.phone}
                    </Typography>
                </Grid>*/}
            </Grid>
            <Grid item container justify="center">
                {/*<Button variant="contained" color="secondary" className={classes.button}
                        onClick={() => push(EDIT_USER_INFO, {modal: true})}>
                    <FormattedMessage id="userAccountPage.editInfoButtonText" defaultMessage="Editer mes informations"/>
                </Button>
                <Button variant="outlined" color="secondary" className={classes.button}
                        onClick={() => push(CHANGE_PASSWORD, {modal: true})}>
                    <FormattedMessage id="userAccountPage.editInfoButtonText"
                                      defaultMessage="Changer mon mot de passe"/>
                </Button>*/}
                <Button color="secondary" className={classes.button}
                        onClick={logout}>
                    <FormattedMessage id="userAccountPage.logoutButtonText"
                                      defaultMessage="Se déconnecter"/>
                </Button>
            </Grid>
        </>
    );
};

export default UserInformation;
