import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SupplierDescription from "../SupplierDescription/index"
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SupplierSav from "../SupplierSav";
import SupplierCategories from "../SupplierCategories/indesx";
import Grid from "@material-ui/core/Grid";
import SupplierPartnerTag from "../SupplierPartnerTag";
import IconButton from "@material-ui/core/IconButton";
import CardActionArea from '@material-ui/core/CardActionArea';
import {RESTAURANTS} from "../../../constants/Routes";
import GeneralPurposeCard from "../../GeneralPurpose/GeneralPurposeCard";
import {blueGrey} from "@material-ui/core/colors";
import amplitudeClient from "../../../amplitudeWrapper";
import SupplierDialogBox from "../SupplierDialogBox/index"
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import {PROFESSIONAL} from "../../../constants/UserTypes";
import {FormattedMessage, useIntl} from "react-intl";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import supplierProductCategories from "../categories";
import Skeleton from '@material-ui/lab/Skeleton';
import {Hidden} from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: '0px 8px 7px -1px rgba(180,201,219,0.2), 0px 0px 10px 1px rgba(180,201,219,0.1), 0px 1px 3px 1px rgba(180,201,219,0.1)',
    },
    icon: {
        fontSize: '2.5rem',
        padding: 10,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        color: theme.palette.primary.contrastText,
    },
    name: {
        fontWeight: 500,
        textTransform: 'uppercase',
        fontSize: '1.1rem',
        marginBottom: 0
    },
    cardContent: {
        flexGrow: 1,
        padding: '16px !important',
        color: blueGrey[800],
        borderBottom: '4px solid #33d573',
        //borderTop: `6px solid ${theme.palette.grey[50]}`
    },
    centerBlock: {
        flexGrow: 1,
    },
    chatIcon: {
        //color: theme.palette.grey.A700,
    },
    partnerIcon: {
        color: theme.palette.primary.main,
        fontSize: '1rem',
    },
    partnerText: {
        fontWeight: 400,
        textTransform: 'lowercase',
        fontSize: '0.8rem'
    },
    iconBlock: {
        display: 'flex',
        marginRight: 15,
    },
    actionBox: {
        backgroundColor: 'hsl(144, 66%, 94%)'
    },
    notValidated: {
        filter: 'opacity(0.4)'
    },
    minOrderText: {
        fontSize: '0.7rem',
        color: theme.palette.grey[500]
    },
    delivery: {
        fontSize: '0.7rem',
        color: theme.palette.grey[500],
        marginBottom: 10
    },
    infoTitle: {
        fontWeight: 700,
    },
    infoIcon: {
        fontSize: '1rem',
        marginRight: 5,
        marginBottom: '-4px',
    },
    categoryIcon: {
        width: 24
    },
    categoryBlock: {
        marginTop: 10
    },
    categoryText: {
        fontSize: '0.6rem'
    },
    nameBox: {
        flexGrow: 1
    },
    infoBox: {
        backgroundColor: 'hsl(204, 15%, 97%)',
        borderRadius: 10,
        marginTop: 10,
        padding: 10,
    },
    categoriesBlock: {
        backgroundColor: 'hsl(144, 66%, 97%)',
        borderRadius: 10,
        marginTop: 10,
        paddingBottom: 10
    },
    contactSupportLink: {
        width: '100%'
    },
    actionContainer: {
        marginTop: 10
    },
    phoneIcon: {
        marginRight: 10
    },
    chatLabel: {
        fontSize: '0.8rem',
        marginLeft: 3
    },
    chatButton: {
        borderRadius: 10
    },
    displayPromoButton: {
        marginTop: 10,
        width: '100%',
        backgroundColor: theme.palette.promo.main
    },
    commande: {
        width: '100%',
        marginTop: '10px'
    },
}));

const SupplierCard = ({
                          supplier,
                          push,
                          restaurantId,
                          editMod,
                          userType,
                          locale,
                          setSelectedRestaurant,
                          denyAccessToUnauthenticatedUser,
                          displayMenu,
                          ...rest
                      }) => {
    const classes = useStyles();
    const intl = useIntl();
    console.log(supplier);
    //We use base64 encoded id because the graphql id contain slashes
    let encodedId = null;
    if (supplier) {
        encodedId = window.btoa(supplier.id);
    }

    const goToSupplierShop = () => {
        amplitudeClient.logEvent('supplier selected');
        push(`${RESTAURANTS}/${restaurantId}/suppliers/${encodedId}`);
    };

    const goToChat = () => {
        amplitudeClient.logEvent('chat displayed', {origin: 'supplier card'});
        push(`/restaurants/${restaurantId}/suppliers/${encodedId}/chat`, {modal: true})
    };

    const goToEdit = () => {
        push(`/suppliers/${encodedId}/edit`, {modal: true})
    };

    const getNextDeliveryDay = () => {
        if (supplier) {
            return supplier.deliveryDates[0];
        }
    };

    const isAvailable = () => {
        switch (supplier.accountStatus) {
            case 'OPEN':
                return true;
            default:
                return false;
        }
    }

    const from = intl.formatMessage(
        {
            id: 'supplierCard.from',
            defaultMessage: 'A partir de '
        }
    );

    const goToPromotions = () => {
        push(`${RESTAURANTS}/${restaurantId}/suppliers/${encodedId}?promo=1`);
    }
    const goToCatalog = () => {
        rest.updateTopNavSubtitle("catalog")
        push(`/restaurants/${restaurantId}/suppliers/${encodedId}/catalog`)
    }
    return (
        <GeneralPurposeCard>
            <Grid container wrap="nowrap"
                  className={supplier && !isAvailable() ? classes.notValidated : null}>
                <CardContent className={classes.cardContent}>
                    <Grid container wrap="nowrap" alignItems="center">
                        <Grid item className={classes.centerBlock}>
                            <Grid container wrap="nowrap" alignItems="center">
                                <Grid item className={classes.nameBox} onClick={goToSupplierShop}>
                                    {supplier ?
                                        <Typography className={classes.name}
                                                    align="left">{supplier.name}</Typography> :
                                        <Skeleton height={40} width="90%"/>}
                                    {supplier && supplier.partner && userType === PROFESSIONAL ? (
                                        <SupplierPartnerTag/>) : null}
                                </Grid>
                                <Grid item>
                                    {supplier ? (
                                        <>
                                            <a href={`mailto:${supplier.supportEmails[0]}`} style={{textDecoration: "none"}}>
                                                <SupplierDialogBox supplier={supplier}
                                                                   nbUnreadMessages={supplier.nbUnreadMessages}/>
                                            </a>
                                        </>
                                    ) : (
                                        <Skeleton variant="circle" width={50} height={50}/>
                                    )}
                                </Grid>
                            </Grid>
                            <Hidden xsUp={supplier && supplier.createdByUser}>
                                <CardActionArea onClick={goToSupplierShop}>
                                    <SupplierDescription supplier={supplier} getNextDeliveryDay={getNextDeliveryDay}
                                                         ShoppingCartOutlinedIcon={ShoppingCartOutlinedIcon}
                                                         from={from} userType={userType}/>
                                    <SupplierCategories supplier={supplier}
                                                        supplierProductCategories={supplierProductCategories}
                                                        locale={locale}/>
                                </CardActionArea>
                            </Hidden>
                        </Grid>
                    </Grid>
                    {supplier ? (
                        <><Button size="small" variant="contained" color="primary" onClick={goToSupplierShop}
                                  className={classes.commande}>
                            <ShoppingCartOutlinedIcon/>
                            <FormattedMessage
                                id="supplierCard.passercommande"
                                defaultMessage="passer commande"/>
                        </Button>
                            <Button size="small" variant="outlined" color="primary" onClick={goToCatalog}
                                    className={classes.commande}>
                                <ShoppingCartOutlinedIcon/>
                                <FormattedMessage
                                    id="supplierCard.discoverCatalog"
                                    defaultMessage="Découvrir le catalogue"/>
                            </Button>
                        </>) : (
                        <Skeleton height={30}/>
                    )}
                    <SupplierSav supplier={supplier} isAvailable={isAvailable} push={push}
                                 setSelectedRestaurant={setSelectedRestaurant}
                                 denyAccessToUnauthenticatedUser={denyAccessToUnauthenticatedUser}
                                 displayMenu={displayMenu}/>
                    {supplier && isAvailable() && !supplier.createdByUser && (
                        <Button size="small" variant="outlined" className={classes.displayPromoButton}
                                onClick={goToPromotions}>
                            <FormattedMessage
                                id="supplierCard.seePromo"
                                defaultMessage="Voir les promos du moment"/>
                        </Button>)}
                </CardContent>
            </Grid>
        </GeneralPurposeCard>
    );
};

export default SupplierCard;
