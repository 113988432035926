import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {createMatchSelector, goBack, push} from "connected-react-router";
import {triggerToastNotification} from "../../reducers/toastNotification";
import {updateOverlayTitle} from "../../reducers/overlay";
import EditProductForm from "../../components/Products/EditProductForm";

const mapStateToProps = (state) => {
    const matchSelector = createMatchSelector('/restaurants/:id/suppliers/:supplierId/edit-product/:productId');
    const match = matchSelector(state);
    return {
        restaurantId: match.params.id,
        supplierId: match.params.supplierId,
        productId: match.params.productId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateOverlayTitle: (title) => dispatch(updateOverlayTitle(title)),
    push: bindActionCreators(push, dispatch),
    triggerNotification: (payload) => dispatch(triggerToastNotification(payload)),
    goBack: () => dispatch(goBack()),
});

const EditProduct = connect(mapStateToProps, mapDispatchToProps)(EditProductForm);

export default EditProduct;
