import {connect} from 'react-redux';
import ChatBox from "../../components/Chat/ChatBox";
import {bindActionCreators} from "redux";
import {setMessage} from "../../reducers/messages";
import {createMatchSelector} from "connected-react-router";
import {updateOverlayTitle} from "../../reducers/overlay";

const mapStateToProps = state => {
    const matchSelector = createMatchSelector('/restaurants/:id/suppliers/:supplierId');
    const match = matchSelector(state);
    if (match) {
        const decodedSupplierId = window.atob(match.params.supplierId);
        const supplierSegments = decodedSupplierId.split('/');
        const supplierTrueId = supplierSegments.pop();

        const message = state.messages[supplierTrueId];
        return {
            supplierId: match.params.supplierId,
            message: message,
        };
    }

    //@TODO: find a way to avoid this ultra über dirty hack to avoid error on rerendrering of the chat when using goback in "KeepOpen" overlay
    return {
        supplierId: window.btoa('zoooooooob'),
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateOverlayTitle: (title) => dispatch(updateOverlayTitle(title)),
    setMessage: bindActionCreators(setMessage, dispatch),
});

const Chat = connect(mapStateToProps, mapDispatchToProps)(ChatBox);

export default Chat;
