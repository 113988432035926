//Order statuses
export const ORDER_WAITING = 'WAITING';
export const ORDER_SENT_TO_SUPPLIER = 'SENT_TO_SUPPLIER';
export const ORDER_RECEIVED_BY_SUPPLIER = 'RECEIVED_BY_SUPPLIER';
export const ORDER_VALIDATED = 'VALIDATED';
export const ORDER_PAYED = 'PAYED';
export const ORDER_CANCELLED = 'CANCELLED';
export const ORDER_ABANDONED = 'ABANDONED';
export const ORDER_FAILED = 'FAILED';
export const ORDER_DELIVERED = 'DELIVERED';

//Payment statuses
export const PAYMENT_AUTHORIZED = 'AUTHORIZED';
export const PAYMENT_REJECTED = 'REJECTED';
export const PAYMENT_SUCCEEDED = 'SUCCEEDED';
export const PAYMENT_ERROR = 'ERROR';
export const PAYMENT_NONE = 'NONE';
export const PAYMENT_ABANDONED = 'ABANDONED';
export const PAYMENT_CANCELLED = 'CANCELLED';