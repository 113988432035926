import amplitude from 'amplitude-js';

const disableEventLogging = process.env.REACT_APP_ENABLE_AMPLITUDE == "1" ? false : true;

const stagingProjectApiKey = '949197d89f71b5f2cf28b1246e1ea63a';
const productionProjectApiKey = 'e4b0fc21e8ca9827ca0221b94a4e4410';

const urlParams = new URLSearchParams(window.location.search);
const deviceId = urlParams.get('amplitude_device_id');

//Amplitude Setup
const amplitudeClient = amplitude.getInstance();

switch(process.env.REACT_APP_AMPLITUDE_PROJECT) {
    case 'staging':
        amplitudeClient.init(stagingProjectApiKey, null, {deviceId: deviceId});
        break;
    case 'production':
        amplitudeClient.init(productionProjectApiKey, null, {deviceId: deviceId});
        break;
    default:
        amplitudeClient.init(stagingProjectApiKey, null, {deviceId: deviceId});
        break;
}

if (disableEventLogging) {
    amplitudeClient.setOptOut(true);
}

export default amplitudeClient;
