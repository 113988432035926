import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {FormattedMessage} from "react-intl";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

const useStyles = makeStyles(theme => ({
    categoriesBlock: {
        backgroundColor: 'hsl(144, 66%, 97%)',
        borderRadius: 10,
        marginTop: 10,
        paddingBottom: 10
    },
    categoryBlock: {
        marginTop: 10
    },
    categoryText: {
        fontSize: '0.6rem'
    },
    categoryIcon: {
        width: 32
    }
}));

const SupplierCategories = ({supplier, supplierProductCategories, locale}) => {
    const classes = useStyles();
    return (
        <>
            <Grid container justify="center" className={classes.categoriesBlock}>
                {supplier ? (
                    <>
                        {supplier.categories.map((category, key) =>
                            <React.Fragment key={key}>
                                {category !== 'DEFAULT' && (
                                    <Grid item container alignItems="flex-start" xs={4}
                                          className={classes.categoryBlock}>
                                        <Grid container direction="column" alignItems="center">
                                           <img className={classes.categoryIcon}
                                                 src={`/images/icons/${supplierProductCategories[category].icon}`}/>
                                            <Typography variant="caption" align="center"
                                                        className={classes.categoryText}>
                                                {category}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </React.Fragment>
                        )}
                    </>
                ) : (
                    <>
                        <Grid item container alignItems="center" justify="center" xs={4}
                              direction="column">
                            <Skeleton variant="circle" width={50} height={50}
                                      style={{marginTop: 10}}/>
                            <Skeleton width="50%"/>
                        </Grid>
                        <Grid item container alignItems="center" justify="center" xs={4}
                              direction="column">
                            <Skeleton variant="circle" width={50} height={50}
                                      style={{marginTop: 10}}/>
                            <Skeleton width="50%"/>
                        </Grid>
                        <Grid item container alignItems="center" justify="center" xs={4}
                              direction="column">
                            <Skeleton variant="circle" width={50} height={50}
                                      style={{marginTop: 10}}/>
                            <Skeleton width="50%"/>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
}
export default SupplierCategories
