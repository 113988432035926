import {gql} from "apollo-boost";

export const ADD_SUPPLIER_MUTATION = gql`
    mutation AddSupplier($supplierId: String!) {
        bindSupplier(input:{supplierId: $supplierId}) {
            supplier{
                _id
                id
                name
                email
                telephone
                partner
                createdByUser
            }
        }
    }
`;
