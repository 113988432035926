import {gql} from "apollo-boost";
import {SHOPPING_LIST_FIELDS} from "../../../graphql/fragments/ShoppingListFields";


export const REMOVE_PRODUCT_FROM_LIST_MUTATION = gql`
    ${SHOPPING_LIST_FIELDS}
    mutation deleteShoppingListItem($id: Int!, $itemIds: [Int!]!) {
        removeFromShoppingList(input:{listId: $id, itemIds: $itemIds}) {
            shoppingList {
                ...ShoppingListFields
            }
        }
    }
`;

export const BATCH_REMOVE_PRODUCT_FROM_LIST_MUTATION = gql`
    ${SHOPPING_LIST_FIELDS}
    mutation batchDeleteShoppingListItem($itemIds: [Int!]!, $listId: Int!) {
        removeFromShoppingList(input:{listId: $listId, itemIds: $itemIds}) {
            shoppingList {
                ...ShoppingListFields
            }
        }
    }
`;
