import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import Link from '@material-ui/core/Link';
import Button from "@material-ui/core/Button";
import {FormattedMessage, useIntl} from 'react-intl';
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import BaseTextField from "../Inputs/BaseTextField";
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import WelcomeScreen from "../WelcomeScreen";
import LoadingScreen from "../LoadingScreen";
import amplitudeClient from "../../amplitudeWrapper";
import {FORGOTTEN_PASSWORD} from "../../constants/Routes";
import {Hooks} from "./hooks";

const useStyles = makeStyles(theme => ({
    iconContainer: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 90,
        height: 110,
        width: 110,
        marginTop: 25,
        marginBottom: 20,
    },
    textWrapper: {
        marginBottom: 25,
        padding: '0 10px',
    },
    iconWrapper: {
        height: '100%',
    },
    logo: {
        height: 75
    },
    input: {
        marginBottom: 25,
    },
    resetPasswordLink: {
        cursor: 'pointer'
    },
    connexionButton: {
        margin: '30px 0',
    }
}));

//@TODO: split form and header
//@TODO: make a proper container for large screen
//@TODO: fix height for small phone
//@TODO: center vertically on large phone
const LoginForm = ({push, triggerNotification, login}) => {
    const classes = useStyles();
    const {emailTexts, passwordTexts, notificationText, formValidate} = Hooks()
    const mutationLoading = false;
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [deferredInstallPrompt, setDeferredInstallPrompt] = React.useState(null);
    const [displayLoading, setDisplayLoading] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };



    //@TODO: make a hook or something ??
    //Add event listener for beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', saveBeforeInstallPromptEvent);

    /**
     * Event handler for beforeinstallprompt event.
     *   Saves the event & shows install button.
     *
     * @param {Event} evt
     */
    function saveBeforeInstallPromptEvent(evt) {
        //Add code to save event & show the install button.
        setDeferredInstallPrompt(evt);
        setOpen(true);
    }

    /**
     * Event handler for butInstall - Does the PWA installation.
     *
     * @param {Event} evt
     */
    function installPWA(evt) {
        amplitudeClient.logEvent('install button clicked');
        //Add code show install prompt & hide the install button.
        deferredInstallPrompt.prompt();
        // Hide the install button, it can't be called twice.
        //evt.srcElement.setAttribute('hidden', true);
        //Log user response to prompt.
        deferredInstallPrompt.userChoice
            .then((choice) => {
                if (choice.outcome === 'accepted') {
                    amplitudeClient.logEvent('installation completed')
                } else {
                    amplitudeClient.logEvent('installation refused')
                }
                setDeferredInstallPrompt(null);
            });


    }

    //Add event listener for appinstalled event
    window.addEventListener('appinstalled', logAppInstalled);

    /**
     * Event handler for appinstalled event.
     *   Log the installation to analytics or save the event somehow.
     *
     * @param {Event} evt
     */
    function logAppInstalled(evt) {
        //Add code to log the event
        console.log('App was installed.', evt);
    }

    return (
        <>  {/*//@TODO: put this in a dedicated component*/}
            {displayLoading ? (<LoadingScreen/>) : (
                <>
                    <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <WelcomeScreen install={installPWA} close={handleClose}/>
                    </Dialog>

                    <Grid container justify={"center"} alignItems={"center"}>
                        <Paper className={classes.iconContainer}>
                            <Grid container justify={"center"} alignItems={"center"} className={classes.iconWrapper}>
                                <Grid item>
                                    <img src="logo192.png" alt="Foodomarket" className={classes.logo}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid className={classes.textWrapper}>
                        <Typography variant="h6" gutterBottom align={"center"}>
                            <FormattedMessage
                                id="loginForm.heading"
                                defaultMessage="Se connecter"
                            />
                        </Typography>
                        <Typography variant="body1" gutterBottom align={"center"}>
                            <FormattedMessage
                                id="loginForm.subheading"
                                defaultMessage="Identifiez-vous pour acceder à votre espace Foodomarket."
                            />
                        </Typography>
                    </Grid>

                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={Yup.object(formValidate)}
                        onSubmit={(values, {setSubmitting}) => {
                            const data = new FormData();
                            data.append('_username', values.email);
                            data.append('_password', values.password);
                            //@TODO: put this in a saga
                            fetch(`${window.apiEndpointUrl}/login_check`, {method: 'POST', body: data, headers: {
                                    'X-Requested-With': 'XMLHttpRequest',
                                }})
                                .then((response) =>
                                    response.json())
                                .then((data) => {
                                    if (data.message === 'BAD_CREDENTIALS') {
                                        triggerNotification({
                                            variant: 'error',
                                            message: notificationText.badCredentials
                                        })
                                    } else if (data.token !== '') {
                                        setDisplayLoading(true);
                                        setTimeout(() => {
                                            setDisplayLoading(false);
                                            login({
                                                email: values.email,
                                                bearer_token: data.token,
                                                refresh_token: data.refresh_token,
                                                userType: data.type,
                                            });
                                        }, 5000)

                                    }
                                })
                                .catch(e => triggerNotification({variant: 'error', message: notificationText.error}));
                            setSubmitting(false);
                        }}
                    >
                        <Form id="form">
                            <Grid container justify={"center"} direction={"column"} alignItems={"center"}>
                                <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                    <Field component={BaseTextField} name="email" id="email" type="email"
                                           label={emailTexts.label}
                                           fullWidth
                                           placeholder={emailTexts.placeholder} className={classes.input}/>
                                </Grid>
                                <Grid item container justify={"center"} lg={2} md={3} sm={4} xs={10}>
                                    <Field component={BaseTextField} name="password" id="password" type="password"
                                           label={passwordTexts.label} fullWidth
                                           placeholder={passwordTexts.placeholder} className={classes.input}/>
                                </Grid>
                                <Grid item lg={2} md={3} sm={4} xs={10}>
                                    {/*<Typography variant={"body2"} align={"center"}>
                                        <FormattedMessage
                                            id="loginForm.forgottenPasswordText"
                                            defaultMessage="Mot de passe oublié ?"
                                        /> <Link href="https://www.foodomarket.com/shop/mot-de-passe-oublie"
                                                 className={classes.resetPasswordLink}>

                                        <FormattedMessage
                                            id="loginForm.forgottenPasswordLinkText"
                                            defaultMessage="Réinitialiser votre mot de passe."
                                        />
                                    </Link>
                                    </Typography>*/}
                                </Grid>
                                <Grid container justify={"center"} direction={"column"} alignItems={"center"}>
                                    <Grid item lg={2} md={3} sm={4} xs={10}>
                                        <Typography variant={"body1"} align={"center"}>
                                            <FormattedMessage
                                                id="loginForm.forgottenPassword"
                                                defaultMessage="Mot de passe oublié ?"
                                            />
                                            <br/>
                                            <Link onClick={() => push(FORGOTTEN_PASSWORD)}
                                                  className={classes.resetPasswordLink}>
                                                <FormattedMessage
                                                    id="loginForm.resetPassword"
                                                    defaultMessage="Réinitialisez votre mot de passe."
                                                />
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={2} md={3} sm={4} xs={10}>
                                    <Button variant="contained" color="primary" size="large" type="submit"
                                            className={classes.connexionButton} disabled={mutationLoading}>
                                        <FormattedMessage
                                            id="loginForm.connection"
                                            defaultMessage="Connexion"/>
                                        {mutationLoading &&
                                        <CircularProgress size={24} className={classes.buttonProgress}/>
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>

                    <Grid container justify={"center"} direction={"column"} alignItems={"center"}>
                        <Grid item lg={2} md={3} sm={4} xs={10}>
                            <Typography variant={"body1"} align={"center"}>
                                <FormattedMessage
                                    id="loginForm.CreateAccountText"
                                    defaultMessage="Pas encore de compte Foodomarket ?"
                                />
                                <Link href="https://www.foodomarket.com/shop/inscription"
                                      className={classes.resetPasswordLink}>
                                    <FormattedMessage
                                        id="loginForm.CreateAccountLinkText"
                                        defaultMessage="Créer votre compte maintenant !"
                                    />
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </>)}
        </>
    );
};

export default LoginForm;
