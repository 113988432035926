import {useQuery} from "@apollo/react-hooks";
import {GET_RESTAURANT_QUERY, GET_RESTAURANTS_QUERY} from '../../Restaurants/RestaurantsList/queries'


let restaurantId = null
const GraphQlFetchData = () => {

    const {
        loading: restaurantsLoading,
        error: restaurantsError,
        data: restaurants,
        refetch: restaurantsRefetch
    } = useQuery(GET_RESTAURANTS_QUERY);

    if (restaurants) {
        restaurantId = restaurants.restaurants.edges[0].node._id;
    } else {
        restaurantId = null;
    }
    const {
        data: restaurantData,
        error: restaurantError,
        refetch: restaurantRefetch,
        loading: restaurantLoading
    } = useQuery(GET_RESTAURANT_QUERY, {variables: {id: restaurantId}, skip: !restaurantId});


    return (
        {
            //get restaurant by ID
            restaurantData, restaurantError, restaurantRefetch, restaurantLoading
        }
    );
};

export default GraphQlFetchData;
