import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {FormattedMessage} from "react-intl";
import Grid from "@material-ui/core/Grid";

export default function AddProductNoteDialog({
    openState,
    toggleOpenState,
    setProductNote,
    productNote,
    cartQuantity,
    cartItemId
}) {
    const [note, setNote] = useState(productNote);

    const cancel = () => {
        toggleOpenState();
    };

    const validate = (e) => {
        if (e !== undefined) {
            e.preventDefault();
            e.stopPropagation();
        }

        //Extract note:
        setProductNote({
            variables: {
                itemId: parseInt(cartItemId),
                quantity: parseFloat(cartQuantity),
                note: note,
            }
        })
        toggleOpenState();
    };

    return (
        <div>

            <Dialog open={openState} onClose={toggleOpenState} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><FormattedMessage id="AddProductNoteDialog.header"
                                                                      defaultMessage="Ajouter une note"/></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="AddProductNoteDialog.note"
                                          defaultMessage="Votre commentaire concernant le produit commandé :"/>

                    </DialogContentText>
                    <TextField
                        value={note}
                        onChange={(e) => {
                            setNote(e.target.value);
                        }
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancel} color="primary">
                        <FormattedMessage id="AddProductNoteDialog.cancel"
                                          defaultMessage="Annuler"/>

                    </Button>
                    <Button onClick={validate} color="primary">
                        <FormattedMessage id="AddProductNoteDialog.submit"
                                          defaultMessage="valider"/>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};