import {createSlice} from "@reduxjs/toolkit";
import {isString} from "formik";

const initialState =
    {};

const cartsSlice = createSlice({
    name: 'carts',
    initialState: initialState,
    reducers: {
        initNewCart(state, action) {
            state[normalizeCartId(action.payload.restaurantId, action.payload.supplierId)] = {
                products: {},
                totalQuantity: 0,
                supplierId: action.payload.supplierId,
                restaurantId: action.payload.restaurantId,
                note: '',
                deliveryDate: null,
                deliveryIntervalStart: null,
                deliveryIntervalEnd: null,
                total: 0,
                shippingFees: 0,
                totalWeight: 0,
            };
            //return initialState;
        },
        setProductQuantity(state, action) {

            const cartId = normalizeCartId(action.payload.restaurantId, action.payload.supplierId);

            //We cant update cart if quantity is NaN
            const parsedQuantity = parseFloat(action.payload.quantity);
            if (isNaN(parsedQuantity) || parsedQuantity < 0) {
                return;
            }

            //@TODO: ensure that the content of the object is a valid cart
            if (state[cartId].products[action.payload.productId] === undefined) {
                //Creating a new cart line
                state[cartId].products[action.payload.productId] = {
                    product: action.payload.product,
                    quantity: parseFloat(action.payload.quantity),
                    supplier: action.payload.supplierId,
                };

                state[cartId].total += normalizePrice(action.payload.product.price * action.payload.quantity);
                state[cartId].total = normalizePrice(state[cartId].total);

                if (state[cartId].products[action.payload.productId].product.unit === 'KG') {
                    state[cartId].totalQuantity++;
                } else {
                    state[cartId].totalQuantity += parseFloat(action.payload.quantity);
                }

            } else {
                const previousQuantity = state[cartId].products[action.payload.productId].quantity;
                const delta = parseFloat(action.payload.quantity - previousQuantity);

                state[cartId].products[action.payload.productId].quantity = parseFloat(action.payload.quantity);

                state[cartId].total += normalizePrice(action.payload.product.price * delta);
                state[cartId].total = normalizePrice(state[cartId].total);

                if (state[cartId].products[action.payload.productId].quantity === 0 && state[cartId].products[action.payload.productId].product.unit === 'KG') {
                    state[cartId].totalQuantity--;
                }

                if (state[cartId].products[action.payload.productId].product.unit !== 'KG') {
                    state[cartId].totalQuantity += parseFloat(delta);
                }
            }

            //If the quantity is null after subtraction, removing the line
            if (state[cartId].products[action.payload.productId].quantity === 0) {
                delete state[cartId].products[action.payload.productId];
            }
        },
        addProductToCart(state, action) {
            const cartId = normalizeCartId(action.payload.restaurantId, action.payload.supplierId);

            const incrementValue = action.payload.product.unit === 'KG' ? 0.5 : 1;

            //@TODO: ensure that the content of the object is a valid cart
            if (state[cartId].products[action.payload.productId] === undefined) {
                //Creating a new cart line
                state[cartId].products[action.payload.productId] = {
                    product: action.payload.product,
                    quantity: incrementValue,
                    supplier: action.payload.supplierId,
                    productNote:'',
                };

                if (state[cartId].products[action.payload.productId].product.unit === 'KG') {
                    state[cartId].totalQuantity++;
                }
            } else {
                state[cartId].products[action.payload.productId].quantity += incrementValue;
            }

            state[cartId].total += normalizePrice(action.payload.product.price * incrementValue);
            state[cartId].total = normalizePrice(state[cartId].total);

            if (state[cartId].products[action.payload.productId].product.unit !== 'KG') {
                state[cartId].totalQuantity++;
            }

        },
        subtractProductFromCart(state, action) {
            const cartId = normalizeCartId(action.payload.restaurantId, action.payload.supplierId);

            //@TODO: ensure that the content of the object is a valid cart
            //@TODO: remove product from cart if quantity reach 0 ??
            if (state[cartId].products[action.payload.productId] === undefined) {
                return;
            }

            //If quantity is null, skip the action
            if (state[cartId].products[action.payload.productId].quantity === 0) {
                return;
            }

            const decrementValue = state[cartId].products[action.payload.productId].product.unit === 'KG' ? 0.5 : 1;

            state[cartId].total -= normalizePrice(action.payload.price * decrementValue);
            state[cartId].total = normalizePrice(state[cartId].total);
            state[cartId].total = state[cartId].total > 0 ? state[cartId].total : 0;
            state[cartId].products[action.payload.productId].quantity -= decrementValue;

            //Avoid negative quantities
            if (state[cartId].products[action.payload.productId].quantity < 0) {
                state[cartId].products[action.payload.productId].quantity = 0;
            }

            if (state[cartId].products[action.payload.productId].product.unit !== 'KG') {
                state[cartId].totalQuantity--;
            }

            if (state[cartId].products[action.payload.productId].quantity === 0 && state[cartId].products[action.payload.productId].product.unit === 'KG') {
                state[cartId].totalQuantity--;
            }

            //If the quantity is null after subtraction, removing the line
            if (state[cartId].products[action.payload.productId].quantity === 0) {
                delete state[cartId].products[action.payload.productId];
            }
        },
        setProductNote(state, action) {
            const cartId = normalizeCartId(action.payload.restaurantId, action.payload.supplierId);
            if (state[cartId] === undefined) {
                return;
            }

            if (state[cartId].products[action.payload.productId] !== undefined) {
                //Creating a new cart line
                state[cartId].products[action.payload.productId].productNote = action.payload.productNote;
            }
        },
        clearCart(state, action) {

            let cartId;
            if (action.payload.restaurantId && action.payload.supplierId) {
                cartId = normalizeCartId(action.payload.restaurantId, action.payload.supplierId);
            } else if (action.payload.cartId) {
                cartId = action.payload.cartId;
            } else {
                cartId = null;
            }

            if (state[cartId] === undefined) {
                return;
            }

            delete state[cartId];
        },
        setShippingDetails(state, action) {
            const cartId = normalizeCartId(action.payload.restaurantId, action.payload.supplierId);

            if (state[cartId] === undefined) {
                return;
            }

            state[cartId].note = action.payload.note;
            state[cartId].shippingAddress = action.payload.shippingAddress;
            state[cartId].deliveryIntervalStart = action.payload.deliveryIntervalStart;
            state[cartId].deliveryIntervalEnd = action.payload.deliveryIntervalEnd;
            state[cartId].deliveryDate = action.payload.deliveryDate;
            state[cartId].shippingFees = action.payload.shippingFees;
            state[cartId].productReplacementAgreement = action.payload.productReplacementAgreement;
        },
        setCurrentCartId(state, action) {
            state.currentCartId = action.payload;
        }
    }
});

const normalizePrice = (price) => {
    price = isString(price) ? price.replace(',', '.') : price;
    price = parseFloat(price).toFixed(2);
    price = parseFloat(price);
    price = isNaN(price) ? 0 : price;
    return price;
};

//@TODO: is it the place for this function??
export const normalizeCartId = (restaurantId, supplierId) => {
    return `${restaurantId}.${supplierId}`;
};


export const {
    initNewCart,
    addProductToCart,
    subtractProductFromCart,
    setShippingDetails,
    clearCart,
    setProductQuantity,
    setCurrentCartId,
    setProductNote
} = cartsSlice.actions;
export default cartsSlice.reducer;

