import {gql} from "apollo-boost";
export const GET_RESTAURANT_TYPES_QUERY = gql`
    query GetRestaurantTypes($locale: String!){
        restaurantTypes(locale: $locale) {
            _id
            id
            name
        }
    }
`;
