import {gql} from "apollo-boost";

export const GET_SUPPLIERS_QUERY = gql`
    query getSupppliers {
        availableSuppliers {
            _id
            id
            name
            email
            telephone
            partner
            createdByUser
        }
    }
`;

export const GET_MORE_SUPPLIERS_QUERY = gql`
    query GetSuppliers($offset: Int, $limit: Int){
        moreSuppliers(offset: $offset, limit: $limit) {
            id
            name
        }
    }
`;
