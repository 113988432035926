import {connect} from "react-redux";
import {push} from "connected-react-router";
import MainBottomBar from "../../components/MainBottomBar";


//@TODO : disable bottom nav on desktop

const mapStateToProps = state => ({
    pathname: state.router.location.pathname,
    userType: state.authentication.userType,
    selectedRestaurantId: state.restaurants.selectedRestaurantId,
});

const mapDispatchToProps = (dispatch) => ({
    push: (data) => dispatch(push(data)),
});

const FooterNavigation = connect(mapStateToProps, mapDispatchToProps)(MainBottomBar);

export default FooterNavigation;