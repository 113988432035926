import {connect} from 'react-redux';
import CreateNewSupplierForm from "../../components/Suppliers/CreateNewSupplierForm";
import {updateOverlayTitle} from "../../reducers/overlay";
import {triggerToastNotification} from "../../reducers/toastNotification";
import {createMatchSelector, goBack, push} from "connected-react-router";
import {bindActionCreators} from "redux";

const mapStateToProps = state => {
    const matchSelector = createMatchSelector('/restaurants/:id/suppliers/:supplierId');
    const match = matchSelector(state);
    return {
        restaurantId: match.params.id,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateOverlayTitle: (title) => dispatch(updateOverlayTitle(title)),
    triggerNotification: (payload) => dispatch(triggerToastNotification(payload)),
    push: bindActionCreators(push, dispatch),
    goBack: () => dispatch(goBack()),
});

const CreateNewSupplier = connect(mapStateToProps, mapDispatchToProps)(CreateNewSupplierForm);

export default CreateNewSupplier;
