import React from "react";
import * as Statuses from '../../../constants/OrderStatus';
import {makeStyles} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import {useIntl} from "react-intl";
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import RemoveShoppingCartOutlinedIcon from '@material-ui/icons/RemoveShoppingCartOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(theme => ({
    [Statuses.PAYMENT_AUTHORIZED]: {
        backgroundColor: "#99e9ff",
        color: '#006b7f'
    },
    [Statuses.PAYMENT_ABANDONED]: {
        backgroundColor: '#ffb3c0',
        color: '#803f4d'
    },
    [Statuses.PAYMENT_SUCCEEDED]: {
        backgroundColor: '#adebad',
        color: '#326c37'
    },
    [Statuses.PAYMENT_ERROR]: {
        backgroundColor: '#ffcccc',
        color: '#990000'
    },
    [Statuses.PAYMENT_CANCELLED]: {
        backgroundColor: '#ffc2b3',
        color: '#7e4b40'
    },
    [Statuses.PAYMENT_REJECTED]: {
        backgroundColor: '#ffb3b3',
        color: '#b30000'
    },
    [Statuses.PAYMENT_NONE]: {
        backgroundColor: '#e0ebeb',
        color: '#3d5c5c',
    },
    ['ARCHIVED']: {
        backgroundColor: '#e0ebeb',
        color: '#3d5c5c',
    },
    gavelIcon:
        {
            marginLeft: 6,
            marginBottom: 1
        }
}));

const PaymentStatus = ({status}) => {
    const classes = useStyles();
    const intl = useIntl();

    const statusLabel = {
        [Statuses.PAYMENT_CANCELLED]:
            intl.formatMessage(
                {
                    id: 'paymentStatus.cancelled',
                    defaultMessage: 'Annulé'
                }),
        [Statuses.PAYMENT_REJECTED]:
            intl.formatMessage(
                {
                    id: 'paymentStatus.rejected',
                    defaultMessage: 'Rejeté'
                }),
        [Statuses.PAYMENT_NONE]:
            intl.formatMessage(
                {
                    id: 'paymentStatus.none',
                    defaultMessage: 'Aucun'
                }),
        [Statuses.PAYMENT_ERROR]:
            intl.formatMessage(
                {
                    id: 'paymentStatus.error',
                    defaultMessage: 'Erreur'
                }),
        [Statuses.PAYMENT_AUTHORIZED]:
            intl.formatMessage(
                {
                    id: 'paymentStatus.authorized',
                    defaultMessage: 'Autorisé'
                }),
        [Statuses.PAYMENT_ABANDONED]:
            intl.formatMessage(
                {
                    id: 'paymentStatus.abandoned',
                    defaultMessage: 'Abandoné'
                }),
        [Statuses.PAYMENT_SUCCEEDED]:
            intl.formatMessage(
                {
                    id: 'paymentStatus.succeeded',
                    defaultMessage: 'Payée'
                }),
        ['ARCHIVED']:
            intl.formatMessage(
                {
                    id: 'paymentStatus.archived',
                    defaultMessage: 'Archivé'
                }),
    };

    switch (status) {
        case Statuses.PAYMENT_CANCELLED:
            return (
                <Chip label={statusLabel[Statuses.PAYMENT_CANCELLED]} size="small"
                      className={classes[Statuses.PAYMENT_CANCELLED]}
                      icon={<CancelOutlinedIcon fontSize={"small"}
                                                className={classes[Statuses.PAYMENT_CANCELLED]}/>}/>);
        case Statuses.PAYMENT_REJECTED:
            return (
                <Chip label={statusLabel[Statuses.PAYMENT_REJECTED]} size="small"
                      className={classes[Statuses.PAYMENT_REJECTED]}
                      icon={<BlockOutlinedIcon fontSize={"small"} className={classes[Statuses.PAYMENT_REJECTED]}/>}/>);
        case Statuses.PAYMENT_NONE:
            return (<Chip label={statusLabel[Statuses.PAYMENT_NONE]} size="small"
                          className={classes[Statuses.PAYMENT_NONE]}
                          icon={<RadioButtonUncheckedOutlinedIcon fontSize={"small"}
                                                                  className={classes[Statuses.PAYMENT_NONE]}/>}/>);
        case Statuses.PAYMENT_ERROR:
            return (<Chip label={statusLabel[Statuses.PAYMENT_ERROR]} size="small"
                          className={classes[Statuses.PAYMENT_ERROR]}
                          icon={<WarningOutlinedIcon fontSize={"small"}
                                                     className={classes[Statuses.PAYMENT_ERROR]}/>}/>);
        case Statuses.PAYMENT_AUTHORIZED:
            return (<Chip label={statusLabel[Statuses.PAYMENT_AUTHORIZED]} size="small"
                          className={classes[Statuses.PAYMENT_AUTHORIZED]}
                          icon={<AccountBalanceOutlinedIcon fontSize={"small"}
                                                            className={classes[Statuses.PAYMENT_AUTHORIZED]}/>}/>);
        case 'ARCHIVED':
            return (<Chip label={statusLabel['ARCHIVED']} size="small"
                          className={classes['ARCHIVED']}
                          icon={<BookOutlinedIcon fontSize={"small"} className={`${classes['ARCHIVED']}`}/>}/>);
        case Statuses.PAYMENT_SUCCEEDED:
            return (<Chip label={statusLabel[Statuses.PAYMENT_SUCCEEDED]} size="small"
                          className={classes[Statuses.PAYMENT_SUCCEEDED]}
                          icon={<CheckOutlinedIcon fontSize={"small"}
                                                   className={`${classes[Statuses.PAYMENT_SUCCEEDED]}`}/>}/>);
        case Statuses.PAYMENT_ABANDONED:
            return (<Chip label={statusLabel[Statuses.PAYMENT_ABANDONED]} size="small"
                          className={classes[Statuses.PAYMENT_ABANDONED]}
                          icon={<RemoveShoppingCartOutlinedIcon fontSize={"small"}
                                                                className={`${classes[Statuses.PAYMENT_ABANDONED]}`}/>}/>);
        default:
            return (<Chip label={status ? status : 'Aucun'} size="small"
                          className={classes['ARCHIVED']}
                          icon={<InfoOutlinedIcon fontSize={"small"} className={`${classes['ARCHIVED']}`}/>}/>);
    }
};

export default PaymentStatus;
