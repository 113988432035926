import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import ToastContentWrapper from "../ToastContentWrapper";

const Toast = ({variant, message, open, close, anchorOrigin}) => {

    if(!anchorOrigin) {
        anchorOrigin = {
            vertical: 'bottom',
            horizontal: 'left',
        }
    }

    return (
        <Snackbar
            anchorOrigin={anchorOrigin}
            open={open}
            autoHideDuration={6000}
            onClose={close}
        >
            <ToastContentWrapper
                onClose={close}
                variant={variant}
                message={message}
            />
        </Snackbar>
    );
};

export default Toast;