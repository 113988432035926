import React from "react";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {
    displayGoBack,
    setActionsComponent,
    updateSubtitle,
    updateTitle
} from "../../reducers/topNavigation";
import {createMatchSelector, push} from "connected-react-router";
import SupplierShoppingList from "../../components/Suppliers/SupplierShoppingList";
import {denyAccessToUnauthenticatedUser} from "../../reducers/authentication";
import {triggerToastNotification} from "../../reducers/toastNotification";
// import SupplierProductCategoriesList from "../../components/Suppliers/SupplierProductCategoriesList"

//@TODO: Maybe create a selector
const mapStateToProps = state => {
    const matchSelector = createMatchSelector('/restaurants/:id/suppliers/:supplierId');
    const match = matchSelector(state);
    const promotionsDisplayRequested = state.router.location.query.promo;
    return {
        restaurantId: match.params.id,
        supplierId: match.params.supplierId,
        locale: state.authentication.language,
        promotionsDisplayRequested: promotionsDisplayRequested
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateTopNavTitle: (title) => dispatch(updateTitle(title)),
    updateTopNavSubtitle: (subtitle) => dispatch(updateSubtitle((subtitle))),
    // addProductToCart: bindActionCreators(addProductToCart, dispatch),
    // subtractProductFromCart: bindActionCreators(subtractProductFromCart, dispatch),
    // setProductQuantity: bindActionCreators(setProductQuantity, dispatch),
    push: bindActionCreators(push, dispatch),
    setActionsComponent: (component) => dispatch(setActionsComponent(component)),
    displayGoBack: (payload) => dispatch(displayGoBack(payload)),
    denyAccessToUnauthenticatedUser: (payload) => dispatch(denyAccessToUnauthenticatedUser(payload)),
    triggerNotification: (payload) => dispatch(triggerToastNotification(payload))
});

const SupplierShop = connect(mapStateToProps, mapDispatchToProps)(SupplierShoppingList);

export default SupplierShop;
