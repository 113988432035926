import {connect} from 'react-redux';
import AddRestaurantForm from "../../components/Restaurants/AddRestaurantForm";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {triggerToastNotification} from "../../reducers/toastNotification"
import {updateOverlayTitle} from "../../reducers/overlay";

const mapStateToProps = state => {
    return {
        locale: state.authentication.language,
    }
};

const mapDispatchToProps = (dispatch) => ({
    updateOverlayTitle: (title) => dispatch(updateOverlayTitle(title)),
    push: bindActionCreators(push, dispatch),
    triggerNotification: (payload) => dispatch(triggerToastNotification(payload)),

});

const AddRestaurant = connect(mapStateToProps, mapDispatchToProps)(AddRestaurantForm);

export default AddRestaurant;
