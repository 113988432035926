import {useMutation,useQuery} from "@apollo/react-hooks";
import {ADD_PRODUCT_TO_LIST_MUTATION} from "../AddProductToListConfirmDialog/mutations";
import {MY_SHOPPING_LIST_QUERY,GET_SHOPPING_LIST_QUERY} from "../../Suppliers/SupplierShoppingList/queries";
import {cloneDeep} from "@apollo/client/utilities/common/cloneDeep";



const GraphQlFetchData = ({supplierId, restaurantId}) => {
    const {
        data: shoppingListData,
        error: shoppingListError,
        loading: shoppingListLoading,
        refetch: shoppingListRefetch
    } = useQuery(GET_SHOPPING_LIST_QUERY, {
        variables: {
            supplierId: supplierId,
            restaurantId: restaurantId
        },
        //pollInterval: 10000,
    });

    const [addToShoppingList, {loading: mutationLoading}] = useMutation(ADD_PRODUCT_TO_LIST_MUTATION, {
       update(cache, mutationData) {
            try {
                cache.writeQuery({
                    query: GET_SHOPPING_LIST_QUERY,
                    variables: {restaurantId: restaurantId, supplierId: supplierId},
                    data: {supplierShoppingList: mutationData.data.addToShoppingList.shoppingList},
                });
            } catch (e) {
                console.log(e);
            }
        }

    });


    return (
        {addToShoppingList, mutationLoading}
    )
};

export default GraphQlFetchData;
