import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
//import Slide from '@material-ui/core/Slide';
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import {Skeleton} from "@material-ui/lab";

//The transition is used by the dialog
//@TODO : put the transition in it's own file
/*
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
*/

const Overlay = ({children, title, subtitle, goBack, keepOpen, fullHeight, isLoadingTitle}) => {
    //Using local state because nothing cares about the open/close state of the dialog
    const [open, setOpen] = React.useState(true);

    const style = {
        backDrop: {
            backdropFilter: 'blur(10px)',
            backgroundColor: 'unset'
        },
        paper: {
            margin: 0,
            width: '100%',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            maxHeight: 'calc(100% - 32px)',
        },
        scrollPaper: {
            alignItems: 'flex-end',
        },
        dialogContent: {
            padding: 0
        },
        dialogTitle: {
            display: 'flex',
            alignItems: 'center',
        },
        closeIcon: {
            marginLeft: -12,
        }
    };

    if(fullHeight) {
        style.paper.height = '100%';
    }

    const useStyles = makeStyles(theme => (style));
    const classes = useStyles();

    const handleClose = () => {
        if (!keepOpen) {
            setOpen(false);
        }
        setTimeout(() => (goBack({modal: keepOpen})), 100);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                BackdropProps={{
                    classes: {
                        root: classes.backDrop
                    }
                }}

                PaperProps={{
                    classes: {
                        root: classes.paper,
                    }
                }}
                classes={{scrollPaper: classes.scrollPaper}}
            >
                <DialogTitle disableTypography classes={{root: classes.dialogTitle}} >
                    <IconButton classes={{root: classes.closeIcon}} onClick={handleClose}>
                        {keepOpen ? (<ArrowBackOutlinedIcon/>) : (<CloseIcon/>)}
                    </IconButton>
                    <Typography variant="h6" style={{width: "100%"}}>
                        {isLoadingTitle ? <Skeleton width="70%"/> : title }
                    </Typography>
                </DialogTitle>
                <DialogContent classes={{root: classes.dialogContent}}>
                    {children}
                </DialogContent>
            </Dialog>
        </div>
    );
};

Overlay.defaultProps = {
  fullHeight: true,
};

export default Overlay;
