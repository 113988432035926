import {call, takeEvery} from 'redux-saga/effects';
import {updated} from "../reducers/updater";

//@TODO: find a way to avoid using state
function* updateServiceWorker(action) {
    yield call(skipWaitingSW);
}

const skipWaitingSW = () => {
    window.__zoooob.waiting.postMessage({type: "SKIP_WAITING"})
};

export function* watchLaunchUpdate() {
    yield takeEvery(updated, updateServiceWorker)
}
