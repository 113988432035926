import {useQuery} from "@apollo/react-hooks";
import {GET_RESTAURANT_QUERY} from "../../Restaurants/RestaurantsList/queries";
import {GET_SUPPLIER_QUERY} from "../../Suppliers/SuppliersList/queries";
import {GET_SHOPPING_CART_QUERY} from "../../Suppliers/SupplierShoppingList/queries";

const GraphQlFetchData = ({
                              updateOverlayTitle,
                              supplierTrueId, decodedRestaurantId, restaurantTrueId, cartId
                          }) => {


    const {
        data: restaurantData,
        error: restaurantError,
        refetch: restaurantRefetch
    } = useQuery(GET_RESTAURANT_QUERY, {variables: {id: restaurantTrueId}});
    const {
        data: supplierData,
        error: supplierError,
        loading: supplierLoading,
        refetch: supplierRefetch
    } = useQuery(GET_SUPPLIER_QUERY, {
        variables: {
            id: supplierTrueId,
            restaurantId: restaurantTrueId
        },
        onCompleted: (res) => {
            if (res) {
                updateOverlayTitle(res.supplier.name);
            }
        }
    });
    const {
        data: cartData,
        error: cartError,
        loading: cartLoading,
        refetch: cartRefetch
    } = useQuery(GET_SHOPPING_CART_QUERY, {
        variables: {
            id: cartId,
        }
    });

    return (
        {
            cartData,
            supplierError,
            supplierLoading,
            supplierRefetch,
            supplierData,
            restaurantError,
            restaurantData,
            restaurantRefetch
        }


    );
};

export default GraphQlFetchData;
