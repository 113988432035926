import {gql} from "apollo-boost";

export const SHOPPING_LIST_FIELDS = gql`
    fragment ShoppingListFields on ShoppingList {
        id
        _id
        name
        items(first: 500) {
            edges {
                node {
                    id
                    _id
                    name
                    origin
                    content
                    supplier {
                        _id
                        name
                    }
                    offer {
                        _id
                        name
                        image {
                            medium
                            large
                        }
                        unit
                        priceWithoutVat
                        content
                        frozen
                        priceDetailsWithoutVat
                        createdByUser
                        origin
                        organic
                        promotion
                    }
                }
            }
        }
    }`;
