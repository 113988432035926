import {gql} from "apollo-boost";

export const CREATE_SHOPPING_CART = gql`
    mutation createShoppingCart($restaurantId: Int!, $supplierId: Int!) {
        createShoppingCart(input: {
            restaurantId: $restaurantId,
            supplierId: $supplierId
        }) {
            shoppingCart {
                _id
            }
        }
    }
`;

export const ADD_TO_CART = gql`
    mutation addToCart($cartId: Int!, $variantId: Int!, $quantity: Float!) {
        addToShoppingCart(input: {
            cartId: $cartId,
            cartItems: [{variantId: $variantId, quantity: $quantity}]
        }) {
            shoppingCart {
                _id
            }
        }
    }
`;

export const REMOVE_FROM_CART = gql`
    mutation removeFromCart($cartId: Int!, $itemId: Int!) {
        removeFromShoppingCart(input: {cartId: $cartId, itemIds: [$itemId]}) {
            shoppingCart {
                _id
            }
        }
    }
`;

export const UPDATE_CART_ITEM = gql`
    mutation updateCartItem($itemId: Int!, $quantity: Float!) {
        updateShoppingCartItem(input: {itemId: $itemId, quantity: $quantity}) {
            shoppingCartItem {
                _id
                offer {
                    _id
                }
                quantity
                unit
            }
        }
    }
`;

export const CLEAR_CART = gql`
    mutation clearCart($id: Int!) {
        clearShoppingCart(input: {
            cartId: $id,
        }) {
            shoppingCart {
                _id
            }
        }
    }
`;

export const ADD_NOTE_ON_CART_ITEM = gql`
    mutation addNoteOnCartItem($itemId: Int!, $note: String!, $quantity: Float!) {
        updateShoppingCartItem(input: {itemId: $itemId, note: $note, quantity: $quantity}) {
            shoppingCartItem {
                _id
                note
                quantity
            }
        }
    }
`;

export const UPDATE_DELIVERY_DATE = gql`
    mutation updateCartDeliveryDate($cartId: Int!, $deliveryDate: String!, $productSubstitutionAllowed: Boolean) {
        updateShoppingCart(input: {cartId: $cartId, deliveryDate: $deliveryDate, productSubstitutionAllowed: $productSubstitutionAllowed}) {
            shoppingCart {
                _id
                scheduledDate,
                productsSubstitutionAllowed
            }
        }
    }
`;
