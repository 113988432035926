import React from "react";
import * as Statuses from '../../../constants/OrderStatus';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import {makeStyles} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import {useIntl} from "react-intl";
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import RemoveShoppingCartOutlinedIcon from '@material-ui/icons/RemoveShoppingCartOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(theme => ({
    [Statuses.ORDER_WAITING]: {
        backgroundColor: '#ffd9b3',
        color: '#994d00'
    },
    [Statuses.ORDER_SENT_TO_SUPPLIER]: {
        backgroundColor: '#fff3a6',
        color: '#965835'
    },
    [Statuses.ORDER_RECEIVED_BY_SUPPLIER]: {
        backgroundColor: "#99e9ff",
        color: '#006b7f'
    },
    [Statuses.ORDER_VALIDATED]: {
        backgroundColor: '#a6f2d9',
        color: '#24705c'
    },
    [Statuses.ORDER_FAILED]: {
        backgroundColor: '#ffb3c0',
        color: '#803f4d'
    },
    [Statuses.ORDER_DELIVERED]: {
        backgroundColor: '#adebad',
        color: '#326c37'
    },
    [Statuses.ORDER_PAYED]: {
        backgroundColor: '#b3e6b3',
        color: '#206020'
    },
    [Statuses.ORDER_ABANDONED]: {
        backgroundColor: '#ffcccc',
        color: '#990000'
    },
    [Statuses.ORDER_CANCELLED]: {
        backgroundColor: '#ffc2b3',
        color: '#7e4b40'
    },
    ['ARCHIVED']: {
        backgroundColor: '#e0ebeb',
        color: '#3d5c5c',
    },
    gavelIcon:
        {
            marginLeft: 6,
            marginBottom: 1
        }
}));

const OrderStatus = ({status}) => {
    const classes = useStyles();
    const intl = useIntl();

    const statusLabel = {
        [Statuses.ORDER_WAITING]:
            intl.formatMessage(
                {
                    id: 'status.waiting',
                    defaultMessage: 'En attente'
                }),
        [Statuses.ORDER_SENT_TO_SUPPLIER]:
            intl.formatMessage(
                {
                    id: 'status.sendToSupplier',
                    defaultMessage: 'Envoyé au fournisseur'
                }),
        [Statuses.ORDER_RECEIVED_BY_SUPPLIER]:
            intl.formatMessage(
                {
                    id: 'status.receivedBySupplier',
                    defaultMessage: 'Reçu par le fournisseur'
                }),
        [Statuses.ORDER_VALIDATED]:
            intl.formatMessage(
                {
                    id: 'status.validated',
                    defaultMessage: 'Validée'
                }),
        [Statuses.ORDER_FAILED]:
            intl.formatMessage(
                {
                    id: 'status.error',
                    defaultMessage: 'Erreur'
                }),
        [Statuses.ORDER_DELIVERED]:
            intl.formatMessage(
                {
                    id: 'status.delivered',
                    defaultMessage: 'Livrée'
                }),
        [Statuses.ORDER_PAYED]:
            intl.formatMessage(
                {
                    id: 'status.payed',
                    defaultMessage: 'Payée'
                }),
        ['ARCHIVED']:
            intl.formatMessage(
                {
                    id: 'status.archived',
                    defaultMessage: 'Archivé'
                }),
        [Statuses.ORDER_CANCELLED]:
            intl.formatMessage(
                {
                    id: 'status.canceled',
                    defaultMessage: 'Annulée'
                }),
        [Statuses.ORDER_ABANDONED]:
            intl.formatMessage(
                {
                    id: 'status.abandoned',
                    defaultMessage: 'Abandonée'
                }),
    };

    switch (status) {
        case Statuses.ORDER_WAITING:
            return (
                <Chip label={statusLabel[Statuses.ORDER_WAITING]} size="small"
                      className={classes[Statuses.ORDER_WAITING]}
                      icon={<HourglassEmptyOutlinedIcon fontSize={"small"}
                                                        className={classes[Statuses.ORDER_WAITING]}/>}/>);
        case Statuses.ORDER_PAYED:
            return (
                <Chip label={statusLabel[Statuses.ORDER_PAYED]} size="small"
                      className={classes[Statuses.ORDER_PAYED]}
                      icon={<CheckOutlinedIcon fontSize={"small"} className={classes[Statuses.ORDER_PAYED]}/>}/>);
        case Statuses.ORDER_SENT_TO_SUPPLIER:
            return (
                <Chip label={statusLabel[Statuses.ORDER_SENT_TO_SUPPLIER]} size="small"
                      className={classes[Statuses.ORDER_SENT_TO_SUPPLIER]}
                      icon={<SendOutlinedIcon fontSize={"small"}
                                              className={classes[Statuses.ORDER_SENT_TO_SUPPLIER]}/>}/>);
        case Statuses.ORDER_RECEIVED_BY_SUPPLIER:
            return (<Chip label={statusLabel[Statuses.ORDER_RECEIVED_BY_SUPPLIER]} size="small"
                          className={classes[Statuses.ORDER_RECEIVED_BY_SUPPLIER]}
                          icon={<MarkunreadMailboxOutlinedIcon fontSize={"small"}
                                                               className={classes[Statuses.ORDER_RECEIVED_BY_SUPPLIER]}/>}/>);
        case Statuses.ORDER_VALIDATED:
            return (<Chip label={statusLabel[Statuses.ORDER_VALIDATED]} size="small"
                          className={classes[Statuses.ORDER_VALIDATED]}
                          icon={<CheckOutlinedIcon fontSize={"small"}
                                                   className={classes[Statuses.ORDER_VALIDATED]}/>}/>);
        case Statuses.ORDER_FAILED:
            return (<Chip label={statusLabel[Statuses.ORDER_FAILED]} size="small"
                          className={classes[Statuses.ORDER_FAILED]}
                          icon={<ErrorOutlineOutlinedIcon fontSize={"small"}
                                                          className={classes[Statuses.ORDER_FAILED]}/>}/>);
        case Statuses.ORDER_DELIVERED:
            return (<Chip label={statusLabel[Statuses.ORDER_DELIVERED]} size="small"
                          className={classes[Statuses.ORDER_DELIVERED]}
                          icon={<LocalShippingOutlinedIcon fontSize={"small"}
                                                           className={classes[Statuses.ORDER_DELIVERED]}/>}/>);
        case 'ARCHIVED':
            return (<Chip label={statusLabel['ARCHIVED']} size="small"
                          className={classes['ARCHIVED']}
                          icon={<BookOutlinedIcon fontSize={"small"} className={`${classes['ARCHIVED']}`}/>}/>);
        case Statuses.ORDER_CANCELLED:
            return (<Chip label={statusLabel[Statuses.ORDER_CANCELLED]} size="small"
                          className={classes[Statuses.ORDER_CANCELLED]}
                          icon={<CancelOutlinedIcon fontSize={"small"}
                                                    className={`${classes[Statuses.ORDER_CANCELLED]}`}/>}/>);
        case Statuses.ORDER_ABANDONED:
            return (<Chip label={statusLabel[Statuses.ORDER_ABANDONED]} size="small"
                          className={classes[Statuses.ORDER_ABANDONED]}
                          icon={<RemoveShoppingCartOutlinedIcon fontSize={"small"}
                                                                className={`${classes[Statuses.ORDER_ABANDONED]}`}/>}/>);
        default:
            return (<Chip label={status} size="small"
                          className={classes['ARCHIVED']}
                          icon={<InfoOutlinedIcon fontSize={"small"} className={`${classes['ARCHIVED']}`}/>}/>);
    }
};

export default OrderStatus;
