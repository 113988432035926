import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import {FormattedMessage} from "react-intl";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    actionContainer: {
        marginTop: 10
    },
    phoneIcon: {
        marginRight: 10
    },

    contactSupportLink: {
        width: '100%'
    },
    commande: {
        width: '100%',
    }
}));

const SupplierSav = ({
                         supplier,
                         isAvailable,
                         push,
                         setSelectedRestaurant,
                         denyAccessToUnauthenticatedUser,
                         displayMenu
                     }) => {
    const classes = useStyles();
    return (
        <>
            {supplier && isAvailable() && (
                <>
                    <Grid container justify="center" className={classes.actionContainer}>
                        <Button size="small" variant="outlined" color="primary" href={`tel:${supplier.supportPhone}`}
                                className={classes.contactSupportLink}>
                            <PhoneOutlinedIcon className={classes.phoneIcon}/> <FormattedMessage
                            id="supplierCard.contactSupport"
                            defaultMessage="Appeler le SAV"/>
                        </Button>
                    </Grid></>)}
        </>
    )
}
export default SupplierSav