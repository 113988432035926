import {gql} from "apollo-boost";

export const GET_RESTAURANT_SUPPLIERS_QUERY = gql`
    query getRestaurantSupppliers($first: Int,$last: Int,$before: String,$after: String,$restaurantId: Int) {
        suppliers(first: $first,last: $last,before: $before,after:$after,restaurantId: $restaurantId, status: true, sort: "frequently_ordered") {
            edges {
                node {
                    id
                    _id
                    name
                    description
                    imageUrl
                    email
                    supportEmails
                    phone
                    supportPhone
                    categories
                    deliveryDates
                    deliveryDays
                    paymentMethods
                    createdByUser
                    minOrderAmount
                    orderCutOff
                    orderModality
                    deliveryTerms
                    deliveryCost
                    freeShippingThreshold
                    currency
                    paymentProvider
                    minNbrItemLines
                    locale
                    paymentMethod
                    accountStatus
                    lastOrderDate
                    hasPromotions
                    orderUnit
                }
            }
        }
    }
`;

export const GET_SUPPLIER_QUERY = gql`
    query getsupplier($id: Int!,$restaurantId: Int) {
        supplier(id: $id, restaurantId: $restaurantId) {
            id
            _id
            name
            description
            imageUrl
            email
            supportEmails
            phone
            supportPhone
            categories
            deliveryDates
            deliveryDays
            paymentMethods
            createdByUser
            minOrderAmount
            orderCutOff
            orderModality
            deliveryTerms
            deliveryCost
            freeShippingThreshold
            currency
            paymentProvider
            stripePublishableKey
            minNbrItemLines
            locale
            paymentMethod
            accountStatus
            lastOrderDate
            hasPromotions
            orderUnit
        }
    }
`;

export const GET_SUPPLIER_INFO_QUERY = gql`
    query getSupplierInfo($id: Int!) {
        supplier(id: $id) {
            _id
            id
            name
            email
            telephone
            partner
            createdByUser
            status
            nbUnreadMessages
            minOrderAmount
            openingDays
            orderCutOff
            deliveryCost
            deliveryWeightUnit
            categories
            currentPaymentMethod
        }
    }
`;

export const GET_SHOPPING_CARTS_QUERY = gql`
    query ShoppingCarts($restaurantId: Int!) {
        shoppingCarts(first: 100, restaurantId: $restaurantId) {
            totalCount
            edges {
                node {
                    _id
                    supplier {
                        _id
                    }
                }
            }
        }
    }
`;
