export const APP_VERSION = '1.18.4';

export const STRIPE_TEST_PUBLIC_KEYS =
    {
        EUR: 'pk_test_51GSO3jEypY5nHqtjmFpDSYRRpziZqxdyP4KDFWKq4MQVVmFKV7ZXHE4sTtHlIMJSZnqweHcgRZvqqnuZajXx3e2d004uazq0hT',
        USD: 'pk_test_51LwQyrBLfRkl0fyLBMSBTNXrTkHHpfTexzyV3h9YWPS2QQCn7IhzrBBe3a3gsL3X5lJi9LZOf5E6a7BQZELOJJps009KP52M1z',
        GBP: 'pk_test_51M54PgHJb04qu1UHgsOQED5Mq3UJG3cZjbqHoghgemRcP6WJRgz6pEMcIGW0m1z1yyw539wMZVd3GmlmaK0S0oAE00vM2iTyt9',
    };

export const STRIPE_PROD_PUBLIC_KEYS = {
    EUR: 'pk_live_Z7MVKj4BG9vdzjTUsw4vaiAD00oyrA3asB',
    USD: 'pk_live_51LwQyrBLfRkl0fyLXsyMaigkbxLMCdIQdAxtHWb8JzXdro170gIy5bU8nEEzYdapuC0YZ7L0UIkKleakFKa3n2Mh009OXgqV4A',
    GBP: 'pk_live_51M54PgHJb04qu1UHJM0vUjwF6aZ4RYK8V7B6Uzhx58kyylbQqbKLQScw8f3daf2vFDyMreiMrH7JLvY5SI99K3PS00WmCTBbkT'
};
