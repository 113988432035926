import {connect} from 'react-redux';
import {closeDrawer} from "../../actions/drawerActions";
import leftDrawer from "../../components/Drawer/LeftDrawer";
import {bindActionCreators} from "redux";
import {push} from 'connected-react-router';
import {updateTitle} from "../../reducers/topNavigation";
import {selectLanguage} from "../../reducers/authentication";

const mapStateToProps = state => ({
    drawerState: state.drawerState,
    pathname: state.router.location.pathname,
    user: {
        email: state.authentication.email,
        userType: state.authentication.userType,
        language: state.authentication.language,
    },
    selectedRestaurantId: state.restaurants.selectedRestaurantId,
});

const mapDispatchToProps = (dispatch) => ({
    closeDrawer: () => dispatch(closeDrawer()),
    updateTopNavTitle: bindActionCreators(updateTitle, dispatch),
    push: bindActionCreators(push, dispatch),
    selectLanguage: bindActionCreators(selectLanguage, dispatch)
});

const MainDrawer = connect(mapStateToProps, mapDispatchToProps)(leftDrawer);

export default MainDrawer;