import {gql} from "apollo-boost";

export const GET_RESTAURANTS_QUERY = gql`
    query GetRestaurants ($first: Int,$last: Int,$before: String,$after: String){
        restaurants(first: $first,last:$last,before:$before,after: $after) {
        edges{
              node{
                    _id
                    id
                    name
                    locale
       } }}
    }
`;


export const GET_RESTAURANT_QUERY = gql`
    query GetRestaurant($id: Int!){
        restaurant(id: $id) {
          id
          _id
          name
          locale
          preferedDeliveryTimeFrame
            type {
                id
                _id
                name
            }
          address{
                  id
                  _id
                  street
                  street2
                  zipCode
                  city
                  countryCode}
            }
        }
    
`;
