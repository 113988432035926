import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import OrderStatus from "../OrderStatus";
import Typography from "@material-ui/core/Typography";
import {FormattedDate, FormattedMessage, useIntl} from "react-intl";
import {makeStyles} from "@material-ui/core";
import ProductCardOrderDetails from "../../Products/ProductCardOrderDetails";
import Button from "@material-ui/core/Button";
import {blueGrey} from "@material-ui/core/colors";
import GeneralPurposeExpansionPanel from "../../GeneralPurpose/GeneralPurposeExpansionPanel";
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import {Skeleton} from "@material-ui/lab";
import parseIsoDate from "yup/lib/util/isodate";
import IconButton from "@material-ui/core/IconButton";
import {faCommentDots} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import amplitudeClient from "../../../amplitudeWrapper";
import FetchingError from "../../Errors/FetchingError";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import {ORDER_DELIVERED, PAYMENT_NONE} from "../../../constants/OrderStatus";
import LinearProgress from "@material-ui/core/LinearProgress";
import CardContent from "@material-ui/core/CardContent";
import GeneralPurposeCard from "../../GeneralPurpose/GeneralPurposeCard";
import PaymentStatus from "../PaymentStatus";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {price} from "../../../utils/currency";
import GraphQlFetchData from "./graphQlFetchData";
import CreateNewClaimForm from "../../Claim/CreateNewClaimForm";

const useStyles = makeStyles(theme => ({
    container: {
        padding: 10
    },
    date: {
        marginBottom: 20
    },
    supplier: {},
    status: {
        marginBottom: 20
    },
    totalBox: {
        backgroundColor: blueGrey[100],
        borderRadius: 4,
        padding: 3,
        marginTop: 10,
        marginBottom: 10
    },
    actionButton: {
        width: '100%',
        marginTop: 15,
        fontSize: '0.75rem'
    },
    infoLabel: {
        fontSize: '0.8rem',
        fontWeight: '600'
    },
    shippingAddress: {
        fontSize: '0.75rem'
    },
    total: {
        fontSize: '1.2rem'
    },
    infoBlock: {
        padding: 10
    },
    title: {
        fontSize: '1rem',
        fontWeight: '500',
        color: 'white',
        backgroundColor: theme.palette.primary.main
    },
    dialogText: {
        marginTop: 15
    },
    shippingFeesCardContent: {
        paddingBottom: '16px !important'
    },
    shippingFeesIcon: {
        fontSize: '2rem',
        marginRight: 30
    },
    shippingFees: {
        marginLeft: 20,
        backgroundColor: theme.palette.grey[100],
        padding: '5px 20px',
        borderRadius: '50px',
    },
    shippingFeesText: {
        fontWeight: 600,
        fontSize: '0.9rem',
        textTransform: 'uppercase'
    },
    shippingFeesCard: {
        marginTop: 10,
        marginBottom: 10
    },
    paymentStatus: {
        padding: 10,
    },
    statusesHistory: {
        marginTop: 10
    },
    paymentStatusLabel: {
        textTransform: 'uppercase',
        fontSize: '0.7rem',
        fontWeight: 'bold',
    }
}));

const OrderDetailsSheet = ({
                               orderId,
                               updateOverlayTitle,
                               push,
                               triggerNotification,
                               locale
                           }) => {
    const classes = useStyles();
    const intl = useIntl();
    const {error, data, refetch, updateStatus, mutationLoading} = GraphQlFetchData({orderId})

    const [alertOpen, setAlertOpen] = useState(false);
    const [claimFormOpenState, setClaimFormOpenState] = useState(false);

    const closeAlert = () => {
        setAlertOpen(false);
    };

    const orderLoc = intl.formatMessage(
        {
            id: 'orderDetails.order',
            defaultMessage: 'Commande'
        }
    );

    updateOverlayTitle('isLoading');

    if (data) {
        updateOverlayTitle(`${orderLoc} #${data.order.reference}`);
    }

    const goToChat = () => {
        amplitudeClient.logEvent('chat displayed', {origin: 'supplier card'});
        push(`/restaurants/123aqw/suppliers/${window.btoa(data.order.supplier.id)}/chat`, {modal: true, keepOpen: true});
    };

    const goToLitigation = () => {
        setClaimFormOpenState(true)
    };

    const refetchData = () => {
        refetch().then(null).catch(e => null);
    };

    const setOrderStatusOnDelivered = () => {
        updateStatus({
            variables: {
                id: orderId,
                status: ORDER_DELIVERED
            }
        })
            .then(() => closeAlert())
            .catch(e => e)
    };

    if (error) {
        return (
            <FetchingError action={refetchData}/>);
    }

    const processStatusesHistory = () => {
        const history = [];

        data.order.statusHistory.edges.map((status, key) => {
            if (history.length === 0 || status['status'] !== history[0]['status']) {
                history.unshift(status);
            }
        })

        return history;
    }

    return (
        <Grid container className={classes.container}>
            {data && data.order &&
            <CreateNewClaimForm openState={claimFormOpenState} close={() => setClaimFormOpenState(false)}
                                restaurantId={data.order.restaurant._id} supplierId={data.order.supplier._id}
                                triggerNotification={triggerNotification}/>
            }
            <Dialog
                open={alertOpen}
                onClose={closeAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={classes.title} disableTypography>
                    <Grid container justify="center">
                        <LocalShippingIcon/>
                        <Typography align="center">
                            <FormattedMessage id="orderDetails.createClaimButton"
                                              defaultMessage="Déclarer un litige"/>
                        </Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" align="center" className={classes.dialogText}>
                        <FormattedMessage id="orderDetails.confirmDeliveryText"
                                          defaultMessage="Votre commande a-t-elle bien été livré ?"/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={setOrderStatusOnDelivered} color="primary">
                        <FormattedMessage id="orderDetails.yes"
                                          defaultMessage="Oui"/>
                    </Button>
                    <Button onClick={closeAlert} color="primary" autoFocus>
                        <FormattedMessage id="orderDetails.no"
                                          defaultMessage="Non"/>
                    </Button>
                </DialogActions>
                {mutationLoading ? <LinearProgress/> : null}
            </Dialog>
            <Grid item container justify="center" className={classes.status}>
                {data ?
                    (<>
                        {data.order.statusHistory.edges.length !== 0 ? <OrderStatus status={data.order.status}/> :
                            <OrderStatus status={'ARCHIVED'}/>}
                    </>) : <Skeleton width="60%" height={40}/>}
            </Grid>
            <GeneralPurposeExpansionPanel
                heading={data ? <FormattedMessage id="orderDetails.info" defaultMessage="Informations"/> :
                    <Skeleton width="100%"/>}>
                <Grid item container direction="column" spacing={2} className={classes.infoBlock}>
                    <Grid container item wrap="nowrap" alignItems="center" justify="space-between">
                        {data ? (<>
                                    <Grid item>
                                        <Typography variant="body1" className={classes.infoLabel}>
                                            <FormattedMessage id="orderDetails.supplier" defaultMessage="Fournisseur"/>
                                        </Typography>
                                    </Grid>

                                </>
                            ) :
                            <Skeleton width="100%"/>}
                    </Grid>
                    <Grid container item wrap="nowrap" alignItems="center" justify="space-between">
                        {data ? (<>
                                    <Grid item>
                                        <Typography variant="body1" className={classes.infoLabel}>
                                            <FormattedMessage id="orderDetails.address"
                                                              defaultMessage="Adresse de livraison"/>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" align="right" className={classes.shippingAddress}>
                                            {`${data.order.shippingAddress.street} ${data.order.shippingAddress.zipCode} ${data.order.shippingAddress.city}`}
                                        </Typography>
                                    </Grid></>
                            ) :
                            <Skeleton width="100%"/>}
                    </Grid>
                    <Grid container item wrap="nowrap" alignItems="center" justify="space-between">
                        {data ? (
                                <>
                                    <Grid item>
                                        <Typography variant="body1" className={classes.infoLabel}>
                                            <FormattedMessage id="orderDetails.createDate"
                                                              defaultMessage="Date de création"/>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" align="right" className={classes.shippingAddress}>
                                            <FormattedDate value={parseIsoDate(data.order.deliveryDate)}
                                                           year="numeric"
                                                           month="long"
                                                           day="2-digit"/>
                                        </Typography>
                                    </Grid>
                                </>
                            ) :
                            <Skeleton width="100%"/>}
                    </Grid>
                    <Grid container item wrap="nowrap" alignItems="center" justify="space-between">
                        {data ? (<>
                                    <Grid item>
                                        <Typography variant="body1" className={classes.infoLabel}>
                                            <FormattedMessage id="orderDetails.total"
                                                              defaultMessage="Total (approximatif)"/>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" className={classes.total}>
                                            {price(parseFloat(data.order.totalWithVat).toFixed(2), data.order.currency)}
                                        </Typography>
                                    </Grid>
                                </>
                            ) :
                            <Skeleton width="100%"/>}
                    </Grid>
                </Grid>
            </GeneralPurposeExpansionPanel>
            <GeneralPurposeExpansionPanel heading={data ? "Actions" : <Skeleton width="100%"/>}>
                <Grid container justify="center">
                    {data ? (data.order.status !== ORDER_DELIVERED ? (
                            <Button className={classes.actionButton} variant="contained" color="primary"
                                    startIcon={<LocalShippingOutlinedIcon/>}
                                    onClick={() => setAlertOpen(true)}><FormattedMessage
                                id="orderDetails.MarkAsDeliveredButtonText"
                                defaultMessage="Marquer la commande comme livrée"/></Button>) : null)
                        :
                        <Skeleton width="80%" height={50}/>}
                    {data ?
                        <Button className={classes.actionButton} variant="outlined" color="primary"
                                onClick={goToLitigation}
                                startIcon={<ReportProblemOutlinedIcon/>}><FormattedMessage
                            id="orderDetails.reportLitigationButtonText"
                            defaultMessage="Signaler un problème"/></Button>
                        :
                        <Skeleton width="80%" height={50}/>}

                </Grid>
            </GeneralPurposeExpansionPanel>
            <GeneralPurposeExpansionPanel heading={data ? <FormattedMessage id="orderDetails.articles"
                                                                            defaultMessage="Articles"/> :
                <Skeleton width="100%"/>} expanded={false}>
                {data ? (
                        <Grid item container spacing={1} justify="center">
                            {data.order.items.edges.map((product, index) => (
                                <Grid item xs={12} key={index}>
                                    <ProductCardOrderDetails product={product.node} locale={locale}
                                                             currency={data.order.currency}/>
                                </Grid>
                            ))}
                            {data.order.deliveryFeesWithVat > 0 && (
                                <Grid item xs={12} className={classes.shippingFeesCard}>
                                    <GeneralPurposeCard>
                                        <CardContent className={classes.shippingFeesCardContent}>
                                            <Grid container alignItems="center">
                                                <LocalShippingOutlinedIcon color="primary"
                                                                           className={classes.shippingFeesIcon}/>
                                                <Typography className={classes.shippingFeesText}>
                                                    <FormattedMessage id="cartSummary.shippingFees"
                                                                      defaultMessage="Frais de port"/>
                                                </Typography>
                                                <Typography className={classes.shippingFees}>
                                                    {price(data.order.deliveryFeesWithVat.toFixed(2), data.order.currency)}
                                                </Typography>
                                            </Grid>
                                        </CardContent>
                                    </GeneralPurposeCard>
                                </Grid>
                            )}
                        </Grid>
                    )
                    : <Skeleton/>}
            </GeneralPurposeExpansionPanel>
            <GeneralPurposeExpansionPanel heading={data ? <FormattedMessage id="orderDetails.history"
                                                                            defaultMessage="Historique"/> :
                <Skeleton width="100%"/>} expanded={false}>
                {data ? (
                    <Grid item container direction="column" spacing={2} className={classes.infoBlock}>
                        {data.order.paymentStatus != PAYMENT_NONE && (
                            <GeneralPurposeCard className={classes.paymentStatus}>
                                <Grid container item wrap="nowrap" alignItems="center" justify="space-between">
                                    <Grid item>
                                        <Typography className={classes.paymentStatusLabel}>
                                            <FormattedMessage id="orderDetails.paymentStatus"
                                                              defaultMessage="État du paiement"/>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <PaymentStatus status={data.order.paymentStatus}/>
                                    </Grid>
                                </Grid>
                            </GeneralPurposeCard>
                        )}
                        <TableContainer component={GeneralPurposeCard} className={classes.statusesHistory}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"><FormattedMessage id="orderDetails.date"
                                                                                    defaultMessage="Date"/></TableCell>
                                        <TableCell align="center"><FormattedMessage id="orderDetails.orderStatus"
                                                                                    defaultMessage="Statut commande"/></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {processStatusesHistory().map((status, key) => {
                                        return (<TableRow key={key}>
                                            <TableCell component="th" scope="row" align="center">
                                                <Typography variant="body1" className={classes.infoLabel}>
                                                    <FormattedDate value={parseIsoDate(status.node.createdAt)}
                                                                   year="numeric"
                                                                   month="numeric"
                                                                   day="numeric"
                                                                   hour="numeric"
                                                                   minute="numeric"
                                                                   second="numeric"
                                                    />

                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <OrderStatus status={status.node.status}/>
                                            </TableCell>
                                        </TableRow>)
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                ) : <Skeleton width="100%"/>}

            </GeneralPurposeExpansionPanel>
        </Grid>
    );
};

export default OrderDetailsSheet;
