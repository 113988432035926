import {useIntl} from 'react-intl';
import * as Yup from "yup";

export function Hooks() {
    const passwordTexts = {
        label: useIntl().formatMessage({
            id: 'newPassword.passwordLabel',
            defaultMessage: 'Nouveau mot de passe',
        }),
        placeholder: useIntl().formatMessage({
            id: 'newPassword.passwordPlaceholder',
            defaultMessage: 'Entrez un nouveau mot de passe'
        }),
        repeatLabel: useIntl().formatMessage({
            id: 'newPassword.password2Label',
            defaultMessage: 'Retaper votre nouveau mot de passe',
        }),
        repeatPlaceholder: useIntl().formatMessage({
            id: 'newPassword.password2Placeholder',
            defaultMessage: 'Entrez à nouveau votre nouveau mot de passe'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'newPassword.passwordRequired',
            defaultMessage: 'Veuillez choisir un nouveau mot de passe'
        })
    };

    const notificationText = {
        error: useIntl().formatMessage(
            {
                id: 'newPassword.error',
                defaultMessage: 'Une erreur est survenue'
            }
        ),
        passwordsDontMatch: useIntl().formatMessage(
            {
                id: 'newPassword.passwordsDontMatch',
                defaultMessage: 'Les deux mots de passe ne sont pas identique.'
            }
        ),
        success: useIntl().formatMessage(
            {
                id: 'newPassword.success',
                defaultMessage: 'Votre mot de passe a bien été mis à jour. Vous allez être redirigé vers la page de connexion dans quelques instant.'
            }
        ),
    };

    const formValidate = {
        newPassword: Yup.string()
            .required(passwordTexts.errorRequired),
        newPasswordRepeat: Yup.string()
            .required(passwordTexts.errorRequired),
    }

    return ({
        notificationText, passwordTexts, formValidate
    })
}