import {connect} from 'react-redux';
import UpdateScreen from "../../components/UpdateScreen";

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({});

const UpdatePage = connect(mapStateToProps, mapDispatchToProps)(UpdateScreen);

export default UpdatePage;
