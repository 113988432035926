import {useIntl} from 'react-intl';

function Hooks() {
    const intl = useIntl()
    const pageTitlePro = intl.formatMessage(
        {
            id: 'restaurantsPage.title',
            defaultMessage: 'Mes Restaurants'
        },
    );

    const pageTitle = intl.formatMessage(
        {
            id: 'restaurantsPage.addresses',
            defaultMessage: 'Mes Adresses'
        },
    );
    return ({
        pageTitlePro, pageTitle
    })
}

export default Hooks