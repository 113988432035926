import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import ProductCardMercurial from "../../Products/ProductCardMercurial";
import {triggerToastNotification} from "../../../reducers/toastNotification";
import {findIndex} from "lodash";
import {cloneDeep} from "@apollo/client/utilities/common/cloneDeep";
import {makeStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useDispatch} from "react-redux";
import GraphQlFetchData from "../graphQlFetchData";

const useStyles = makeStyles(theme => ({
    innerGrid: {
        padding: '5px 10px',
    },
    productsBox: {
        flex: '1 1 auto',
    },
    supplierHeader: {
        backgroundColor: theme.palette.secondary.main,
        padding: '10px 20px',
        marginBottom: 10,
        marginTop: 25,
        boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;',
    },
    supplierName: {
        fontSize: '1.2rem',
        color: 'white'
    }
}));

const SupplierPromoBox = ({supplier, restaurantId}) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const {createShoppingList, mutationLoading, shoppingListData, shoppingListLoading} = GraphQlFetchData({
        supplier,
        restaurantId
    })

    if (shoppingListData && !mutationLoading) {
        if (shoppingListData.supplierShoppingList === null) {
            createShoppingList({
                variables: {
                    restaurantId: restaurantId,
                    supplierId: supplier.supplierId,
                    listName: `${restaurantId} - ${supplier.supplierName}`
                }
            }).then().catch(e => console.log(e));
        }
    }

    const processProduct = () => {
        const sortedProduct = [];
        //We need to clone the products because the object is immutable
        const products = cloneDeep(supplier.products);
        if (products) {
            products.map((product) => {
                if (shoppingListData && shoppingListData.supplierShoppingList !== null) {
                    const index = findIndex(shoppingListData.supplierShoppingList.items, (item) => {
                        return item.offers._id === product._id;
                    });

                    if (index !== -1) {
                        product.inShoppingList = true;
                        product.shoppingListItemId = shoppingListData.supplierShoppingList.items[index]._id;
                    } else {
                        product.inShoppingList = false;
                    }
                }
                sortedProduct.push(product);
            });
        }

        return sortedProduct;
    }

    console.log(shoppingListData);

    return (
        <Grid>
            <Grid className={classes.supplierHeader}>
                <Typography className={classes.supplierName}>
                    {supplier.supplierName}
                </Typography>
            </Grid>
            {shoppingListData && shoppingListData.supplierShoppingList !== null && (
                <>
                    <Grid container className={classes.productsBox}>
                        {processProduct().map((product) =>
                            <Grid item xs={12} key={product._id} className={classes.innerGrid}>
                                <ProductCardMercurial product={product} shoppingList={shoppingListData.supplierShoppingList}
                                                      supplierId={supplier.supplierId}
                                                      restaurantId={restaurantId}
                                                      triggerNotification={(toastData) => dispatch(triggerToastNotification(toastData))}/>
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
            {(mutationLoading || shoppingListLoading) && <CircularProgress/> &&
            <Grid container alignContent="center" justify="center" className={classes.progressBox}>
                <Grid item>
                    <CircularProgress/>
                </Grid>
            </Grid>
            }
        </Grid>
    )
}

export default SupplierPromoBox;