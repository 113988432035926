import {useIntl} from 'react-intl';

export function Hooks() {
    const pageTitle = useIntl().formatMessage(
        {
            id: 'newSupplierPage.title',
            defaultMessage: 'Créer un nouveau fournisseur'
        }
    );


    const notificationText = {
        success: useIntl().formatMessage(
            {
                id: 'createSupplierPage.SuccessNotificationText',
                defaultMessage: 'Fournisseur créé avec succès'
            }
        ),
        error: useIntl().formatMessage(
            {
                id: 'createSupplierPage.errorNotificationText',
                defaultMessage: 'Une erreur est survenue lors de la création d\'un nouveau fournisseur.'
            }
        ),
    };
    return ({
        notificationText, pageTitle
    })
}