import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import SearchBar from "../../SearchBar";
import {useIntl} from "react-intl";
import ProductCard from "../../Products/ProductCard";
import SearchResults from "react-filter-search";
import {AutoSizer, CellMeasurer, CellMeasurerCache, List} from "react-virtualized";
import Dialog from "@material-ui/core/Dialog";
import {INDIVIDUAL} from "../../../constants/UserTypes";
import IconButton from "@material-ui/core/IconButton";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import Typography from "@material-ui/core/Typography";
import FloatingCart from "../../Cart/FloatingCart";
import {isEmpty} from "lodash";

const useStyles = makeStyles(theme => ({
    container: {
        height: '100vh'
    },
    withFloatingCart: {
        paddingBottom: 64
    },
    innerGrid: {
        padding: '5px 10px',
    },
    search: {
        flexGrow: 1
    },
    topContainer: {
        padding: 10,
        boxShadow: '0px 8px 8px 0px rgba(0,0,0, 0.1)',
        zIndex: 1100,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
    },
    productsBox: {
        flex: '1 1 auto',
    },
    categoryFilterProduct: {
        width: '100%',
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    closeEditButton: {
        marginTop: 7,
        borderRadius: 0,
        padding: 8
    },
    alertDialogHeader: {
        backgroundColor: theme.palette.error.main,
        color: 'white',
        textAlign: 'center',
        fontSize: '1rem'
    },
    alertDialogIcon: {
        fontSize: '2rem'
    },
    alertDialogText: {
        fontSize: '0.9rem',
        textAlign: 'center',
        marginTop: 15,
        fontWeight: 600
    },
    link: {
        marginTop: 30,
        display: 'block'
    },
    categoryImage: {
        height: 0,
        paddingBottom: '30%',
        borderRadius: theme.spacing(1),
        backgroundColor: '#fff',
        position: 'absolute',
        width: '95%',
        top: 65,
        zIndex: '100',
        marginTop: 0,
        boxShadow: '0px 8px 7px -1px rgba(180,201,219,0.2), 0px 0px 10px 1px rgba(180,201,219,0.1), 0px 1px 3px 1px rgba(180,201,219,0.1)',
        '&:after': {
            content: '" "',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'linear-gradient(147deg, #eafbf1 0%, #95e9b7 74%)',
            borderRadius: theme.spacing(1), // 16
            opacity: 0.5,
        },
    },
    titleBox: {
        position: 'relative',
        zIndex: 1000
    }
}));

//@TODO: too many rerender, make better conditions adn state management to reduce rerenders
//@TODO: reorganise state management instructions
const SupplierIndividualProductList = ({
                                           push,
                                           initNewCart,
                                           cart,
                                           restaurantId,
                                           supplierId,
                                           supplierTrueId,
                                           restaurantTrueId,
                                           addProductToCart,
                                           subtractProductFromCart,
                                           products,
                                           denyAccessToUnauthenticatedUser,
                                           triggerNotification,
                                           setProductQuantity,
                                           openState,
                                           close,
                                           supplier,
                                           category
                                       }) => {

    const classes = useStyles();
    const intl = useIntl();
    const [searchValue, setSearchValue] = useState('');

    //If no cart found for the supplier, init a new one
    if (cart === undefined) {
        initNewCart({supplierId: supplierId, restaurantId: restaurantId});
        cart = {products: {}, total: 0}
    }

    //virtualized rendering cache
    let cache = new CellMeasurerCache({
        fixedWidth: true,
    });
    useEffect(() => {
        denyAccessToUnauthenticatedUser();

    }, [])
    const handleChange = event => {
        const {value} = event.target;
        setSearchValue(value);
    };

    const searchPlaceholder = intl.formatMessage(
        {
            id: 'supplierShop.searchPlaceholder',
            defaultMessage: 'Recherche'
        }
    );

    const isDeliveryAvailable = () => {
        return !isEmpty(supplier.openingDays);
    }

    console.log(supplier)
    return (
        <>
            <Dialog fullScreen open={openState} onClose={close}>
                <Grid container direction="column"
                      className={`${classes.container} ${cart.totalQuantity ? (classes.withFloatingCart) : null}`}>
                    <Grid container alignItems="center" className={classes.topContainer}>
                        <Grid item container alignItems="center">
                            <Grid item container wrap="nowrap">
                                <IconButton onClick={close}>
                                    <CloseOutlinedIcon/>
                                </IconButton>
                                <Grid item className={classes.search}>
                                    <SearchBar placeholder={searchPlaceholder} onChange={handleChange}
                                               value={searchValue}/>
                                </Grid>
                            </Grid>
                            <Grid container item direction="column" alignItems="center">
                                <Grid item container direction="column" alignItems="center"
                                      className={classes.titleBox}>
                                    <Typography variant="h5">
                                        {category && category.name}
                                    </Typography>
                                    <Typography>
                                        {supplier.name}
                                    </Typography>
                                </Grid>
                                {/*<CardMedia
                                    className={classes.categoryImage}
                                    image={category && `/images${category.image}`}
                                />*/}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.productsBox}>
                        <SearchResults
                            value={searchValue}
                            data={products}
                            renderResults={results =>
                                (<>
                                    <AutoSizer>
                                        {({height, width}) => (
                                            <List width={width}
                                                  height={height}
                                                  rowCount={results.length}
                                                  rowHeight={cache.rowHeight}
                                                  rowRenderer={({
                                                                    key,
                                                                    index,
                                                                    parent,
                                                                    isScrolling,
                                                                    isVisible,
                                                                    style
                                                                }) => {
                                                      return (
                                                          <CellMeasurer
                                                              cache={cache}
                                                              columnIndex={0}
                                                              key={key}
                                                              parent={parent}
                                                              rowIndex={index}
                                                          >
                                                              <Grid item xs={12} key={index} style={style}
                                                                    className={classes.innerGrid}>
                                                                  <ProductCard
                                                                      currency={supplier.currency}
                                                                      product={results[index].node}
                                                                      editMod={false}
                                                                      push={push}
                                                                      addToCart={addProductToCart}
                                                                      subtractFromCart={subtractProductFromCart}
                                                                      supplierId={supplierTrueId}
                                                                      restaurantId={restaurantTrueId}
                                                                      supplierIRI={supplierId}
                                                                      restaurantIRI={restaurantId}
                                                                      cartQuantity={cart.products[results[index].node.offer] ? cart.products[results[index].node.offer].quantity : 0}
                                                                      triggerNotification={triggerNotification}
                                                                      supplierActive={true}
                                                                      setProductQuantity={setProductQuantity}
                                                                      unavailable={isDeliveryAvailable() ? -1 : 1}
                                                                      userType={INDIVIDUAL}
                                                                  />
                                                              </Grid>
                                                          </CellMeasurer>
                                                      )
                                                  }}
                                                  overscanRowCount={4}
                                            />)}
                                    </AutoSizer>
                                </>)}
                        />
                    </Grid>
                </Grid>
                <FloatingCart push={push} cart={cart} orderMinAmount={supplier.minOrderAmount} noBottomBar={true}
                              currency={supplier.currency}/>
            </Dialog>
        </>
    );
};

export default SupplierIndividualProductList;
