import {createSlice} from "@reduxjs/toolkit";

const initialState =
    {
        variant: 'info',
        message: '',
        open: false,
        anchorOrigin: false,
    };

const toastNotificationSlice = createSlice({
    name: 'toastNotification',
    initialState: initialState,
    reducers: {
        triggerToastNotification(state, action) {
            state.variant = action.payload ? action.payload.variant : 'info';
            state.message = action.payload ? action.payload.message : '';
            state.anchorOrigin = action.payload ? action.payload.anchorOrigin : false;
            state.open = true;
        },
        closeToastNotification(state, action) {
            state.open = false;
        }
    }
});

export const {triggerToastNotification, closeToastNotification} = toastNotificationSlice.actions;
export default toastNotificationSlice.reducer;