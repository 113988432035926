import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    root: {
        '&:before': {
            backgroundColor: 'unset',
        },
        width: '100%',
        background: 'none',
        boxShadow: 'none',
        margin: '0 !important'
    },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        textTransform: 'uppercase',
        fontWeight: theme.typography.fontWeightRegular,
        width: '100%'
    },
    summary: {
        backgroundColor: 'white',
        borderBottom: '2px solid #e2e2e2',
        boxShadow: '0px 8px 7px -1px rgba(180,201,219,0.2), 0px 0px 10px 1px rgba(180,201,219,0.1), 0px 1px 3px 1px rgba(180,201,219,0.1)',
        minHeight: 'auto !important',
        marginBottom: 4,
        marginTop: 4,
        borderTop: 'none'
    },
    summaryContent: {
        margin: '0 !important'
    },
    details: {
        padding: 0,
    }
}));

const GeneralPurposeExpansionPanel = ({heading, children, id, expanded}) => {
    const classes = useStyles();

    if(expanded === undefined) {
        expanded = true;
    }

    return (
        <ExpansionPanel classes={{root: classes.root}} square defaultExpanded={expanded}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls={`panel${id}a-content`}
                id={`panel${id}a-header`}
                classes={{root: classes.summary, content: classes.summaryContent}}
            >
                <Typography className={classes.heading}>{heading}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{root: classes.details}}>
                {children}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default GeneralPurposeExpansionPanel;
