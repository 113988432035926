import {all} from 'redux-saga/effects';
import {watchDenyAccessToUnauthenticatedUser, watchLogin, watchLogout, watchRefreshToken} from "./authentication";
import {watchLaunchUpdate} from "./updater";
import {watchSendMessage} from "./support";

export default function* rootSaga() {
    yield all([
        watchDenyAccessToUnauthenticatedUser(),
        watchLogin(),
        watchLogout(),
        watchRefreshToken(),
        watchLaunchUpdate(),
        watchSendMessage(),
    ])
}
