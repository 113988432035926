import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {FormattedMessage} from "react-intl";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {blueGrey} from "@material-ui/core/colors";
import Avatar from "@material-ui/core/Avatar";
import StoreMallDirectoryOutlinedIcon from "@material-ui/icons/StoreMallDirectoryOutlined";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxOpen, faWeightHanging} from "@fortawesome/free-solid-svg-icons";
import ReactCountryFlag from "react-country-flag";
import AcUnitIcon from '@material-ui/icons/AcUnit';
import {price} from "../../../utils/currency";

const useStyles = makeStyles(theme => ({
    headingIcon: {
        marginBottom: 5
    },
    text: {
        marginTop: 10
    },
    header: {
        width: '100%',
        fontSize: '1rem',
        fontWeight: '500',
        textTransform: 'uppercase',
        borderTop: '5px solid #33d573',
    },
    avatar: {
        backgroundColor: blueGrey[100],
        width: theme.spacing(18),
        height: theme.spacing(18),
        marginRight: 10,
        marginLeft: "20px"
    },
    unit: {
        color: blueGrey[400],
        fontWeight: 300,
        fontSize: '0.8rem',
        marginTop: 15,
        display: 'flex',
        flexDirection: 'column',
    },
    price: {
        backgroundColor: blueGrey[100],
        fontSize: '1.2rem',
        marginBottom: 20,
        marginTop: 40,
        fontWeight: 600,
        display: 'flex',
        padding: '10px 20px',
        borderRadius: 50,
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    unitValue: {
        color: theme.palette.grey[800],
        fontSize: '1rem',
        marginTop: 10
    },
    priceDetail: {
        fontSize: '0.9rem',
        color: theme.palette.grey[800],
        marginLeft: 5
    },
    content: {
        fontSize: '0.8rem',
        color: blueGrey[400],
        marginTop: 15,
        display: 'flex',
        flexDirection: 'column',
    },
    contentValue: {
        color: theme.palette.grey[800],
        fontSize: '1rem',
        marginTop: 10
    },
    productInfoBlock: {
        flexBasis: '50%',
        backgroundColor: theme.palette.grey[50],
        borderRadius: 10,
        padding: '10px 5px',
        height: '100%'
    },
    infoBlockIcon: {
        color: 'white',
        fontSize: '1.4rem',
    },
    infoBlockIconWrapper: {
        backgroundColor: theme.palette.primary.main,
        marginTop: '-20px',
        borderRadius: 50,
        width: 45,
        height: 45,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    productInfo: {
        marginTop: 20
    },
    frozen: {
        marginLeft: '115px',
        color: '#3FD0D4',
    },
    surgelé: {
        marginLeft: '5px'
    },
    photoWarning: {
        color: theme.palette.grey[200]
    },
    origin: {
        width: '100%',
        fontSize: '0.9rem',
        fontWeight: 500,
        textTransform: 'uppercase',
        marginTop: 10
    },
    bio: {
        backgroundColor: '#00b33c',
        borderRadius: 50,
        fontSize: '0.6rem',
        color: 'white',
        fontWeight: 600,
        zIndex: 10000,
        left: '3px',
        bottom: '3px',
        padding: '6px 4px'
    }
}));

const ProductDetailsSheet = ({product, openState, toggleState}) => {
    const classes = useStyles();
    return (
        <Dialog
            open={openState}
            onClose={toggleState}
            fullWidth
        >
            <DialogTitle id="alert-dialog-title" align="center" className={classes.header} disableTypography>
                <Grid container justify="center">
                    {product.name}
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container wrap="nowrap" direction="column">
                    <Grid item container alignItems="center" justify="center">
                        {product.image ? (
                            <>
                                <Avatar className={classes.avatar} alt="P" variant="rounded"
                                        src={product.image.large}/>
                                <Typography variant="caption">
                                    <FormattedMessage id="productCategoryCard.NonContractualPicture" defaultMessage="Photo non
                                    contractuelle"/></Typography>
                            </>) : (
                            <Avatar className={classes.avatar} variant="rounded">
                                <StoreMallDirectoryOutlinedIcon className={classes.icon}/>
                            </Avatar>)}
                    </Grid>
                    <Grid container>
                        <Typography className={classes.origin} align="center">
                            {product && product.origin ? (
                                <>  <FormattedMessage id="productCategoryCard.origin" defaultMessage="Origine"/>
                                    {` : ${product.origin}`} {product.origin.includes('France') &&
                                    <ReactCountryFlag countryCode="FR" style={{
                                        marginLeft: 4,
                                        marginBottom: 2
                                    }} svg/>}
                                </>) : null}
                            {product && product.organic === 'BIO' ? (
                                <span className={classes.bio}>BIO</span>) : null}
                        </Typography>
                        {product && product.frozen ? (
                                <>
                                    <span className={classes.frozen}>  < AcUnitIcon/>  </span>
                                    <span className={classes.surgelé}> surgelé </span></>)
                            : null}
                    </Grid>
                    <Grid item container wrap="nowrap" className={classes.productInfo} justify="center" spacing={2}>
                        <Grid item>
                            <Grid container direction="column"
                                  justify="flex-start" alignItems="center" className={classes.productInfoBlock}>
                                <div className={classes.infoBlockIconWrapper}>
                                    <FontAwesomeIcon icon={faWeightHanging} className={classes.infoBlockIcon}/>
                                </div>
                                <Typography className={classes.unit} align="center"><FormattedMessage
                                    id="productCategoryCard.orderUnit" defaultMessage="Unité de commande"/></Typography>
                                <Typography className={classes.unitValue} align="center">{product.unit}</Typography>
                            </Grid>
                        </Grid>
                        {product.content ? (
                            <Grid item>
                                <Grid item container direction="column"
                                      justify="flex-start" alignItems="center" className={classes.productInfoBlock}>
                                    <div className={classes.infoBlockIconWrapper}>
                                        <FontAwesomeIcon icon={faBoxOpen} className={classes.infoBlockIcon}/>
                                    </div>
                                    <Typography className={classes.content} align="center">
                                        <FormattedMessage id="productCategoryCard.unitContent"
                                                          defaultMessage="Contenu de l'unité"/>
                                    </Typography>
                                    <Typography className={classes.contentValue} align="center">
                                        {product.content}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : null}
                    </Grid>
                    <Grid item container direction="column" alignItems="center">
                        <Grid item container alignItems="center" justify="center">
                            {product.priceDetailsWithoutVat ? (
                                <>
                                    <div className={classes.price}>
                                        {product.priceDetailsWithoutVat ? (
                                            <>
                                                {product.priceDetailsWithoutVat
                                                    , product.currency}
                                                <Typography className={classes.priceDetail}>
                                                    {product.priceDetailsWithoutVat}
                                                </Typography>
                                            </>
                                        ) : <>
                                            {`${price(product.price.toFixed(2), product.priceDetail.currency)} / ${product.priceDetail.unit}`}
                                        </>}
                                    </div>
                                </>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify="center">
                    <Button onClick={toggleState} color="primary">
                        <FormattedMessage id="productDetailsDialog.close" defaultMessage="Fermer"/>
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    )
};

export default ProductDetailsSheet;
