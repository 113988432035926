import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {Container} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";

import {indexOf} from "lodash";
import MainCatalog from "./mainCatalog";
import CatalogProducts from "./catalogProducts"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import GraphQlFetchData from "./GraphQlFetchData";
import {FormattedMessage} from "react-intl";
import FetchingError from "../../Errors/FetchingError";

const useStyles = makeStyles(theme => ({
    container: {
        overflow: 'hidden'
    },
    arrowBack: {
        cursor: 'pointer',
        margin: '15px 0px 10px 10px',
        "&:hover": {
            opacity: 0.8
        }
    }
}));

const SupplierCatalog = ({matchParams, ...rest}) => {
    const classes = useStyles()
    const [listProduct, setListProduct] = useState([])
    const [selectedCategoryId, setSelectedCategoryId] = useState(null)
    //We use base64 encoded id because the graphql id contain slashes
    let supplierId = null;
    let restaurantId = null;
    let decodeRestaurantId = null
    let decodeSupplierId = null
    //decode restaurant id
    if (matchParams?.restaurantId) {
        decodeRestaurantId = window.atob(matchParams.restaurantId)
        restaurantId = parseInt(decodeRestaurantId.split('/').pop());
    }
    // decode supplier id
    if (matchParams?.supplierId) {
        decodeSupplierId = window.atob(matchParams.supplierId)
        supplierId = parseInt(decodeSupplierId.split('/').pop());
    }
    const {
        restaurantData,
        restaurantError,
        taxonData,
        taxonError,
        taxonLoading,
        supplierData,
        supplierError,
        shoppingListData,
        shoppingListError,
        isLoadingSupplierProducts
    } = GraphQlFetchData({supplierId, restaurantId})

    const totalProductsByCat = (categoryId) => taxonData.taxa.edges.find(ele => ele.node._id === categoryId)

    let availableCategories = [];
    if (taxonData) {
        taxonData.taxa.edges.forEach((item, key) => {
            if (indexOf(availableCategories, parseInt(item.node._id)) === -1) {
                availableCategories.push(parseInt(item.node._id));
            }
        });
    }

    const onSelectCategory = (category) => {
        setSelectedCategoryId((category.id.toString()))
        const taxon = taxonData.taxa.edges.find(ele => ele.node._id === (category.id));
        let listOfProducts = taxon? taxon.node.products.edges : []
        rest.updateSubtitle(`${supplierData.supplier.name} - ${category.name}`)
        setListProduct(listOfProducts)
    }

    const goToCatalog = () => {
        setSelectedCategoryId(null)
        rest.updateSubtitle(supplierData.supplier.name);
    }

    useEffect(() => {
        rest.denyAccessToUnauthenticatedUser()
    }, [])
    //const {restaurant: {address}} = restaurantData || null // it crushes the app

    let content = "";

    if (isLoadingSupplierProducts) content =
        <Container>
            {[1, 2, 3, 4, 5].map((ele, index) => (<Skeleton key={index} animation="wave" height={100}/>))}
        </Container>

    else if (supplierError || taxonError || shoppingListError || restaurantError) content =
        <FetchingError action={() => window.location.reload()}/>
    else if (!selectedCategoryId) content =
        <MainCatalog totalProductsByCat={totalProductsByCat} availableCategories={availableCategories}
                     restaurantData={restaurantData} onSelectCategory={onSelectCategory} push={rest.push}
                     encodedRestaurantId={matchParams.restaurantId} encodedSupplierId={matchParams.supplierId}
        />
    else content = <CatalogProducts listProduct={listProduct} restaurant={restaurantData?.restaurant || null}
                                    supplier={supplierData?.supplier || {}}
                                    shoppingList={shoppingListData}
        />

    return (
        <div className={classes.container}>
            <div id={"go-back-btn"} style={selectedCategoryId ? {display: 'inline-block'} : {display: 'none'}}
                 className={classes.arrowBack}>
                <div style={{display: 'flex', alignItems: 'center'}} onClick={goToCatalog}>
                    <ArrowBackIosIcon/> <span style={{marginLeft: 10, fontWeight: 500}}>
                    <FormattedMessage id="catalog.backBtn"
                                      defaultMessage="Retour aux catégories"/>
                   </span>
                </div>
            </div>
            {content}
        </div>
    );
};

export default SupplierCatalog;
