import {connect} from 'react-redux';
import {createMatchSelector, push} from "connected-react-router";
import SupplierManagement from "../../components/Suppliers/SupplierManagement"



const mapStateToProps = (state) => {
    const matchSelector = createMatchSelector('/supplierManagement/suppliers/:supplierId');
    const match = matchSelector(state);
    return {
        supplierId: match.params.id,

    };
};
const mapDispatchToProps = (dispatch) => ({
});



const SupplierManagementPage = connect(mapStateToProps,mapDispatchToProps)(SupplierManagement);

export default SupplierManagementPage;
