import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage, useIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import SupplierMessage from "../SupplierMessage";
import UserMessage from "../UserMessage";
import ChatForm from "../ChatForm";
import {AutoSizer, CellMeasurer, CellMeasurerCache, List} from "react-virtualized";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import Typography from "@material-ui/core/Typography";
import FetchingError from "../../Errors/FetchingError";
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import GraphQlFetchData from "./graphQlFetchData";

const useStyles = makeStyles(theme => ({
    container: {
        padding: 10,
        maxHeight: '100%'
    },
    messagesBox: {
        overflowY: 'auto',
        flex: '1 1 auto',
        height: 'calc(100vh - 201px)',
    },
    messagesBoxReduced: {
        overflowY: 'auto',
        flex: '1 1 auto',
        height: 'calc(100vh - 280px)',
    },
    chatActions: {
        flex: '0 0 auto',
        paddingTop: 15,
        borderTop: '2px solid #f2f2f2',
    },
    picturePreview: {
        maxWidth: '100%',
        borderRadius: 10,
        maxHeight: 275
    },
    previewBox: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        zIndex: 1000,
        backgroundColor: '#fafafa',
        padding: '20px 20px 0px 0px',
        borderTop: '2px solid #f2f2f2'
    },
    deletePicture: {
        position: 'absolute',
        zIndex: 1200,
        right: 0,
        top: 0,
        size: '1.5rem',
    },
    deletePictureIcon: {
        fontSize: '2rem'
    },
    attachmentHeader: {
        marginBottom: 15
    },
    attachmentIcon: {
        marginLeft: 10,
        marginRight: 10,
    },
    icon: {
        fontSize: '5rem',
        marginBottom: 40
    },
    emptyText: {
        padding: 20,
    }
}));

//@TODO: fix chat form bar on page without modal
const ChatBox = ({updateOverlayTitle, setMessage, sendMessage, message, supplierId}) => {

    const classes = useStyles();
    const intl = useIntl();
    const decodedSupplierId = window.atob(supplierId);
    const supplierSegments = decodedSupplierId.split('/');
    const supplierTrueId = supplierSegments.pop();
    const pageTitle = intl.formatMessage(
        {
            id: 'chat.title',
            defaultMessage: 'Chat'
        }
    );
    const {
        supplierInfoError,
        supplierInfoLoading,
        messagesLoading,
        messagesData,
        messagesError, messageRefetch
    } = GraphQlFetchData({supplierTrueId, updateOverlayTitle, pageTitle, decodedSupplierId})

    let cache = new CellMeasurerCache({
        fixedWidth: true,
    });


    if (supplierInfoLoading) {
        updateOverlayTitle('isLoading');
    }


    const deletePicture = () => {
        setMessage({
            supplier: {id: supplierTrueId},
            message:
                {
                    text: message && message.text,
                    picture: null,
                    important: message && message.important,
                }
        });
        //We need to reset the form that wraps the input to clear it
        document.getElementById("pictureForm").reset();
    };

    //Used to update the scrollToIndex value to ensure the list is always showing the last message
    const [scrollToIndex, setScrollToIndex] = useState(0);

    const skeleton = [0, 1, 2, 3, 4, 5, 6];

    const refetchMessage = () => {
        messageRefetch().then(null).catch(e => null)
    };

    if (messagesError || supplierInfoError) {
        return (
            <FetchingError action={refetchMessage}/>);
    }

    return (
        <Grid container className={classes.container} wrap="nowrap" direction="column">
            <Grid item container
                  className={(message && message.text ? classes.messagesBoxReduced : classes.messagesBox)}>
                {message && message.picture ? (
                    <div className={classes.previewBox}>
                        <Grid container className={classes.attachmentHeader}>
                            <AttachmentOutlinedIcon className={classes.attachmentIcon}/>
                            <Typography>
                                <FormattedMessage id="chat.attachment"
                                                  defaultMessage="Pièce jointe"/>
                            </Typography>
                        </Grid>
                        <IconButton onClick={deletePicture} className={classes.deletePicture}>
                            <DeleteForeverOutlinedIcon color={"error"} className={classes.deletePictureIcon}/>
                        </IconButton>
                        <img id="preview" className={classes.picturePreview} alt={<FormattedMessage id="chat.attachment"
                                                                                                    defaultMessage="Pièce jointe"/>}
                             src={message.picture}/>
                    </div>
                ) : null}
                {messagesData ? (
                    <AutoSizer>
                        {({height, width}) => (
                            <List
                                onRowsRendered={() => setScrollToIndex(messagesData.getMessages.edges.length - 1)}
                                width={width}
                                height={height}
                                scrollToIndex={scrollToIndex}
                                rowCount={messagesData.getMessages.edges.length}
                                rowHeight={cache.rowHeight}
                                rowRenderer={({key, index, parent, isScrolling, isVisible, style}) => {
                                    return (
                                        <CellMeasurer
                                            cache={cache}
                                            columnIndex={0}
                                            key={key}
                                            parent={parent}
                                            rowIndex={index}
                                        >
                                            <Grid container item className={classes.innerGrid} key={key}
                                                  style={style}>
                                                {messagesData.getMessages.edges[index].node.sender.type === 'supplier' ? (
                                                    <SupplierMessage
                                                        message={messagesData.getMessages.edges[index].node}/>) : (
                                                    <UserMessage
                                                        message={messagesData.getMessages.edges[index].node}/>)}
                                            </Grid>
                                        </CellMeasurer>
                                    )
                                }}
                                overscanRowCount={10}
                            />)}
                    </AutoSizer>
                ) : (null)}
                {messagesData && messagesData.getMessages.edges.length === 0 ? (
                    <Grid item container direction="column" alignItems="center" justify="center">
                        <TextsmsOutlinedIcon className={classes.icon} color="primary"/>
                        <Typography className={classes.emptyText} align="center">
                            <FormattedMessage id="chat.emptyMessage"
                                              defaultMessage="Cet espace vous permet de communiquer avec ce fournisseur, vous pouvez lui faire part de vos remarques ainsi que lui envoyer des photos si besoin."/>
                        </Typography>
                    </Grid>
                ) : (null)}
                {messagesLoading ? (
                    <>
                        {skeleton.map((item, key) => {
                            return (
                                <Grid container item className={classes.innerGrid} key={key}>
                                    {key % 2 === 1 ? <SupplierMessage/> : <UserMessage/>}
                                </Grid>
                            )
                        })}
                    </>
                ) : null}
            </Grid>
            <Grid item container className={classes.chatActions}>
                <ChatForm setMessage={setMessage} sendMessage={sendMessage} message={message} supplierId={supplierId}
                          refetchMessages={refetchMessage}/>
            </Grid>
        </Grid>
    );
};

export default ChatBox;
