import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: '0px 8px 7px -1px rgba(180,201,219,0.2), 0px 0px 10px 1px rgba(180,201,219,0.1), 0px 1px 3px 1px rgba(180,201,219,0.1)',
    }
}));

const GeneralPurposeCard = (props) => {
    const classes = useStyles();

    return (
        <Card className={`${classes.root} ${props.className}`}>
            {props.children}
        </Card>
    );
};

export default GeneralPurposeCard;
