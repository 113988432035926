import {connect} from 'react-redux';
import CreateNewProductForm from "../../components/Products/CreateNewProductForm";
import {updateOverlayTitle} from "../../reducers/overlay";
import {createMatchSelector, goBack} from "connected-react-router";
import {triggerToastNotification} from "../../reducers/toastNotification";

//@TODO: Maybe create a selector
const mapStateToProps = state => {
    const matchSelector = createMatchSelector('/restaurants/:id/suppliers/:supplierId/add-product/:shoppingListId');
    const match = matchSelector(state);
    return {
        restaurantId: match.params.id,
        supplierId: match.params.supplierId,
        shoppingListId: match.params.shoppingListId
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateOverlayTitle: (title) => dispatch(updateOverlayTitle(title)),
    triggerNotification: (payload) => dispatch(triggerToastNotification(payload)),
    goBack: () => dispatch(goBack()),
});

const AddProduct = connect(mapStateToProps, mapDispatchToProps)(CreateNewProductForm);

export default AddProduct;
