import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {createMatchSelector, goBack} from "connected-react-router";
import {updateOverlayTitle} from "../../reducers/overlay";
import {triggerToastNotification} from "../../reducers/toastNotification";
import {denyAccessToUnauthenticatedUser} from "../../reducers/authentication";
import EditSupplierForm from "../../components/Suppliers/EditSupplier";

const mapStateToProps = (state) => {
    const matchSelector = createMatchSelector('/suppliers/:supplierId/edit');
    const match = matchSelector(state);
    return {
        supplierId: match.params.supplierId,

    };
};

const mapDispatchToProps = (dispatch) => ({
    updateOverlayTitle: (title) => dispatch(updateOverlayTitle(title)),
    goBack: bindActionCreators(goBack, dispatch),
    triggerNotification: bindActionCreators(triggerToastNotification, dispatch),
    denyAccessToUnauthenticatedUser: () => dispatch(denyAccessToUnauthenticatedUser()),
});

const EditSupplier = connect(mapStateToProps, mapDispatchToProps)(EditSupplierForm);

export default EditSupplier;
