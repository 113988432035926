import {connect} from 'react-redux';
import CartSummary from "../../components/Cart/CartSummary";
import {updateOverlayTitle} from "../../reducers/overlay";
import {bindActionCreators} from "redux";
import {
    addProductToCart,
    setProductQuantity,
    subtractProductFromCart,
    setCurrentCartId,
    setProductNote,
} from "../../reducers/carts";
import {createMatchSelector, push} from "connected-react-router";

const mapStateToProps = state => {
    const matchSelector = createMatchSelector('/restaurants/:id/suppliers/:supplierId/cart-summary/:cartId');
    const match = matchSelector(state);

    return {
        cartId: parseInt(match.params.cartId),
        restaurantId: match.params.id,
        supplierId: match.params.supplierId,
        userType: state.authentication.userType,
        locale: state.authentication.language
    };
};

const mapDispatchToProps = (dispatch) => ({
    setProductQuantity: bindActionCreators(setProductQuantity, dispatch),
    updateOverlayTitle: (title) => dispatch(updateOverlayTitle(title)),
    addProductToCart: bindActionCreators(addProductToCart, dispatch),
    subtractProductFromCart: bindActionCreators(subtractProductFromCart, dispatch),
    push: bindActionCreators(push, dispatch),
    setCurrentCartId: (payload) => dispatch(setCurrentCartId(payload)),
    setProductNote: bindActionCreators(setProductNote, dispatch),
});

const CartSummaryPage = connect(mapStateToProps, mapDispatchToProps)(CartSummary);

export default CartSummaryPage;
