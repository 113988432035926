import {useIntl} from 'react-intl';

export function Hooks() {
    const pageTitle = useIntl().formatMessage(
        {
            id: 'editSupplierPage.title',
            defaultMessage: 'Editer un fournisseur'
        }
    );


    const notificationText = {
        success: useIntl().formatMessage(
            {
                id: 'editSupplierPage.SuccessNotificationText',
                defaultMessage: 'Fournisseur édité avec succès'
            }
        ),
        error: useIntl().formatMessage(
            {
                id: 'editSupplierPage.errorNotificationText',
                defaultMessage: 'Une erreur est survenue lors de la création de l\'édition du fournisseur.'
            }
        ),
    };
    return ({
        notificationText, pageTitle
    })
}
