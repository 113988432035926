import {call, takeEvery} from 'redux-saga/effects';
import {sendMessageToSupport} from "../reducers/support";

function* sendMessage(action) {
    try {
        yield call(sendEmailToSupport(action))
    } catch (e) {
    }
}

function sendEmailToSupport() {

}

sendEmailToSupport();

export function* watchSendMessage() {
    yield takeEvery(sendMessageToSupport, sendMessage)
}
