import {connect} from 'react-redux';
import CatalogPage from "../../components/Suppliers/Catalog";
import {bindActionCreators} from "redux";
import {createMatchSelector, push} from "connected-react-router";
import {denyAccessToUnauthenticatedUser, setBearerToken} from "../../reducers/authentication";
import {updateSubtitle, updateTitle} from "../../reducers/topNavigation";


const mapStateToProps = (state) => {
    const matchSelector = createMatchSelector('/restaurants/:restaurantId/suppliers/:supplierId/catalog');
    const match = matchSelector(state);
    return {
        matchParams: match && match.params,
        userType: state.authentication.userType,
        locale: state.authentication.language,
        query: state.router.location.query
    };
};

const mapDispatchToProps = (dispatch) => ({

    push: bindActionCreators(push, dispatch),
    setBearerToken: (payload) => dispatch(setBearerToken(payload)),
    updateTitle: (payload) => dispatch(updateTitle(payload)),
    updateSubtitle: (payload) => dispatch(updateSubtitle(payload)),
    denyAccessToUnauthenticatedUser: () => dispatch(denyAccessToUnauthenticatedUser())


});

const SuppliersPage = connect(mapStateToProps, mapDispatchToProps)(CatalogPage);

export default SuppliersPage;
