import {connect} from 'react-redux';
import AddSupplierList from "../../components/Suppliers/AddSupplierList";
import {bindActionCreators} from "redux";
import {createMatchSelector, push} from "connected-react-router";
import {updateOverlayTitle} from "../../reducers/overlay";
import {triggerToastNotification} from "../../reducers/toastNotification";
import {denyAccessToUnauthenticatedUser} from "../../reducers/authentication";

const mapStateToProps = (state) => {
    const matchSelector = createMatchSelector('/restaurants/:id/suppliers/add');
    const match = matchSelector(state);
    return {
        restaurantId: match.params.id
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateOverlayTitle: (title) => dispatch(updateOverlayTitle(title)),
    push: bindActionCreators(push, dispatch),
    triggerNotification: bindActionCreators(triggerToastNotification, dispatch),
    denyAccessToUnauthenticatedUser: () => dispatch(denyAccessToUnauthenticatedUser()),
});

const AddSupplier = connect(mapStateToProps, mapDispatchToProps)(AddSupplierList);

export default AddSupplier;
