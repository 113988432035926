import {useIntl} from 'react-intl';
import * as Yup from "yup";

export function Hooks(){
    const pageTitle = useIntl().formatMessage(
        {
            id: 'shippingDetailsForm.title',
            defaultMessage: 'Nous contacter'
        }
    );
    const subjectTexts = {
        label: useIntl().formatMessage({
            id: 'contactForm.subjectLabel',
            defaultMessage: 'Sujet',
        }),
        placeholder: useIntl().formatMessage({
            id: 'contactForm.subjectPlaceholder',
            defaultMessage: 'Entrez le sujet de votre message'
        }),
        helperText: useIntl().formatMessage({
            id: 'contactForm.subjectHelperText',
            defaultMessage: '255 caractères Max.'
        }),
        errorToLong: useIntl().formatMessage({
            id: 'contactForm.subjectErrorToLong',
            defaultMessage: 'Le sujet ne doit pas dépasser 255 caractères.'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'contactForm.subjectErrorRequired',
            defaultMessage: 'Le sujet est obligatoire.'
        }),
    };

    const messageTexts = {
        label: useIntl().formatMessage({
            id: 'contactForm.messageLabel',
            defaultMessage: 'Votre message',
        }),
        placeholder: useIntl().formatMessage({
            id: 'contactForm.messagePlaceholder',
            defaultMessage: 'Rédigez votre message'
        }),
        helperText: useIntl().formatMessage({
            id: 'contactForm.messageHelperText',
            defaultMessage: '512 caractères Max.'
        }),
        errorToLong: useIntl().formatMessage({
            id: 'contactForm.messageErrorToLong',
            defaultMessage: 'Le message ne doit pas dépasser 4048 caractères.'
        }),
        errorRequired: useIntl().formatMessage({
            id: 'contactForm.messageErrorRequired',
            defaultMessage: 'Le message est obligatoire.'
        }),
    };
    const formValidate = {
        subject: Yup.string()
            .required(subjectTexts.errorRequired)
            .max(255, subjectTexts.errorToLong),
        message: Yup.string()
            .required(messageTexts.errorRequired)
            .max(4048, messageTexts.errorToLong),
    }
    return({
        pageTitle, subjectTexts, messageTexts, formValidate
    })
}