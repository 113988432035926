import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {FormattedMessage, useIntl} from "react-intl";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import amplitudeClient from "../../../amplitudeWrapper";

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import GraphQlFetchData from "../graphQlFetchData";

const useStyles = makeStyles(theme => ({
    supplierName: {
        textTransform: 'uppercase',
        backgroundColor: theme.palette.grey[50],
        padding: 10,
        borderRadius: 5,
        color: theme.palette.grey[700],
        marginTop: 15
    },
    title: {
        fontSize: '1rem',
        fontWeight: '500',
        color: 'white',
        backgroundColor: theme.palette.warning.main
    },
    headingIcon: {
        marginBottom: 5
    },
    text: {
        marginTop: 10
    },
}));

const RemoveProductFromListConfirmDialog = ({openState, handleClose, triggerNotification, restaurantId, supplierId, product, shoppingListId, shoppingListItemId}) => {
    const classes = useStyles();
    const intl = useIntl();

    const {removeProductFromList, mutationLoading} = GraphQlFetchData({supplierId, restaurantId})

    const notificationText = {
        success: intl.formatMessage(
            {
                id: 'removeProduct.SuccessNotificationText',
                defaultMessage: 'Produit retiré avec succès'
            }
        ),
        error: intl.formatMessage(
            {
                id: 'removeProduct.errorNotificationText',
                defaultMessage: 'Une erreur est survenue lors de la suppression du produit'
            }
        ),
    };

    const removeProduct = () => {
        if (!mutationLoading) {
            removeProductFromList({variables: {id: shoppingListId, itemIds: [shoppingListItemId]}})
                .then(() => {
                    amplitudeClient.logEvent('product removed from shopping list');
                    triggerNotification({variant: 'success', message: notificationText.success});
                    handleClose();
                })
                .catch(e => triggerNotification({variant: 'error', message: notificationText.error}))
        }
    };

    return (
        <Dialog
            open={openState}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" align="center" className={classes.title} disableTypography>
                <Grid container justify="center" direction="column" alignItems="center">
                    <DeleteOutlineOutlinedIcon className={classes.headingIcon}/>
                    <FormattedMessage id="removeProduct.heading"
                                      defaultMessage="Retirer un produit"/>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Typography align="center" className={classes.text}>
                    <FormattedMessage id="removeProduct.text"
                                      defaultMessage="Souhaitez-vous vraiment retirer le produit suivant de votre list d'achat ?"/>
                </Typography>
                <Typography className={classes.supplierName} align="center">
                    {product.name}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={removeProduct} color="secondary" autoFocus disabled={mutationLoading}>
                    <FormattedMessage id="removeProduct.yes" defaultMessage="Oui"/>
                </Button>
                <Button onClick={handleClose} color="secondary">
                    <FormattedMessage id="removeProduct.no" defaultMessage="Non"/>
                </Button>
            </DialogActions>
            {mutationLoading ? <LinearProgress/> : null}
        </Dialog>
    )
};

export default RemoveProductFromListConfirmDialog;
