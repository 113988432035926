import {connect} from 'react-redux';
import Overlay from "../../components/Overlay";
import {goBack} from 'connected-react-router';

const mapStateToProps = state => ({
    keepOpen: state.router.location.state.keepOpen,
    fullHeight: state.router.location.state.fullHeight,
    title: state.overlay.title,
    subtitle: state.overlay.subtitle,
    isLoadingTitle: state.overlay.isLoadingTitle
});

const mapDispatchToProps = (dispatch) => ({
    goBack: () => dispatch(goBack()),
});

const OverlayPage = connect(mapStateToProps, mapDispatchToProps)(Overlay);

export default OverlayPage;