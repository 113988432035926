import {GET_RESTAURANT_SUPPLIERS_QUERY} from "../SuppliersList/queries";
import {GET_SUPPLIER_PRODUCTS_QUERY} from "../SupplierShoppingList/queries";
import {useQuery} from "@apollo/react-hooks";


const GraphQlFetchData = ({
                              supplier,
                              restaurant,

                          }) => {

    const {loading, error, data, refetch} = useQuery(GET_RESTAURANT_SUPPLIERS_QUERY, {
        variables: {
            restaurantId: restaurant._id,
            offset: 0
        }
    });

    const {
        loading: productloading,
        error: producterror,
        data: productdata,
        refetch: productrefrech
    } = useQuery(GET_SUPPLIER_PRODUCTS_QUERY, {
        variables: {
            restaurantId: restaurant._id,
            supplierId: supplier._id,
            limit: 2000,
            offset: 0
        },
        //pollInterval: 120000,
        fetchPolicy: 'no-cache'
    });


    /*    const {
            loading: categoriesLoading,
            error: categoriesError,
            data: categoriesData,
            refetch: categoriesRefresh
        } = useQuery(GET_TOP_CATEGORIES_QUERY, {
            variables: {
                locale: restaurant.locale,
            }
        });*/


    return (
        {data, refetch, error, loading, productdata}
    );
};

export default GraphQlFetchData;
