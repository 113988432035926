import {gql} from "apollo-boost";

export const ADD_RESTAURANT_MUTATION = gql`
    mutation AddRestaurant(
        $name: String!,
        $street: String!, 
        $zip: String!, 
        $city: String!, 
        $country: String!
        $cuisineType: Int!
        $preferredDeliveryTime: String!
    ) {
        createRestaurant(
            input:{
                name: $name,
                street: $street,
                zipCode: $zip,
                city: $city,
                countryCode: $country,
                cuisineType: $cuisineType,
                preferredDeliveryTime: $preferredDeliveryTime,
            }
        ) {
            restaurant{
                _id
                id
                name
                locale
            }
        }
    }
`;
