import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {updateTitle} from "../../reducers/topNavigation";
import {push} from "connected-react-router";
import ChangePasswordForm from "../../components/UserAccount/ChangePasswordForm";

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
    updateTopNavTitle: (title) => dispatch(updateTitle(title)),
    push: bindActionCreators(push, dispatch),
});

const ChangePasswordPage = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);

export default ChangePasswordPage;
