import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {FormattedMessage, useIntl} from "react-intl";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from "@material-ui/core/Typography";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Grid from "@material-ui/core/Grid";
import amplitudeClient from "../../../amplitudeWrapper";
import GraphQlFetchData from "./graphQlFetchData";

const useStyles = makeStyles(theme => ({
    supplierName: {
        textTransform: 'uppercase',
        backgroundColor: theme.palette.grey[50],
        padding: 10,
        borderRadius: 5,
        color: theme.palette.grey[700],
        marginTop: 15
    },
    title: {
        fontSize: '1rem',
        fontWeight: '500',
        color: 'white',
        backgroundColor: theme.palette.primary.main
    },
    headingIcon: {
        marginBottom: 5
    },
    text: {
        marginTop: 10
    }
}));

const AddSupplierConfirmDialog = ({supplierName, supplierId, openState, handleClose, triggerNotification, restaurantId}) => {
    const classes = useStyles();
    const intl = useIntl();

    const {addSupplierMutation, mutationLoading} = GraphQlFetchData({restaurantId})
    const notificationText = {
        success: intl.formatMessage(
            {
                id: 'AddSupplierConfirmDialog.SuccessNotificationText',
                defaultMessage: 'Fournisseur ajouté avec succès'
            }
        ),
        error: intl.formatMessage(
            {
                id: 'AddSupplierConfirmDialog.errorNotificationText',
                defaultMessage: 'Une erreur est survenue lors de l\'ajout du fournisseur'
            }
        ),
    };

    const addSupplier = () => {
        addSupplierMutation({variables: {supplierId: supplierId}})
            .then(() => {
                amplitudeClient.logEvent('supplier added');
                triggerNotification({variant: 'success', message: notificationText.success});
                handleClose();
            })
            .catch(e => triggerNotification({variant: 'error', message: notificationText.error}))
    };

    return (
        <Dialog
            open={openState}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" align="center" className={classes.title} disableTypography>
                <Grid container justify="center">
                    <LocalShippingIcon className={classes.headingIcon}/>
                    <FormattedMessage id="AddSupplierConfirmDialog.heading"
                                      defaultMessage="Ajouter un nouveau fournisseur"/>
                </Grid>
            </DialogTitle>
            <DialogContent>
                    <Typography align="center" className={classes.text}>
                        <FormattedMessage id="AddSupplierConfirmDialog.text"
                                          defaultMessage="Souhaitez-vous ajouter le fournisseur suivant ?"/>
                    </Typography>
                    <Typography className={classes.supplierName} align="center">
                        {supplierName}
                    </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={addSupplier} color="secondary" autoFocus>
                    <FormattedMessage id="AddSupplierConfirmDialog.yes" defaultMessage="Oui"/>
                </Button>
                <Button onClick={handleClose} color="secondary">
                    <FormattedMessage id="AddSupplierConfirmDialog.no" defaultMessage="Non"/>
                </Button>
            </DialogActions>
            {mutationLoading ? <LinearProgress /> : null}
        </Dialog>
    )
};

export default AddSupplierConfirmDialog;
